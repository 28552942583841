import React from 'react';

import '../../css/react-day-picker.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

const FORMAT = 'yyyy/MM/dd';

export default class DatePicker extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        disabledDays: [],
    }

    componentDidMount(){
        var startDate = new Date(1901,0,1);
        var endDate = new Date(2050,11,31);

        if(this.props.startDate !== undefined){
            startDate = this.props.startDate;
        }

        if(this.props.endDate !== undefined){
            endDate = this.props.endDate;
        }

        this.setState({startDate: startDate, 
            endDate:endDate,
        });

        /*if(this.props.after !== undefined && this.props.before !== undefined){
            var dateAfter = this.parseDate(this.props.before, FORMAT, null);
            var dateBefore = this.parseDate(this.props.after, FORMAT, null);
            this.setState({after: dateAfter, before: dateBefore});
        } else {
            this.setState({after: new Date(2050,11,31) , before: new Date(1901,0,1)});
        }*/

        if(this.props.disabledDays !== undefined){
            this.setState({disabledDays: this.props.disabledDays});
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.startDate !== undefined && this.props.startDate !== undefined){
            if (prevProps.startDate !== this.props.startDate){
                this.setState({startDate: this.props.startDate});
            }
        }

        if(prevProps.endDate !== undefined && this.props.endDate !== undefined){
            if (prevProps.endDate !== this.props.endDate){
                this.setState({endDate: this.props.endDate});
            }
        }
    }

    parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
      }
      
    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    } 
    
    render() {
        var disabled = false;
        if (this.props.disabled !== undefined){
            disabled = this.props.disabled;
        }

        var readOnly = false;
        if (this.props.readOnly !== undefined){
            readOnly = this.props.readOnly;
        }

        return(
            <div>
                <DayPickerInput
                inputProps={{id: this.props.id,className: this.props.className, readOnly: readOnly, disabled: disabled}} 
                dayPickerProps={{disabledDays: [{daysOfWeek: this.state.disabledDays}, 
                {after: this.state.endDate, before: this.state.startDate},
                this.props.isHoliday]}}
                value={this.props.value} onDayChange={this.props.onDayChange} formatDate={this.formatDate.bind(this)} format={FORMAT} parseDate={this.parseDate.bind(this)} placeholder={"Select a date..."}></DayPickerInput>
            </div>
        );
    }
}