import React from 'react';
import * as mime from 'mime-types';

import {getAttachments, getAttachment, deleteE2EAttachment} from '../../networking/NetworkingAttachments';
import NewDynamicTable from '../shared/NewDynamicTable';
import AttachmentsForm from './AttachmentsForm';
import AlertBox from './AlertBox'

import {getStyle} from '../../styles/styles';
var style = getStyle();

//props
//linkType
//linkID
export default class AttachmentsTable extends React.Component {
    
    state = {
        listAttachments: [],
        openForm: false,
        showDeleteAlert: false,
        readyToDeleteAttachment: {}
    }

    deleteAttachmentClick(obj){
        this.setState({showDeleteAlert: true, readyToDeleteAttachment: obj});
    }

    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDeleteAttachment: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert(){
        deleteE2EAttachment(this.state.readyToDeleteAttachment.ID).then((value)=>{
            this.setState({showDeleteAlert: false,});
            this.loadAttachments();
        });
    }

    componentDidMount(){
        this.loadAttachments();
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.linkID !== this.props.linkID){
            this.loadAttachments();
        }
    }
    
    loadAttachments(){
        if(this.props.linkID !== undefined && this.props.linkID !== null){
            getAttachments(this.props.linkType, this.props.linkID).then(
                (json) => {
                    if (json.attachments !== undefined){
                        console.group('Attachments');
                        console.table(json.attachments);
                        var newAttachments = this.transformAttachmentsList(json.attachments);
                        this.setState({listAttachments: newAttachments});
                        console.groupEnd();
                    } else {
                        this.setState({listAttachments: []});
                    }
                }
            );
        } else {
            this.setState({listAttachments: []});
        }
    }

    transformAttachmentsList(attachments){
        for(var i=0; i<attachments.length; i++){
            Object.defineProperty(attachments[i], "Uploaded On", Object.getOwnPropertyDescriptor(attachments[i], "UploadedOn"));
            delete attachments[i]["UploadedOn"];
        }
        return attachments;
    }

    handleAttachmentClick(obj){
        getAttachment(obj.ID).then((value)=>{
            console.log(value);
            var byteCharacters = atob(value.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var bytes = new Uint8Array(byteNumbers);
            var blob = new Blob([bytes],{type: mime.lookup(obj.Filename)});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = obj.Filename;
            link.click();
        });
    }

    onAddAttachmentButtonClick(){
        this.setState({openForm: true});
    }

    onCloseForm(){
        this.setState({openForm: false});
        this.loadAttachments();
    }

    render(){
        if(this.props.linkID !== undefined && this.props.linkID !== null){
            return(
                <div style={{paddingRight: '40px'}}>
                    <h6 className="uk-heading-divider">Attachments</h6>
                    <button disabled={true} className="uk-button uk-button-default" type="button" onClick={this.onAddAttachmentButtonClick.bind(this)}>Add Attachment</button>
                    <NewDynamicTable hiddenHeaders={['ID','UpdateToken']} data={this.state.listAttachments} enableClick={true} clickFunction={this.handleAttachmentClick.bind(this)}  newSearch={true} useDeleteButton={false} deleteFunction={this.deleteAttachmentClick.bind(this)}/>
                    <AttachmentsForm linkType={this.props.linkType} linkID={this.props.linkID} open={this.state.openForm} onClose={this.onCloseForm.bind(this)}/>
                    <AlertBox id="delete-attachment" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this attachment?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>
                </div>);
        } else {
            return(
                <div>
                    <h5 style={style.alignCenter}>Please, first save the {this.props.linkType}.</h5>
                </div>
            );
        }
    }
}