import React from 'react';
import {getStyle} from '../../styles/styles';

import {getEntityTasks, updateEntityTask, completeEntityTask} from '../../networking/NetworkingEntityTasks';
import {getLookUpTable} from '../../networking/Networking';
import {titleCase} from '../../helpers/FormatHelper';

import {TaskLinkType} from '../../constants/LinkTypeNames';

import AttachmentsTable from './AttachmentsTable';
import DatePicker from './DatePicker';
import DynamicSelect from './DynamicSelect';
import { isThisSecond } from 'date-fns';

import PlusIcon from '../../css/custom-icons/plus.svg';
import MinusIcon from '../../css/custom-icons/minus.svg';

var style = getStyle();
var counter = 1;
var helperCounter = 1;
var lengthListOfTasks = 0;

class TaskRow extends React.Component {
    state = {
        completed: false,
        completedBy: '',
        completedOn: '',
        deadlineDate: '',
        expiryDate: '',
        numberOfAttachments: 0,
        requestCustomer: false,
        title: '',
        linkID: '',
        taskDefID: '',
        attachmentsID: '',
        toggle: '',
        tooltip: '',
    }

    componentDidMount(){
        var entityTask = this.props.entityTask;
        var attachmentsID = 'attachments-' + entityTask.LinkID;
        var toggle = 'target: #'+ attachmentsID + '; animation: uk-animation-slide-top-small';
        var attachmentsText = 'No Attachments'
        if(entityTask.NoOfAttachments > 0){
            attachmentsText = entityTask.NoOfAttachments + " Attachment";
            if(entityTask.NoOfAttachments > 1){
                attachmentsText = entityTask.NoOfAttachments + " Attachments";
            }
        }
        var requestCustomer = false;
        if(entityTask['Request Customer'] !== ''){
            requestCustomer = entityTask['Request Customer'];
        }
        this.setState({
            completed: entityTask.Completed,
            completedBy: entityTask.CompletedBy,
            completedOn: entityTask.CompletedOn,
            deadlineDate: entityTask.DeadlineDate,
            expiryDate: entityTask.ExpiryDate,
            numberOfAttachments: entityTask.NoOfAttachments,
            requestCustomer: requestCustomer,
            title: entityTask.Title,
            linkID: entityTask.LinkID,
            taskDefID: entityTask.taskdefid,
            attachmentsID: attachmentsID,
            toggle: toggle,
            tooltip: attachmentsText,
        }, ()=> this.updateTask());
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityTask !== this.props.entityTask){
            var entityTask = this.props.entityTask;
            var attachmentsID = 'attachments-' + entityTask.LinkID;
            var toggle = 'target: #'+ attachmentsID + '; animation: uk-animation-slide-top-small';
            var attachmentsText = 'No Attachments'
            if(entityTask.NoOfAttachments > 0){
                attachmentsText = entityTask.NoOfAttachments + " Attachment";
                if(entityTask.NoOfAttachments > 1){
                    attachmentsText = entityTask.NoOfAttachments + " Attachments";
                }
            }
            var requestCustomer = false;
            if(entityTask['Request Customer'] !== ''){
                requestCustomer = entityTask['Request Customer'];
            }
            var completedBy = '';
            if(entityTask.Completed){
                completedBy = entityTask.CompletedBy;
            }
            this.setState({
                completed: entityTask.Completed,
                completedBy: completedBy,
                completedOn: entityTask.CompletedOn,
                deadlineDate: entityTask.DeadlineDate,
                expiryDate: entityTask.ExpiryDate,
                numberOfAttachments: entityTask.NoOfAttachments,
                requestCustomer: requestCustomer,
                title: entityTask.Title,
                linkID: entityTask.LinkID,
                taskDefID: entityTask.taskdefid,
                attachmentsID: attachmentsID,
                toggle: toggle,
                tooltip: attachmentsText,
            }, ()=> this.updateTask());
        }
    }

    handleUpdateDeadlineDate(date){
        this.setState({deadlineDate: date},() => this.updateTask());
    }

    handleUpdateExpiryDate(date){
        this.setState({expiryDate: date},() => this.updateTask());
    }

    handleCompleteTask(event){
        this.setState({completed: event.target.checked}, () => this.updateTask());
    }

    updateTask(){
        let userID = sessionStorage.getItem('UserID');
        var entityTask = this.props.entityTask;
        var objectToUpdate = {};
        objectToUpdate.Completed = this.state.completed;
        if(objectToUpdate.Completed){
            objectToUpdate.CompletedBy = userID;
        }
        objectToUpdate.UpdatedBy = userID;
        objectToUpdate.Status = entityTask.Status;
        objectToUpdate.DeadlineDate = this.state.deadlineDate;
        objectToUpdate.ExpiryDate = this.state.expiryDate;
        objectToUpdate.EntityTaskId = entityTask.LinkID;
        objectToUpdate.LinkID = this.state.linkID;
        objectToUpdate.UpdateToken = entityTask.UpdateToken;
        objectToUpdate['GroupBy'] = entityTask['GroupBy'];

        this.props.getTask(objectToUpdate);
    }

    render() {
        let newCompleted = this.state.completedBy + ' on ' + this.state.completedOn;
        return (<div className="uk-grid">
                    <div className="uk-width-6-6"><p style={{fontSize: '14px'}}>{this.state.title}</p></div>
                    <div className="uk-width-1-6">
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-deadline">Deadline Date</label>
                            <div className="uk-form-controls">
                                <DatePicker disabled={true} onDayChange={this.handleUpdateDeadlineDate.bind(this)} id="form-entity-task-deadline" key="form-entity-task-deadline" className="uk-input" value={this.state.deadlineDate}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-6">
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-expiry">Expiry Date</label>
                            <div className="uk-form-controls">
                                <DatePicker disabled={true} onDayChange={this.handleUpdateExpiryDate.bind(this)} id="form-entity-task-expiry" key="form-entity-task-expiry" className="uk-input" value={this.state.expiryDate}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-6 uk-margin"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-complete" ><input disabled={true} id="form-entity-task-complete" className="uk-checkbox" type="checkbox" checked={this.state.completed} value={this.state.completed} onChange={this.handleCompleteTask.bind(this)}/> Complete</label>                            
                        </div>
                    </div>
                    <div className="uk-width-1-6 uk-margin"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-request" ><input disabled={true} id="form-entity-task-request" className="uk-checkbox" type="checkbox" checked={this.state.requestCustomer} value={this.state.requestCustomer} /*checked={this.state.contact.isMainContact} value={this.state.contact.isMainContact} onChange={this.handleOnCheckMainContact.bind(this)}*//>Request Customer</label>
                            
                        </div>
                    </div>
                    <div className="uk-width-1-6"> 
                        <div className="uk-margin">
                            <label className="uk-form-label-properties-task" htmlFor="form-entity-task-completed-by" >Completed</label>
                            <div className="uk-form-controls">                                
                                <input disabled={true} key="form-entity-task-completed-by" className="uk-input" id="form-entity-task-completed-by" type="text" placeholder="Completed" value={newCompleted} readOnly/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-6" style={{paddingTop: '25px'}}> 
                        <i className="fa fa-fw fa-paperclip uk-align-center" uk-tooltip={this.state.tooltip} uk-toggle={this.state.toggle} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer' }} />
                    </div>
                    <div className="uk-width-1-1 uk-margin" id={this.state.attachmentsID} hidden>
                        <AttachmentsTable linkType={TaskLinkType} linkID={this.state.linkID} />
                    </div>
                </div>);
    }
}

export default class EntityTasks extends React.Component {
    state ={
        listCategories: [],
        filteredCategories: [],
        originalTasks: [],
        finalListEntityTasks: [],
        listEntityTasks: [],
        filteredListEntityTasks: [],
        filterScreen: false,
        loading: false,
        filtering: false,
        nameFilter: '',
        completionFilter: '', 
        completedStatusFilter: [],
        selectedStatusFilter: {name: 'All', value: 'All'},
    }
    
    componentDidMount(){
        getLookUpTable('Completed Task Filter').then((value)=>{
            let helper = [];
            console.log(value);
            let completedTasksFilterOptions = value.lookUpTables[0].lookUpTableDetails;
            for (let i = 0; i < completedTasksFilterOptions.length; i++) {
                const element = completedTasksFilterOptions[i];
                console.log(element);
                helper.push({value: element.LookUpTableDetail, name: element.LookUpTableDetail});
            }
            
            this.setState({completedStatusFilter: helper});
        });
        this.getTasks();
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityID !== this.props.entityID){            
            this.getTasks();
        }

        if(prevProps.runSaveProcess !== this.props.runSaveProcess && this.props.runSaveProcess){
            this.saveTasks();
        }
    }

    getTasks(){
        getEntityTasks(this.props.entityID, this.props.entityType).then(
            (value) => {
                console.group('EntityTasks');
                console.table(value.taskDefs);
                console.groupEnd();
                if(value.taskDefs !== undefined){
                    this.setState({originalTasks: value.taskDefs, finalListEntityTasks: value.taskDefs, listEntityTasks: value.taskDefs, filteredListEntityTasks: value.taskDefs});
                    this.getCategories(value.taskDefs);
                }
            }
        );
    }

    getCategories(json){
        var helper = [];
        var categoryName = '';
        for(var i=0; i<json.length; i++){
            if(json[i]['GroupBy'] !== categoryName){
                helper.push(json[i]['GroupBy']);
                categoryName = json[i]['GroupBy'];
            }
        }
        this.setState({listCategories: helper.reverse()});
    }

    getTask(value){
        console.log(value);
        var listOfTasks = [...this.state.finalListEntityTasks];
        for(var i=0; i<this.state.finalListEntityTasks.length; i++){
            if(this.state.finalListEntityTasks[i].LinkID === value.LinkID){
                listOfTasks.push(value);
            } else {
                listOfTasks.push(this.state.finalListEntityTasks[i]);
            }
            
        };
        this.setState({finalListEntityTasks: listOfTasks});
        console.log(listOfTasks);
    }

    saveTasks(){
        var listOfTasks = this.state.finalListEntityTasks;
        //var originalTasks = this.state.originalTasks;
        console.log(listOfTasks);
        counter = 1;
        helperCounter = 1;
        lengthListOfTasks = listOfTasks.length;
            //let userID = sessionStorage.getItem('UserID');
            for(var i = 0; i<listOfTasks.length; i++){
                // eslint-disable-next-line no-loop-func
                updateEntityTask(listOfTasks[i]).then((value)=>{
                    counter++;
                    if(helperCounter === lengthListOfTasks && counter === lengthListOfTasks){
                        this.getTasks();
                        this.props.finishSaveProcess();
                    }
                });
                // eslint-disable-next-line no-loop-func
                completeEntityTask(listOfTasks[i]).then((res)=>{
                    helperCounter++;
                    if(helperCounter === lengthListOfTasks && counter === lengthListOfTasks){
                        this.getTasks();
                        this.props.finishSaveProcess();
                    }
                });
                

               // eslint-disable-next-line no-loop-func
                /*updateEntityTask(listOfTasks[counter-1]).then((value)=>{
                    console.log(listOfTasks[counter-1]);
                    debugger 
                    completeEntityTask(listOfTasks[counter-1]).then((res) => {
                    if (counter === lengthListOfTasks){
                        this.getTasks();
                        this.props.finishSaveProcess();
                    } else {
                        counter++;
                    }
                    })
               })*/
            }

    }

    onChangeNameFilter = (event) => {
        this.setState({nameFilter: event.target.value},()=>{
            this.runFilter();
        });
    }

    onChangeCompletedStatusFilter = (objValue) => {
        let helper = '';
        switch (objValue.value) {
            case 'All':
                helper = '';
                break;
            case 'Completed':
                helper = true;
                break;
            case 'Not Completed':
                helper = false;
                break;
            default:
                break;
        }
        
        this.setState({completionFilter: helper},()=>{
            this.runFilter();
        });
    }

    runFilter = () => {
        let newList = [];
        let filterList = this.state.listEntityTasks;
        let name = this.state.nameFilter;
        let complete = this.state.completionFilter;

        if (name === '' && complete === ''){
            this.setState({filteredListEntityTasks: this.state.listEntityTasks});
            this.getCategories(this.state.listEntityTasks);
            return;
        }

        filterList.forEach(element => {
            if(element.Title.toLowerCase().includes(name.toLowerCase()) && (element.Completed === complete || complete === '') ) {
                newList.push(element);
            }
        });
        this.setState({filteredListEntityTasks: newList});
        this.getCategories(newList);
    }

    onFilterButtonClick = () => {
        if(this.state.filtering){
            this.setState({filtering: false, nameFilter: '', contentFilter: ''},()=>{this.runFilter()});
        } else {
            this.setState({filtering: true});
            this.runFilter();
        }
    }

    render(){
        var fullScreen = false;

        if(this.props.fullScreen !== undefined){
            fullScreen = this.props.fullScreen;
        }

        var filterIcon = "fa fa-fw fa-filter uk-align-right";
        if (this.state.filtering){
            filterIcon = "fa fa-fw fa-ban uk-align-right";
        }

        var id = "";
        if(this.props.id !== undefined){
            id = "-"+this.props.id;
        }

        var idForFilter = "entity-tasks-filter"+this.props.entityID;

        var tasks = [];
        
        for(var j=0; j<this.state.listCategories.length; j++){
            var idForGroup = 'task-group-' + j;
            var toogleForGroup = "target: #"+idForGroup+";"
            tasks.push(<h5 key={j} uk-toggle={toogleForGroup} className="uk-heading-divider uk-margin propoerties-tasks-category-title" style={{...style.titleStyle, cursor: 'pointer'}}>{titleCase(this.state.listCategories[j])} 
                <img 
                        id={idForGroup}
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{position: 'absolute',
                        paddingTop: '5px',
                        right: '30px', color: 'black'}}
                    />
                    <img 
                        id={idForGroup} 
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{position: 'absolute',
                        paddingTop: '5px',
                        right: '30px', color: 'black'}} 
                        hidden
                    />
            
               </h5>);
            var listTask = [];
            for (var k=0; k<this.state.filteredListEntityTasks.length; k++){
                if(this.state.filteredListEntityTasks[k]['GroupBy'] === this.state.listCategories[j]){
                    listTask.push(<TaskRow key={this.state.filteredListEntityTasks[k].LinkID} entityTask={this.state.filteredListEntityTasks[k]} getTask={this.getTask.bind(this)}/>);
                }
            }
            tasks.push(<div id={idForGroup} hidden>{listTask}</div>);
        }


        if(this.state.filteredListEntityTasks === undefined || this.state.filteredListEntityTasks.length === 0){
            tasks.push(<h5 style={style.alignCenter}>No Tasks Available.</h5>)
        }

        if(fullScreen){
            return(
                <div>
                    <div className="uk-grid container-filter-properties" id={idForFilter} >
                        <div className="uk-width-2-12 filter-label">
                            <form className="uk-form-horizontal">
                                <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                <div className="uk-form-controls-filter"><input disabled={true} className="uk-input" id="property-name-filter-input" type="text" placeholder="Task Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                            </form>
                        </div>
                        <div className="uk-width-1-12">
                            <form className="uk-form-horizontal">
                                <label htmlFor="form-filter-completed-task">
                                    Completed
                                </label>
                            </form>
                        </div>
                        <div className="uk-width-2-12">
                            <DynamicSelect id="form-filter-completed-task" disabled={true} className="uk-select" objValue={this.state.selectedStatusFilter} listHasPlaceholder={true} getValue={this.onChangeCompletedStatusFilter} placeholder="Select your item" data={this.state.completedStatusFilter}/>
                        </div>
                    </div>
                    <div id="entity-tasks" className="uk-margin">
                        {tasks}  
                    </div>
                </div>
            );
        } else {
            return(
                <div>
                    <button className="uk-button uk-button-default" type="button" uk-toggle="target: #entity-tasks; animation: uk-animation-slide-top-medium">Task List</button>
                    <div className="uk-margin" id="entity-tasks" hidden>
                        <div className="uk-grid container-filter-properties" id={idForFilter} >
                            <div className="uk-width-2-12 filter-label">
                                <form className="uk-form-horizontal">
                                    <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                    <div className="uk-form-controls-filter"><input disabled={true} className="uk-input" id="property-name-filter-input" type="text" placeholder="Task Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                                </form>
                            </div>
                            <div className="uk-width-1-12">
                                <label htmlFor="form-filter-completed-task">
                                     Completed
                                </label>
                            </div>
                            <div className="uk-width-2-12">
                                <DynamicSelect disabled={true} id="form-filter-completed-task" className="uk-select" objValue={this.state.selectedStatusFilter} listHasPlaceholder={true} getValue={this.onChangeCompletedStatusFilter} placeholder="Select your item" data={this.state.completedStatusFilter}/>
                            </div>
                        </div>
                        <div>
                            {tasks}  
                        </div>
                    </div>
                    
                </div>
            );
        }
    }
}