import React from 'react';

import AlertBox from '../shared/AlertBox';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import {LookUpTables} from '../../constants/LookUpTables'

import {getConfirmation, sendDealReportByEmail} from '../../networking/NetworkingDeals'
import {getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../styles/styles';

import * as mime from 'mime-types';
var style = getStyle();

const DocumentTypes = {
    CONFIRMATION: 'Confirmation'
}

export default class DealInformationForm extends React.Component {

    state = {
        dealHeaderInfo: {},
        language: "",
        email: "",
        additionalEmail: "",
        fax: "",
        additionalFax: "",
        //
        dealHeaderId: 0,
        title: 'Deal Confirmation',
        documentType: DocumentTypes.CONFIRMATION,
        //
        emailChecked: false,
        additionalEmailChecked: false,
        faxChecked: false,        
        additionalFaxChecked: false,
        //
        emailDisabled: true,
        additionalEmailDisabled: true,
        faxDisabled: true,
        additionalFaxDisabled: true,
        //
        listLanguages: [],
        //
        loading: false,
        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        //
        showAlertEmail: false,
        alertMessageEmail: '',
        alertTitleEmail: '',
        //
        validations: {
            language: true,
            email: true,
            additionalEmail: true,
            fax: true,
            additionalFax: true
        }
    }

    componentDidMount() {
        console.log(this.props)
        if (typeof this.props.lookUpTables !== undefined) {
            this.setState({dealHeaderId: this.props.dealHeaderId},
                () => {
                    let lookUpTables = this.props.lookUpTables

                    let languages = lookUpTables.filter(x => x.LookUpTableDescription === LookUpTables.Languages)[0];

                    languages.lookUpTableDetails.forEach(element => {
                        element.value = element.LookUpTableDetail;
                        element.name = element.LookUpTableDetail;
                    });
                    this.setState({listLanguages: languages.lookUpTableDetails})
                })
        }
        if (typeof this.props.dealHeaderInfo !== undefined) {
            this.setState({dealHeaderInfo: this.props.dealHeaderInfo}, () => {
                //default values:
                this.setState({
                    email: this.state.dealHeaderInfo.Email,
                    emailChecked: this.state.dealHeaderInfo.EmailConfirmations,
                    fax: this.state.dealHeaderInfo.OfficeFax,
                    faxChecked: this.state.dealHeaderInfo.FaxConfirmations,
                    language: this.state.dealHeaderInfo.PreferredLanguage,
                    emailDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.Email),
                    faxDisabled: !this.stringEmptyOrNull(this.state.dealHeaderInfo.OfficeFax)
                })
            })
        }
    }

    handleBackLink() {
        this.setState({}, () => this.props.backLink());
    }

    //services
    showConfirmation = () => {
        this.setState({loading: true}, () => {
            getConfirmation(this.state.dealHeaderId).then((value) => {
                this.setState({loading: false})
                if (value !== undefined) {
                    let fileName = 'Deal Confirmation ' + this.state.dealHeaderId + '.pdf';
                    var byteCharacters = atob(value.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var bytes = new Uint8Array(byteNumbers);
                    var blob = new Blob([bytes], {
                        type: mime.lookup(fileName)
                    });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            })
        })        
    }

    yesToAlertEmail = () => {
        this.sendConfirmationDirect()
    }

    sendConfirmation = () => {
 
        if (this.validateEmailBelongsToCustomer()) {
            this.sendConfirmationDirect()
        } else {
            this.setState({
                showAlertEmail: true,
                alertTitleEmail: 'Warning',
                alertMessageEmail: 'One or more emails do not belong to this customer. Do you still want to send these emails?'
            });
        }
    }

    sendConfirmationDirect = () => {
        this.setState({
            showAlertEmail: false, alertTitleEmail: '', alertMessageEmail: ''
        })

        if (!this.validateRequiredFields()) {
            return
        }

        if (this.state.emailChecked && this.state.additionalEmailChecked && this.state.faxChecked && this.state.additionalFaxChecked) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please select who to send the confirmation to.'
            });
            return
        }

        let sendDealReportByEmailRequest = {
            DealHeaderID: this.state.dealHeaderId,
            Language: this.state.language
        }

        let DealMailInfo = []

        if (this.state.emailChecked) {
            DealMailInfo.push({MainInfo: this.state.email, DocType: DocumentTypes.CONFIRMATION})
        }
        
        if (this.state.additionalEmailChecked) {
            DealMailInfo.push({MainInfo: this.state.additionalEmail, DocType: DocumentTypes.CONFIRMATION})
        }

        if (this.state.faxChecked) {
            DealMailInfo.push({MainInfo: this.state.fax, DocType: DocumentTypes.CONFIRMATION})
        }
        
        if (this.state.additionalFaxChecked) {
            DealMailInfo.push({MainInfo: this.state.additionalFax, DocType: DocumentTypes.CONFIRMATION})
        }

        sendDealReportByEmailRequest.DealMailInfo = DealMailInfo;        

        this.setState({loading: true}, () => {
            sendDealReportByEmail(sendDealReportByEmailRequest).then((response) => {
                console.log(response)
                this.setState({loading: false})
                if (response.httpStatusCode !== 200) {
                    this.setState({
                        showAlert: true,
                        alertMessage: 'An error occurred while sending the report. Please try again.',
                        alertTitle: 'Error'
                    });
                } else {
                    if (typeof response.error !== undefined && response.error !== '') {
                        this.setState({
                            showAlert: true,
                            alertMessage: response.error,
                            alertTitle: 'Error'
                        })
                    } else {
                        this.setState({
                            showAlert: true,
                            alertMessage: 'Confimation sent successfully.'
                        });
                    }                    
                }
            })
        })
    }

    //validations
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }


    stringEmptyOrNull(str){
        return (str == null || str === '')
    }

    validateEmailBelongsToCustomer() {
        if (typeof this.props.dealHeaderInfo.EmailsForConfirmations !== undefined && this.props.dealHeaderInfo.EmailsForConfirmations.length > 0) {
            let customerContactEmails = this.props.dealHeaderInfo.EmailsForConfirmations.map(x => x.Email)
            let emails = []

            if (this.state.emailChecked && !this.stringEmptyOrNull(this.state.email)) {
                emails.push(this.state.email)
            }
    
            if (this.state.additionalEmailChecked && !this.stringEmptyOrNull(this.state.additionalEmail)) {
                emails.push(this.state.additionalEmail)
            }
    
            if (this.state.faxChecked && !this.stringEmptyOrNull(this.state.fax)) {
                emails.push(this.state.fax)
            }
    
            if (this.state.additionalFaxChecked && !this.stringEmptyOrNull(this.state.additionalFax)) {
                emails.push(this.state.additionalFax)
            }
            let invalidEmails = emails.filter(x => !customerContactEmails.includes(x))
            if (invalidEmails.length > 0) {
                return false;
            }
        }
        return true
    }

    validateRequiredFields() {
        let valid = true;
        let validLanguage = true, validEmail = true, validAdditionalEmail = true, validFax = true, validAdditionalFax=true;

        if (this.stringEmptyOrNull(this.state.language) || this.state.language.includes('Select')) {
            validLanguage = false;
        }
        
        if (this.state.emailChecked && this.stringEmptyOrNull(this.state.email)) {
            validEmail = false;
        }

        if (this.state.additionalEmailChecked && this.stringEmptyOrNull(this.state.additionalEmail)) {
            validAdditionalEmail = false;
        }

        if (this.state.faxChecked && this.stringEmptyOrNull(this.state.fax)) {
            validFax = false;
        }

        if (this.state.additionalFaxChecked && this.stringEmptyOrNull(this.state.additionalFax)) {
            validAdditionalFax = false;
        }

        valid = validLanguage && validEmail && validAdditionalEmail && validFax && validAdditionalFax;

        this.setState({
            validations: {
                ...this.state.validations,
                language: validLanguage,
                email: validEmail,
                additionalEmail: validAdditionalEmail,
                fax: validFax,
                additionalFax: validAdditionalFax
            }
        })
        return valid;
    }

    //events

    handleLanguage(value){
        if (typeof value !== undefined) {
            this.setState({language: value !== null ? value.value : ''});
        }
    }

    handleEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({emailChecked: checked})
    }

    handleEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleAdditionalEmailChecked = (event) => {
        let checked = event.target.checked;
        this.setState({additionalEmailDisabled: !checked, additionalEmail: '', additionalEmailChecked: checked})
    }

    handleAdditionalEmail = (event) => {
        this.setState({additionalEmail: event.target.value});
    }

    handleFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({faxChecked: checked})
    }

    handleFax = (event) => {
        this.setState({fax: event.target.value});
    }

    handleAdditionalFaxChecked = (event) => {
        let checked = event.target.checked;
        this.setState({additionalFaxDisabled: !checked, additionalFax: '', additionalFaxChecked: checked})
    }

    handleAdditionalFax = (event) => {
        this.setState({additionalFax: event.target.value});
    }

    //alerts
    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''})
    }

    closeAlertEmail = () => {
        this.setState({showAlertEmail: false, alertMessageEmail: '', alertTitleEmail: ''})
    }

    render() { 
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    {this.state.title}
                </h3>
                <div className="uk-grid uk-margin">
                    
                    {/*Line1*/}
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Language:</label>
                        <DynamicSelect className={this.formatValidSelect(this.state.validations.language)} id="form-dealconfirmation-language" data={this.state.listLanguages} 
                            objValue={{value: this.state.language}} getValue={this.handleLanguage.bind(this)} />
                    </div>
                    <div className="uk-width-2-3"> </div>
                    
                    {/*Line2*/}
                    <div className="uk-width-1-3">                        
                        <input className="uk-checkbox  uk-margin-right" type="checkbox" id="form-dealconfirmation-emailcheck" onChange={this.handleEmailChecked} 
                            checked={this.state.emailChecked}/>
                        <label className="uk-form-label" >Email</label>
                        <input className={this.formatValidInput(this.state.validations.email)} type="text" id="form-dealconfirmation-email" value={this.state.email} 
                        onChange={this.handleEmail} disabled={this.state.emailDisabled}/>
                    </div>

                    <div className="uk-width-1-3">                        
                        <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalEmailCheck" onChange={this.handleAdditionalEmailChecked}/>
                        <label className="uk-form-label" >Additional Email</label>
                        <input className={this.formatValidInput(this.state.validations.additionalEmail)} type="text" id="form-dealconfirmation-additionalemail" value={this.state.additionalEmail} 
                        onChange={this.handleAdditionalEmail} disabled={this.state.additionalEmailDisabled}/>
                    </div>
                    <div className="uk-width-1-3"></div>

                    {/*Line3*/}
                    <div className="uk-width-1-3">                        
                        <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-faxcheck" onChange={this.handleFaxChecked}
                            checked={this.state.faxChecked}/>
                        <label className="uk-form-label" >Fax</label>
                        <input className={this.formatValidInput(this.state.validations.fax)} type="text" id="form-dealconfirmation-fax" value={this.state.fax} 
                        onChange={this.handleFax} disabled={this.state.emailDisabled}/>
                    </div>

                    <div className="uk-width-1-3">                     
                        <input className="uk-checkbox uk-margin-right" type="checkbox" id="form-dealconfirmation-additionalfaxcheck" onChange={this.handleAdditionalFaxChecked}/>
                        <label className="uk-form-label" >Additional Fax</label>
                        <input className={this.formatValidInput(this.state.validations.additionalFax)} type="text" id="form-dealconfirmation-additionalfax" value={this.state.additionalFax} 
                        onChange={this.handleAdditionalFax} disabled={this.state.additionalFaxDisabled}/>
                    </div>
                    <div className="uk-width-1-3"></div> 

                    {/*Line4*/}
                    <div className="uk-width-2-3 uk-margin">
                        <button className="uk-button uk-button-default uk-button-green" type="button" onClick={this.sendConfirmation}>Send Confirmation</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.showConfirmation}>View PDF</button>
                    </div>
                    
                    <div className="uk-width-1-3 uk-margin"></div>

                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} type="Ok" title={this.state.alertTitle} 
                    message={this.state.alertMessage} okClick={this.closeAlert}/>

                <AlertBox open={this.state.showAlertEmail} onClose={this.closeAlertEmail} type="Yes/No" title={this.state.alertTitleEmail} 
                    message={this.state.alertMessageEmail} noClick={this.closeAlertEmail} yesClick={this.sendConfirmationDirect}/>

                <LoadingBox loading={this.state.loading}/>
            </div>
        );        
    }
}