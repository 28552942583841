/* eslint-disable default-case */
import React from 'react';
import DynamicSelect from '../../../shared/DynamicSelect';
import {getCurrencyList, getCurrenciesByCustomer} from '../../../../networking/NetworkingCurrencies';
import {regularSelectClass} from '../../../../styles/styles';
import { getInstrumentsByCustomer } from '../../../../networking/NetworkingInstruments';
import { getLookUpTable } from '../../../../networking/Networking';
import { saveOnlineAccess } from '../../../../networking/NetworkingCustomers';
import AlertBox from '../../../shared/AlertBox';
import ApprovalTiersScreen from './ApprovalTiersScreen';

const textCanPayUs = 'Currencies the customer can pay us';
const textCanPurchaseFromUs = 'Currencies the customer can purchase from us';

export default class OnlineAccessInformationScreen extends React.Component {
    
    constructor(props)
    {
        super(props);
        var customer = this.props.customer;
        var idCustomer = this.props.id
        this.state = {
            currentID                   : idCustomer,
            currentCustomerObj          : customer,
            beneCheckbox                :'',
            payCheckbox                 :'',

            listOfCurrencyType          : [
                                            {value:'Settlement' , name:'Settlement'},
                                            {value:'Payment'    , name: 'Payment'}
                                          ],
            listOfInstrumentType        : [
                                            {value:'Incoming' , name:'Incoming'},
                                            {value:'Outgoing' , name: 'Outgoing'}
                                          ],

            textToShowNextToSelect      : '',
            currencyTypeSelected        : '',   //Select Currencies
            instrumentTypeSelected      : '',   //Select Instruments

            currencyListByCustomer      : [],   //AllCurrenciesByCustomer
            instrumentListByCustomer    : [],   //AllInstrumentsByCustomer

            //Currencies
            selectedIDRowLeftCurrency   : '',   //Seleccionado de lista Izquierda
            selectedIDRowRightCurrency  : '',   //Seleccionado de lista Derecha
            currencyListHaveShow        : [],   
            currencyListHaveSettlement  : [],
            currencyListHavePayment     : [],
            currencyListWantShow        : [],
            currencyListWantSettlement  : [],
            currencyListWantPayment     : [],

            //Instrumens
            selectedIDRowLeftInstrument : '',   //Seleccionado de lista Izquierd2
            selectedIDRowRightInstrument: '',   //Seleccionado de lista Derecha2
            instrumentListHaveShow      : [],   
            instrumentListHaveIncoming  : [],
            instrumentListHaveOutgoing  : [],
            instrumentListWantShow      : [],
            instrumentListWantIncoming  : [],
            instrumentListWantOutgoing  : [],

            //Alert Box
            showAlert                   : false,
            alertTitle                  : '',
            alertMessage                : '',
            isSamePage: true,
        };
    }
    
    handleBackLink() {
        this.setState({
            isSamePage: true
        });
    }

    componentDidMount()
    {
        //Poner [Textos, Info, etc] por defecto al Inicio
        this.setState({
            textToShowNextToSelect : textCanPayUs,
            currencyTypeSelected : 'Settlement',
            instrumentTypeSelected: 'Incoming'
        })
        
        //Para obtener los [Currencies] del tipo [Settlement] y [Payment]
        getCurrenciesByCustomer(this.state.currentID).then(
            (jsonResponseGetCurrenciesByCustomer) => {

                if(jsonResponseGetCurrenciesByCustomer.currencies!== undefined){
                    this.setState({
                        currencyListByCustomer: jsonResponseGetCurrenciesByCustomer.currencies
                    });

                    var listCurrenciesByCustomer = jsonResponseGetCurrenciesByCustomer.currencies

                    var listOfSettlement =  listCurrenciesByCustomer.filter(function(currency){
                        return currency.Type == "Settlement";
                    });

                    var listOfPayment =  listCurrenciesByCustomer.filter(function(currency){
                        return currency.Type == "Payment";
                    });
                    
                    this.setState({
                        currencyListWantSettlement : listOfSettlement,
                        currencyListWantShow: listOfSettlement,
                        currencyListWantPayment    : listOfPayment,
                    });
                }
            }
        );

        //Para obtener los Available [Currencies - Settlement]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Settlement"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Settlement"}
                var wantlistSettlement = this.state.currencyListWantSettlement

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistSettlement.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistSettlement[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHaveSettlement: currencies,
                });

            }
        );

        //Para obtener los Available [Currencies - Payment]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Payment"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Payment"}
                var wantlistPayment = this.state.currencyListWantPayment

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistPayment.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistPayment[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHavePayment: currencies,
                });

            }
        );

        var hasInstruments = false

        //Para obtener los [Instruments] del tipo [Incoming] y [Outgoing]
        getInstrumentsByCustomer(this.state.currentID).then(
            (jsonResponseGetInstrumentByCustomer) =>{

                if(jsonResponseGetInstrumentByCustomer.instruments!== undefined){

                    hasInstruments = true

                    this.setState({
                        instrumentListByCustomer: jsonResponseGetInstrumentByCustomer.instruments
                    });

                    var listInstrumentsByCustomer = jsonResponseGetInstrumentByCustomer.instruments

                    var listOfIncoming =  listInstrumentsByCustomer.filter(function(instrument){
                        return instrument.InstrumentType == "Incoming";
                    });

                    var listOfOutcoming =  listInstrumentsByCustomer.filter(function(instrument){
                        return instrument.InstrumentType == "Outgoing";
                    });

                    if(hasInstruments == true)
                    {
                        this.getInOutINstruments('Incoming Instruments', listOfIncoming);
                        this.getInOutINstruments('Outgoing Instruments', listOfOutcoming);
                    }

                    this.setState({
                        instrumentListWantIncoming : listOfIncoming,
                        instrumentListWantOutgoing : listOfOutcoming,
                    });
                    
                }
            }
        );

        if(hasInstruments == false)
        {
            this.getInOutINstruments('Incoming Instruments', []);
            this.getInOutINstruments('Outgoing Instruments', []);
        }
    }
    
    async getInOutINstruments (description, wantlist){
       getLookUpTable(description).then(
        (json) => {
            var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
            var helper = [];
            for(var i=1; i<lookUpTableDetails.length; i++){
                lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                helper.push(lookUpTableDetails[i]);
            }
            // eslint-disable-next-line default-case
            switch(description){
                case 'Incoming Instruments':
                {
                    //var wantlistIncoming = this.state.instrumentListWantIncoming
                    for( var i = helper.length - 1; i >= 0; i--)
                    {
                        for( var j = 0; j<wantlist.length; j++)
                        {
                            if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                            {
                                helper.splice(i, 1);
                            }
                        }
                    }
                    this.setState({
                        instrumentListHaveIncoming: helper,
                    });
                    break;
                }
                case 'Outgoing Instruments':
                {
                    //var wantlistOutgoing = this.state.instrumentListWantOutgoing
                    for( var i = helper.length - 1; i >= 0; i--)
                    {
                        for( var j = 0; j<wantlist.length; j++)
                        {
                            if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                            {
                                helper.splice(i, 1);
                            }
                        }
                    }
                    this.setState({
                        instrumentListHaveOutgoing: helper
                    });
                    break;
                }
            }
        }
        );
    }

    onchangeValueOfCurrencyType(typeSelected)
    {
        if(typeSelected != undefined)
        {
            switch(typeSelected.value)
            {
                case 'Settlement':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPayUs,
                    });
                    break;
                case 'Payment':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPurchaseFromUs,
                    });
                    break;
                default:
                    break;
            }
            this.setState({
                currencyTypeSelected: typeSelected.value
            })
        }
    }
    
    onchangeValueOfInstrumentType(typeSelected)
    {
        if(typeSelected != undefined)
        {
            this.setState({
                instrumentTypeSelected: typeSelected.value
            });            
        }
    }

    // Currency
    currencyChangeColorLeft = selectedIDRowLeftCurrency => e => {
        if (selectedIDRowLeftCurrency !== undefined) {
          this.setState({ selectedIDRowLeftCurrency  });
        }
    };
    
    currencyChangeColorRight = selectedIDRowRightCurrency => e => {
        if (selectedIDRowRightCurrency !== undefined) {
          this.setState({ selectedIDRowRightCurrency  });
        }
    };

    // Instruments
    instrumentChangeColorLeft = selectedIDRowLeftInstrument => e => {
        if (selectedIDRowLeftInstrument !== undefined) {
          this.setState({ selectedIDRowLeftInstrument  });
        }
    };

    instrumentChangeColorRight = selectedIDRowRightInstrument => e => {
        if (selectedIDRowRightInstrument !== undefined) {
          this.setState({ selectedIDRowRightInstrument  });
        }
    };

    //Currency - BUTTONS -------------------------------------------------------------
    currencyAddItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                {listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
                break;}
            case 'Payment': 
                {listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
                break;}
        }
        
        var idSelected = this.state.selectedIDRowLeftCurrency;
        var add = true
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((currency, index) => {
                    if(currency.CurrencyID == idSelected)
                    {
                        listRight.push(currency)
                        listLeft.splice(index,1)
                    }

                    switch(this.state.currencyTypeSelected)
                    {
                        case 'Settlement':
                            {this.setState({
                                currencyListWantSettlement:  listRight,
                                currencyListHaveSettlement: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                        case 'Payment': 
                            {this.setState({
                                currencyListWantPayment:  listRight,
                                currencyListHavePayment: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                    }
                });
                
            }else
            {
                listRight.forEach(rightCurrency => {
                    if(rightCurrency.CurrencyID == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftCurrency, index) => {
                            if(leftCurrency.CurrencyID == idSelected)
                            {
                                listRight.push(leftCurrency)
                                listLeft.splice(index,1)

                                switch(this.state.currencyTypeSelected)
                                {
                                    case 'Settlement':
                                    {
                                        this.setState({
                                            currencyListWantSettlement:  listRight,
                                            currencyListHaveSettlement: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                    case 'Payment': 
                                    {
                                        this.setState({
                                            currencyListWantPayment:  listRight,
                                            currencyListHavePayment: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }

    currencyRemoveItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }

        var idSelected = this.state.selectedIDRowRightCurrency
        var add = true
        if(idSelected != '')
        {
            listRight.forEach((rightCurrency, index) => {
                if(rightCurrency.CurrencyID == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightCurrency)
                }
            });

            switch(this.state.currencyTypeSelected)
            {
                case 'Settlement':
                    {this.setState({
                        currencyListWantSettlement:  listRight,
                        currencyListHaveSettlement: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
                case 'Payment': 
                    {this.setState({
                        currencyListWantPayment:  listRight,
                        currencyListHavePayment: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
            }
        }
    }

    currencyAddAllItems()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listRight,listLeft)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  listRight,
                    currencyListHaveSettlement: [],
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  listRight,
                currencyListHavePayment: [],
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }
    
    currencyRemoveAll()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listLeft,listRight)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  [],
                    currencyListHaveSettlement: listLeft,
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  [],
                currencyListHavePayment: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }

    //Instruments - BUTTONS -------------------------------------------------------------
    instrumentAddItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }
        
        var idSelected = this.state.selectedIDRowLeftInstrument;
        var add = true

        //{LookUpTableDetail: "Transfer in", Field4: "", value: "Transfer in", name: "Transfer in"}
        //{CustomerInstrumentId: 82, InstrumentType: "Incoming", Instrument: "Transfer in"}

        var newIncomingToAdd = {
            CustomerInstrumentId: 0, 
            InstrumentType      : this.state.selectedIDRowLeftInstrument, 
            Instrument          : ""
        }
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((instrument, index) => {
                    if(instrument.name == idSelected)
                    {
                        newIncomingToAdd['Instrument'] = instrument.name
                        listRight.push(newIncomingToAdd)
                        listLeft.splice(index,1)
                    }

                    switch(this.state.instrumentTypeSelected)
                    {
                        case 'Incoming':
                        {
                            this.setState({
                                instrumentListWantIncoming: listRight,
                                instrumentListHaveIncoming: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;
                        }
                        case 'Outgoing': 
                        {
                            this.setState({
                                instrumentListWantOutgoing:  listRight,
                                instrumentListHaveOutgoing: listLeft,
                                selectedIDRowLeftInstrument: ''
                            });
                            break;
                        }
                    }
                });
                
            }else
            {
                listRight.forEach(rightInstrument => {
                    if(rightInstrument.Instrument == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((lefInstrument, index) => {
                            if(lefInstrument.name == idSelected)
                            {
                                newIncomingToAdd['Instrument'] = lefInstrument.name
                                listRight.push(newIncomingToAdd)
                                listLeft.splice(index,1)

                                switch(this.state.instrumentTypeSelected)
                                {
                                    case 'Incoming':
                                    {
                                        this.setState({
                                            instrumentListWantIncoming: listRight,
                                            instrumentListHaveIncoming: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                    case 'Outgoing': 
                                    {
                                        this.setState({
                                            instrumentListWantOutgoing:  listRight,
                                            instrumentListHaveOutgoing: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }

    instrumentRemoveItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        var idSelected = this.state.selectedIDRowRightInstrument
        var add = true

        //{LookUpTableDetail: "Transfer in", Field4: "", value: "Transfer in", name: "Transfer in"}
        //{CustomerInstrumentId: 82, InstrumentType: "Incoming", Instrument: "Transfer in"}

        var oldIncomingToAdd = {
            LookUpTableDetail   : "", 
            Field4              : "Incoming", 
            value               : "",
            name                : ""
        }

        if(idSelected != '')
        {
            listRight.forEach((rightInstrument, index) => {
                if(rightInstrument.Instrument == idSelected)
                {
                    oldIncomingToAdd.LookUpTableDetail = rightInstrument.Instrument
                    oldIncomingToAdd.value = rightInstrument.Instrument
                    oldIncomingToAdd.name = rightInstrument.Instrument
                    listRight.splice(index,1)
                    listLeft.push(oldIncomingToAdd)
                }
            });

            switch(this.state.instrumentTypeSelected)
            {
                case 'Incoming':
                    {this.setState({
                        instrumentListWantIncoming: listRight,
                        instrumentListHaveIncoming: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
                case 'Outgoing': 
                    {this.setState({
                        instrumentListWantOutgoing:  listRight,
                        instrumentListHaveOutgoing: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
            }
        }
    }

    instrumentAddAllItems()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        var newListLeft = []
        listLeft.forEach((lefInstrument, index) => {
            var newIncomingToAdd = {
                CustomerInstrumentId: 0, 
                InstrumentType      : this.state.instrumentTypeSelected, 
                Instrument          : lefInstrument.name
            }
            newListLeft.push(newIncomingToAdd)
        })
        
        Array.prototype.push.apply(listRight,newListLeft)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming  : listRight,
                    instrumentListHaveIncoming  : []
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  listRight,
                    instrumentListHaveOutgoing: []
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: ''
        })
    }
    
    instrumentRemoveAll()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }

        var newListRight = []
        listRight.forEach((rightInstrument, index) => {
            var oldInstrumentToAdd = {
                LookUpTableDetail   : rightInstrument.Instrument, 
                Field4              : this.state.instrumentTypeSelected, 
                value               : rightInstrument.Instrument,
                name                : rightInstrument.Instrument
            }
            newListRight.push(oldInstrumentToAdd)
        })

        Array.prototype.push.apply(listLeft,newListRight)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming:  [],
                    instrumentListHaveIncoming: listLeft,
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  [],
                    instrumentListHaveOutgoing: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: '',
        })
    }
    // ----------------------------------------------------------------------------------

    handleOnCheckBene(event){
        this.setState({beneCheckbox: event.target.checked});    
    }

    handleOnCheckPay(event){
        this.setState({payCheckbox: event.target.checked});
    }

    handleSaveClick(event){
        event.preventDefault();
        var currencySettList    = this.state.currencyListWantSettlement;
        var currencyPayList     = this.state.currencyListWantPayment;
        var insList             = this.state.instrumentListWantIncoming;
        var outList             = this.state.instrumentListWantOutgoing;
        var Customer            = this.state.currentCustomerObj;
        var CurrenciesJson      = [];
        var InstrumentsJson     = [];
        
        if(this.state.beneCheckbox===''){Customer.BeneDualApproval=false}else{Customer.BeneDualApproval= this.state.beneCheckbox;}
        if(this.state.payCheckbox===''){Customer.PaymentDualApproval=false}else{Customer.PaymentDualApproval = this.state.payCheckbox;}
        Customer.OrderDualApproval = true;

        for (var i = 0; i < currencySettList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencySettList[i].CurrencyID,
                'Type': 'Settlement'});
        }
        for (var i = 0; i < currencyPayList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencyPayList[i].CurrencyID,
                'Type': 'Payment'});
        }
        for (var i = 0; i < insList.length; i++) {
            InstrumentsJson.push({
                'Instrument': insList[i].Instrument,
                'Type': 'Incoming'});
        }
        for (var i = 0; i < outList.length; i++) {
            InstrumentsJson.push({
                'Instrument': outList[i].Instrument,
                'Type': 'Outgoing'});
        }
        var objFilter={
            Customer,
            CurrenciesJson,
            InstrumentsJson
        }
        saveOnlineAccess(objFilter).then(
            (json)=>{
                console.log(json);
                if(json.status!==200){
                    this.setState({showAlert: true, alertTitle: 'An error occurred while trying to save the Online Access.'})
                }else{
                    this.setState({showAlert: true,alertTitle:'Successfully', alertMessage: 'Online Access successfully saved.'});
                }
            }
        );
    }

    handleApprovalTiersClick(event){
        this.setState({isSamePage: false});
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    render(){
        var losQueHayAhora  = []
        var losQueQuiero    = [];
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                losQueHayAhora = this.state.currencyListHaveSettlement
                losQueHayAhora.sort(this.dynamicSort("CurrencyID"));
                losQueQuiero = this.state.currencyListWantSettlement
                losQueQuiero.sort(this.dynamicSort("CurrencyID"));
                break;
            }
            case 'Payment': 
            {
                losQueHayAhora = this.state.currencyListHavePayment
                losQueHayAhora.sort(this.dynamicSort("CurrencyID"));
                losQueQuiero = this.state.currencyListWantPayment
                losQueQuiero.sort(this.dynamicSort("CurrencyID"));
                break;
            }
        }
        
        var losQueHayAhoraIns  = []
        var losQueQuieroIns    = []

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                losQueHayAhoraIns = this.state.instrumentListHaveIncoming
                losQueHayAhoraIns.sort(this.dynamicSort("name"));
                losQueQuieroIns = this.state.instrumentListWantIncoming
                losQueQuieroIns.sort(this.dynamicSort("Instrument"));
                break;
            }
            case 'Outgoing': 
            {
                losQueHayAhoraIns = this.state.instrumentListHaveOutgoing
                losQueHayAhoraIns.sort(this.dynamicSort("name"));
                losQueQuieroIns = this.state.instrumentListWantOutgoing
                losQueQuieroIns.sort(this.dynamicSort("Instrument"));
                break;
            }
        }

        if (!this.state.isSamePage) {
            return (
                <ApprovalTiersScreen backLink={this.handleBackLink.bind(this)} id={this.state.currentID} payCheckbox={this.state.payCheckbox} beneCheckbox={this.state.beneCheckbox} />
            );
        }
        else {
            return (
                <div>
                    <ul id="accordion-online-access" uk-accordion="multiple: true">
                        <li className="uk-open">
                            <button className="uk-accordion-title">Dual Approval Information</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="dual-approval">
                                    <div className="uk-margin">
                                        <label className="uk-margin-right">
                                            <input disabled={true} className="uk-checkbox" type="checkbox" checked={this.state.beneCheckbox} onChange={this.handleOnCheckBene.bind(this)} /> Beneficiaries
                                        </label>
                                        <label className="uk-margin-right">
                                            <input disabled={true} className="uk-checkbox" type="checkbox" checked={this.state.payCheckbox} onChange={this.handleOnCheckPay.bind(this)} />
                                            Payment
                                        </label>
                                        <button className="uk-button uk-button-green uk-margin-right" type="button" onClick={this.handleApprovalTiersClick.bind(this)}>Approval Tiers</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="uk-open">
                            <button className="uk-accordion-title">Settlement Currencies</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="settlement-currencies">
                                    <div style={{ display: 'flex' }}>
                                        <div className="uk-width-1-3">
                                            <div className="uk-margin">
                                                <label className="uk-form-label" htmlFor="form-bank-status">Currency Type</label>
                                                <div className="uk-form-controls">
                                                    <DynamicSelect disabled={true} className={regularSelectClass} objValue={'value'} getValue={this.onchangeValueOfCurrencyType.bind(this)} listHasPlaceholder={false} data={this.state.listOfCurrencyType} id='select-currency-type' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3">
                                            <div className="uk-form-controls" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}    >
                                                <label disabled={true} style={{ fontSize: 16 }}>{this.state.textToShowNextToSelect}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h3 class="uk-width-2-3" style={{ marginLeft: 20 }}>Available Currencies</h3>
                                        <h3 class="uk-width-1-3">Assigned Currencies</h3>
                                    </div>
                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                        <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody disabled={true} >
                                                    {losQueHayAhora.map((item, i) => {
                                                        return (
                                                            <tr disabled={true} key={item.CurrencyID} onClick={this.currencyChangeColorLeft(item.CurrencyID)} onDoubleClick={this.currencyAddItemSelected.bind(this)} style={this.state.selectedIDRowLeftCurrency === item.CurrencyID ? { backgroundColor: '#8bc34a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td disabled={true} style={this.state.selectedIDRowLeftCurrency === item.CurrencyID ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.CurrencyIDName}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="uk-card-body uk-width-1-3" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyAddItemSelected.bind(this)}>Add</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyAddAllItems.bind(this)}>Add all</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyRemoveItemSelected.bind(this)}>Remove</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" onClick={this.currencyRemoveAll.bind(this)}>Remove all</button>
                                        </div>
                                        <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody disabled={true} >
                                                    {losQueQuiero.map((item, i) => {
                                                        return (
                                                            <tr disabled={true} key={item.CurrencyID} onClick={this.currencyChangeColorRight(item.CurrencyID)} onDoubleClick={this.currencyRemoveItemSelected.bind(this)} style={this.state.selectedIDRowRightCurrency === item.CurrencyID ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td disabled={true} style={this.state.selectedIDRowRightCurrency === item.CurrencyID ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.CurrencyIDName}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="uk-open">
                            <button className="uk-accordion-title">Instruments</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="instruments">
                                    <div style={{ display: 'flex' }}>
                                        <div className="uk-width-1-3">
                                            <div className="uk-margin">
                                                <label className="uk-form-label" htmlFor="form-bank-status">Instrument Type</label>
                                                <div className="uk-form-controls">
                                                    <DynamicSelect disabled={true} className={regularSelectClass} objValue={'value'} getValue={this.onchangeValueOfInstrumentType.bind(this)} listHasPlaceholder={false} data={this.state.listOfInstrumentType} id='select-instrument-type' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h3 class="uk-width-3-3" style={{ marginLeft: 20 }}>Available Instruments</h3>
                                        <h3 class="uk-width-1-3">Assigned Instruments</h3>
                                    </div>
                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                        <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody disabled={true} >
                                                    {losQueHayAhoraIns.map((item, i) => {
                                                        return (
                                                            <tr disabled={true} key={item.value} onClick={this.instrumentChangeColorLeft(item.value)} onDoubleClick={this.instrumentAddItemSelected.bind(this)} style={this.state.selectedIDRowLeftInstrument === item.value ? { backgroundColor: '#8bc34a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td disabled={true} style={this.state.selectedIDRowLeftInstrument === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.LookUpTableDetail}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="uk-card-body uk-width-1-3" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentAddItemSelected.bind(this)} >Add</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentAddAllItems.bind(this)}>Add all</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentRemoveItemSelected.bind(this)}>Remove</button>
                                            <button disabled={true} className="uk-button uk-button-green uk-width-1-2" onClick={this.instrumentRemoveAll.bind(this)}>Remove all</button>
                                        </div>
                                        <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody disabled={true} >
                                                    {losQueQuieroIns.map((item) => {
                                                        return (
                                                            <tr disabled={true} key={item.Instrument} onClick={this.instrumentChangeColorRight(item.Instrument)} onDoubleClick={this.instrumentRemoveItemSelected.bind(this)} style={this.state.selectedIDRowRightInstrument === item.Instrument ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td disabled={true} style={this.state.selectedIDRowRightInstrument === item.Instrument ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.Instrument}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        <button disabled={true} className="uk-button uk-button-green" style={{ marginTop: 20 }} onClick={this.handleSaveClick.bind(this)} >Save</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                </div>
            );
        }
    }
}