import {genericGetWithParameters, genericDeleteWithParameters, genericGetWithParametersNoEncrypted} from './Networking';
import jwt from 'jsonwebtoken';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function searchActiveBeneficiariesBankAccountsByCustomerAndCurrency(customerID, currencyID, searchTerm){
    try {
        let params = {"customerID":customerID,
                    "currencyID":currencyID,
                    "searchTerm":searchTerm,};

        let response = await genericGetWithParameters('Beneficiary/SearchActiveBeneficiariesBankAccountsByCustomerAndCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getActiveBeneficiariesBankAccountsByCustomerAndCurrency(customerID, currencyID){
    try {
        let params = {"customerID":customerID,
                    "currencyID":currencyID,};

        let response = await genericGetWithParameters('Beneficiary/GetActiveBeneficiariesBankAccountsByCustomerAndCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByCustomer(customerID, page){
    try {
        let params = {
            "customerID":customerID,
            "page": page,
            "items":0
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiariesByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByID(id){
    try {
        let params = {"beneficiaryId":id,};

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getRiskScoreBeneDetails(beneID){
    try {
        let params = {
            EntityType: "Beneficiary",
            EntityID: beneID,
            checkBankScore: true
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiaryRiskScore(beneficiaryID){
    try {
        let params = {
            BeneficiaryID: beneficiaryID
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetBeneficiaryRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Beneficiary/SaveBeneficiary
export async function addBeneficiary(ojbBene) {
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Beneficiary/SaveBeneficiary',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(ojbBene),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

//Beneficiary/DeleteBeneficiary
export async function deleteBeneficiary(putBene) {
    try{
        let params = {
            'beneficiaryID': putBene
        };
        let response = await genericDeleteWithParameters('Beneficiary/DeleteBeneficiary',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getProvinceByCountryID(id){
    try {
        let params = {"CountryID":id,};

        let response = await genericGetWithParameters('States/GetStatesByCountry_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Autocomplete
export async function getBeneficiariesByNameMin(beneficiary){
    try {
        let params = {"beneficiary":beneficiary,};

        let response = await genericGetWithParameters('Beneficiary/SearchBeneficiaryByIdNameMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiariesByCustomerFormatedMin(customerId, currencyId, status) {
    try {
        let params = {
            "CustomerID": customerId,
            "CurrencyID": currencyId,
            "Status": status
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiariesByCustomerFormatedMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function getNewBeneficiaryEmail(customerId, personId, beneEmail) {
    try {
        let params = {
            "CustomerID": customerId,
            "PersonID": personId,
            "BeneficiaryEmail": beneEmail,
            "BeneficiaryUrl": window.REACT_APP_BENE_URL,
        };
//"http://10.69.69.79:3000/NewBeneficiary"----"localhost:3000/NewBeneficiary"
        let response = await genericGetWithParameters('Beneficiary/SendRequestBeneficiaryInformationEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getNewBeneficiaryKey(key) {
    try {
        let params = {
            "BeneficiaryInfoRequestID": key
        };
        let response = await genericGetWithParametersNoEncrypted('Beneficiary/GetCustomerIDByKey', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBeneficiaryForDisbursementsScreen(customerID, currencyID){
    try {
        let params = {
            "customerID":customerID,
            "currencyID": currencyID,
        };

        let response = await genericGetWithParameters('Beneficiary/GetBeneficiaryForDisbursementsScreen',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}