import React from 'react';
import CustomersSearchForm from './CustomersSearchForm';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { searchCustomers, newSearchCustomers } from '../../../networking/Networking';
import CustomerScreen from './updateCustomer/CustomerScreen';
import { getStyle } from '../../../styles/styles'
import AddCustomerScreen from './addCustomer/AddCustomerScreen';
const style = getStyle();

export default class CustomersScreen extends React.Component {
    state = {
        searchedCustomerName: '',
        customers: [],
        updateCustomer: false,
        addCustomer: false,
        customerID: 0,
        customerName: '',
        pages: 0,
        totalPages: 0,
        newSearch: false,
        useFilter: false
    }

    handleUpdateSearchedCustomerName = (value) => {
        this.setState({ searchedCustomerName: value });
    }

    handleTableData = async () => {
        const value = await searchCustomers(this.state.searchedCustomerName);
        this.setState({ customers: value });
    }

    newHandleTableData = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, sessionStorage.getItem('AgentCustomerID'), 0, 0);
        if (value !== undefined && value.totalCount > 0) {
            const totalCount = value.totalCount;
            const totalPages = Math.ceil(totalCount / 15);
            this.setState({ customers: value.customers, pages: 1, totalPages, newSearch: true, useFilter: true });
        } else {
            this.setState({ customers: [], pages: 0, totalPages: 0, newSearch: true, useFilter: false });
        }
    }
    
    handleAddCustomer = () => {
        this.setState({ addCustomer: true });
    }

    handleRowClick = (obj) => {
        console.group("Customer handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({
            updateCustomer: true,
            customerID: obj["Customer ID"],
            customerName: obj['Customer Name']
        });
    }

    handleBackLink = () => {
        this.setState({
            updateCustomer: false,
            addCustomer: false
        });
    }

    clearData = () => {
        this.setState({
            customers: [], searchedCustomerName: '', pages: 0, totalPages: 0, newSearch: false, useFilter: false
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getAllCustomers = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, 0, 0);
        return value.customers;
    }

    render() {
        if (this.state.updateCustomer) {
            return <CustomerScreen backLink={this.handleBackLink} customerID={this.state.customerID} customerName={this.state.customerName} />
        }

        if (this.state.addCustomer) {
            return <AddCustomerScreen backLink={this.handleBackLink} />
        }

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>
                {/*<CustomersSearchForm searchedString={this.state.searchedCustomerName} updateSearchedCustomerName={this.handleUpdateSearchedCustomerName} updateTable={this.handleTableData} clearData={this.clearData}/>*/}
                {/*<DynamicTable headers={headers} data={this.state.customers} enableClick={true} clickFunction={this.handleRowClick}/>*/}
                <CustomersSearchForm
                    searchedString={this.state.searchedCustomerName}
                    updateSearchedCustomerName={this.handleUpdateSearchedCustomerName}
                    addCustomer={this.handleAddCustomer}
                    updateTable={this.newHandleTableData}
                    clearData={this.clearData}
                />
                <NewDynamicTable
                    data={this.state.customers}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={false}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={15}
                    enableSortByHeader={true}
                    useFilter={this.state.useFilter}
                    filterFunction={this.getAllCustomers}
                />
            </div>
        );
    }
}