import React from 'react';
import { getCustomerOverview } from '../../networking/NetworkingCustomers';
import NewDynamicTable from './NewDynamicTable';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper';
import LoadingBox from './LoadingBox';
import DealInformationScreen from '../deal/DealInformationScreen';
import { getDepositsByCustomer } from '../../networking/NetworkingDeposits';

export default class CustomerOverview extends React.Component {
    state = {
        heldFundsBalances: [],
        undisbursedDeals: [],
        undisbursedForwards: [],
        unpaidDeals: [],
        unpaidDeposits: [],
        loading: true,
        newSearch: false,
        filter: false,
        pagesUnpaidDeals: 1,
        totalPagesUnpaidDeals: 0,
        pagesUndisbursedDeals: 1,
        totalPagesUndisbursedDeals: 0,
        pagesUndisbursedForwards: 1,
        totalPagesUndisbursedForwards: 0,
        pagesHeldFundBalances: 1,
        totalPagesHeldFundBalances: 0,
        pagesUnpaidDeposits: 1,
        totalPagesUnpaidDeposits: 0,
        showUnpaidDealInfo: false,
        showUndisbursedDealInfo: false,
        showUndisbursedForwardInfo: false,
        objID: 0,
        activeTabUnpaidDeals: 1,
        activeTabUndisbursedDeals: 1,
        activeTabUndisbursedForwards: 1
    }

    componentDidMount() {
        getCustomerOverview(this.props.customerID).then(
            (response) => {
                let unpaidDeals = [], undisbursedDeals = [],
                    undisbursedForwards = [], heldFundsBalances = [],
                    unpaidDeposits = [];
                let totalPagesUnpaidDeals = this.state.totalPagesUnpaidDeals;
                let totalPagesUndisbursedDeals = this.state.totalPagesUndisbursedDeals;
                let totalPagesUndisbursedForwards = this.state.totalPagesUndisbursedForwards;
                let totalPagesHeldFundBalances = this.state.totalPagesHeldFundBalances;
                let totalPagesUnpaidDeposits = this.state.totalPagesUnpaidDeposits;

                if (response.overview.UnpaidDeals !== null) {
                    unpaidDeals = response.overview.UnpaidDeals;
                    const totalCount = unpaidDeals.length;
                    totalPagesUnpaidDeals = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.UndisbursedDeals !== null) {
                    undisbursedDeals = response.overview.UndisbursedDeals;
                    const totalCount = undisbursedDeals.length;
                    totalPagesUndisbursedDeals = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.UndisbursedForwards) {
                    undisbursedForwards = response.overview.UndisbursedForwards;
                    const totalCount = undisbursedForwards.length;
                    totalPagesUndisbursedForwards = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.HeldFundsBalances) {
                    heldFundsBalances = response.overview.HeldFundsBalances;
                    const totalCount = heldFundsBalances.length;
                    totalPagesHeldFundBalances = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                if (response.overview.UnpaidDeposits) {
                    unpaidDeposits = response.overview.UnpaidDeposits;
                    const totalCount = unpaidDeposits.length;
                    totalPagesUnpaidDeposits = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                }

                this.setState({
                    loading: false,
                    newSearch: true,
                    filter: true,
                    heldFundsBalances,
                    undisbursedDeals,
                    undisbursedForwards,
                    unpaidDeals,
                    unpaidDeposits,
                    totalPagesUnpaidDeals,
                    totalPagesUndisbursedDeals,
                    totalPagesUndisbursedForwards,
                    totalPagesHeldFundBalances,
                    totalPagesUnpaidDeposits
                });
            }
        );
        getDepositsByCustomer(1242, true, 0, 0).then(
            (json) => {
                if (json !== undefined && json.deposits !== undefined) {

                }
            }
        )
    }

    onBackClick = () => {
        if (this.props.onBackClick !== undefined) {
            this.props.onBackClick();
        }
    }

    returnToUnpaidDeal = () => {
        this.setState({
            objID: 0,
            showUnpaidDealInfo: false,
            activeTabUnpaidDeals: 1
        });
    }

    handleUnpaidDealClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUnpaidDealInfo: true,
            activeTabUnpaidDeals: 2
        });
    }

    handleUndisbursedDealClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUndisbursedDealInfo: true,
            activeTabUndisbursedDeals: 2
        })
    }

    returnToUndisbursedDeal = () => {
        this.setState({
            objID: 0,
            showUndisbursedDealInfo: false,
            activeTabUndisbursedDeals: 1
        });
    }

    handleUndisbursedForwardClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUndisbursedForwardInfo: true,
            activeTabUndisbursedForwards: 2
        })
    }

    returnToUndisbursedForward = () => {
        this.setState({
            objID: 0,
            showUndisbursedForwardInfo: false,
            activeTabUndisbursedForwards: 1
        });
    }

    handleUnpaidDepositClick = (obj) => {
        this.setState({
            objID: obj['Deal ID'],
            showUnpaidDepositInfo: true,
            activeTabUnpaidDeposits: 2
        })
    }

    returnToUnpaidDeposit = () => {
        this.setState({
            objID: 0,
            showUnpaidDepositInfo: false,
            activeTabUnpaidDeposits: 1
        });
    }

    changePageUnpaidDeals = (value) => {
        this.setState({ pagesUnpaidDeals: value });
    }

    changePageUndisbursedDeals = (value) => {
        this.setState({ pagesUndisbursedDeals: value });
    }

    changePageUndisbursedForwards = (value) => {
        this.setState({ pagesUndisbursedForwards: value });
    }

    changePageHeldFundsBalances = (value) => {
        this.setState({ pagesHeldFundsBalances: value });
    }

    changePageUnpaidDeposits = (value) => {
        this.setState({ pagesUnpaidDeposits: value });
    }

    getCustomerData = async (value) => {
        const response = await getCustomerOverview(this.props.customerID);
        switch (value) {
            case 'Unpaid Deals': {
                if (response.overview.UnpaidDeals !== null) {
                    return response.overview.UnpaidDeals;
                }
                break;
            }
            case 'Undisbursed Deals': {
                if (response.overview.UndisbursedDeals !== null) {
                    return response.overview.UndisbursedDeals;
                }
                break;
            }
            case 'Undisbursed Forwards': {
                if (response.overview.UndisbursedForwards) {
                    return response.overview.UndisbursedForwards;
                }
                break;
            }
            case 'Held Funds Balances': {
                if (response.overview.HeldFundsBalances) {
                    return response.overview.HeldFundsBalances;
                }
                break;
            }
            case 'Unpaid Deposits': {
                if (response.overview.UnpaidDeposits) {
                    return response.overview.UnpaidDeposits;
                }
                break;
            }
            default: return [];
        }
        return [];
    }

    handleActiveTabUnpaidDeal = (tab) => {
        return this.state.activeTabUnpaidDeals === tab ? 'uk-active' : '';
    }

    handleActiveTabUndisbursedDeal = (tab) => {
        return this.state.activeTabUndisbursedDeals === tab ? 'uk-active' : '';
    }

    handleActiveTabUndisbursedForward = (tab) => {
        return this.state.activeTabUndisbursedForwards === tab ? 'uk-active' : '';
    }
    
    render() {
        return (
            <div>
                <h4>
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.onBackClick}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button>
                    New customer search
                </h4>
                <ul id="accordion-customer-overview" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <button className="uk-accordion-title">Unpaid Deals {this.state.loading ? '' : `(${this.state.unpaidDeals.length} Items)`}</button>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8', paddingBottom: '0px', paddingTop: '0px' }}>
                            <div id="unpaid-deals">
                                {
                                    this.state.showUnpaidDealInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTabUnpaidDeal(1)}><a onClick={() => this.returnToUnpaidDeal()}>Unpaid Deals</a></li>
                                        <li className={this.handleActiveTabUnpaidDeal(2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUnpaidDealInfo ?
                                    <DealInformationScreen backLink={this.returnToUnpaidDeal} data={this.state.objID} />
                                    :
                                    <NewDynamicTable id="table-unpaid-deals"
                                        data={this.state.unpaidDeals}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        dynamicActions={[{ name: 'fa fa-info-circle', function: this.handleUnpaidDealClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUnpaidDeals}
                                        numberOfPages={this.state.totalPagesUnpaidDeals}
                                        pageNumber={this.state.pagesUnpaidDeals}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Unpaid Deals')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <button className="uk-accordion-title">Undisbursed Deals {this.state.loading ? '' : `(${this.state.undisbursedDeals.length} Items)`}</button>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="undisbursed-deals">
                                {
                                    this.state.showUndisbursedDealInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTabUndisbursedDeal(1)}><a onClick={() => this.returnToUndisbursedDeal()}>Undisbursed Deals</a></li>
                                        <li className={this.handleActiveTabUndisbursedDeal(2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUndisbursedDealInfo ?
                                    <DealInformationScreen backLink={this.returnToUndisbursedDeal} data={this.state.objID} />
                                    :
                                    <NewDynamicTable id="table-undisbursed-deals"
                                        data={this.state.undisbursedDeals}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        hasChildren={true}
                                        dynamicActions={[{ name: 'fa fa-info-circle', function: this.handleUndisbursedDealClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUndisbursedDeals}
                                        numberOfPages={this.state.totalPagesUndisbursedDeals}
                                        pageNumber={this.state.pagesUndisbursedDeals}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Undisbursed Deals')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <button className="uk-accordion-title">Undisbursed Forwards {this.state.loading ? '' : `(${this.state.undisbursedForwards.length} Items)`}</button>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="undisbursed-forwards">
                                {
                                    this.state.showUndisbursedForwardInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTabUndisbursedForward(1)}><a onClick={() => this.returnToUndisbursedForward()}>Undisbursed Forwards</a></li>
                                        <li className={this.handleActiveTabUndisbursedForward(2)}><a>Deal Information</a></li>
                                    </ul>)
                                }
                                {
                                    this.state.showUndisbursedForwardInfo ?
                                    <DealInformationScreen backLink={this.returnToUndisbursedForward} data={this.state.objID} />
                                    :
                                    <NewDynamicTable id="table-undisbursed-forwards"
                                        data={this.state.undisbursedForwards}
                                        newSearch={this.state.newSearch}
                                        useDeleteButton={false}
                                        dynamicActions={[{ name: 'fa fa-info-circle', function: this.handleUndisbursedForwardClick, isIcon: true }]}
                                        usePaginator={true}
                                        changePage={this.changePageUndisbursedForwards}
                                        numberOfPages={this.state.totalPagesUndisbursedForwards}
                                        pageNumber={this.state.pagesUndisbursedForwards}
                                        numberPerPage={getDefaultPagingNumberOfItems()}
                                        enableSortByHeader={true}
                                        useFilter={this.state.filter}
                                        filterFunction={() => this.getCustomerData('Undisbursed Forwards')}
                                    />
                                }
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <button className="uk-accordion-title">Held Funds Balances {this.state.loading ? '' : `(${this.state.heldFundsBalances.length} Items)`}</button>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="funds-balances">
                                <NewDynamicTable id="held-funds-balances"
                                    data={this.state.heldFundsBalances}
                                    newSearch={this.state.newSearch}
                                    useDeleteButton={false}
                                    usePaginator={true}
                                    changePage={this.changePageHeldFundsBalances}
                                    numberOfPages={this.state.totalPagesHeldFundBalances}
                                    pageNumber={this.state.pagesHeldFundBalances}
                                    numberPerPage={getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}
                                    useFilter={this.state.filter}
                                    filterFunction={() => this.getCustomerData('Held Funds Balances')}
                                />
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <button className="uk-accordion-title">Unpaid Deposits {this.state.loading ? '' : `(${this.state.unpaidDeposits.length} Items)`}</button>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="unpaid-deposits">
                                {
                                    this.state.showUnpaidDepositInfo && (
                                    <ul className="uk-tab">
                                        <li className={this.handleActiveTabUnpaidDeposit(1)}><a onClick={() => this.returnToUnpaidDeposit()}>Unpaid Deposits</a></li>
                                        <li className={this.handleActiveTabUnpaidDeposit(2)}><a>Deposit Information</a></li>
                                    </ul>)
                                }
                                <NewDynamicTable id="unpaid-deposits"
                                    data={this.state.unpaidDeposits}
                                    newSearch={this.state.newSearch}
                                    useDeleteButton={false}
                                    // dynamicActions={[{ name: 'fa fa-info-circle', function: this.handleUnpaidDepositClick, isIcon: true }]}
                                    usePaginator={true}
                                    changePage={this.changePageUnpaidDeposits}
                                    numberOfPages={this.state.totalPagesUnpaidDeposits}
                                    pageNumber={this.state.pagesUnpaidDeposits}
                                    numberPerPage={getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}
                                    useFilter={this.state.filter}
                                    filterFunction={() => this.getCustomerData('Unpaid Deposits')}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}