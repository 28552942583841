import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted, genericDeleteWithParameters } from "./Networking";
import jwt from 'jsonwebtoken';


export async function getActiveTradersMin(){
    try {
        let response = await genericGetWithParameters('Trader/GetActiveTradersMin', null);
        return response;
    } catch (error) {
        console.error(error);
    }
}