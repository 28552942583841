import React from 'react';

import {getLookUpTable} from '../../../../networking/Networking';
import {searchCountries, getCountryById} from '../../../../networking/NetworkingCountries';
import {getStatesByCountry, getStatesById} from '../../../../networking/NetworkingStates';
import DynamicSelect from '../../../shared/DynamicSelect';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import {getStyle, regularInputClass} from '../../../../styles/styles';
var style = getStyle();

export default class AddressInformationForm extends React.Component {

    state = {
        address:{
            type: '',
            defaultAddress: '',
            status: '',
            address1: '',
            address2: '',
            countryId: '',
            countryName: '',            
            stateCode: '',
            stateName: '',
            other: '',
            city: '',
            district: '',
            postalCode: '',
            poBox: '',
            sinceWhen: '',
        },
        countryID: '',
        referredBySelect: [],
        "Country": [],
        "Customer Address Status": [],
        "State": [],
        stateList: [],
        countryList: [],
        lookUpTables: [
            "Customer Address Status",
            "Contact Status"
        ],
    }    

    handleClearClick(){
        this.setState({
            address1: '',
            address2: '',
            city: '',
            district: '',
            postalCode: '',
            poBox: ''
        });
    }

    componentDidMount() {
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedContact !== {} && prevProps.selectedContact !== this.props.selectedContact){
            var address = this.props.selectedContact;
            console.log(address["Country ID"]);
            if(address["Country ID"] !== '' && address["Country ID"] !== null && address["Country ID"] !== undefined){          
                this.getCountry(address["Country ID"]);
                this.getStateFromContact(address["State ID"]);
                getStatesByCountry(address["Country ID"],1,100).then(                
                    (json) => {
                        var helper = [];
                        if(address["State ID"] !== '' && json.states !== undefined){
                            var states = json.states;
                        for(var i=0; i<states.length; i++){
                            states[i].value = states[i]["State Id"];
                            states[i].name = states[i]["State Name"];
                            helper.push(states[i]);
                        }
                        console.group("helper");
                        console.log(helper);
                        console.groupEnd();
                        this.setState({stateList: helper});                
                        }
                    })
            }
            console.log("Adress aca")
            console.log(address);       
            this.setState({
                address:{
                type: address["Address Type"],
                address1: address["Address Value"],
                status: address["Address Status"],
                address2: address["Address2"],
                city: address["City"],
                postalCode: address["Zip Code"],
                stateCode: address["State ID"],
                countryId: address["Country ID"],
                other: address["Other State"],
                defaultAddress: address["Default Address"],
                sinceWhen: address["Since When"],
                district: address["District"],
                poBox: address["POBox"]}
            });
        };
    }

    handleCopyFromCustomerClick(){

    }
    
    getCountry(id){
        getCountryById(id).then(
            (json) => {
                console.log(json);
                var country = json.country[0];
                var name = country['Country Id'] + ' - ' + country['Country Name'];
                this.setState({address: {...this.state.address, countryId: country.CountryId, countryName: name}});
            }
        );
    }

    getStateFromContact(id){
        getStatesById(id).then(
            (json) => {
                console.log(json);
                var state = json.state[0];
                this.setState({address: {...this.state.address, stateCode: state["State Id"], stateName: state["State Name"]}});
            }
        );
    }

    searchCountry(query){ 
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                for(var i=0; i<json.length; i++){
                    json[i].value = json[i].CountryId;
                    json[i].name = json[i].CountryIdName;
                    helper.push(json[i]);
                }
                console.log(helper);
                this.setState({countryList: helper});
                if(helper.length === 1){
                    var value = helper[0];
                    this.setState({address: {...this.state.address, countryId: value.CountryId, countryName: value.CountryIdName}});
                }
            }}
        );
    }

    getCountryValue(value){
        console.log(value);
        this.setState({address: {...this.state.address, countryId: value.CountryId, countryName: value.CountryIdName}}, ()=> this.props.getAddressInfo(this.state.address));
        if(value.value !== undefined){
            console.log("Value que llega al getStates");
            console.log(value.value);
            getStatesByCountry(value.CountryId,1,100).then(                
                (json) => {
                    var helper = [];
                    if(value.value !== '' && json.states !== undefined){
                        var states = json.states;
                    for(var i=0; i<states.length; i++){
                        states[i].value = states[i]["State Id"];
                        states[i].name = states[i]["State Name"];
                        helper.push(states[i]);
                    }
                    console.group("helper");
                    console.log(helper);
                    console.groupEnd();
                    this.setState({stateList: helper});                
                    }
                })
        }
    }

    getStatusSelect(value){
        this.setState({address: {...this.state.address, status: value.LookUpTableDetail}}, ()=> {console.log("Address"); console.log(this.state.address); this.props.getAddressInfo(this.state.address)});
    }

    getStateSelect(value){
        this.setState({address: {...this.state.address, stateCode: value.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    handleUpdateAddress1(event){
        this.setState({address: {...this.state.address, address1: event.target.value}}, ()=> {console.log("Address"); console.log(this.state.address);this.props.getAddressInfo(this.state.address)});
    }

    handleUpdateAddress2(event){
        this.setState({address: {...this.state.address, address2: event.target.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    handleUpdateCity(event){
        this.setState({address: {...this.state.address, city: event.target.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    handleUpdateDistrict(event){
        this.setState({address: {...this.state.address, district: event.target.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    handleUpdatePostalCode(event){
        this.setState({address: {...this.state.address, postalCode: event.target.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    handleUpdatePoBox(event){
        this.setState({address: {...this.state.address, poBox: event.target.value}}, ()=> this.props.getAddressInfo(this.state.address));
    }

    render() {
        
        return (
            <div className="">
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Copy Address</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <button className="uk-button uk-button-green" onClick={this.handleCopyFromCustomerClick.bind(this)} type="button">Copy from Customer</button>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Status</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect id="status-list" getValue={this.getStatusSelect.bind(this)} listHasPlaceholder={true} data={this.state["Customer Address Status"]} objValue={{value: this.state.address.status, LookUpTableDetail: this.state.address.status}}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Address 1 *</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="address1" type="text" placeholder="" value={this.state.address.address1} onChange={this.handleUpdateAddress1.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Address 2</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="address2" type="text" placeholder="" value={this.state.address.address2} onChange={this.handleUpdateAddress2.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Country *</label>                        
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect className={regularInputClass} id="country-list" placeholder="Select a country..." searchFunction={this.searchCountry.bind(this)} getValue={this.getCountryValue.bind(this)} data={this.state.countryList} objValue={{value: this.state.address.countryId, name: this.state.address.countryName, CountryId: this.state.address.countryId, CountryIdName: this.state.address.countryName}}/>
                            </div>                            
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Province / State *</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect id="status-list" getValue={this.getStateSelect.bind(this)} listHasPlaceholder={false} placeholder="Select a state..." data={this.state.stateList} objValue={{value: this.state.address.stateCode, name: this.state.address.stateName, "State Id": this.state.address.stateCode, "State Name": this.state.address.stateName}}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >City</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="city" type="text" placeholder="" value={this.state.address.city} onChange={this.handleUpdateCity.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >District</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="district" type="text" placeholder="" value={this.state.address.district} onChange={this.handleUpdateDistrict.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Postal / Zip Code</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="postalCode" type="text" placeholder="" value={this.state.address.postalCode} onChange={this.handleUpdatePostalCode.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >PO BOX</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" id="poBox" type="text" placeholder="" value={this.state.address.poBox} onChange={this.handleUpdatePoBox.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Since When</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <select className="uk-select">
                                    <option>Select an item...</option>
                                    <option>Customer (Buy CCY)</option>
                                    <option>Customer (Sell CCY</option>
                                    <option>Deal Profit</option>
                                </select>
                            </div>
                        </div>
                    </div>               
                </form>
            </div>
        );
    }

}