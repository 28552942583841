import {genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody} from './Networking';

export async function getNostroBankAccounts(){
    try {
        let params = {}
        let response = await genericGetWithParameters('Bank/GetNostroAccountsBanksMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBankAccountsByPerson(personID) {
    try {
        let params = {
            PersonID: personID
        }
        let response = await genericGetWithParameters('BankAccount/GetBankAccountsByPerson_min', params)
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function getBankAccountsByAccountID(bankAccountID) {
    try {
        let params = {
            BankAccountID: bankAccountID
        }
        let response = await genericGetWithParameters('BankAccount/GetBankAccountsByAccountID', params)
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function getBeneAccountByCustomerID(customerID, currencyID){
    try {
        let params = {
            CustomerID: customerID,
            CurrencyID: currencyID
        }
        let response = await genericGetWithParameters('BankAccount/GetBeneficiaryAccountByCustomerId_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBankAccount(model) {
    var method = '';
    var apiName = '';
    if (model.localAccount.BankAccountID === 0 || model.localAccount.BankAccountID === '') {
        method = 'POST';
        apiName = 'BankAccount/Post'

    } else {
        method = 'PUT';
        apiName = 'BankAccount/Put'
    }
    
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function deleteBankAccount(id){
    try{
        let params = {
            'BankAccountID': id
        };
        let response = await genericDeleteWithParameters('BankAccount/Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getNostroBankAccountsForFunds(currencyID){
    try {
        let params = {
            'CurrencyID': currencyID
        }
        let response = await genericGetWithParameters('BankAccount/GetNostroAccountsByCcy_ForFunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}