import React from 'react';
import CustomerInformationScreen from './CustomerInformationScreen';
import AddressScreen from './AddressesScreen';
import OnlineAccessInformationScreen from './OnlineAccessInformationScreen';
import TradingInformationScreen from './TradingInformationScreen';
import ContactsScreen from './ContactsScreen';
import AdditionalPropertiesScreen from './AdditionalPropertiesScreen';
import TasksListScreen from './TasksListScreen';
import ComplianceScreen from './ComplianceScreen';
import BankAccountsScreen from './BankAccountsScreen';
import BeneficiariesScreen from './BeneficiariesScreen';
import LimitProfilesScreen from './LimitProfilesScreen';
import { getCustomerByID } from '../../../../networking/NetworkingCustomers';

const titleStyle = {
    paddingLeft: "25px",
    paddingTop: "25px",
    paddingRight: "25px"
}

export default class CustomerScreen extends React.Component {

    state = {
        loading: true,
        viewHistory: false,
        id: 0
    }

    handleBackLink() {
        this.props.backLink();
    }

    componentDidMount(){
        getCustomerByID(this.props.customerID).then(
            (json) => {
                this.setState({
                    customer: json,
                    id: json.CustomerID,
                    loading: false
                });
            }
        );
    }

    render() {

        if (this.state.loading) {
            return (<div></div>);
        }

        return(
            <div>
                <h3 className="uk-heading-divider" style={titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button>
                    Customer - {this.props.customerName}
                </h3>
                <ul uk-tab="">
                    <li><a href="#">Customer Information</a></li>
                    <li><a href="#">Addresses</a></li>
                    {/* <li><a href="#">Comments</a></li> */}
                    {/* <li><a href="#">Online Access Information</a></li> */}
                    <li><a href="#">Trading Information</a></li>
                    <li><a href="#">Contacts</a></li>
                    {/* <li><a href="#">Commissions</a></li> */}
                    <li><a href="#">Additional Properties</a></li>
                    {/* <li><a href="#">Tasks Lists</a></li> */}
                    {/* <li><a href="#">Compliance</a></li> */}
                    {/* <li><a href="#">Direct Debit Accounts</a></li> */}
                    <li><a href="#">Beneficiaries</a></li>
                    {/* <li><a href="#">Default Outgoing Payments</a></li> */}
                    {/* <li><a href="#">Commission Details</a></li> */}
                    {/* <li><a href="#">Limit Profiles</a></li> */}
                    {/* <li><a href="#">Person Identifications</a></li> */}
                    {/* <li><a href="#">Commercial Information</a></li> */}
                    {/* <li><a href="#">Extended online properties</a></li> */}
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li><CustomerInformationScreen id={this.state.id} data={this.state.customer}/></li>
                    <li><AddressScreen data={this.state.customer.PersonID}/></li>
                    {/* <li><CommentsScreen/></li> */}
                    {/* <li><OnlineAccessInformationScreen id={this.state.id} customer={this.state.customer} /></li> */}
                    <li><TradingInformationScreen id={this.state.id} data={this.state.customer}/></li>
                    <li><ContactsScreen data={this.props.customerID}/></li>
                    {/* <li><CommissionsScreen/></li> */}
                    <li><AdditionalPropertiesScreen customerID={this.state.customer.CustomerID}/></li>
                    {/* <li><TasksListScreen customerID={this.state.customer.CustomerID}/></li> */}
                    {/* <li><ComplianceScreen customer={this.state.customer}/></li> */}
                    {/* <li><BankAccountsScreen  data={this.state.customer.PersonID} title={"Direct Debit"}/></li> */}
                    <li><BeneficiariesScreen id={this.state.id} customer={this.state.customer} data={this.state.customer.Name + " - " + this.state.customer.CustomerID}/></li>
                    {/* <li><DefaultOutgoingPaymentsScreen/></li> */}
                    {/* <li><CommissionDetailsScreen/></li> */}
                    {/* <li><LimitProfilesScreen id={this.state.id}/></li> */}
                    {/* <li><PersonIdentificationsScreen/></li> */}
                    {/* <li><CommercialInformationScreen/></li> */}
                    {/* <li><ExtendedOnlinePropertiesScreen/></li> */}
                </ul>
            </div>
        );
    }
}