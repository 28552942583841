import React from 'react';
import { getStyle } from '../../styles/styles';
import { resetPassword } from '../../networking/NetworkingUsers';
import AlertBox from '../shared/AlertBox';
var style = getStyle();

export default class ResetPasswordForm extends React.Component {

    state = {
        newPassword: '',
        repeatPassword: '',
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
    }

    handleChangePasswordClick = (event) => {
        event.preventDefault();
        if (this.state.newPassword === this.state.repeatPassword) {
            var {params} = this.props.match;
            let token = params.token;
            let model = {
                "ForgotPasswordKey": token,
                "NewPassword": this.state.newPassword
            }
            console.log(model);
            resetPassword(model).then(
                (json) => {
                    if (json !== undefined) {
                        if (json.status !== 200) {
                            this.setState({showAlertError: true, alertMessage: 'An error ocurred while changing the password', alertTitle: 'Error', newPassword: '', repeatPassword: ''});
                        } else {
                            this.setState({showAlert: true, alertTitle: 'Password changed'});
                        }
                    }
                }
            );
        } else {
            this.setState({showAlertError: true, alertMessage: 'Passwords don\'t match', alertTitle: 'Error'});
        }
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''},
            () => {
                window.location = '../';
            }
        );
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: ''});
    }

    handleUpdateNewPassword = (event) => {
        this.setState({ newPassword: event.target.value});
    }

    handleUpdateRepeatPassword = (event) => {
        this.setState({ repeatPassword: event.target.value});
    }

    render() {
        return (
            <div>
                <div className="uk-position-center uk-box-shadow-large">
                    <div className="uk-card uk-card-default uk-card-large uk-width-large">
                        <div className="uk-card-header">
                            <h3 className="uk-card-title">Reset Password</h3>
                        </div>
                        <div className="uk-card-body">
                            <form>
                                <div className="uk-margin">
                                    <input className="uk-input" type="password" placeholder="New password" onChange={this.handleUpdateNewPassword}  value={this.state.newPassword}/>
                                </div>
                                <div className="uk-margin">
                                    <input className="uk-input" type="password" placeholder="Repeat new password" onChange={this.handleUpdateRepeatPassword} value={this.state.repeatPassword}/>
                                </div>
                            </form>
                        </div>
                        <div className="uk-card-footer">
                            <div className="uk-margin">
                                <button className="uk-button uk-button-green uk-width-1-1" onClick={this.handleChangePasswordClick}>Change Password</button>
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError}/>
            </div>
        );
    }
}