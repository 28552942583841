import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import { getStyle } from '../../../../styles/styles';
var style = getStyle();

export default class RiskCalculationDialog extends React.Component {

    state = {
        newSearch: false,
        importance: 0,
        total: 0,
        totalRiskScore: 0
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            let reducer = (accumulator, currentValue) => accumulator + currentValue;
            let importance = this.props.riskScoreDetails.map((detail) => detail["Risk Importance"]).reduce(reducer);
            let total = this.props.riskScoreDetails.map((detail) => detail.Total).reduce(reducer);
            let totalRiskScore = total !== 0 ? Math.ceil(total / importance): 0;
            this.setState({
                importance,
                total,
                totalRiskScore
            })
        }
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.close} maxWidth={"md"} fullWidth={true}>
                <DialogContent>
                    <h3 style={{fontWeight: "bold"}}>Customer Risk Score Calculation</h3>
                    <div className="">
                        <NewDynamicTable hiddenHeaders={["Entity ID", "Entity Type", "Order", "Risk Score Text", "Score Calculated", "Parent Risk Score Text"]} 
                        data={this.props.riskScoreDetails} enableClick={false} useDeleteButton={false} useCheckBox={false} newSearch={this.state.newSearch} />
                        <div className="uk-margin-small uk-text-right">
                            <label className="uk-margin-large-right" style={{fontWeight: "bold"}}>Sub totals</label>
                            <label className="uk-margin-large-right uk-margin-left">{this.state.importance}</label>
                            <label className="uk-margin-small-right" style={{marginLeft: "5px"}}>{this.state.total}</label>
                        </div>
                        <div className="uk-margin-small-top uk-text-right">
                            <label className="uk-margin-xlarge-right" style={{fontWeight: "bold"}} >Total Risk Score</label>
                            <label className="uk-margin-large-right" style={{fontWeight: "bold"}}>{this.state.totalRiskScore}</label>
                        </div>
                        <div className="uk-text-right uk-margin-bottom" style={{marginTop: "-10px"}}>
                            <label className="uk-form-label" style={{fontSize: "10px", marginRight: "240px"}}>(Total / Importance)</label>
                        </div>
                        <div className="" style={{lineHeight: "1"}}>
                            <label className="uk-form-label" style={{fontSize: "10px", marginRight: "240px", color: "red"}}>* Please note, total risk score values are rounded up.</label>
                        </div>
                        <div className="" style={{lineHeight: "1"}}>
                            <label className="uk-form-label" style={{fontSize: "10px", marginRight: "240px", color: "red"}}>** When there are multiple items within a Risk Factor (i.e. A customer could have addresses in multiple countries, province/states). the risk scores of the group are added together and then averaged to come up with one overall risk score for the risk factor.</label>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}