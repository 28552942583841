import jwt from 'jsonwebtoken';
import {genericCallWithBody, genericGetWithParameters} from './Networking';

export async function getAllFees(description, agentCustomerID, page, items) {
    try {
        let params = {
            'description': description,
            "agentCustomerID": agentCustomerID,
            "page": page,
            "items": items
        };

        let response = await genericGetWithParameters('Fees/GetAgentFeesByDescription', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeeById(feeStructureId){
    try {
        let params = {
            'FeeStructureId': feeStructureId
        }

        let response = await genericGetWithParameters('Fees/GetFeesByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}


export async function addFee(model) {
    try {
        let response = await genericCallWithBody('POST', 'Fees/SaveFee', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function calculateWireItemFee(customerID, settlementCurrency, foreignCurrency, amount, customerRate, instrument, deliveryMethod){
    try {
        let params = {customerID: customerID,
            settlementCurrency: settlementCurrency,
            foreignCurrency: foreignCurrency,
            amount: amount,
            customerRate: customerRate,
            instrument: instrument,
            deliveryMethod: deliveryMethod,
        };
        let response = await genericGetWithParameters('Fees/CalculateWireItemFee', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}