import React from 'react';
import DatePicker from '../../../shared/DatePicker';
import DynamicSelect from '../../../shared/DynamicSelect';
import { regularSelectClass, notFilledSelectClass, notFilledInputClass, regularInputClass, getStyle } from '../../../../styles/styles';
import { getCountries, searchCountries } from '../../../../networking/NetworkingCountries';
import { getStatesByCountry } from '../../../../networking/NetworkingStates';
import { saveAddress } from '../../../../networking/NetworkingAddresses';
import AlertBox from '../../../shared/AlertBox';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { formatDate } from '../../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../../networking/Networking';
var style = getStyle();

export default class AddressForm extends React.Component {

    state = {
        address: {
            Address2: '',
            AddressId: '',
            AddressType: '',
            AddressValue: '',
            City: '',
            CountryId: '',
            CountryName: '',
            CreatedBy: '',
            CreatedOn: '',
            DefaultAddress: false,
            District: '',
            OtherState: '',
            POBox: '',
            SinceWhen: '',
            StateId: '',
            StateName: '',
            Status: 'Not verified',
            UpdateToken: '',
            UpdatedOn: '',
            ZipCode: ''
        },
        dropdowns: {
            countries: [],
            states: [],
            "Address Type": []
        },
        lookUpTables: [
            "Address Type"
        ],
        validations: {
            addressType: true,
            address1: true,
            country: true,
            state: true
        },
        addresses: [],
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
    }

    componentDidMount() {
        if (this.props.personID !== undefined) {
            var personID = this.props.personID;
            this.setState({address: {...this.state.address, PersonID: personID}})
        }
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        });

        getCountries(1, 250).then(
            (json) => {
                for (var i = 0; i < json.countries.length; i++) {
                    json.countries[i].value = json.countries[i].Country;
                    json.countries[i].name = json.countries[i]["Country Name"];
                }
                this.setState({ dropdowns: {...this.state.dropdowns, countries: json.countries}});
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedAddress !== this.props.selectedAddress) {
            var address = this.props.selectedAddress;
            if (address.CountryId !== undefined && address.CountryId !== "" && address.CountryId !== prevProps.selectedAddress.CountryId) {
                this.searchStates(address.CountryId);
            }
            this.setState({
                address: address,
                validations: {
                    addressType: true,
                    address1: true,
                    country: true,
                    state: true
                }
            })
        }
        if(prevProps.notFirstLoad !== this.props.notFirstLoad) {
            this.setState({notFirstLoad: this.props.notFirstLoad});
        }
    }

    searchStates(query) {
        getStatesByCountry(query, 1, 100).then(
            (json) => {
                var helper = [];
                if (json !== undefined) {                    
                    if(query !== '' && json.states !== undefined) {
                        var states = json.states;
                        for(var i = 0 ; i < states.length; i++) {
                            states[i].value = states[i]["State Id"];
                            states[i].name = states[i]["State Name"];
                            helper.push(states[i]);
                        }
                    }
                }
                this.setState({ dropdowns: {...this.state.dropdowns, states: helper}});
            }
        )
    }

    searchCountry(query) {
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({ dropdowns: {...this.state.dropdowns, countries: helper}});
                    if (helper.length === 1) {
                        var value = helper[0];
                        this.setState({address: {
                            ...this.state.address, CountryId: value.CountryId}
                        });
                    }
                }
            }
        );
        console.table(this.state.dropdowns.countries)
    }

    clearFields() {
        this.setState({
            dropdowns: {
                ...this.state.dropdowns, states: []
            }
        });
    }

    clearValidations() {
        this.setState({
            validations: {
                ...this.state.validations,
                addressType: true,
                address1: true,
                country: true,
                state: true
            }
        });
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    formatValidDatePicker(valid) {
        return valid ? "" : notFilledInputClass;
    }

    closeAlert() {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    validateRequiredFields() {
        var valid = true;
        var addressType = true, address1 = true, country =  true, state = true;
        if (this.state.address.AddressType === "select" || this.state.dropdowns["Address Type"].find(e => e.value === this.state.address.AddressType) === undefined) {
            valid = false;
            addressType = false;
        }
        if (this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) {
            valid = false;
            country = false;
        }
        if (this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) {
            valid = false;
            state = false;
        }
        if (this.state.address.AddressValue === '') {
            valid = false;
            address1 = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                addressType: addressType,
                address1: address1,
                country: country,
                state: state
            }
        })
        return valid;
    }

    validateRequiredFields1() {        
        if (this.state.address.AddressType === "select" || this.state.address.AddressType === '' || this.state.address.AddressType === undefined) {
            return false;
        }
        if (this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) {
            return false;
        }
        if (this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) {
            return false;
        }
        if (this.state.address.AddressValue === '') {
            return false;
        }
        return true;
    }

    validateAddressType() {
        if (this.state.address.AddressType === "select" || this.state.address.AddressType === '' || this.state.address.AddressType === undefined) {
            return false;
        }
        return true;
    }

    validateCountry() {
        if (this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) {
            return false;
        }
        return true;
    }

    validateState() {
        if (this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) {
            return false;
        }
        return true;
    }

    validateAddress1() {
        if (this.state.address.AddressValue === '') {
            return false;
        }
        return true;
    }

    handleSaveAddressClick(event) {
        event.preventDefault();
        if (this.validateRequiredFields()){
            saveAddress(this.state.address).then(
                (json) => {
                    if (json.updateToken !== undefined) {
                        this.setState({
                            address: {...this.state.address, UpdateToken: json.updateToken},
                            showAlert: true, alertTitle: 'Address successfully saved.'
                        });
                        this.clearFields();
                        this.clearValidations();
                        this.props.clearFields();
                    }
                    this.props.updateGrid();
                }
            )
        } else {
            this.setState({ notFirstLoad: true});
        }
    }

    handleClearClick(event) {
        event.preventDefault();
        this.clearFields();
        this.clearValidations();
        this.setState({ notFirstLoad: false});
        this.props.clearFields();
    }

    handleGetValueAddressType(value) {
        this.setState({
            address: {...this.state.address, AddressType: value.value}
        },
            () => {
                if (this.validateAddressType()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredFields1());
                } else {
                    this.props.isValid(false);
                }
            }
        );
    }

    handleGetValueCountry(value) {
        this.setState({
            address: {...this.state.address, CountryId: value.value, CountryName: value.name}
        },
            () => {
                if (this.validateCountry()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredFields1());
                } else {
                    this.props.isValid(false);
                }
            }
        );
        if (value.value !== undefined) {
            this.searchStates(value.value);
        }
    }

    handleGetValueState(value) {
        this.setState({
            address: {...this.state.address, StateId: value.value, StateName: value.name}
        },
            () => {
                if (this.validateState()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredFields1());
                } else {
                    this.props.isValid(false);
                }
            }
        );
    }

    handleUpdateAddress1(event) {
        this.setState({
            address: {...this.state.address, AddressValue: event.target.value}
        },
            () => {
                if (this.validateAddress1()) {
                    this.props.getAddressInfo(this.state.address);
                    this.props.isValid(this.validateRequiredFields1());
                } else {
                    this.props.isValid(false);
                }
            }
        );
    }

    handleUpdateAddress2(event) {
        this.setState({
            address: {...this.state.address, Address2: event.target.value}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateCity(event) {
        this.setState({
            address: {...this.state.address, City: event.target.value}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDefaultAddress(event) {
        this.setState({
            address: {...this.state.address, DefaultAddress: event.target.checked}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateDistrict(event) {
        this.setState({
            address: {...this.state.address, District: event.target.value}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdatePOBox(event) {
        this.setState({
            address: {...this.state.address, POBox: event.target.value}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateZipCode(event) {
        this.setState({
            address: {...this.state.address, ZipCode: event.target.value}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    handleUpdateSinceWhen(date) {
        this.setState({
            address: {...this.state.address, SinceWhen: formatDate(date)}
        },
            () => {
                this.props.getAddressInfo(this.state.address);
            }
        );
    }

    render() {

        var classNameAddressValue = regularInputClass;
        var classNameAddressType = regularSelectClass; 
        var classNameCountry = regularSelectClass;
        var classNameState = regularSelectClass;
        if((this.state.address.AddressValue === '') && this.state.notFirstLoad){
            classNameAddressValue = notFilledInputClass;
        }
        if((this.state.address.AddressType === "select" || this.state.address.AddressType === '' || this.state.address.AddressType === undefined) && this.state.notFirstLoad){
            classNameAddressType = notFilledSelectClass;
        }
        if((this.state.address.CountryId === "select" || this.state.address.CountryId === '' || this.state.address.CountryId === undefined) && this.state.notFirstLoad){
            classNameCountry = notFilledSelectClass;
        }
        if((this.state.address.StateId === "select" || this.state.address.StateId === '' || this.state.address.StateId === 0 || this.state.address.StateId === undefined) && this.state.notFirstLoad){
            classNameState = notFilledSelectClass;
        }

        return(
            <div>
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Address Type (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect className={classNameAddressType} objValue={{value: this.state.address.AddressType}} getValue={this.handleGetValueAddressType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Address Type"]} id="form-address-type" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Address (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className={classNameAddressValue} type="text" placeholder="" value={this.state.address.AddressValue} onChange={this.handleUpdateAddress1.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Address 2</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.Address2} onChange={this.handleUpdateAddress2.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Country (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect id="country-list" listHasPlaceholder={false} placeholder={"Select an Item..."} searchFunction={this.searchCountry.bind(this)} getValue={this.handleGetValueCountry.bind(this)}  data={this.state.dropdowns.countries} objValue={{value: this.state.address.CountryId, name: this.state.address.CountryName, CountryId: this.state.address.CountryId, CountryIdName: this.state.address.CountryName}}/> 
                                {/*<DynamicSelect className={classNameCountry} getValue={this.handleGetValueCountry.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.countries} objValue={{value: this.state.address.CountryId, name: this.state.address.CountryName, CountryId: this.state.address.CountryId, CountryName: this.state.address.CountryName}} id="form-provinces-countries" />*/}
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Province / State (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect className={classNameState} getValue={this.handleGetValueState.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.states} objValue={{value: this.state.address.StateId, name: this.state.address.StateName, "State Id": this.state.address.StateId, "State Name": this.state.address.StateName}} id="form-provinces-states" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >City</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.City} onChange={this.handleUpdateCity.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >District</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.District === null ? '' : this.state.address.District } onChange={this.handleUpdateDistrict.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Postal / Zip Code</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.ZipCode} onChange={this.handleUpdateZipCode.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >PO BOX</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" placeholder="" value={this.state.address.POBox === null ? '' : this.state.address.POBox} onChange={this.handleUpdatePOBox.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-margin">
                            <label className="uk-form-label"><input className="uk-checkbox" value={this.state.address.DefaultAddress} checked={this.state.address.DefaultAddress} onChange={this.handleUpdateDefaultAddress.bind(this)} type="checkbox" /> Default Address</label>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Since When</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker className="uk-input" value={this.state.address.SinceWhen} onDayChange={this.handleUpdateSinceWhen.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.mode === "Customer" || this.props.mode !== undefined ?
                            <div>
                                <button className="uk-button uk-button-green" onClick={this.handleSaveAddressClick.bind(this)} >Save</button>
                                <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)} >Clear</button>
                            </div>
                            : ""
                    }
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </div>
        );
    }
}