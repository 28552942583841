import React from 'react';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import Paginator from '../../../shared/Paginator';
import BeneficiaryInformationForm from '../beneficiaries/BeneficiaryInformationForm';
import { getStyle } from '../../../../styles/styles'
import AlertBox from '../../../shared/AlertBox';
import { getBeneficiariesByCustomer, getBeneficiariesByID, deleteBeneficiary, getNewBeneficiaryEmail } from '../../../../networking/NetworkingBeneficiaries';
import BeneficiaryMaintenanceScreen from '../beneficiaries/BeneficiaryMaintenanceScreen';
import NewBeneficiaryMaintenance from '../newBeneficiary/NewBeneficiaryMaintenance';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();
export default class BeneficiariesScreen extends React.Component {

    state = {
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        updateBene: false,
        newBene: false,
        customerID: '',
        beneID: '',
        selectedCustomer: [],
        customersBene: [],
        beneList: {},
        originList: {},
        type: '',
        totalpages: 0,
        currentPage: 1,
        newSearch: false,
        readyToDeleteBene: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        email: '',
        isEmailValidated: false
    }

    componentDidMount() {
        //GetForTable
        this.updatePage()
    }

    transformBeneList(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'Beneficiary ID': list[i].BeneficiaryID,
                'Beneficiary Name': list[i].BeneficiaryName,
                'Country': list[i].CountryName,
                'Currency': list[i].Currency,
                'Risk Score': list[i].BeneficiaryRiskScore,
                'Beneficiary Status': list[i].BeneficiaryStatus
            });
        }
        return helper;
    }

    handleAddClick() {
        this.setState({ updateBene: true, type: '', beneID: '', beneList: {} });
    }
    handleSendMailClick() {
        if (this.state.isEmailValidated) {
            getNewBeneficiaryEmail(this.props.id, this.props.customer.PersonID, this.state.email).then(
                (json) => {
                    console.log(json);
                    this.setState({ showAlert: true, alertTitle: 'Send Beneficiary invitation successfully.', email: '' });
                }
            );
        } else {
            this.setState({ showAlert: true, alertTitle: 'Invalid email. Please, try again.' });
        }
    }

    handleRowClick(obj) {
        console.group("Beneficiary handleRowClick")
        console.log(obj);
        console.groupEnd();
        getBeneficiariesByID(obj['Beneficiary ID'], 1).then(
            (json) => {
                console.log(json.beneficiary);
                this.setState({ beneList: json.beneficiary[0], type: json.beneficiary[0]['BeneficiaryType'], beneID: json.beneficiary[0]['BeneficiaryId'], updateBene: true });
            }
        );
    }
    handleDeleteClick(obj) {
        console.group("Beneficiary handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteBene: obj });
        /*deleteBeneficiary(obj['Beneficiary ID']){}
        */
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteBene: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() {
        deleteBeneficiary(this.state.readyToDeleteBene['Beneficiary ID']).then(
            (value) => {
                this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                this.updatePage();
            }
        )
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }
    handleBackLink() {
        this.setState({ updateBene: false, newBene: false });
    }
    changePage(value) {
        this.setState({ currentPage: value })
    }
    updatePage() {
        getBeneficiariesByCustomer(this.props.id, 0).then(
            (json) => {
                if (json.beneficiaries != undefined) {
                    var helper = this.transformBeneList(json.beneficiaries);
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount / itemsPerPage);
                    var helper = this.transformBeneList(json.beneficiaries);
                    //bene
                    this.setState({ totalpages: pages, newSearch: true, customersBene: helper });
                } else { }
                console.log(this.state.customersBene);
            }
        )
    }
    isEmailValid(validator) {
        if (validator) {
            this.setState({ isEmailValidated: true });
        } else {
            this.setState({ isEmailValidated: false });
        }
    }
    handleUpdateEmail(event) {
        this.setState({ email: event.target.value },
            () => {
                if (this.validateEmail()) {
                    this.isEmailValid(true);
                } else {
                    this.isEmailValid(false);
                }
            });
    }
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email.match(regex)) {
            return false;
        }
        return true;
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }
    getAllBeneficiaries = async () => {
        var json = await getBeneficiariesByCustomer(this.props.id, 0);
        var helper = this.transformBeneList(json.beneficiaries);
        return helper;
    }

    render() {
        if (this.state.updateBene && !this.state.newBene) {
            return <BeneficiaryMaintenanceScreen updateFunctionOrigin={this.updatePage.bind(this)}
                backLink={this.handleBackLink.bind(this)} beneID={this.state.beneID} selectedBene={this.state.beneList}
                type={this.state.type} customerID={this.props.id} customerName={this.props.data} />
        }
        if (this.state.newBene && !this.state.updateBene) {
            return <NewBeneficiaryMaintenance />
        }

        return (
            <div>
                <div className="uk-grid">
                    <div className="uk-form-controls uk-width-3-5">
                        <h3 className="">Beneficiaries</h3>
                    </div>
                    <div className="uk-width-2-5">
                        <div className="uk-grid">
                            <div className="uk-form-controls uk-width-2-3 uk-text-right" style={{ marginTop: '5px' }}>
                                <input disabled={true} className="uk-input" id="sendMail" type="text" value={this.state.email} placeholder="Enter an email..." onChange={this.handleUpdateEmail.bind(this)} />
                            </div>
                            <div className="uk-width-1-3 uk-text-right">
                                <button disabled={true} className="uk-button uk-button-green" onClick={this.handleSendMailClick.bind(this)}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ margin: '5px' }} />
                <NewDynamicTable key="beneficiary-screen"
                    hiddenHeaders={['Beneficiary ID']}
                    data={this.state.customersBene}
                    enableClick={true}
                    clickFunction={this.handleRowClick.bind(this)}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick.bind(this)}
                    enableSortByHeader={true}
                    usePaginator={true}
                    changePage={this.changePage.bind(this)}
                    numberOfPages={this.state.totalpages}
                    pageNumber={this.state.currentPage}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    useFilter={true}
                    filterFunction={this.getAllBeneficiaries} />
                <div>
                    <button disabled={true} className="uk-button uk-button-green" onClick={this.handleAddClick.bind(this)}>Add Beneficiary</button>
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Beneficiary?"
                    message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Beneficiary successfully removed." message="" type="Ok" okClick={this.okSuccessAlert.bind(this)} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}