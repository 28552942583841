import React from 'react';
import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../../../styles/styles';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTable } from '../../../../networking/Networking';
import { getNostroBankAccounts} from '../../../../networking/NetworkingBankAccounts';
import { getTradingInformationByCustomerID, updateCustomerTradingInformation } from '../../../../networking/NetworkingCustomers';
var style = getStyle();

export default class TradingInformationScreen extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tradingInformation: 
            {
                Trader: '',
                TraderID: '',
                CustomerID: '',
                DefaultDepositPercent: '',
                MaximumExposureRatio: '',
                MaximumExposureLimit: '',
                ExposureLimitType: '',
                DefaultIncomingNostroBankID: '',
                DefaultIncomingNostroBankName: '',
                DefaultOutgoingNostroBankID: '',
                DefaultOutgoingNostroBankName: '',
                AverageTradeSize: '',
                ConfirmationType: '',
                MonthlyTradingVolume: '',
                ConfirmationLimit: '',
                EmailConfirmations: false,
                FaxConfirmations: false,
                DealNotification: false,
                Email: '',
                SMSConfirmation: false,
                MobilePhone: '',
                DefaultSettlementInstrument: '',
                DefaultDeliveryMethod: '',
                CreditLimit: '',
                ManualRateLimit: '',
                SpotLimit: '',
                ForwardLimit: '',
                SpreadHistoryDays: '',
                OnlineDealAlert: '',
                SpotLimitWarningOrEnforce: '',
                ForwardLimitWarningOrEnforce: '',
                BankFixInterface: '',
                UpdatedBy: '',
                UpdateToken: ''
            },
            validations: {
                trader: true,
                depositPercentage: true,
                creditLimit: true,
                incomingBank: true,
                outgoingBank: true,
                listMissing:[]
            },
            dropdowns: {
                traders: [],
                "Confirmation Type": [],
                "Incoming Instruments": [],
                "Settlement Delivery Method": [],
                "BankInterfaces": [],
                banks: [],
            },
            lookUpTables: [
                "Confirmation Type",
                "Incoming Instruments",
                "Settlement Delivery Method",
                "BankInterfaces"
            ],
            showAlert: false,
            alertTitle: '',
            alertMessage: '',
        }
    }

    componentDidMount() 
    {
        var propsCustomer = this.props.data;
        var customerID = 0;
        if(propsCustomer.CustomerID != null){
            customerID = propsCustomer.CustomerID;
            getTradingInformationByCustomerID(customerID).then(
                (value) => {
                    this.setState({tradingInformation: value});
                }
            );
        }
        
        var checkRatio = false;
        var checkLimit = false;
        if(propsCustomer.ExposureLimitType != null){
            if(propsCustomer.ExposureLimitType === "Ratio"){
                checkRatio = true;
            }
            else if(propsCustomer.ExposureLimitType === "Amount"){
                checkLimit = true;        
            }
        }
        getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({dropdowns: {...this.state.dropdowns, traders: json.traders}});
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        }
        );
        getNostroBankAccounts().then(
            (json) => {
                for (var i = 0; i < json.banks.length; i++){
                    json.banks[i].name = json.banks[i].BankName;
                    json.banks[i].value = json.banks[i].BankID;
                }
                this.setState({dropdowns: {...this.state.dropdowns, banks: json.banks}});
            }
        );
    }

    validateRequiredFields() 
    {
        var valid = true
        var trader = true
        var incomingBank = true
        var outgoingBank = true
        var depositPercentage = true
        var creditLimit = true

        console.log("----> "+ JSON.stringify(this.state.dropdowns.traders.find(e => 
            e.TraderID === parseInt(this.state.tradingInformation.TraderID))))

        var listItems =[]

        if (this.state.tradingInformation.TraderID === "select" || 
            this.state.dropdowns.traders.find(e => 
                String.toString(e.TraderID) === String.toString(this.state.tradingInformation.TraderID)) === undefined
            )
        {
            valid = false;
            trader = false;
            listItems.push("Trader")
        }

        if (this.state.tradingInformation.DefaultDepositPercent === "select" || 
            this.state.tradingInformation.DefaultDepositPercent === '' || 
            this.state.tradingInformation.DefaultDepositPercent === undefined ||
            this.state.tradingInformation.DefaultDepositPercent < 0) 
        {
            valid = false;
            depositPercentage = false;
            listItems.push("Default Deposit Percent")
        }

        if(this.state.tradingInformation.CreditLimit === undefined || 
           this.state.tradingInformation.CreditLimit === "" || 
           this.state.tradingInformation.CreditLimit < 0)
        {
            valid = false;
            creditLimit = false;
            listItems.push("Credit Limit")
        }

        this.setState({
            validations: {
                ...this.state.validations,
                trader: trader,
                incomingBank: incomingBank,
                outgoingBank: outgoingBank,
                depositPercentage: depositPercentage,
                creditLimit: creditLimit,
                listMissing: listItems
            }
        });

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleUpdateTradingInformationClick(event) {
        console.log("TradingInformation: "+ JSON.stringify(this.state.tradingInformation))
        event.preventDefault();
        if (this.validateRequiredFields()) {
            updateCustomerTradingInformation(this.state.tradingInformation).then(
                (json) => {
                    console.log(json);
                    if(json.Message !== "" && json.Message !== undefined)
                    {
                        this.setState({  
                            showAlert: true, 
                            alertTitle: json.Message
                        });
                    }else
                    {
                        if(json.updateToken !== "" && json.updateToken !== undefined)
                        {
                            this.setState({  
                                tradingInformation: {
                                    ...this.state.tradingInformation, UpdateToken: json.updateToken
                                },
                                showAlert: true, 
                                alertTitle: 'Saved',
                                alertMessage: 'Trading Information successfully saved.'
                            });
                        }
                    }
                }
            )
        }
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    handleGetValueTrader(value) {
        this.setState({
            tradingInformation: {...this.state.tradingInformation, TraderID: value.value, Trader: value.name},
            validations: {...this.state.validations, trader: true}
        });
    }

    handleGetValueNostroBank(value) {
        this.setState({
            tradingInformation: {...this.state.tradingInformation, bank: value.value},
            validations: {...this.state.validations, bank: true}
        });
    }

    handleGetValueConfirmationType (value) {
        this.setState({tradingInformation: {...this.state.tradingInformation, ConfirmationType: value.value}});
    }
    
    handleGetValueInterface (value) {
        this.setState({tradingInformation: {...this.state.tradingInformation, BankFixInterface: value.value}});
    }

    handleGetValueSpotLimitWarningOrEnforce (value){
        this.setState({tradingInformation: {...this.state.tradingInformation, SpotLimitWarningOrEnforce: value.value}});
    }

    handleGetValueForwardLimitWarningOrEnforce (value){
        this.setState({tradingInformation: {...this.state.tradingInformation, ForwardLimitWarningOrEnforce: value.value}});
    }

    handleUpdateDepositPercentage(event) {
        this.setState({
            tradingInformation: {
                ...this.state.tradingInformation, 
                DefaultDepositPercent: event.target.value
            },
            validations: {...this.state.validations, depositPercentage: true}
        });
    }

    handleUpdateMaximumExposureRatio(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, MaximumExposureRatio: event.target.value}});
    }

    handleUpdateExposureLimitType(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, ExposureLimitType: event.target.value}});
    }

    handleUpdateMaximumExposureLimit(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, MaximumExposureLimit: event.target.value}});
    }

    handleUpdateTradeAverageSize(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, AverageTradeSize: event.target.value}});
    }

    handleUpdateMonthlyAverageVolume(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, MonthlyTradingVolume: event.target.value}});
    }

    handleUpdateConfirmationLimit(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, ConfirmationLimit: event.target.value}});
    }

    handleUpdateEmailConfirmations(event){
        this.setState({tradingInformation: {...this.state.tradingInformation, EmailConfirmations: event.target.checked}});
    }

    handleUpdateFaxConfirmations(event){
        this.setState({tradingInformation: {...this.state.tradingInformation, FaxConfirmations: event.target.checked}});
    }

    handleUpdateDealNotification(event){
        this.setState({tradingInformation: {...this.state.tradingInformation, DealNotification: event.target.checked}})
    }

    handleUpdateEmail(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, Email: event.target.value}});
    }
    
    handleUpdateSmsCellNumber(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, MobilePhone: event.target.value}});
    }

    handleUpdateSMSConfirmation(event){
        this.setState({tradingInformation: {...this.state.tradingInformation, SMSConfirmation: event.target.checked}})
    }
    
    handleUpdateCreditLimit(event) {
        this.setState({
            tradingInformation: {
                ...this.state.tradingInformation, 
                CreditLimit: event.target.value
            },
            validations: {...this.state.validations, creditLimit: true}
        });
    }
    
    handleUpdateManualRateLimit(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, ManualRateLimit: event.target.value}});
    }
    
    handleUpdateSpotLimit(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, SpotLimit: event.target.value}});
    }
    
    handleUpdateForwardLimit(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, ForwardLimit: event.target.value}});
    }
    
    handleUpdateDaysOfSpreadHistory(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, SpreadHistoryDays: event.target.value}});
    }
    
    handleUpdateOnlineDealAlert(event) {
        this.setState({tradingInformation: {...this.state.tradingInformation, OnlineDealAlert: event.target.value}});
    }
    
    render() {
        return(
            <div>
                <div className="">
                    <form className="uk-form-horizontal uk-margin-large">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel} >Trader (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.trader)} objValue={{value: this.state.tradingInformation.TraderID, name: this.state.tradingInformation.Trader}} getValue={this.handleGetValueTrader.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.traders} id="form-trader" />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility:"hidden"}}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className="uk-input" type="text" />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}>Deposit Percentage (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className={this.formatValidInput(this.state.validations.depositPercentage)} type="number" placeholder="0.00" value={this.state.tradingInformation.DefaultDepositPercent} onChange={this.handleUpdateDepositPercentage.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label">Maximum Exposure Ratio</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.MaximumExposureRatio} onChange={this.handleUpdateMaximumExposureRatio.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label"> </label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} value="Ratio" className="uk-radio" type="radio" name="chkExposureLimitType" onChange={this.handleUpdateExposureLimitType.bind(this)} checked={this.checkRatio}/>
                                </div>
                            </div>

                            <div className="uk-width-1-2" style={{visibility:"hidden"}}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label">Maximum Exposure Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.MaximumExposureLimit} onChange={this.handleUpdateMaximumExposureLimit.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label"> </label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} value="Limit" className="uk-radio" type="radio" name="chkExposureLimitType" onChange={this.handleUpdateExposureLimitType.bind(this)} checked={this.checkLimit}/>
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Default Incoming Nostro Bank</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.incomingBank)} objValue={{value: this.state.tradingInformation.DefaultIncomingNostroBankName}} getValue={this.handleGetValueNostroBank.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-incomingBank" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Default Outgoing Nostro Bank</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.outgoingBank)} objValue={{value: this.state.tradingInformation.DefaultOutgoingNostroBankName}} getValue={this.handleGetValueNostroBank.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-outgoingBank" />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Trade Average Size</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.AverageTradeSize} onChange={this.handleUpdateTradeAverageSize.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Confirmation Type</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.ConfirmationType}} getValue={this.handleGetValueConfirmationType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Confirmation Type"]} id="form-confirmation-type" />
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Monthly Average Volume</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.MonthlyTradingVolume} onChange={this.handleUpdateMonthlyAverageVolume.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Confirmation Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.ConfirmationLimit} onChange={this.handleUpdateConfirmationLimit.bind(this)} />
                                </div>
                            </div>

                            <div className="uk-width-1-2 uk-margin">
                                <label className="uk-form-label">Confirmation Delivery</label>
                                <label disabled={true} className="uk-margin-right"><input disabled={true} value="Email" className="uk-radio" type="radio" name="radioDeliveryConfo" onChange={this.handleUpdateEmailConfirmations.bind(this)} checked={this.state.tradingInformation.EmailConfirmations}/> Email</label>
                                <label><input disabled={true} value="Fax" className="uk-radio" type="radio" name="radioDeliveryConfo" onChange={this.handleUpdateFaxConfirmations.bind(this)} checked={this.state.tradingInformation.FaxConfirmations}/> Fax</label>
                                
                            </div>
                            <div className="uk-width-1-2 uk-margin">
                                <label disabled={true} className="uk-form-label"><input disabled={true} className="uk-checkbox" value={this.state.tradingInformation.DealNotification} checked={this.state.tradingInformation.DealNotification} onChange={this.handleUpdateDealNotification.bind(this)} type="checkbox" />Deal Notification</label>
                            </div>
                            
                            <div className="uk-width-1-2" style={{visibility:"hidden"}}>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Email</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.Email} onChange={this.handleUpdateEmail.bind(this)} />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2 uk-margin">
                                <label disabled={true} className="uk-form-label"><input disabled={true} className="uk-checkbox" value={this.state.tradingInformation.SMSConfirmation} checked={this.state.tradingInformation.SMSConfirmation} onChange={this.handleUpdateSMSConfirmation.bind(this)} type="checkbox" />SMS Confirmation</label>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">SMS Cell Number</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.MobilePhone} onChange={this.handleUpdateSmsCellNumber.bind(this)} />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Default Settlement Instrument</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.DefaultSettlementInstrument}} getValue={this.handleGetValueConfirmationType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Incoming Instruments"]} id="form-incoming-instruments" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Default Delivery Method</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.DefaultDeliveryMethod}} getValue={this.handleGetValueConfirmationType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Settlement Delivery Method"]} id="form-settlement-delivery-method" />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Credit Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className={this.formatValidSelect(this.state.validations.creditLimit)} type="number" placeholder="0.00" value={this.state.tradingInformation.CreditLimit} onChange={this.handleUpdateCreditLimit.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Manual Rate Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.ManualRateLimit} onChange={this.handleUpdateManualRateLimit.bind(this)} />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-4">
                                <label className="uk-form-label">Spot Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.SpotLimit} onChange={this.handleUpdateSpotLimit.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label" > </label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <select disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.SpotLimitWarningOrEnforce}} getValue={this.handleGetValueSpotLimitWarningOrEnforce.bind(this)}>
                                        <option>Select an item...</option>
                                        <option>Warning</option>
                                        <option>Enforce</option>
                                    </select>
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label">Forward Limit</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="0.00" value={this.state.tradingInformation.ForwardLimit} onChange={this.handleUpdateForwardLimit.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-4">
                                <label className="uk-form-label" > </label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <select disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.ForwardLimitWarningOrEnforce}} getValue={this.handleGetValueForwardLimitWarningOrEnforce.bind(this)}>
                                        <option>Select an item...</option>
                                        <option>Warning</option>
                                        <option>Enforce</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2">
                                <label className="uk-form-label"># Days of Spread History</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="number" placeholder="" value={this.state.tradingInformation.SpreadHistoryDays} onChange={this.handleUpdateDaysOfSpreadHistory.bind(this)} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={{visibility:"hidden"}}>Online Deal Alert</label>
                                <div className="uk-margin" style={{visibility:"hidden"}}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.OnlineDealAlert} onChange={this.handleUpdateOnlineDealAlert.bind(this)} />
                                </div>
                            </div>
                            
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Interface</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.tradingInformation.BankFixInterface}} getValue={this.handleGetValueInterface.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["BankInterfaces"]} id="form-bank-interfaces" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button disabled={true} className="uk-button uk-button-green" onClick={this.handleUpdateTradingInformationClick.bind(this)}>Save</button>
                        </div>
                    </form>
                    <AlertBox 
                        open={this.state.showAlert} 
                        onClose={this.closeAlert.bind(this)} 
                        title={this.state.alertTitle} 
                        message={this.state.alertMessage}
                        type="Ok" 
                        okClick={this.closeAlert.bind(this)}
                        messageBoldList={this.state.validations.listMissing}
                    />
                </div>
            </div>
        );
    }
}