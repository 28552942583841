import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import {BeneficiaryType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class BeneficiaryAdditionalProperties extends React.Component {
    state = {
        beneficiaryID: 0,
        entityPropertiesSaveProcess: false,
    }

    onFinishEntityPropertiesSaveProcess(){
        this.props.getSaveProperties(false);
    }

    render(){
        let componentID = 'entity-properties-beneficiary'+ this.props.beneficiaryID;
        var section = (
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties id={componentID} fullScreen={true} entityType={BeneficiaryType} entityID={this.props.beneficiaryID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                    </div>
                </form>
            )
        if(this.props.beneficiaryID === ''){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create beneficiary first.</h5>
                </div>
            )
        }
        return(
            <div>
            {section}
            </div>
        );
    }
}