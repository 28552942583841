import * as countries from 'i18n-iso-countries';
import { regularInputClass, notFilledInputClass, notFilledSelectClass, regularSelectClass } from '../styles/styles';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export function formatToCurrency(amount){
    return parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
//module.exports.formatToCurrency = formatToCurrency;

export function formatToRate(rate){
    return parseFloat(rate).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
//module.exports.formatToRate = formatToRate;

export function formatDateToDDMMYYYY(date){
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
}

export function formatDateString(dateString){
    const d = new Date(dateString);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
//module.exports.formatDate = formatDate;

export function formatDate(date){
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
}

function addZero(i) {
    if (i < 10) {
    i = "0" + i;
    }
    return i;
}

export function getHoursDate(dateString) {
    var d = new Date(dateString);

    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());

    return [h,m,s].join(':');
}
//module.exports.getHoursDate = getHoursDate;

export function toDDMMMFormat(stringDate){
    var date = new Date(stringDate);
    var month_names =["Jan","Feb","Mar",
                      "Apr","May","Jun",
                      "Jul","Aug","Sep",
                      "Oct","Nov","Dec"];
    var day = date.getDate();
    var month_index = date.getMonth();
    var year = date.getFullYear();
    return month_names[month_index] + " " + day;
}
//module.exports.toDDMMMFormat = toDDMMMFormat;

export function getYear(stringDate){
    var date = new Date(stringDate);
    return date.getFullYear();
}
//module.exports.getYear = getYear;

export function getCountryName(isoCode){
    return countries.getName(isoCode,'en');
}

export function titleCase(str) {
    if (str.includes(' ')){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        return splitStr.join(' ');
    } else {
        return str;
    } 
}

export function addDays(date, days) {
    var result = date;
    result.setDate(date.getDate() + days);
    return result;
}

export const removeWhiteSpace = (string) => string.replace(/\s/g, '');

export const formatValidSelect = (valid) => valid ? regularSelectClass : notFilledSelectClass;

export const formatValidInput = (valid) => valid ? regularInputClass : notFilledInputClass;

export const formatValidDatePicker = (valid) => valid ? regularInputClass : notFilledInputClass;
