import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted } from "./Networking";
import jwt from 'jsonwebtoken';

export async function sendForgotPasswordEmail(applicationName, userName){
    try {
        let params = {
            'applicationName': applicationName,
            'userName': userName
        };
        let response = await genericGetWithParametersNoEncrypted('User/SendForgotPasswordEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function resetPassword(model) {
    try {
        let response = await genericCallWithBody('POST', "User/ResetPassword", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListOfUserActions() {
    try {
        let userID = await sessionStorage.getItem('UserID');
        let params = {
            'UserID': userID,
        };
        let response = await genericGetWithParameters('UserActions/Get',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}export async function getListOfBranch() {
    try {
        let response = await genericGetWithParameters('User/GetUserBranches_min', null);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getBusinessUnits(countryID) {
    try {
        let params = {
            'CountryID': countryID,
        };
        let response = await genericGetWithParameters('User/GetUserBusinessUnits_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getReportTo(appName) {
    try {
        let params = {"ApplicationName":appName,};
        let response = await genericGetWithParametersNoEncrypted('User/GetUserReportsTo',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getAllUsers(appName) {
    try {
        let params = {"ApplicationName":appName,};
        let response = await genericGetWithParametersNoEncrypted('User/GetAllUsers',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveUser(model){
    try {
        console.log(model);
        let response = await genericCallWithBody('POST','User/CreateUser',model)
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getUsersRol(model){
    try {
        console.log(model);
        let response = await genericCallWithBody('POST','User/GetUsersByCustomTextAndRole',model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getUsersByName(appName, username) {
    try {
        let params = {
            'ApplicationName': appName,
            'UserName': username
        };
        let response = await genericGetWithParametersNoEncrypted('User/GetUserByUserName',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}