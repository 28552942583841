import React from 'react';
import {getStyle, notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass} from '../../styles/styles';

import {getEntityProperties, saveEntityProperty, deleteEntityProperty} from '../../networking/NetworkingEntityProperties';
import {titleCase} from '../../helpers/FormatHelper';

import {TextDataType, NumericDataType, DateTimeDataType} from '../../constants/DataTypeNames';

import {EntityPropertyLinkType} from '../../constants/LinkTypeNames';

import AttachmentsTable from './AttachmentsTable';
import DatePicker from './DatePicker';
import NumberInput from './NumberInput';
import TableFilter from './TableFilter';
import { blue } from '@material-ui/core/colors';

import PlusIcon from '../../css/custom-icons/plus.svg';
import MinusIcon from '../../css/custom-icons/minus.svg';

var style = getStyle();
var counter = 1;

class TextProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: regularInputClass
    }

    componentDidMount(){
        // console.groupCollapsed('TextProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description;
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({id: newId});
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #'+ idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({idAttachments: idAttachments, toggleAttachments: toggle});
        if(this.props.property.TextValue !== null){
            this.setState({value: this.props.property.TextValue});
        } else {
            this.setState({value: ''});
        }
        this.inputStyle();
    }

    componentDidUpdate(prevProps){
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID){
            if(this.props.property.TextValue !== null){
                this.setState({value: this.props.property.TextValue});
            } else {
                this.setState({value: ''});
            }
            this.inputStyle();
        }
    }

    onChange(event){
        this.setState({value: event.target.value});
        var newProperty = this.props.property;
        newProperty.TextValue = event.target.value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    onDelete(){
        /*if(this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined){
            deleteEntityProperty(this.props.property.EntityPropertyID).then((value)=>{
                this.props.onDelete();
            })
        }*/
    }

    inputStyle(){
        if (this.props.property.Mandatory){
            if((this.props.property.TextValue === null || this.props.property.TextValue === '') || this.state.value === ''){
                this.setState({style: notFilledInputClass});
            } else {
                this.setState({style: regularInputClass});
            }
        } else {
            this.setState({style: regularInputClass});
        }
    }

    render(){
        var readOnly = this.props.property.ReadOnly;
        var attachmentsText = "No Attachments";
        if(this.props.property.Attachments > 0){
            attachmentsText = this.props.property.Attachments + " Attachment";
            if(this.props.property.Attachments > 1){
                attachmentsText = this.props.property.Attachments + " Attachments";
            }
        }
        var input = [];
        if(readOnly){
            input.push(<input disabled={true} key={this.state.id} style={{background: this.props.backgroundColor}} className={this.state.style} id={this.state.id} type="text" placeholder="" value={this.state.value} readOnly/>)
        } else {
            input.push(<input disabled={true} key={this.state.id} className={this.state.style} style={{background: this.props.backgroundColor}} id={this.state.id} type="text" placeholder="" value={this.state.value} onChange={this.onChange.bind(this)}/>);
        }
        return(
            <div className="uk-grid  property-box" style={{background: this.props.backgroundColor}}>
                
                <div className="uk-width-11-12">
                    <div className="uk-margin">
                        <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties" >
                        <i className="fa fa-fw fa-paperclip uk-align-center" uk-tooltip={attachmentsText} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                    </div>
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-eraser uk-align-center" onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} style={{marginTop: '10px'}} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID} />
                </div>
            </div>
        );
    }

}

class DateProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: '',
    }

    componentDidMount(){
        // console.groupCollapsed('DateProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description; 
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({id: newId});
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #'+ idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({idAttachments: idAttachments, toggleAttachments: toggle});
        if(this.props.property.DateValue !== null){
            this.setState({value: this.props.property.DateValue});
        } else {
            this.setState({value: ''});
        }
        this.inputStyle();
    }

    componentDidUpdate(prevProps){
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID){
            if(this.props.property.DateValue !== null){
                this.setState({value: this.props.property.DateValue});
            } else {
                this.setState({value: ''});
            }
            this.inputStyle();
        }
    }

    onChange(value){
        this.setState({value: value});
        var newProperty = this.props.property;
        newProperty.DateValue = value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    onDelete(){
        if(this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined){
            deleteEntityProperty(this.props.property.EntityPropertyID).then((value)=>{
                this.props.onDelete();
            })
        }
    }

    inputStyle(){
        if (this.props.property.Mandatory){
            if((this.props.property.DateValue === null || this.props.property.DateValue === '') || this.state.value === ''){
                this.setState({style: notFilledInputClass});
            } else {
                this.setState({style: regularInputClass});
            }
        } else {
            this.setState({style: regularInputClass});
        }
    }

    render(){
        var readOnly = this.props.property.ReadOnly;
        var attachmentsText = "No Attachments";
        if(this.props.property.Attachments === 1) {
            attachmentsText = this.props.property.Attachments + " Attachment";
        }
        if(this.props.property.Attachments > 1){
            attachmentsText = this.props.property.Attachments + " Attachments";
        }
        var input = [];
        if(readOnly){
            input.push(<input disabled={true} key={this.state.id} style={{background: this.props.backgroundColor}} className={this.state.style} id={this.state.id} type="text" placeholder="" value={this.state.value} readOnly/>)
        } else {
            input.push(<DatePicker disabled={true} key={this.state.id} style={{background: this.props.backgroundColor}} className={this.state.style} value={this.state.value} onDayChange={this.onChange.bind(this)}/>);
        }
        return(
            <div className="uk-grid  property-box" style={{background: this.props.backgroundColor}}>
                <div className="uk-width-11-12" >
                    <div className="uk-margin">
                        <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-paperclip uk-align-center" uk-tooltip={attachmentsText} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                    </div>
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-eraser uk-align-center" onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID} />
                </div>
            </div>
        );
    }
}

class NumericProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: ''
    }

    componentDidMount(){
        // console.groupCollapsed('NumericProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description; 
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({id: newId});
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #'+ idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({idAttachments: idAttachments, toggleAttachments: toggle});
        if(this.props.property.NumValue !== null){
            this.setState({value: this.props.property.NumValue});
        } else {
            this.setState({value: ''});
        }
        this.inputStyle();
    }

    componentDidUpdate(prevProps){
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID){
            if(this.props.property.NumValue !== null){
                this.setState({value: this.props.property.NumValue});
            } else {
                this.setState({value: ''});
            }
            this.inputStyle();
        }
    }

    onChange(event){
        this.setState({value: event.target.value});
        var newProperty = this.props.property;
        newProperty.NumValue = event.target.value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    onDelete(){
        if(this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined){
            deleteEntityProperty(this.props.property.EntityPropertyID).then((value)=>{
                this.props.onDelete();
            })
        }
    }

    inputStyle(){
        if (this.props.property.Mandatory){
            if((this.props.property.NumValue === null || this.props.property.NumValue === '') || (this.state.value === '' || this.state.value === 0)){
                this.setState({style: notFilledInputClass});
            } else {
                this.setState({style: regularInputClass});
            }
        } else {
            this.setState({style: regularInputClass});
        }
    }

    render(){
        var readOnly = this.props.property.ReadOnly;
        var attachmentsText = "No Attachments";
        if(this.props.property.Attachments > 0){
            attachmentsText = this.props.property.Attachments + " Attachment";
            if(this.props.property.Attachments > 1){
                attachmentsText = this.props.property.Attachments + " Attachments";
            }
        }
        var input = [];
        if(readOnly){
            input.push(<input disabled={true} key={this.state.id} style={{background: this.props.backgroundColor}} className={this.state.style} id={this.state.id} type="number" placeholder="" value={this.state.value} readOnly/>)
        } else {
            input.push(<NumberInput disabled={true} key={this.state.id} id={this.state.id} style={{background: this.props.backgroundColor}} type="Currency" className={this.state.style}
            value={this.state.value} 
            onChange={this.onChange.bind(this)}
            />);
        }
        return(
            <div className="uk-grid property-box" style={{background: this.props.backgroundColor}}>
                <div className="uk-width-11-12" >
                    <div className="uk-margin">
                    <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                            {/*<input className="uk-input" id={this.state.id} type="number" placeholder="" value={this.state.value} onChange={this.onChange.bind(this)}/>*/}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-paperclip uk-align-center" uk-tooltip={attachmentsText} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                        {/*<button className="uk-button uk-button-default" uk-tooltip={attachmentsText} type="button" uk-toggle={this.state.toggleAttachments}>Attachments</button>*/}
                    </div>
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-eraser uk-align-center" onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer'}} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID}/>
                </div>
            </div>
        );
    }
}

//PROPS:
//largeScreen = {true, false} (true, uses self save button. false, needs a function to save)
//isSaving = {state.variable} (only when largeScreen is false)
//entityType = {} (from constants/EntityTypeNames)
//entityID = {} (int/string)
export default class EntityProperties extends React.Component {
    state={
        listEntityProperties: [],
        filteredListEntityProperties: [],
        listCategories: [],
        filteredCategories: [],
        finalListEntityProperties: [],
        filterScreen: false,
        loading: false,
        filtering: false,
        nameFilter: '',
        contentFilter: '',
    }

    componentDidMount(){
        this.getProperties();
    }

    componentDidUpdate(prevProps){
        if(prevProps.entityID !== this.props.entityID){            
            this.getProperties();
        }

        if(prevProps.runSaveProcess !== this.props.runSaveProcess && this.props.runSaveProcess){
            this.saveProperties();
        }
    }

    onDelete(){
        this.getProperties();
    }

    saveProperties(){
        if(this.validateProperties()){
            var listOfProperties = this.state.finalListEntityProperties;
            let userID = sessionStorage.getItem('UserID');
            counter = 1;
            for(var i = 0; i<listOfProperties.length; i++){
                var value = '';
                    switch (listOfProperties[i].Type) {
                    case TextDataType: value = listOfProperties[i].TextValue; break;
                    case DateTimeDataType: value = listOfProperties[i].DateValue; break;
                    case NumericDataType: value = listOfProperties[i].NumValue; break;
                    default: value = ''; break;
                }

                var model = {
                    EntityId: this.props.entityID,
                    EntityType: this.props.entityType,
                    PropertyDescription: listOfProperties[i].Description,
                    Value: value,
                    CreatedBy: userID,
                    UpdateToken: listOfProperties[i].UpdateToken,
                }

                // eslint-disable-next-line no-loop-func
                saveEntityProperty(model).then((value)=>{
                    if (counter === listOfProperties.length){
                        this.props.finishSaveProcess();
                    } else {
                        counter++;
                    }
                });
            }
        }
    }

    validateProperties(){
        var listOfProperties = this.state.finalListEntityProperties;
        var isValid = true;
        for (var i = 0; i<listOfProperties.length; i++){
            var property = listOfProperties[i];
            if(property.Mandatory === true){
                switch (property.Type) {
                    case TextDataType:
                        if (property.TextValue === null || property.TextValue === ''){
                            isValid = false;
                        }
                        break;
                    case NumericDataType:
                        if (property.NumValue === null || property.NumValue === ''){
                            isValid = false;
                        }
                        break;
                    case DateTimeDataType:
                        if (property.DateValue === null || property.DateValue === ''){
                            isValid = false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return isValid;
    }

    getProperties(){
        getEntityProperties(this.props.entityID, this.props.entityType).then(
            (value) => {
                console.group('EntityProperties');
                console.table(value.entityProperties);
                console.groupEnd();
                this.setState({listEntityProperties: value.entityProperties, finalListEntityProperties: value.entityProperties, filteredListEntityProperties: value.entityProperties});
                this.getCategories(value.entityProperties);
            }
        );
    }

    getCategories(json){
        var helper = [];
        var categoryName = '';
        for(var i=0; i<json.length; i++){
            if(json[i]['Group By'] !== categoryName){
                helper.push(json[i]['Group By']);
                categoryName = json[i]['Group By'];
            }
        }
        console.log(helper);
        this.setState({listCategories: helper, filteredCategories: helper});
    }

    getProperty(value){
        console.log(value);
        var listOfProperties = this.state.finalListEntityProperties;
        for(var i=0; i<listOfProperties.length; i++){
            if(listOfProperties[i].UserDefinedPropertyID === value.UserDefinedPropertyID){
                listOfProperties[i] = value;
            }
        }
        this.setState({finalListEntityProperties: listOfProperties});
        console.log(listOfProperties);
    }

    isOdd = (number) => {
        return number % 2;
    }

    getController(property, number){
        let backgroundColor = '#ffffff';
        if(this.isOdd(number) === 1){
            backgroundColor = '#ffffff'
        }
        var key = this.props.entityID + '-' + this.props.entityType + '-' + property.Description.replace(' ','-');
        switch (property.Type) {
            case TextDataType:
                return (
                    <div>
                        <TextProperty key={key} property={property} getProperty={this.getProperty.bind(this)} /*onDelete={this.onDelete.bind(this)}*/ backgroundColor={backgroundColor}/>
                    </div>
                );
            case NumericDataType:
                return (
                    <div>
                        <NumericProperty key={key} property={property} getProperty={this.getProperty.bind(this)} /*onDelete={this.onDelete.bind(this)}*/ backgroundColor={backgroundColor}/>
                    </div>
                );
            case DateTimeDataType:
                return (
                    <div>
                        <DateProperty key={key} property={property} getProperty={this.getProperty.bind(this)} /*onDelete={this.onDelete.bind(this)}*/ backgroundColor={backgroundColor}/>
                    </div>
                );
            default:
                break;
        }

        return (
            <div>

            </div>
        );
    }

    onChangeNameFilter = (event) => {
        this.setState({nameFilter: event.target.value},()=>{
            this.runFilter();
        });
    }

    onChangeContentFilter = (event) => {
        this.setState({contentFilter: event.target.value},()=>{
            this.runFilter();
        });
    }

    runFilter = () => {
        let newList = [];
        let filterList = this.state.listEntityProperties;
        let name = this.state.nameFilter;
        let content = this.state.contentFilter;

        if (name === '' && content === ''){
            this.setState({filteredListEntityProperties: this.state.listEntityProperties});
            this.getCategories(this.state.listEntityProperties);
            return;
        }

        filterList.forEach(element => {
            switch (element.Type) {
                case TextDataType:
                    if(element.TextValue){
                        if(element.Description.toLowerCase().includes(name.toLowerCase()) && element.TextValue.toLowerCase().includes(content.toLowerCase())){
                            newList.push(element);
                        }
                    } 
                    break;
                case NumericDataType:
                    if(element.NumValue){
                        if(element.Description.includes(name.toLowerCase()) && element.NumValue.toString().toLowerCase().includes(content.toLowerCase())){
                            newList.push(element);
                        }
                    }
                    break;
                case DateTimeDataType:
                    if(element.DateValue){
                        if(element.Description.includes(name.toLowerCase()) && element.DateValue.toString().toLowerCase().includes(content.toLowerCase())){
                            newList.push(element);
                        }
                    }    
                    break;
                default:
                    break;
            }

            if(!element.TextValue && !element.NumValue && !element.DateValue && content === ''){
                if(element.Description.toLowerCase().includes(name.toLowerCase())){
                    newList.push(element);
                }
            }
        });
        this.setState({filteredListEntityProperties: newList});
        this.getCategories(newList);
    }

    onFilterButtonClick = () => {
        if(this.state.filtering){
            this.setState({filtering: false, nameFilter: '', contentFilter: ''},()=>{this.runFilter()});
        } else {
            this.setState({filtering: true});
            this.runFilter();
        }
    }
    
    render(){
        var fullScreen = false;
        var filterIcon = "fa fa-fw fa-filter uk-align-right";
        if (this.state.filtering){
            filterIcon = "fa fa-fw fa-ban uk-align-right";
        }

        if(this.props.fullScreen !== undefined){
            fullScreen = this.props.fullScreen;
        }

        var properties = [];
        var id = "";
        if(this.props.id !== undefined){
            id = "-"+this.props.id;
        }

        var idForEP = "entity-properties"+id;
        var idForFilter = "entity-properties-filter"+id;
        var toogle = "target: #"+idForEP+"; animation: uk-animation-slide-top-medium";
        var toggleFilter = "target: #"+idForFilter+";";

        console.group("Entity Properties");
        let setCategories = new Set(this.state.filteredCategories);
        let uniqueCategories = [...setCategories];
        for (var j = 0; j < uniqueCategories.length; j++){
            console.group(uniqueCategories[j]);
            var idForGroup = 'entity-group-' + j;
            var toogleForGroup = "target : #"+idForGroup+";"
            properties.push(
                <h5 
                    key={j} 
                    className="uk-heading-divider propoerties-tasks-category-title" 
                    uk-toggle={toogleForGroup} 
                    style={{...style.titleStyle, 
                        cursor: 'pointer'
                    }}>{titleCase(uniqueCategories[j])} 
                    <img disabled={true} 
                        id={idForGroup}
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{position: 'absolute',
                        paddingTop: '5px',
                        right: '30px', color: 'black'}}
                    />
                    <img disabled={true} 
                        id={idForGroup} 
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{position: 'absolute',
                        paddingTop: '5px',
                        right: '30px', color: 'black'}} 
                        hidden
                    />
                </h5>);
            var listProp = [];
            for (var k=0; k<this.state.filteredListEntityProperties.length; k++){
                if(this.state.filteredListEntityProperties[k]['Group By'] === uniqueCategories[j]){
                    listProp.push(this.getController(this.state.filteredListEntityProperties[k],k+1));
                    console.groupCollapsed(this.state.filteredListEntityProperties[k].Type + "Property - " + this.state.filteredListEntityProperties[k].Description);
                    console.table(this.state.filteredListEntityProperties[k]);
                    console.groupEnd();
                }
            }
            properties.push(<div key={idForGroup} id={idForGroup} hidden style={{paddingLeft: '50px', paddingRight: '50px'}}>{listProp}</div>);
            console.groupEnd();
        }
        console.groupEnd();

        if(fullScreen){
            return(
                <div>
                    <div className="uk-grid container-filter-properties" id={idForFilter} >
                        <div className="uk-width-2-12 filter-label">
                            <form className="uk-form-horizontal">
                                <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                <div className="uk-form-controls-filter"><input disabled={true} className="uk-input" id="property-name-filter-input" type="text" placeholder="Property Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                            </form>
                        </div>
                        <div className="uk-width-3-12">
                            <input disabled={true} className="uk-input" type="text" placeholder="Content" value={this.state.contentFilter} onChange={this.onChangeContentFilter}/>
                        </div>
                    </div>
                    <div id={idForEP} className="uk-margin">
                    <form className="uk-form-horizontal">
                    {properties}
                    </form>    
                    </div>
                </div>
            );
        } else {
            return(
                <div>    
                    <button className="uk-button uk-button-default" type="button" uk-toggle={toogle}>Additional Properties</button>
                    <div id={idForEP} className="uk-margin" hidden>
                        
                        <div className="uk-grid container-filter-properties" id={idForFilter}>
                            <div className="uk-width-2-12 filter-label">
                                <form className="uk-form-horizontal">
                                    <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                    <div className="uk-form-controls-filter"><input disabled={true} className="uk-input" id="property-name-filter-input" type="text" placeholder="Property Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter}/></div>     
                                </form>
                            </div>
                            <div className="uk-width-3-12">
                                <input className="uk-input" type="text" placeholder="Content" value={this.state.contentFilter} onChange={this.onChangeContentFilter}/>
                            </div>
                        </div>
                        <form className="uk-form-horizontal">
                            {properties}
                        </form>
                    </div>
                </div>
            );
        }
    }
}