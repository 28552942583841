import React from 'react';
import * as mime from 'mime-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {uploadAttachment} from '../../networking/NetworkingAttachments';

export default class AttachmentsForm extends React.Component {
    constructor(props){
        super(props);
        this.fileInput = React.createRef();
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    state = {
        description: '',
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps){

    }

    getDescriptionValue(event){
        this.setState({description: event.target.value});
    }

    handleUploadFile(event) {
        event.preventDefault();
        var file = this.fileInput.current.files[0];
        this.readFileAsBase64(file).then((resolve, reject)=>{
           console.log(resolve);
           var fileContent = resolve.replace(/^data:.+;base64,/, '');
            uploadAttachment(this.state.description,this.props.linkType, this.props.linkID, file.name, fileContent).then((value)=>{
                console.log(value);
                this.setState({description: ''});
                this.props.onClose();
            })
        });
    }

    readFileAsBase64(file){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    }

    render() {
        return(
            <div>
                <Dialog open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle>Upload Attachment</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please upload the file you want to attach.
                    </DialogContentText>
                    <div className="uk-margin">
                        <div uk-form-custom="target: true" className="uk-form-controls">
                            <input type="file" ref={this.fileInput}/>
                            <input className="uk-input uk-form-width-large" type="text" placeholder="Select a file..." disabled/>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-attachment-description">Description</label>
                        <div className="uk-form-controls">
                            <input className="uk-input uk-form-width-large" id="form-attachment-description" type="text" placeholder="Description" value={this.state.description} onChange={this.getDescriptionValue.bind(this)}/>
                        </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={this.props.onClose}>Cancel</button>
                        <button className="uk-button uk-button-green" key={"upload"} type="button" onClick={this.handleUploadFile}>Upload</button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}