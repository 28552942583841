import React from 'react';

import { getStyle } from '../../../../styles/styles'
import BeneficiaryInformationForm from './BeneficiaryInformationForm';
import { addBeneficiary } from '../../../../networking/NetworkingBeneficiaries';
import AlertBox from '../../../shared/AlertBox';
import AddressForm from '../../addresses/AddressForm';
import BeneficiaryAdditionalProperties from './BeneficiaryAdditionalProperties';
import BeneficiaryTasks from './BeneficiaryTasks';
import BeneficiariesScreen from '../updateCustomer/BeneficiariesScreen';
import AuditBeneficiaryScreen from '../auditEntity/AuditBeneficiaryScreen';
import BankAccountsScreen from '../updateCustomer/BankAccountsScreen';
import ComplianceScreen from '../updateCustomer/ComplianceScreen';
var style = getStyle();

export default class BeneficiaryMaintenanceScreen extends React.Component {
    state = {
        beneficiaryID: 0,
        updateToken: 0,
        isValidated: true,
        isValidatedAddress: true,
        beneInfoErrors:[],
        addressErrors:[],
        notFirstLoad: true,
        notFirstLoadBene: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        updateBeneficiary: false,
        pressedButton: false,
        saveProperties: false,
        saveTasks: false,
        selectBene: {},
        viewHistory: false,
        //OBJ FILTERS
        objBeneInfo: {},
        objAddress: {},
        objBankAccount: {}
    }

    componentDidMount() {
        console.log(this.props.selectedBene);
        this.setState({ selectBene: this.props.selectedBene })
        if (this.props.selectedBene.Address !== undefined && this.props.selectedBene.Address !== null) {
            var objAdd = this.props.selectedBene.Address[0];
            this.setState({ objAddress: objAdd });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedBene !== {} && prevProps.updateToken !== this.props.updateToken) {
            this.setState({ selectBene: this.props.selectedBene, updateToken: this.props.updateToken });
        }
    }

    changePage(value) {
    }

    updatePage() {
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            notFirstLoad: true,
            notFirstLoadBene: true,
            showAlert: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            updateBeneficiary: false,
            pressedButton: false,
            saveProperties: false,
            saveTasks: false,
            selectBene: null,
            viewHistory: false,
            //OBJ FILTERS
            objBeneInfo: null,
            objAddress: null,
            objBankAccount: {}
        })
    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick() {
        this.saveAll();
    }

    saveAll() {
        let UserID = sessionStorage.getItem('UserID');
        var objFilter = {
            BeneficiaryId: this.state.objBeneInfo.beneficiaryID === ''?0:this.state.objBeneInfo.beneficiaryID,
            CustomerId: this.props.customerID,
            StatusBeneficiary: this.state.objBeneInfo.status,
            BeneficiaryType: this.state.objBeneInfo.customerType,
            BypassValidation: this.state.objBeneInfo.bypassValidation,
            ContactEmail: this.state.objBeneInfo.contactEmail,
            ContactPhone: this.state.objBeneInfo.contactPhone,
            EmailIndicator: this.state.objBeneInfo.emailIndicator,
            DetailsOfPayment: this.state.objBeneInfo.detailsPayments,
            ExpiryByPassDate: this.state.objBeneInfo.expiryByPassDate,
            IndustrySector: this.state.objBeneInfo.industrySector,
            ReferencesOfPayment: this.state.objBeneInfo.paymentReference,
            ReceiveLimit: this.state.objBeneInfo.limit,
            PreferredMethodOfPayment: this.state.objBeneInfo.preferredPaymentIns,
            PreferredDeliveryMethod: this.state.objBeneInfo.preferredDeliveryMet,
            PaymentFlow: this.state.objBeneInfo.paymentFlow,
            Salutation: this.state.objBeneInfo.salutation!==undefined?this.state.objBeneInfo.salutation:'',
            Name: this.state.objBeneInfo.companyName,
            ContactName: this.state.objBeneInfo.contactName,
            FirstName: this.state.objBeneInfo.firstName,
            LastName: this.state.objBeneInfo.lastName,
            ShortName: this.state.objBeneInfo.shortName,
            Email: '',
            Phone: '',
            PhoneExt: '',
            OfficeFax: '',
            MobilePhone: '',
            UpdateToken: this.state.objBeneInfo.updateToken,
            UserId: UserID,

            Address: this.state.objAddress,
            BankAccounts: null
        }
        objFilter.Address.AddressID = this.state.selectBene.Address[0].AddressID
        objFilter.Address.UpdateToken = this.state.objAddress.UpdateToken;
        this.setState({ selectBene: objFilter });
        if (this.state.isValidated && this.state.isValidatedAddress) {
            if (this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail !== '' || !this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail === '') {
                addBeneficiary(objFilter).then(
                    (json) => {
                        if (json.status !== 200) {
                            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to save the beneficiary. Please try again and complete the required fields(*).', alertTitle: 'Error' });
                        } else {
                            this.state.selectBene.BeneficiaryId = json.beneficiaryId;
                            this.state.selectBene.UpdateToken = json.updateToken;
                            this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.' })
                            this.props.updateFunctionOrigin();
                        }
                    }
                );
                this.setState({ pressedButton: true });
            } else {
                this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadBene: false });
            }
        } else {
            if (this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false,
                    notFirstLoad: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false
                });
            }
        }
    }
    isValidated = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadBene: true,
                beneInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                beneInfoErrors: { tab: "Beneficiary Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true });
    }

    handleReturnToBeneficiary() {
        this.setState({ viewHistory: false });
    }

    handleAttachmentsClick() {

    }

    handleExitClick() {

    }

    handleResetAnswersClick() {

    }

    handleSendOAEmailClick() {

    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '', updateBeneficiary: true});
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadBene: true, notFirstLoad: true });
    }
    formatVisibilityNew(beneID) {
        if (beneID === '' || beneID === null) { return "visible"; }
        else { return "hidden"; }
    }
    formatVisibilityUpdate(beneID) {
        if (beneID === '' || beneID === null) { return "hidden"; }
        else { return "visible"; }
    }

    //OBJ FILTERS
    objFilterBeneInfo(objFilter) {
        this.setState({ objBeneInfo: objFilter });
    }
    objFilterBeneAddress(objFilterA) {
        this.setState({ objAddress: objFilterA });
    }
    objFilterBeneAddProperties(value) {
        this.setState({ saveProperties: value });
    }
    objFilterBeneAddTasks(value) {
        this.setState({ saveTasks: value });
    }

    //beneID={this.props.beneID} selectedBene={this.state.beneList} type={this.state.type}
    render() {
        var selectBene = {}
        selectBene.PersonID = this.props.selectedBene.PersonId
        selectBene.CustomerID = this.props.selectedBene.CustomerId
        selectBene.DealStatus = null

        var errors = this.state.objBeneInfo !== null ? this.state.objBeneInfo.errors : []
        var section = (
            <h3 className="uk-heading-divider" style={style.titleStyle, { visibility: this.formatVisibilityUpdate(this.props.beneID) }} >
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                </button>
                Beneficiary - {this.props.selectedBene.Name} - {this.props.beneID}
            </h3>
        );
        if (this.formatVisibilityNew(this.props.beneID) == "visible") {
            section = (
                <h3 className="uk-heading-divider" style={style.titleStyle, { visibility: this.formatVisibilityNew(this.props.beneID) }} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    New Beneficiary
                </h3>
            );
        }
        if (this.state.updateBeneficiary) {
            return <BeneficiariesScreen id={this.props.customerID} data={this.props.customerName} />
        }
        if (this.state.viewHistory) {
            return <AuditBeneficiaryScreen backLink={this.handleReturnToBeneficiary.bind(this)} beneficiaryID={this.props.beneID} />
        }
        return (
            <div>
                <div>
                    {section}
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="beneficiary-information">Beneficiary Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information">Address</a>
                        </li>
                        <li>
                            <a href="#" id="account-information">Bank Account Info</a>
                        </li>
                        <li>{/*class="uk-disabled"*/}
                            <a href="#" id="additional-properties">Additional Properties</a>
                        </li>
                        {/*<li>
                            <a href="#" id="compliance">Compliance</a>
                        </li>
                        <li>
                            <a href="#" id="tasks-list">Tasks List</a>
                        </li>*/}
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <BeneficiaryInformationForm newOrUpdate={this.formatVisibilityNew(this.props.beneID)} isValid={this.isValidated.bind(this)} updateFunction={this.updatePage.bind(this)} selectedBene={this.state.selectBene} beneID={this.props.beneID} customerName={this.props.customerName} objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} notFirstLoad={this.state.notFirstLoadBene} />
                        </li>
                        <li>
                            <AddressForm selectedAddress={this.state.objAddress} getAddressInfo={this.objFilterBeneAddress.bind(this)} isValid={this.isValidatedAddress.bind(this)} validations={this.state.notFirstLoad}/>
                            {/*<BeneficiaryAddressForm updateFunction={this.updatePage.bind(this)} selectedBeneAddress={this.props.selectedBene.Address}  beneID={this.props.beneID} objFilterBeneAddress={this.objFilterBeneAddress.bind(this)}/>*/}
                        </li>
                        <li>
                            <BankAccountsScreen data={this.props.selectedBene.PersonId} />
                        </li>
                        <li>
                            <BeneficiaryAdditionalProperties getSaveProperties={this.objFilterBeneAddProperties.bind(this)} beneficiaryID={this.props.beneID} saveProperties={this.state.saveProperties} />
                        </li>
                        {/*<li>
                            <ComplianceScreen customer={selectBene} />
                        </li>
                        <li>
                            <BeneficiaryTasks getSaveTasks={this.objFilterBeneAddTasks.bind(this)} beneficiaryID={this.props.beneID} saveTasks={this.state.saveTasks} />
                        </li>*/}
                    </ul>
                </div>
                <div>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">View History</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleAttachmentsClick.bind(this)} type="button">Attachments</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleResetAnswersClick.bind(this)} type="button">Reset Answers</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendOAEmailClick.bind(this)} type="button">Send Online Access Email</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleExitClick.bind(this)} type="button">Exit</button>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.beneInfoErrors, this.state.addressErrors]} type="Ok" okClick={this.closeAlertError.bind(this)} />
            </div>
        );
    }
}