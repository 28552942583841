import React from 'react';

import { getLookUpTable } from '../../networking/Networking';
import { getStyle, regularInputClass, regularSelectClass } from '../../styles/styles';
import { getActiveTradersMin } from '../../networking/NetworkingTrader';
import DynamicSelect from '../shared/DynamicSelect';
import { getDealTypes } from '../../networking/NetworkingDeals';
import { getCurrencyList } from '../../networking/NetworkingCurrencies';
import { getBeneficiariesByNameMin } from '../../networking/NetworkingBeneficiaries';
import DynamicSearchSelect from '../shared/DynamicSearchSelect';
import DatePicker from '../shared/DatePicker';
import { formatDate } from '../../helpers/FormatHelper';
import NumberInput from '../shared/NumberInput';
import { searchCustomerByIdNameMin } from '../../networking/NetworkingCustomers';
var style = getStyle();

export default class DealForm extends React.Component {
    state = {
        criteria:{
            dealNroFrom:'',
            dealNroTo:'',
            CustomerIDName:'',
            dealType:'...',
            in:'...',
            out:'...',
            buy:'...',
            sell:'...',
            inStatus: '...',
            outStatus: '...',
            buyFrom:'',
            buyTo:'',
            inInstrument: '...',
            outInstrument:'...',
            valueDateFrom:'',
            valueDateTo:'',
            rateFrom:'',
            rateTo:'',
            maturityDateFrom:'',
            maturityDateTo:'',
            sellFrom:'',
            sellTo:'',
            tradeDateFrom:'',
            tradeDateTo:'',
            status:'...',
            confirmed:'',
            comments:'',
            createdBy:'',
            customerRefNro:'',
            includeAdjustDeals:'',
            beneficiaryName:'',
            beneficiaryID:'',
            paymentPrefeNro:'',
            dealTypeList:[],
            currenciesList:[],
            beneficiaryList:[],
            beneficiaryID:'',
            CustomerID:'',
            customerList:[]
        },
        dropdowns: {
            traders: [],
            "Deal Paid Status": [],
            "Deal Disbursement Status Names":[],
            "Incoming Instruments": [],
            "Outgoing Instruments": [],
        },
        lookUpTables: [
            "Deal Paid Status",
            "Deal Disbursement Status Names",
            "Incoming Instruments",
            "Outgoing Instruments",
        ],
        ddHardCode:[
            {name: "Select an Item...", value: "Select an Item..."},
            {name: "Yes", value: "Yes"},
            {name: "No", value: "No"}
        ] ,
        ddHCStatus:[
            {name: "Select a Status...", value: "Select a Status..."},
            {name: "Accepted", value: "Accepted"},
            {name: "Voided", value: "Voided"},
            {name: "Pending", value: "Pending"}
        ]       
    }
    
    componentDidMount(){
       getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({dropdowns: {...this.state.dropdowns, traders: json.traders}});
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        });
        getDealTypes().then(
            (json)=>{
                console.log(json.dealTypes);
                if(json!==undefined){
                    var deals = json.dealTypes;
                    var helper = [];
                    for(var i=0; i<deals.length; i++){
                        deals[i].value = deals[i]['DealType ID'];
                        deals[i].name = deals[i]['Description'];
                        helper.push(deals[i]);
                    }
                    this.setState({criteria: {...this.state.criteria,dealTypeList: helper}});
                }else{}
            }
        );

        getCurrencyList().then(
            (json)=>{
                console.log(json.currencies);
                if(json!==undefined){
                    var currencies = json.currencies;
                    var helper = [];
                    for(var i=0; i<currencies.length; i++){
                        currencies[i].value = currencies[i]['CurrencyID'];
                        currencies[i].name = currencies[i]['CurrencyIDName'];
                        helper.push(currencies[i]);
                    }
                    this.setState({criteria: {...this.state.criteria,currenciesList: helper}});
                }else{}
            }
        );
    }

    //Autocomplete Beneficiary Name
    searchBeneficiary(query) {
        console.group("searchBeneficiary");
        console.log(query);
        console.groupEnd();
        getBeneficiariesByNameMin(query).then(
            (response) => {
                if (response.beneficiaries !== undefined) {
                    for(var i=0; i < response.beneficiaries.length; i++) {
                        response.beneficiaries[i].value = response.beneficiaries[i].BeneficiaryId;
                        response.beneficiaries[i].name = response.beneficiaries[i].BeneficiaryIDName;
                    }
                    this.setState({criteria:{...this.state.criteria,beneficiaryList: response.beneficiaries}});
                }
            }
        )
    }
    //Autocomplete Customer Name
    searchCustomer(query) {
        console.group("searchCustomer");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                debugger;
                if (response.customers !== undefined) {
                    for(var i=0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({criteria:{...this.state.criteria,customerList: response.customers}});
                }
            }
        )
    }

    //SAVE CRITERIA
    handleSaveCriteriaClick(event){
        event.preventDefault();
        var objFilter = {
            dealNroFrom:this.state.criteria.dealNroFrom,
            dealNroTo:this.state.criteria.dealNroTo,
            CustomerName:this.state.criteria.CustomerIDName,
            dealType:this.state.criteria.dealType,
            in:this.state.criteria.in,
            out:this.state.criteria.out,
            buy:this.state.criteria.buy,
            sell:this.state.criteria.sell,
            inStatus: this.state.criteria.inStatus,
            outStatus: this.state.criteria.outStatus,
            buyFrom:this.state.criteria.buyFrom,
            buyTo:this.state.criteria.buyTo,
            inInstrument: this.state.criteria.inInstrument,
            outInstrument:this.state.criteria.outInstrument,
            valueDateFrom:this.state.criteria.valueDateFrom,
            valueDateTo:this.state.criteria.valueDateTo,
            rateFrom:this.state.criteria.rateFrom,
            rateTo:this.state.criteria.rateTo,
            maturityDateFrom:this.state.criteria.maturityDateFrom,
            maturityDateTo:this.state.criteria.maturityDateTo,
            sellFrom:this.state.criteria.sellFrom,
            sellTo:this.state.criteria.sellTo,
            tradeDateFrom:this.state.criteria.tradeDateFrom,
            tradeDateTo:this.state.criteria.tradeDateTo,
            status:this.state.criteria.status,
            confirmed:this.state.criteria.confirmed,
            comments:this.state.criteria.comments,
            createdBy:this.state.criteria.createdBy,
            customerRefNro:this.state.criteria.customerRefNro,
            includeAdjustDeals:this.state.criteria.includeAdjustDeals,
            beneficiaryName:this.state.criteria.beneficiaryName,
            paymentPrefeNro:this.state.criteria.paymentPrefeNro,
        };
        console.log(objFilter);
    }
    
    filterName(name, id){
        var fil = ' - '+ id;
        var sub = name.length - fil.length;
        var newName = name.substring(sub,0);
        return newName;
    }

    //CLEAR CRITERIA
    handleClearCriteriaClick(event){
        event.preventDefault();
        this.setState({
            criteria:{...this.state.criteria, 
                dealNroFrom:'',
                dealNroTo:'',
                CustomerIDName:'',
                dealType:'...',
                in:'...',
                out:'...',
                buy:'...',
                sell:'...',
                inStatus: '...',
                outStatus: '...',
                buyFrom:'',
                buyTo:'',
                inInstrument: '...',
                outInstrument:'...',
                valueDateFrom:'',
                valueDateTo:'',
                rateFrom:'',
                rateTo:'',
                maturityDateFrom:'',
                maturityDateTo:'',
                sellFrom:'',
                sellTo:'',
                tradeDateFrom:'',
                tradeDateTo:'',
                status:'...',
                confirmed:'',
                comments:'',
                createdBy:'',
                customerRefNro:'',
                includeAdjustDeals:'',
                beneficiaryName:'',
                beneficiaryID:'',
                paymentPrefeNro:'',
                beneficiaryID:'',
                CustomerID:''
            }
        });
        this.props.clearGrid();
    }

    //SEARCH CRITERIA
    handleSearchCriteriaClick(event){
        event.preventDefault();
        var dnfrom, dnto, bfrom, bto, include, rfrom, rto, sfrom, sto, conf, dtype='';
        if(this.state.criteria.dealNroFrom===''||this.state.criteria.dealNroFrom==0){dnfrom=-1;}else{dnfrom=this.state.criteria.dealNroFrom;}
        if(this.state.criteria.buyFrom===''||this.state.criteria.buyFrom==0){bfrom=-1;}else{bfrom=this.state.criteria.buyFrom;}
        if(this.state.criteria.buyTo===''||this.state.criteria.buyTo==0){bto=-1;}else{bto=this.state.criteria.buyTo;}
        if(this.state.criteria.rateFrom===''||this.state.criteria.rateFrom==0){rfrom=-1;}else{rfrom=this.state.criteria.rateFrom;}
        if(this.state.criteria.rateTo===''||this.state.criteria.rateTo==0){rto=-1;}else{rto=this.state.criteria.rateTo;}
        if(this.state.criteria.sellFrom===''||this.state.criteria.sellFrom==0){sfrom=-1;}else{sfrom=this.state.criteria.sellFrom;}
        if(this.state.criteria.sellTo===''||this.state.criteria.sellFrom==0){sto=-1;}else{sto=this.state.criteria.sellTo;}
        if(this.state.criteria.confirmed==='select'||this.state.criteria.confirmed==undefined){conf=-1;}else{conf=this.state.criteria.confirmed;}
        if(this.state.criteria.dealType==='...'){dtype=-1;}else{dtype=this.state.criteria.dealType;}
        
        if(this.state.criteria.includeAdjustDeals===''){include=0;}else{include=this.state.criteria.includeAdjustDeals;}
        
        var newName = this.filterName(this.state.criteria.CustomerIDName,this.state.criteria.CustomerID);
        var objFilter = {
            DealsFrom:dnfrom,
            DealsTo:dnfrom,
            CustomerName:newName,
            DealType:dtype,
            InFirst:-1,
            OutFirst:-1,
            BuyCurrency:this.state.criteria.buy,
            SellCurrency:this.state.criteria.sell,
            IncomingStatus: this.state.criteria.inStatus,
            OutgoingStatus: this.state.criteria.outStatus,
            BuyFrom:bfrom,
            BuyTo:bto,
            IncomingInstrument: this.state.criteria.inInstrument,
            OutgoingInstrument:this.state.criteria.outInstrument,
            ValueDateFrom:this.state.criteria.valueDateFrom,
            ValueDateTo:this.state.criteria.valueDateTo,
            RateFrom:rfrom,
            RateTo:rto,
            MaturityDateFrom:this.state.criteria.maturityDateFrom,
            MaturityDateTo:this.state.criteria.maturityDateTo,
            SellFrom:sfrom,
            SellTo:sto,
            TradeDateFrom:this.state.criteria.tradeDateFrom,
            TradeDateTo:this.state.criteria.tradeDateTo,
            Status:this.state.criteria.status,
            Confirmed:conf,
            Comments:this.state.criteria.comments,
            CreatedBy:this.state.criteria.createdBy,
            CustomerRefNo:this.state.criteria.customerRefNro,
            IsAdjustmentDeal:include,
            BeneficiaryName:this.state.criteria.beneficiaryName,
            Page: '',
            Items: 8,
            //paymentPrefeNro:this.state.criteria.paymentPrefeNro,
        };
        this.props.searchDealsGrid(objFilter);
    }


    //handleUpdates:::
    handleUpdateDealNroFrom(event){
        this.setState({criteria:{...this.state.criteria, dealNroFrom: event.target.value}});
    }
    handleUpdateDealNroTo(event){
        this.setState({criteria:{...this.state.criteria, dealNroTo: event.target.value}});
    }
    handleUpdateCustomerIDName(value){
        if (value.value !== undefined) {
            this.setState({criteria:{...this.state.criteria, CustomerIDName: value.name, CustomerID: value.value}});
        }
    }
    handleUpdateDealType(value){
        if(value!==undefined){
            this.setState({criteria:{...this.state.criteria, dealType: value.value}});
        }else{this.setState({criteria:{...this.state.criteria,dealType: '',}});}
    }
    handleUpdateIn(value){
        this.setState({criteria:{...this.state.criteria, in: value.value}});
    }
    handleUpdateOut(value){
        this.setState({criteria:{...this.state.criteria, out: value.value}});
    }
    handleUpdateBuy(value){
        this.setState({criteria:{...this.state.criteria, buy: value.value}});
    }
    handleUpdateSell(value){
        this.setState({criteria:{...this.state.criteria, sell: value.value}});
    }
    handleUpdateInStatus(value){
        this.setState({criteria:{...this.state.criteria, inStatus: value.value}});
    }
    handleUpdateOutStatus(value){
        this.setState({criteria:{...this.state.criteria, outStatus: value.value}});
    }
    handleUpdateBuyFrom(event){
        this.setState({criteria:{...this.state.criteria, buyFrom: event.target.value}});
    }
    handleUpdateBuyTo(event){
        this.setState({criteria:{...this.state.criteria, buyTo: event.target.value}});
    }
    handleUpdateInInstrument(value){
        this.setState({criteria:{...this.state.criteria, inInstrument: value.value}});
    }
    handleUpdateOutInstrument(value){
        this.setState({criteria:{...this.state.criteria, outInstrument: value.value}});
    }
    handleUpdateValueDateFrom(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, valueDateFrom: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, valueDateFrom: ''}});
        }
    }
    handleUpdateValueDateTo(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, valueDateTo: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, valueDateTo: ''}});
        }
    }
    handleUpdateRateFrom(event){
        this.setState({criteria:{...this.state.criteria, rateFrom: event.target.value}});
    }
    handleUpdateRateTo(event){
        this.setState({criteria:{...this.state.criteria, rateTo: event.target.value}});
    }
    handleUpdateMaturityDateFrom(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, maturityDateFrom: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, maturityDateFrom: ''}});
        }
    }
    handleUpdateMaturityDateTo(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, maturityDateTo: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, maturityDateTo: ''}});
        }
    }
    handleUpdateSellFrom(event){
        this.setState({criteria:{...this.state.criteria, sellFrom: event.target.value}});
    }
    handleUpdateSellTo(event){
        this.setState({criteria:{...this.state.criteria, sellTo: event.target.value}});
    }
    handleUpdateTradeDateFrom(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, tradeDateFrom: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, tradeDateFrom: ''}});
        }
    }
    handleUpdateTradeDateTo(date){
        if(date!==undefined){
            this.setState({criteria:{...this.state.criteria, tradeDateTo: formatDate(date)}});
        }else{
            this.setState({criteria:{...this.state.criteria, tradeDateTo: ''}});
        }
    }
    handleUpdateStatus(value){
        this.setState({criteria:{...this.state.criteria, status: value.value}});
    }
    handleUpdateConfirmed(value){
        this.setState({criteria:{...this.state.criteria, confirmed: value.value}});
    }
    handleUpdateComments(event){
        this.setState({criteria:{...this.state.criteria, comments: event.target.value}});
    }
    handleUpdateCreatedBy(event){
        this.setState({criteria:{...this.state.criteria, createdBy: event.target.value}});
    }
    handleUpdateCustomerRefNro(event){
        this.setState({criteria:{...this.state.criteria, customerRefNro: event.target.value}});
    }
    handleOnCheck(event){
        console.log(event.target.checked);
        this.setState({criteria:{...this.state.criteria.includeAdjustDeals = event.target.checked}});
    }
    handleUpdateBeneficiaryName(value){
        if (value.value !== undefined) {
            this.setState({criteria:{...this.state.criteria, beneficiaryName: value.name, beneficiaryID: value.value}});
        }
    }
    handleUpdatePaymentPrefeNro(event){
        this.setState({criteria:{...this.state.criteria, paymentPrefeNro: event.target.value}});
    }

    render(){
        //for required inputs
        var classNameInputName = regularInputClass;
        var classNameSelect= regularSelectClass;
        
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-dealNroFrom">Deal No From</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="dealNroFrom" placeholder="0" type="Integer" value={this.state.criteria.dealNroFrom} onChange={this.handleUpdateDealNroFrom.bind(this)}/>
                        </div>
                    </div>
                   {/*} <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-dealNroTo">Deal No To</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="dealNroTo" placeholder="0" type="Integer" value={this.state.criteria.dealNroTo} onChange={this.handleUpdateDealNroTo.bind(this)}/>
                        </div>
                    </div>*/}
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-CustomerIDName">Customer</label>
                        <div className="uk-form-controls">
                            <DynamicSearchSelect id="CustomerIDName" placeholder="Customer" searchFunction={this.searchCustomer.bind(this)} getValue={this.handleUpdateCustomerIDName.bind(this)} data={this.state.criteria.customerList} objValue={{value: this.state.criteria.CustomerID, name: this.state.criteria.CustomerIDName}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-dealType">Deal Type</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} objValue={{value: this.state.criteria.dealType}} getValue={this.handleUpdateDealType.bind(this)} placeholder={"Select an item..."} listHasPlaceholder={false} data={this.state.criteria.dealTypeList} id="form-deal-dealType" />
                        </div>
                    </div>
                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-in">In</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="in" objValue={{value: this.state.criteria.in}} getValue={this.handleUpdateIn.bind(this)} listHasPlaceholder={true} data={this.state.ddHardCode} />
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-out">Out</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="out" objValue={{value: this.state.criteria.out}} getValue={this.handleUpdateOut.bind(this)} listHasPlaceholder={true} data={this.state.ddHardCode} />
                        </div>
                    </div>*/}
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-buy">Buy</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="buy" objValue={{value: this.state.criteria.buy}} getValue={this.handleUpdateBuy.bind(this)} placeholder={"Select an item..."} listHasPlaceholder={false} data={this.state.criteria.currenciesList} />
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-sell">Sell</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="sell" objValue={{value: this.state.criteria.sell}} getValue={this.handleUpdateSell.bind(this)} placeholder={"Select an item..."} listHasPlaceholder={false} data={this.state.criteria.currenciesList} />
                        </div>
                    </div>
                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-inStatus">Incoming Status</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="inStatus" objValue={{value: this.state.criteria.inStatus}} getValue={this.handleUpdateInStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Deal Paid Status"]}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-outStatus">Outgoing Status</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="outStatus" objValue={{value: this.state.criteria.outStatus}} getValue={this.handleUpdateOutStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Deal Disbursement Status Names"]}/>
                        </div>
                    </div>*/}
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-buyFrom">Buy From</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="buyFrom" type="Currency" scale={1} value={this.state.criteria.buyFrom} onChange={this.handleUpdateBuyFrom.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-buyTo">Buy To</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="buyTo" type="Rate" scale={1} value={this.state.criteria.buyTo} onChange={this.handleUpdateBuyTo.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-inInstrument">Incoming Instruments</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="inInstrument" objValue={{value: this.state.criteria.inInstrument}} getValue={this.handleUpdateInInstrument.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Incoming Instruments"]}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-outInstrument">Outgoing Instruments</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="outInstrument" objValue={{value: this.state.criteria.outInstrument}} getValue={this.handleUpdateOutInstrument.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Outgoing Instruments"]}/>
                        </div>
                    </div>
                    */}

                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-valueDateFrom">Value Date From</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.valueDateFrom} onDayChange={this.handleUpdateValueDateFrom.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-valueDateTo">Value Date To</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.valueDateTo} onDayChange={this.handleUpdateValueDateTo.bind(this)}/>
                        </div>
                    </div>
                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-rateFrom">Rate From</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="rateFrom" type="Rate" scale={1} value={this.state.criteria.rateFrom} onChange={this.handleUpdateRateFrom.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-rateTo">Rate To</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="rateTo" type="Rate" scale={1} value={this.state.criteria.rateTo} onChange={this.handleUpdateRateTo.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
              
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-maturityDateFrom">Maturity Date From</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.maturityDateFrom} onDayChange={this.handleUpdateMaturityDateFrom.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-maturityDateTo">Maturity Date To</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.maturityDateTo} onDayChange={this.handleUpdateMaturityDateTo.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-sellFrom">Sell From</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="sellFrom" type="Rate" scale={1} value={this.state.criteria.sellFrom} onChange={this.handleUpdateSellFrom.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-sellTo">Sell To</label>
                        <div className="uk-form-controls">
                            <NumberInput className={classNameInputName} id="sellTo" type="Rate" scale={1} value={this.state.criteria.sellTo} onChange={this.handleUpdateSellTo.bind(this)} placeholder="0,0"/>
                        </div>
                    </div>
*/}
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-customerRefNro">Customer Ref No</label>
                        <div className="uk-form-controls">
                            <input className={classNameInputName} id="customerRefNro" type="text" value={this.state.criteria.customerRefNro} onChange={this.handleUpdateCustomerRefNro.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-tradeDateFrom">Trade Date From</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.tradeDateFrom} onDayChange={this.handleUpdateTradeDateFrom.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-tradeDateTo">Trade Date To</label>
                        <div className="uk-form-controls">
                            <DatePicker className={classNameInputName} value={this.state.criteria.tradeDateTo} onDayChange={this.handleUpdateTradeDateTo.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-status">Status</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="status" objValue={{value: this.state.criteria.status}} getValue={this.handleUpdateStatus.bind(this)} listHasPlaceholder={true} data={this.state.ddHCStatus} />
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-confirmed">Confirmed</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameSelect} id="confirmed" objValue={{value: this.state.criteria.confirmed}} getValue={this.handleUpdateConfirmed.bind(this)} listHasPlaceholder={true} data={this.state.ddHardCode} />
                        </div>
                    </div>

                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-comments">Comments</label>
                        <div className="uk-form-controls">
                            <input className={classNameInputName} id="comments" type="text" value={this.state.criteria.comments} onChange={this.handleUpdateComments.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-createdBy">Created By</label>
                        <div className="uk-form-controls">
                            <input className={classNameInputName} id="createdBy" type="text" value={this.state.criteria.createdBy} onChange={this.handleUpdateCreatedBy.bind(this)}/>
                        </div>
                    </div>
                    */}
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-beneficiaryName">Beneficiary</label>
                        <div className="uk-form-controls">
                            <DynamicSearchSelect id="beneficiaryName" placeholder="Beneficiary" searchFunction={this.searchBeneficiary.bind(this)} getValue={this.handleUpdateBeneficiaryName.bind(this)} data={this.state.criteria.beneficiaryList} objValue={{value: this.state.criteria.beneficiaryID, name: this.state.criteria.beneficiaryName}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-includeAdjustDeals"><input className="uk-checkbox" id="includeAdjustDeals" type="checkbox" checked={this.state.criteria.includeAdjustDeals} onChange={this.handleOnCheck.bind(this)}/> Include Ajustment Deals</label>
                        
                    </div>
                    {/* 
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-deal-paymentPrefeNro">Payment Reference No</label>
                        <div className="uk-form-controls">
                            <input className={classNameInputName} id="paymentPrefeNro" type="text" value={this.state.criteria.paymentPrefeNro} onChange={this.handleUpdatePaymentPrefeNro.bind(this)}/>
                        </div>
                    </div>
                    */}

                    <div className="uk-width-1-2">
                        <br></br>
                        <button className="uk-button uk-button-green" onClick={this.handleClearCriteriaClick.bind(this)}>Clear</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSearchCriteriaClick.bind(this)}>Search</button>
                        <button disabled={true} className="uk-button uk-button-green" onClick={this.handleSaveCriteriaClick.bind(this)}>Save</button>
                    </div>
                </form>
            </div>
        );
    }
}