import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import {CustomerType} from '../../../../constants/EntityTypeNames';
import AlertBox from '../../../shared/AlertBox';

export default class AdditionalPropertiesScreen extends React.Component {
    state = {
        customerID: 0,
        entityPropertiesSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: ''
    }

    onFinishEntityPropertiesSaveProcess(){
        this.setState({entityPropertiesSaveProcess: false});
    }

    handleButtonSaveClick(){
        this.setState({entityPropertiesSaveProcess: true, showAlert: true, alertTitle: 'Customer properties saved.'});
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    render(){
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties fullScreen={true} entityType={CustomerType} entityID={this.props.customerID} runSaveProcess={this.state.entityPropertiesSaveProcess} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                    </div>
                    <div>
                        <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>                        
                    </div>
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </div>
        );
    }
}