import React from 'react';
import BeneficiaryInformationForm from "./BeneficiaryInformation"
import {getStyle} from '../../../../styles/styles'
import AddressForm from './AddressForm';
import { getNewBeneficiaryKey, addBeneficiary, getBeneficiariesByID } from '../../../../networking/NetworkingBeneficiaries';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import LocalAccountForm from './LocalAccountForm';
import { saveBankAccount } from '../../../../networking/NetworkingBankAccounts';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
var style = getStyle();

export default class NewBeneficiaryMaintenance extends React.Component {
    
    state = {
        loading: true,
        //----
        objNewBene:{},
        objNewAdd:{},
        objNewBeneKey:{},
        isValidated: true,
        isValidatedAddress: true,
        notFirstLoad: false,
        notFirstLoadBene: false,
        localAccount:null,
        isValidatedLocalAccount: false,
        validateLocalAccountFields: true,
        beneStatus:[],
        //---For mensage
        showAlert : '',
        alertTitle : '',
        alertMessage : '',
        showAlertError : ''
    }
    componentDidMount(){
        var params = this.props.history.location.search;
        params = params.substr(5)
        console.log(params);
        getNewBeneficiaryKey(params).then(
            (json)=>{
                /*BeneficiaryEmail: "danisotch@gmail.com"
                CustomerId: 161
                SessionID: "5d136e03-08ee-4d48-b439-a9355c178ff8"
                StatusCode: 200 */        
                if(json.httpStatusCode === 200) {
                    sessionStorage.setItem('SessionID',json.SessionID);
                    this.setState({loading: false, objNewBeneKey: json})
                    this.getStatus()
                }
                console.log(json)
            }
        );
    }
    getStatus(){
        getParametersByName('BeneficiaryStandardMethodStatus').then(
            (json)=>{
                this.setState({beneStatus: json})
            }
        );
    }
    objFilterBeneInfo(objNewBene){
        this.setState({objNewBene: objNewBene})
    }
    objFilterAdd(objNewAdd){
        this.setState({objNewAdd: objNewAdd})
    }

    isValidated(validator){
        if(validator){
            this.setState({isValidated: true});
        }else{
            this.setState({isValidated: false});
        }
    }
    isValidatedAddress(validator){
        if(validator){
            this.setState({isValidatedAddress: true});
        }else{
            this.setState({isValidatedAddress: false});
        }
    }
    isValidatedLocalAccount = (validator) => {
        if (validator) {
            this.setState({
                isValidatedLocalAccount: true,
                validateLocalAccountFields: true
            });
        } else{
            this.setState({isValidatedLocalAccount: false});
        }
    }
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: '', updateBeneficiary: true});
    }
    closeAlertError(){
        this.setState({showAlertError: false, alertMessage: '', alertTitle: ''});
    }

    //Buttons
    handleClearFieldsClick(){

    }
    handleButtonSaveClick(){
        this.saveAll();
    }
    saveAll(){
        var idAdd, upTo='';
        if(this.state.objNewAdd.AddressId===''&& this.state.objNewAdd.UpdateToken===''){idAdd=0;upTo=0;}else{idAdd = this.state.objNewAdd.AddressId;upTo=this.state.objNewAdd.UpdateToken;}
        var objFilter = {
        BeneficiaryId: 0,
        CustomerId: this.state.objNewBeneKey.CustomerId,
        StatusBeneficiary: this.state.beneStatus.parameters[0].Value,
        BeneficiaryType: this.state.objNewBene.customerType,
        BypassValidation: false,
        ContactEmail: this.state.objNewBene.contactEmail,
        ContactPhone: this.state.objNewBene.contactPhone,
        EmailIndicator: this.state.objNewBene.emailIndicator,
        DetailsOfPayment: '',
        ExpiryByPassDate: '',
        IndustrySector: this.state.objNewBene.industrySector,
        ReferencesOfPayment: this.state.objNewBene.paymentReference,
        ReceiveLimit: '',
        PreferredMethodOfPayment: 'Electronic Funds Transfer',
        PreferredDeliveryMethod: 'Wire Transfer',
        PaymentFlow: '',
        Salutation: this.state.objNewBene.salutation,
        Name: this.state.objNewBene.companyName,
        ContactName: this.state.objNewBene.contactName,
        FirstName: this.state.objNewBene.firstName,
        LastName: this.state.objNewBene.lastName,
        ShortName: this.state.objNewBene.shortName,
        Email: '',
        Phone: '',
        PhoneExt: '',
        OfficeFax: '',
        MobilePhone: '',
        UpdateToken: 0,
        UserId: '',
        
        Address: this.state.objNewAdd,
        BankAccounts:null
        }
        objFilter.Address.AddressId = idAdd;
        objFilter.Address.UpdateToken = upTo;
        if(this.state.isValidated&&this.state.isValidatedAddress){
            if(this.state.objNewBene.isEmailValid&&this.state.objNewBene.contactEmail!==''||!this.state.objNewBene.isEmailValid&&this.state.objNewBene.contactEmail===''){
                addBeneficiary(objFilter).then(
                    (json)=>{
                        if (json.status !== 200){
                            this.setState({showAlert: true, alertMessage: 'An error occurred while trying to save the beneficiary. Please try again and complete the required fields(*).', alertTitle: 'Error'});  
                        } else {
                            this.saveBank(json.beneficiaryId);
                        }
                    }
                );
                this.setState({pressedButton: true});
            }else{
                this.setState({showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: true, notFirstLoadBene:true});
            }
        }else {   
            if(this.state.isValidated&& !this.state.isValidatedAddress){
                this.setState({showAlertError: true, alertMessage: 'Please, add required address data.', alertTitle: 'Error', notFirstLoad: true, notFirstLoadBene:false});
            }else if(!this.state.isValidated&& this.state.isValidatedAddress){
                this.setState({showAlertError: true, alertMessage: 'Please, add required information data.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadBene: true});
            }else{
               this.setState({notFirstLoad: true,notFirstLoadBene: true}); 
            }
        }
    }

    saveBank(beneficiaryId){
        getBeneficiariesByID(beneficiaryId).then(
            (json)=>{
                console.log(json);
                if(this.state.isValidatedLocalAccount){
                    var model = {
                        localAccount: {
                            ABANo: this.state.localAccount.ABANo,
                            AccountCurrencyID: this.state.localAccount.Currency,
                            AccountType: "Actual / Local",
                            Active: this.state.localAccount.Status,
                            BankAccount: this.state.localAccount.AccountNo,
                            BankAccountID: '',
                            BankAccountParentID: null,
                            BankBranchID: this.state.localAccount.BranchID,
                            BankID: this.state.localAccount.BankID,
                            CreatedOn: this.state.localAccount.CreatedOn,
                            DefaultAccount: this.state.localAccount.DefaultAccount,
                            DefaultDeliveryMethod: null,
                            Description: this.state.localAccount.Description,
                            GLAccountID: 0,
                            IsSettlementAccount: false,
                            PersonID: json.beneficiary[0].PersonId,
                            PublishOnline: false,
                            Swift: this.state.localAccount.Swift,
                            TransitNo: this.state.localAccount.TransitNo,
                            UpdateToken: this.state.localAccount.UpdateToken,
                            UserID: sessionStorage.getItem('UserID')
                        },
                        intermediateAccount: null,
                        correspondantAccount: null
                    }
                    saveBankAccount(model).then(
                        (json) => {
                            if (json.httpStatusCode !== 200) {
                                this.setState({showAlertError: true, alertMessage: 'An error occurred while trying to save the bank account. Please try again.', alertTitle: 'Error'});
                            } else {
                                this.setState({saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Beneficiary successfully saved.'})
                            }
                        }
                    )
                }else{    
                    this.setState({showAlertError: true, alertMessage: 'Please, add required Bank Account data', alertTitle: 'Error'});
                }
            }
        );
    }
    getLocalAccount = (localAccount) => {
        this.setState({localAccount: localAccount});
    }
    render() {
        if (this.state.loading) {
            return (<LoadingBox loading={this.state.loading}/>);
        }
        return(
            <div className="uk-card uk-card-default uk-card-body">
                <div className="uk-grid" style={{padding: '0px 40px'}}>
                    <div className="uk-heading-divider uk-width-1-2">
                        <h3 style={style.titleStyle}>
                            Contact and Banking Information
                        </h3>
                    </div>
                    <div className="uk-flex uk-flex-center@m uk-flex-right@l uk-width-1-2">
                        <div className="uk-card uk-card-default uk-card-small uk-card-body" style={{width:'90%' ,fontWeight: 'bold',fontSize: '0.750rem', color: '#fff',background: '#8bc34a'}}>
                            <p>
                                Please make sure that your banking information is accurate as it will be used to send the funds to you
                                <br></br>
                                Your information will be forwarded to your customer after you save it for their review
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="beneficiary-information">Beneficiary Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information">Address</a>
                        </li>
                        <li>
                            <a href="#" id="account-information">Bank Account Info</a>
                        </li>
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <BeneficiaryInformationForm objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} isValid={this.isValidated.bind(this)} notFirstLoad={this.state.notFirstLoadBene} objNewBeneKey = {this.state.objNewBeneKey}/>
                        </li>
                        <li>
                            <AddressForm selectedAddress={this.state.objNewAdd} getAddressInfo={this.objFilterAdd.bind(this)} isValid={this.isValidatedAddress.bind(this)} notFirstLoad={this.state.notFirstLoad}/> 
                        </li>
                        <li>
                            <LocalAccountForm getLocalAccount={this.getLocalAccount} isValid={this.isValidatedLocalAccount} validated={this.state.validateLocalAccountFields}/>
                        </li>
                    </ul>
                </div>
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError.bind(this)}/>
            </div>
        );
    }
}