import {genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody, genericGetWithParametersNoEncrypted} from './Networking';

export async function getAttachments(linkType, linkID){
    try {
        let params = {
            'LinkType': linkType,
            'LinkId': linkID
        }
        let response = await genericGetWithParameters('Attachment/GetAttachmentByLink',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachment(id){
    try {
        let params = {
            'id': id,
        }
        let response = await genericGetWithParametersNoEncrypted('Attachment/GetAttachmentContent',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteE2EAttachment(id){
    try {
        let params = {
            'attachmentId': id,
        }
        let response = await genericDeleteWithParameters('Attachment/Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function uploadAttachment(description,linkType,linkID,fileName,fileContent){
    try {
        let userID = sessionStorage.getItem('UserID');
        var date = new Date();
        let isoDate = date.toISOString();
        let addFileModel = {
            'Description': description,
            'UploadedBy': userID,
            'LinkType': linkType,
            'LinkId': linkID,
            'UpdateToken': '0',
        };
        //AddFileCall
        let addFileResponse = await genericCallWithBody('POST','Attachment/AddFile',addFileModel);
        if(addFileResponse.status === 200){
            let uploadFileModel = {
                'Id': addFileResponse.id,
                'Filename': fileName,
                'Description': addFileResponse.description,
                'UploadedBy': userID,
                'UploadedOn': isoDate,
                'FileContent': fileContent,
                'UpdateToken': addFileResponse.updateToken,
            };
            //UploadFileCall
            let uploadFileResponse = await genericCallWithBody('POST','Attachment/UploadFile',uploadFileModel);
            return uploadFileResponse;
        } else {
            return addFileResponse;
        }
    } catch (error) {
        console.error(error);
    }
}