import React from 'react';
import { GetBoardByName, GetCategoriesByGenericBoardID, ExecuteGQuery } from '../../../../networking/NetworkingGenericBoards';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import NewDynamicTable from '../../../shared/NewDynamicTable';

export default class AuditEntity extends React.Component {

    state = {
        grid: [],
        newSearch: false,
    }

    componentDidMount() {
        GetBoardByName("AuditBoard").then(
            (boardJson) => {
                if (boardJson !== undefined) {
                    GetCategoriesByGenericBoardID(boardJson.ID).then(
                        (categoriesJson) => {
                            if (categoriesJson !== undefined) {
                                var query = "";
                                for (var i = 0; i < categoriesJson.boardCategories.length; i++) {
                                    for (var j = 0, found = false; !found && (j < categoriesJson.boardCategories[i].boardQueries.length); j++) {
                                        if (categoriesJson.boardCategories[i].boardQueries[j].Name === this.props.categoryName) {
                                            query = categoriesJson.boardCategories[i].boardQueries[j].Query;
                                            found = true;
                                        }
                                    }
                                }
                                if (query !== "") {
                                    let userID = sessionStorage.getItem('UserID');
                                    query = query.replace("§", this.props.entityID);
                                    ExecuteGQuery(query, userID, null, null, null, 1, getDefaultPagingNumberOfItems()).then(
                                        (json) => {
                                            if (json !== null && json !== undefined) {
                                                if (json.result !== undefined) {
                                                    this.setState({grid: json.result})
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        }
                    );
                }
            }
        );
    }

    render() {

        return (
            <div>
                <NewDynamicTable data={this.state.grid} hiddenHeaders={['Key']}  enableClick={false} useDeleteButton={false} useCheckBox={false} newSearch={this.state.newSearch} />
            </div>
        );
    }
}