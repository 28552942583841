import React from 'react';
import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass } from '../../../../styles/styles';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import Paginator from '../../../shared/Paginator';
import LoadingBox from '../../../shared/LoadingBox';
import { getApprovalTiersByCustomerID, getApprovalTiersByEntityType, saveApprovalTier, getApprovalTiersInfo, deleteApprovalTier } from '../../../../networking/NetworkingCustomers';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import NumberInput from '../../../shared/NumberInput';
import { formatToCurrency } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();

export default class ApprovalTiersScreen extends React.Component {

    state = {
        approvalTier: {
            CustomerID: 0,
            EntityType: '',
            FromTier: 0,
            ToTier: 0,
            RequiredApprovals: 0,
            UpdatedBy: '',
            UpdateToken: '',
        },
        validations: {
            fromtier: true,
            totier: true,
            entityTypes: true,
        },
        alertTitle: '',
        alertMessage: '',
        approvalTiers: [],
        beneCheckbox: false,
        currentPage: 1,
        disableControls: false,
        entityTypes: [],
        loading: false,
        newSearch: false,
        payCheckbox: false,
        readyToDeleteApprovalTier: {},
        showAlert: false,
        showAlertError: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        totalPages: 0,
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }
    closeAlert2() {
        this.setState({ showAlertError: false });
    }

    handleDeleteClick(obj) {
        console.group("ApprovalTier handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteApprovalTier: obj });
    }

    handleRowClick1(obj) {
        console.group("State handleRowClick")
        console.log(obj);
        console.groupEnd();
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteApprovalTier: {} });
    }
    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }
    yesDeleteAlert() {
        deleteApprovalTier(this.state.readyToDeleteApprovalTier).then(
            (json) => {
                this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                this.handleClearClick();
            }
        );
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount() {
        var payCheckbox = this.props.payCheckbox;
        var beneCheckbox = this.props.beneCheckbox;
        var customerID = this.props.id;
        this.setState({ approvalTier: { ...this.state.approvalTier, CustomerID: customerID } });

        var nroChecks = 0;
        if (payCheckbox !== '' && payCheckbox !== false) {
            this.setState({ entityTypes: [{ value: "Deal", name: "Deal" }] });
            nroChecks = nroChecks + 1;
        }

        if (beneCheckbox !== '' && beneCheckbox !== false) {
            this.setState({ entityTypes: [{ value: "Beneficiary", name: "Beneficiary" }] });
            nroChecks = nroChecks + 1;
        }

        if (nroChecks === 2 || nroChecks === 0) {
            this.setState({ entityTypes: [{ value: "Deal", name: "Deal" }, { value: "Beneficiary", name: "Beneficiary" }] });
        }

        this.setState({ loading: true });
        getApprovalTiersByCustomerID(customerID, 1, getDefaultPagingNumberOfItems()).then(
            (value) => {
                console.table(value.approvalTiers);
                var approvalTiers = value.approvalTiers;
                this.setState({ approvalTiers: approvalTiers });
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                this.setState({ totalPages: pages, newSearch: false, loading: false });
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            var customerID = this.props.id;
            this.setState({ approvalTier: { ...this.state.approvalTier, CustomerID: customerID } });

            this.setState({ loading: true });
            getApprovalTiersByCustomerID(customerID, 1, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    console.table(value.approvalTiers);
                    var approvalTiers = value.approvalTiers;
                    this.setState({ approvalTiers: approvalTiers });
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({ totalPages: pages, newSearch: false, loading: false });
                }
            );
        }

    }

    validateRequiredFields() {
        const fields = [
            { displayName: 'Entity Type', validateName: 'entityTypes', value: this.state.approvalTier.EntityType, type: 'string' },
            { displayName: 'From Tier', validateName: 'fromtier', value: this.state.approvalTier.FromTier, type: 'string' },
            { displayName: 'To Tier', validateName: 'totier', value: this.state.approvalTier.ToTier, type: 'string' },
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    handleGetEntityType(value) {
        if (value !== null && value !== undefined && value !== 'select') {
            this.setState({
                approvalTier: { ...this.state.approvalTier, EntityType: value.value },
                validations: { ...this.state.validations, entityTypes: true, }
            });
            if (value.value === 'Beneficiary') {
                this.setState({
                    approvalTier: { ...this.state.approvalTier, ToTier: 9999, EntityType: value.value },
                    disableControls: true
                });
            }
            else {
                this.setState({
                    approvalTier: { ...this.state.approvalTier, ToTier: 0, EntityType: value.value },
                    disableControls: false
                });
            }
            /*getApprovalTiersByEntityType(this.state.approvalTier.CustomerID, value.value, 1, getDefaultPagingNumberOfItems()).then(
                (json) => {
                    console.table(json.approvalTiers);
                    var approvalTiers = json.approvalTiers;
                    this.setState({approvalTiers: approvalTiers});
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:true, loading:false});                    
                }
            );*/
            this.loadApprovalTiersByEntityId(value.value);
        }
    }

    handleUpdateFromTier(event) {
        this.setState({
            approvalTier: { ...this.state.approvalTier, FromTier: event.target.value },
            validations: { ...this.state.validations, fromtier: true }
        });
    }

    handleUpdateToTier(event) {
        this.setState({
            approvalTier: { ...this.state.approvalTier, ToTier: event.target.value },
            validations: { ...this.state.validations, totier: true }
        });
    }

    handleUpdateRequiredApprovals(event) {
        this.setState({ approvalTier: { ...this.state.approvalTier, RequiredApprovals: event.target.value } });
    }

    handlerUpdateApprovalTiersClick(event) {
        event.preventDefault();
        if (this.validateRequiredFields()) {
            this.IsOverlapped();
        }
    }

    IsOverlapped() {
        var fromTier = this.state.approvalTier.FromTier;
        var toTier = this.state.approvalTier.ToTier;

        var aTList = this.state.approvalTiers;
        var isOverlapped = false;
        if (aTList > 0) {
            for (var i = 0; i < aTList.length; i++) {
                if (aTList[i]["From Tier"] <= fromTier && fromTier < aTList[i]["To Tier"]) {
                    isOverlapped = true;
                    this.setState({ showAlert: true, alertMessage: "There is an overlap with the tier [" + aTList[i]["From Tier"] + " - " + aTList[i]["To Tier"] + "]" });
                } else if (aTList[i]["From Tier"] < toTier && toTier <= aTList[i]["To Tier"]) {
                    isOverlapped = true;
                    this.setState({ showAlert: true, alertMessage: "There is an overlap with the tier [" + aTList[i]["From Tier"] + " - " + aTList[i]["To Tier"] + "]" });
                } else if (fromTier <= aTList[i]["From Tier"] && aTList[i]["To Tier"] <= toTier) {
                    isOverlapped = true;
                    this.setState({ showAlert: true, alertMessage: "There is an overlap with the tier [" + aTList[i]["From Tier"] + " - " + aTList[i]["To Tier"] + "]" });
                }
            }
        }
        if (!isOverlapped) {
            this.saveApprovalTier();
        }
    }

    loadApprovalTiersByEntityId(entityType) {
        getApprovalTiersByEntityType(this.state.approvalTier.CustomerID, entityType, 1, getDefaultPagingNumberOfItems()).then(
            (json) => {
                console.table(json.approvalTiers);
                var approvalTiers = json.approvalTiers !== undefined ? json.approvalTiers : [];
                var totalCount = json.totalCount;
                var pages = approvalTiers.length !== 0 ? Math.ceil(totalCount / getDefaultPagingNumberOfItems()) : 0;
                this.setState({ approvalTiers: approvalTiers, totalPages: pages, newSearch: false, loading: false });
            }
        );
    }

    saveApprovalTier() {
        if (this.state.approvalTier.FromTier < this.state.approvalTier.ToTier) {
            saveApprovalTier(this.state.approvalTier).then(
                (json) => {
                    console.log(json)
                    if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                        this.setState({ showAlert: true, alertMessage: json.Message, alertTitle: 'Error' });
                    } else {
                        this.setState({
                            approvalTier: { ...this.state.approvalTier, UpdateToken: json.updateToken },
                            showAlert: true, alertMessage: '', alertTitle: 'Approval Tier successfully saved.'
                        });
                        if (this.state.approvalTier.EntityType === 'Beneficiary') {
                            this.setState({ disableControls: true });
                        } else {
                            this.setState({ disableControls: false });
                        }
                        //this.props.updateFunction();
                        this.loadApprovalTiersByEntityId(this.state.approvalTier.EntityType);
                    }
                }
            );
        } else {
            this.setState({ showAlert: true, alertMessage: 'To Tier is lower than From Tier. Please, try again.', alertTitle: 'Error' });
        }
    }
    handleClearFieldsClick() {
        var modelApprovalTiers = this.state.approvalTiers
        this.setState({
            approvalTier: { ...this.state.approvalTier, FromTier: 0, ToTier: 0, RequiredApprovals: 0, UpdatedBy: '', UpdateToken: '' },
            disableControls: false, isOverlapped: false, newSearch: false,
            validations: {
                fromtier: true,
                totier: true,
                entityTypes: true,
            },
        });
        if (modelApprovalTiers !== undefined) {
            if (modelApprovalTiers.length !== undefined && modelApprovalTiers.length !== 0) {
                modelApprovalTiers.forEach((value, index) => {
                    if (index >= 0) {
                        deleteApprovalTier(value).then(
                            (json) => {
                                modelApprovalTiers.splice(0, 1)
                                this.setState({ approvalTiers: modelApprovalTiers, totalPages: 0 }, () => this.loadApprovalTiersByEntityId(this.state.approvalTier.EntityType));
                            }
                        );
                    }
                });
            }
        }
    }
    handleClearClick() {
        this.setState({
            approvalTier: { ...this.state.approvalTier, EntityType: '', FromTier: 0, ToTier: 0, RequiredApprovals: 0, UpdatedBy: '', UpdateToken: '' },
            disableControls: false, isOverlapped: false, newSearch: false,
            validations: {
                fromtier: true,
                totier: true,
                entityTypes: true,
            },
        }, () => {
            getApprovalTiersByCustomerID(this.state.approvalTier.CustomerID, 1, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    console.table(value.approvalTiers);
                    var approvalTiers = value.approvalTiers !== undefined ? value.approvalTiers : [];
                    this.setState({ approvalTiers: approvalTiers });
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({ totalPages: pages, newSearch: false, loading: false });
                }
            );
        });
    }

    handleBackLink() {
        this.props.backLink();
    }

    changePage(value) {
        this.setState({ currentPage: value });
    }

    handleRowClick(obj) {
        console.group("ApprovalTiers handleRowClick")
        console.log(obj);
        console.groupEnd();
        //this.setState({outgoingFundsID: {id:obj['OutgoingFundsID']}});
        this.loadApprovalTierInfo(obj['CustomerID'], obj['Entity Type'], obj['From Tier'], obj['To Tier']);
    }

    loadApprovalTierInfo(customerID, entityType, fromTier, toTier) {
        getApprovalTiersInfo(customerID, entityType, toTier, fromTier).then(
            (value) => {
                this.setState({ approvalTier: value })
            }
        );
    }

    render() {
        return (
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <div className="">
                            <h3 className="uk-heading-divider" style={style.titleStyle} >
                                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                                </button>
                                    Approval Tiers
                            </h3>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Entity Type(*)</label>
                            <div className="uk-form-controls">
                                <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.entityTypes)} objValue={{ value: this.state.approvalTier.EntityType }} getValue={this.handleGetEntityType.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.entityTypes} id="dynamic-select-type" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: "hidden" }}>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>From Tier(*)</label>
                            <div className="uk-form-controls">
                                <NumberInput disabled={true} className={this.formatValidInput(this.state.validations.fromtier)} id="form-approval-tiers-from-tier" placeholder="0,00" type="Rate" scale={2} value={this.state.approvalTier.FromTier} onChange={this.handleUpdateFromTier.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>To Tier(*)</label>
                            <div className="uk-form-controls">
                                <NumberInput disabled={true} className={this.formatValidInput(this.state.validations.totier)} id="form-approval-tiers-to-tier" placeholder="0,00" type="Rate" scale={2} value={this.state.approvalTier.ToTier} onChange={this.handleUpdateToTier.bind(this)} />
                            </div>
                        </div>

                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Required Approvals</label>
                            <div className="uk-form-controls">
                                <input disabled={true} className="uk-input" type="number" placeholder="0" value={this.state.approvalTier.RequiredApprovals} onChange={this.handleUpdateRequiredApprovals.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: "hidden" }}>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button disabled={true} className="uk-button uk-button-green" type="button" onClick={this.handlerUpdateApprovalTiersClick.bind(this)}>Save</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleClearFieldsClick.bind(this)}>Clear Fields</button>
                    </div>
                    <div>
                        <NewDynamicTable
                            id="table-approval-tiers"
                            formats={[{ header: 'From Tier', formatFunction: formatToCurrency }, { header: 'To Tier', formatFunction: formatToCurrency }]}
                            hiddenHeaders={['CustomerID', 'UpdateToken', 'CreatedBy', 'CreatedOn', 'UpdatedBy', 'UpdatedOn']}
                            data={this.state.approvalTiers}
                            enableClick={true}
                            clickFunction={this.handleRowClick.bind(this)}
                            newSearch={this.state.newSearch}
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteClick.bind(this)} />
                        <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch} />
                        <LoadingBox loading={this.state.loading} />
                    </div>
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)} />
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Tier?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Approval Tier successfully removed." message="" type="Ok" okClick={this.okSuccessAlert.bind(this)} />
            </div>
        );
    }
}