import React from 'react';

import LoadingBox from '../shared/LoadingBox';
import NumberInput from '../shared/NumberInput';
import NewDynamicTable from '../shared/NewDynamicTable';
import Paginator from '../shared/Paginator';
import AlertBox from '../shared/AlertBox'

import { getOtherSettlementFunds } from '../../networking/NetworkingCustomers';
import { getDealsForPayments } from '../../networking/NetworkingDeals';
import {createIncomingFund, voidIncomingFund} from '../../networking/NetworkingFunds'

import {formatToCurrency} from '../../helpers/FormatHelper';
import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';
import {getStyle} from '../../styles/styles';

var style = getStyle();

export default class OtherSettlementFunds extends React.Component {

    state = {
        settlementID : 0,
        customerID: 0, 
        currencyID : '',
        listToApplyView : [],
        listToApply : [],
        listFunds : [],
        newSearch: false,
        currentPage : 1,
        totalPages: 0,
        amountReceived : 0,
        availableBalance: 0,
        loading : false,
        typeRecordsApply : 'Deal',
        sourceID : 0,
        sourceTable: '',
        showAlert:false,
        alertMessage:'',
        alertTitle: '',
        showVoidIncomingAlert: false,
        incomingFundsIDToVoid: 0,
    }

    componentDidMount()
    {
        if(this.props.customerID !== undefined)
        {
            this.getOtherSettlementFunds(this.props.customerID);
            this.setState({customerID: this.props.customerID});
        }
    }

    changePage = (value) =>
    {
        this.setState({currentPage: value},() =>
            this.getOtherSettlementFunds(this.state.customerID)
        );
        
    }

    handleRowClick = (obj)=>
    {
        //alert(JSON.stringify(obj));
        let sourceID = obj["RecordID"];
        let currencyID = obj['Currency'];
        var sourceTable = '';
        let amountReceived = obj['Amount Received'];
        if(obj["Record No"] !== "Held Funds"){
            sourceTable = 'D';
        }
        else{
            sourceTable = 'H';
        }
        this.setState({sourceTable: sourceTable,
            sourceID: sourceID,
            amountReceived: amountReceived,
            currencyID: currencyID,
        });
        this.getDealsForPayments(this.state.customerID, currencyID, sourceTable, sourceID);
    }

    getOtherSettlementFunds(customerID){
        if(customerID !== undefined && customerID > 0)
        {
            getOtherSettlementFunds(customerID, this.state.currentPage,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if(value !== undefined && value.totalCount > 0)
                    {
                        var funds = this.transformFundsList(value.result);
                        this.setState({listFunds: funds});
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                        this.setState({totalPages: pages, newSearch:true});
                    }
                    else{
                        this.setState({listFunds: [], newSearch:true});
                    }
                }
            );
        }
        else{
            this.setState({listFunds: [], newSearch:true});
        }
    }

    transformFundsList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Record No': list[i]['RecordNo'],
            'Currency': list[i]['CurrencyID'],
            'Amount': list[i]['Amount'],
            'Amount Received': list[i]['AmountReceived'], 
            'Pending Amount': list[i]['PendingAmount'],             
            'RecordID': list[i]['RecordID']
        
        });
        }
        return helper;
    }

    getDealsForPayments(customerID, currencyID, sourceTable, sourceID){
        if(Number(sourceID) > 0 || sourceTable === 'H')
        {
            getDealsForPayments(customerID, currencyID, sourceTable, sourceID, this.state.typeRecordsApply, 1, 100).then(
                (value) => {
                    if(value !== undefined && value.totalCount > 0)
                    {
                        console.log(value.result);
                        var deals = value.result;
                        this.setState({listToApplyView: deals, listToApply: []});
                    }
                    else{
                        this.setState({listToApplyView: [], listToApply: []});
                    }
                    this.calculateBalance();
                }
            );
        }
    }

    handleAmountToUse(data, event) { 
        
        let tempToApply = this.state.listToApply;
        let amountToUse = event.target.value;
        var paymentChanged = this.state.listToApplyView.filter(item => item['RecordID'] === data.recordID)[0]
        var valid = true;
        if(amountToUse < 0)
        {            
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }

        if(amountToUse > data.pendingAmount)
        {
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
       //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
          if (tempToApply.length > 0) {
                var amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
               
                if (amountTotalToApply > this.state.amountReceived) {
                    this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error'});
                    paymentChanged['AmountToUse'] = 0;
                    valid = false;
                }
            }
        //remove if exist
        if (tempToApply.length > 0) {
            tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
        }

        if(valid && amountToUse > 0)
        {
            //add if amount > 0
                if (amountToUse > 0) {
                    tempToApply.push({
                        RecordID: data.recordID,
                        AmountToUse: amountToUse
                    })
                }

                paymentChanged['AmountToUse'] = amountToUse;          
            
        }
        this.calculateBalance();
        this.setState({
            listToApply: tempToApply
        });
    }

    //alerts
    handleApply = () => {
        this.setState({showApplyPaymentsAlert: true});
    }

    yesApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false, loading: true});
        let userID = sessionStorage.getItem('UserID');
        var model = {
            SourceID: this.state.sourceID,
            SourceTable: this.state.sourceTable,
            UserID: userID,
            Records: this.state.listToApply 
        }

        createIncomingFund(model).then((json) => {
            var type = 'Held Fund';
            if(this.state.sourceTable === 'D')
            {
                type = 'Deposit'
            }
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlert: true,
                    alertMessage: 'An error occurred while trying to apply '+type+'. Please try again.',
                    alertTitle: 'Error',
                    loading: false,
                });
            } else {
                this.getOtherSettlementFunds(this.state.customerID);
                this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                if(this.props.reloadDealHeaderInfo !== undefined){
                    this.props.reloadDealHeaderInfo();
                }

                this.setState({
                    listPaymentsToApply: [],
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: type + ' applied successfully.',
                    loading: false,
                });
            }
        });
    }

    noApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    calculateBalance()
    {
        var availableBalance = 0;
        if(this.state.sourceTable === 'H'){//Held funds balance
            availableBalance = this.state.amountReceived;
        }
        else{//Deposits
            var userAmount = 0;
            var listToApplyView = this.state.listToApplyView;

            for(var d = 0; d< listToApplyView.length; d++)
            {
                if (listToApplyView[d].AmountToUse !== undefined )
                {
                    userAmount += listToApplyView[d].AmountToUse;
                }
            }
            availableBalance = this.state.amountReceived - userAmount;
        }
        this.setState({availableBalance: availableBalance});
    }

    handleUnlinkPayment = (incomingFundsID) => {
        this.setState({showVoidIncomingAlert: true,
            incomingFundsIDToVoid: incomingFundsID,
        });
    }

    yesVoidIncomingFund = () => {
        this.setState({loading: true});
        voidIncomingFund(this.state.incomingFundsIDToVoid, sessionStorage.getItem('UserID'), null, null, null).then(
            (response) => {
                if(Number(response.httpStatusCode) === 200){
                    this.getOtherSettlementFunds(this.state.customerID)
                    this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                    if(this.props.reloadDealHeaderInfo !== undefined){
                        this.props.reloadDealHeaderInfo();
                    }
                    this.setState({showVoidIncomingAlert: false,
                        incomingFundsIDToVoid: 0,
                        alertTitle: 'Success',
                        alertMessage: 'Payment application voided!',
                        showAlert: true,
                        loading: false,
                    });                    
                }
                else{
                    this.setState({showVoidIncomingAlert: false,
                        incomingFundsID: 0,
                        alertTitle: 'Error',
                        alertMessage: 'Payment application not voided! Please try again or contact administrator.',
                        showAlert: true,
                        loading: false,
                    });
                }
            }
        );
    }

    closeVoidIncomingAlert = () => {
        this.setState({showVoidIncomingAlert: false});
    }

    render()
    {
        //new dynamic table with editable cell
        var rows = [];

        let headers = (
            <tr>
                <th>Record Number</th>
                <th>Type</th>
                <th>Value Date</th>
                <th>Buy Amount</th>
                <th>Complete Amount</th>
                <th>Pending Amount</th>
                <th>Amount To Use</th>
                <th>Actions</th>
            </tr>
        );

        var index=0;
        if(this.state.listToApplyView !== undefined)
        {
        this.state.listToApplyView.forEach(element => {
            index++;

            var voidAction;
            var amountControl;
            if(Number(element.IncomingFundsID) > 0){
                voidAction = (<i className="fa fa-chain-broken" onClick={() => this.handleUnlinkPayment(element.IncomingFundsID)} style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>);
                amountControl = (formatToCurrency(element['AmountToUse']));
            }
            else{
                amountControl = (
                    <NumberInput id={'amounttouse-' + index} 
                        type="Currency" disabled={true}
                        value={element['AmountToUse']} 
                        className="uk-input"
                        onChange={this.handleAmountToUse.bind(this, 
                            {
                                recordID: element['RecordID'],
                                typeRecord: element['TypeRecord'],
                                pendingAmount: element['PendingAmount'],
                                incomingFundsID: element['IncomingFundsID'],
                                amount: element['BuyAmount']
                            }
                        )}
                    />
                );
            }

            rows.push(<tr key={index}>
                <td>{element['RecordNo']}</td>
                <td>{element['Description']}</td>
                <td>{element['ValueDate']}</td>
                <td>{formatToCurrency(element['BuyAmount'])}</td>
                <td>{formatToCurrency(element['CompleteAmount'])}</td>
                <td>{formatToCurrency(element['PendingAmount'])}</td>
                <td>
                    {amountControl}
                </td>
                <td align="center">
                    {voidAction}
                </td>                   
            </tr>);
        });
        }
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfRecordsToApply = 0;
        this.state.listToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;   
            numberOfRecordsToApply++;         
        });

        var validToApply = false;
                 
        if(numberOfRecordsToApply > 0)
        {
            validToApply = true;
        }
        

        return(
            <div>
                <div className="uk-width-1-1 uk-margin-right">                      
                    <div className="uk-grid uk-margin" >
                        <div className="uk-width-1-5">
                            <h4>Deposit and held funds</h4>
                        </div>
                    </div>                                   
                </div>  
                <div>
                    <NewDynamicTable id="osf-funds" 
                        hiddenHeaders={["RecordID"]} 
                        data={this.state.listFunds} 
                        enableClick={true} 
                        clickFunction={this.handleRowClick}
                        formats={[{header:'Amount', formatFunction: formatToCurrency},
                                    {header:'Amount Received', formatFunction:formatToCurrency},
                                    {header:'Pending Amount', formatFunction:formatToCurrency}]}
                    />
                    <Paginator id="ndt-deposits-paginator" 
                                    changePage={this.changePage} 
                                    numberOfPages={this.state.totalPages} 
                                    newSearch={this.state.newSearch}/> 
                </div>
                <hr></hr>
                <div className="uk-width-1-1 uk-margin-right">                      
                    <div className="uk-grid uk-margin" >
                        <div className="uk-width-1-5">
                            <h4>Deals</h4>
                        </div>
                    </div>  
                    <div className="uk-width-1-1">
                    <label className="uk-form-label uk-align-right">Balance: {formatToCurrency(this.state.availableBalance)} </label>
                    </div>
                    <div className="uk-width-1-1">
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                            <thead>
                                {headers}
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>

                <div className="uk-width-1-1" hidden> 
                    <button className={!validToApply ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                    onClick = {this.handleApply} disabled={!validToApply}>Apply</button>
                </div>
                                                     
                </div>  
                <div>
                
                </div>
                <LoadingBox loading={this.state.loading}/>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            
                <AlertBox id="apply-alert"
                    open={this.state.showApplyPaymentsAlert} 
                    title="Please confirm" 
                    message = {"You will apply: " + formatToCurrency(amountTotalToApply) + ' ' + this.state.currencyID}
                    type = "Yes/No"
                    yesClick = {this.yesApplyPaymentsAlert.bind(this)}
                    noClick = {this.noApplyPaymentsAlert.bind(this)}
                    />

                <AlertBox id="alert-void-incoming"
                type="Yes/No"
                open={this.state.showVoidIncomingAlert}
                title="Confirm"
                message="Are you sure you want to void this payment application?"
                yesClick={this.yesVoidIncomingFund} 
                noClick={this.closeVoidIncomingAlert} 
                onClose={this.closeVoidIncomingAlert}/>
            </div>
        );
    }

}