import jwt from 'jsonwebtoken';
import {genericGetWithParameters, genericDeleteWithParameters} from './Networking';


const apiURL = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export async function deleteState(stateID){
    let params = {
        stateId: stateID,
    }
    let response = await genericDeleteWithParameters('States/DeleteState',params);
    return response;
}

export async function getStatesByCountry(countryId, page, items){
    try {
        let params = {
            'CountryId': countryId,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('States/GetStatesByCountry', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getStatesByCountryMin(countryID){
    try {
        let params = {
            'CountryID': countryID
        }
        let response = await genericGetWithParameters('States/GetStatesByCountry_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getStatesById(stateId){
    try {
        let params = {
            'stateId': stateId
        }
        let response = await genericGetWithParameters('States/GetStatesById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

/*export async function getStatesById(stateId){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let params = {
            'StateID': stateId
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'States/GetStatesById?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        var decoded = jwt.decode(responseJWT.data);
        console.log(decoded);
        return decoded.response;
    } catch (error) {
        console.error(error);
    }
}*/

export async function addState(addState) {
    try {
        console.log(addState);
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'States/SaveState',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(addState),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}