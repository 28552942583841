import jwt from 'jsonwebtoken';
import {genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;


export async function searchCurrencies(currency,status){
    if (currency !== '') {
        try {
            let params = {
                'currency': currency,
                'status': status
            }
            let response = await genericGetWithParameters('Currency/SearchCurrenciesByStatusMin', params);
            
            return response.currencies;
        } catch (error) {
            console.error(error);
        }
    }
}

export async function getCurrencyType(){
    try {
        let params = {}
        let response = await genericGetWithParameters('Currency/GetCurrencyType', params);
        return response.CurrencyType;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyList(){
    try {
        let params = {}
        let response = await genericGetWithParameters('Currency/GetCurrenciesMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByStatus(status){
    try {
        let params = {
            'status': status,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByStatus',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByCustomer(customerID){
    try {
        let params = {
            'customerID': customerID,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrenciesByCountry(countryID){
    try {
        let params = {
            'countryID': countryID,
        }
        let response = await genericGetWithParameters('Currency/GetCurrenciesByCountry',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveCurrenciesByCountry(model){
    let response = await genericCallWithBody('POST','Currency/SaveCurrenciesByCountry',model);
    return response;
}

export async function getCurrencies(page,items){
    try {
        let params = {
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('Currency/GetCurrencies',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyMessages(page,items){
    try {
        let params = {
            'page': page,
            'items': items
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyMessage',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyByID(id){
    try {
        let params = {
            'currencyId':id
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCurrencyMessageByID(id){
    try {
        let params = {
            'currencyMessageId':id
        }
        let response = await genericGetWithParameters('Currency/GetCurrencyMessageById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function saveCurrency(objCurrency){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Currency/SaveCurrency';
    let response = await genericCallWithBody(method, apiName, objCurrency);
    return response;
}

export async function saveCurrencyMessage(objCurrencyMessage){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Currency/SaveCurrencyMessage';
    let response = await genericCallWithBody(method, apiName, objCurrencyMessage);
    return response;
}

export async function deleteCurrency(putCurrency) {
    try{
        let params = {
            'currencyId': putCurrency
        };
        let sessionID = await sessionStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + 'Currency/DeleteCurrency' + '?' + query, {
            method: 'DELETE',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function deleteCurrencyMessage(putCurrencyM) {
    try{
        let params = {
            'currencyId': putCurrencyM
        };
        let response = await genericDeleteWithParameters('Currency/DeleteCurrencyMessage',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}