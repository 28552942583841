import React from 'react';
import { addCategory } from '../../../networking/NetworkingPricing';
import AlertBox from '../../shared/AlertBox';
import { formatValidInput } from '../../../helpers/FormatHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { getStyle } from '../../../styles/styles';
const style = getStyle();

export default class PricingCategoryForm extends React.Component {
    
    state = {
        showAlert: false,
        showAlertError:false,
        alertTitle: '',
        alertMessage: '',
        categoryID: 0,
        categoryName:'',
        isGetDefault: '',
        updateToken: 0,
        categoryList: [],
        validations: {
            categoryName: true
        },
        errors:[]
    }

    componentDidMount(){
        if (this.props.categoryName !== undefined && this.props.categoryName !== '') {
            this.setState({ categoryName: this.props.categoryName });
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedCategory !== {} && prevProps.selectedCategory !== this.props.selectedCategory){
            var category = this.props.selectedCategory;
            var categoryID = 0;
            if(category['Category ID'] !== null){
                categoryID = category['Category ID'];
            }

            console.log(category);
            this.setState({
                categoryID: categoryID,
                categoryName: category['Category Name'],
                isGetDefault: category['Is Default?'],
                updateToken: category['UpdateToken']
            });
        };
    }

    handleOnCheck(event){
        console.log(event.target.checked);
        this.setState({isGetDefault: event.target.checked});
    }
    
    handleSaveClick(event){
        event.preventDefault();
        let UserID = sessionStorage.getItem('UserID');
        if(this.state.updateToken==0){
            var objectFilter = {
                categoryID: 0,
                categoryName:this.state.categoryName,
                defaultCategory: this.state.isGetDefault,
                userID: UserID
            }
        }else{
            var catList = this.props.selectedCategory;
            var objectFilter = {
                categoryID: catList['Category ID'],
                categoryName: this.state.categoryName,
                defaultCategory: this.state.isGetDefault,
                updateToken: catList['UpdateToken'],
                userID: UserID
            }
        }
        if(this.validateRequiredInputs()){
            console.log(objectFilter);
            addCategory(objectFilter).then(
            (json)=>{
                    this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Pricing Category successfully saved.' });
                    this.props.updateCategoryTable();
                }
            );
        }
    }
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }
    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }

    handleClearClick(event){
        event.preventDefault();
        this.setState({
            categoryName:'',
            isGetDefault: '',
            updateToken:0,
            categoryID:0,
            validations: {
                categoryName: true
            }
        });
        this.props.clearCategoryName();
    }

    handleUpdateCategoryName(event){
        this.setState({
            validations: {...this.state.validations, categoryName: true},
            categoryName: event.target.value
        });
    }

    //Input Validation
    validateRequiredInputs = () =>{
        const fields = [
            {displayName: 'Category Name', validateName: 'categoryName', value: this.state.categoryName, type: 'string'}
        ];

        const [valid, validations, errors] = validate(fields);

        this.setState({validations});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    render() {
        return (
            <div>
                <form className="uk-form-stacked uk-grid uk-margin">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-pricingCat-id" style={style.boldLabel}>Category Name (*)</label>
                        <div className="uk-form-controls">
                            <input className={formatValidInput(this.state.validations.categoryName)} placeholder="Category Name" id="categoryName" type="text"value={this.state.categoryName} onChange={this.handleUpdateCategoryName.bind(this)}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2 uk-margin">
                        <label className="uk-form-label" htmlFor="form-pricingCat-authorizedDeal"><input className="uk-checkbox" id="isDefault" type="checkbox" checked={this.state.isGetDefault} onChange={this.handleOnCheck.bind(this)}/> Default Category</label>                    
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                    <hr/>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>
            </div>
        );
    }
}