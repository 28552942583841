import React, { useState } from 'react';
import CustomerInformationForm from './CustomerInformationForm';
import { createCustomer } from '../../../../networking/NetworkingCustomers';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import TradingInformationForm from './TradingInformationForm';
const style = getStyle();

const AddCustomerScreen = (props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertDetails, setAlertDetails] = useState({alertTitle: "", alertMessage: ""});
    const [customer, setCustomer] = useState({});
    const [validatedCustomerInfo, setValidatedCustomerInfo] = useState(false);
    const [validatedTradingInfo, setValidatedTradingInfo] = useState(false);
    const [validationsCustomerInfoFields, setValidationsCustomerInfoFields] = useState(true);
    const [validationsTradingInfoFields, setValidationsTradingInfoFields] = useState(true);
    const [customerInfoErrors, setCustomerInfoErrors] = useState([]);
    const [tradingInfoErrors, setTradingInfoErrors] = useState([]);

    const handleBackLink = () => {
        props.backLink();
    }

    const handleAddCustomer = async () => {
        if (validatedCustomerInfo && validatedTradingInfo) {
            const model = {
                CommissionBase: customer.CommissionBase,
                CountryID: "CAN",
                CreatedBy: sessionStorage.getItem('UserID'),
                CustomerGroupID: customer.CustomerGroupID,
                CustomerType: customer.CustomerType,
                DefaultBranchID: customer.DefaultBranchID,
                DefaultPricingID: customer.DefaultPricingID,
                FirstName: customer.FirstName,
                LastName: customer.LastName,
                Name: customer.CustomerType === "Corporate" ? customer.Name : `${customer.LastName}, ${customer.FirstName}`,
                ParentCustomerID: customer.ParentCustomerID,
                PreferredLanguage: customer.PreferredLanguage,
                Region: customer.Region,
                Salutation: customer.Salutation,
                ServiceLevel: customer.ServiceLevel,
                ShortName: customer.ShortName,
                SpreadPoints: customer.SpreadPoints,
                AverageTradeSize: customer.AverageTradeSize,
                ConfirmationLimit: customer.ConfirmationLimit,
                ConfirmationType: customer.ConfirmationType,
                MonthlyTradingVolume: customer.MonthlyTradingVolume,
                TraderID: sessionStorage.getItem('TraderID'),
                AffiliateReferredBy: sessionStorage.getItem('AgentCustomerID')
            }
            const json = await createCustomer(model);
            if (json.httpStatusCode !== 200) {
                setShowAlertError(true);
                setAlertDetails({alertMessage: "An error occurred while trying to save the customer. Please try again.", alertTitle: "Error"});
            } else {
                setShowAlert(true);
                setAlertDetails({alertMessage: "Customer successfully saved.", alertTitle: "Success"});
            }
        } else {
            if (validatedTradingInfo && !validatedCustomerInfo) {
                setValidationsCustomerInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error", });
            } else if (validatedCustomerInfo && !validatedTradingInfo) {
                setValidationsTradingInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error", });
            } else {
                setValidationsCustomerInfoFields(false);
                setValidationsTradingInfoFields(false);
                setShowAlertError(true);
                setAlertDetails({alertMessage: "Please fill all mandatory fields", alertTitle: "Error" });
            }
        }
    }

    const getCustomerInfo = (customerInfo) => {
        setCustomer({...customer, ...customerInfo})
    }

    const getTradingInfo = (tradingInfo) => {
        setCustomer({
            ...customer,
            TraderID: tradingInfo.trader,
            AverageTradeSize: tradingInfo.tradeAverageSize,
            ConfirmationType: tradingInfo.confirmationType,
            MonthlyTradingVolume: tradingInfo.monthlyAverageVolume,
            ConfirmationLimit: tradingInfo.confirmationLimit
        })
    }

    const isValidatedCustomerInfo = (validator) => {
        if (validator.valid) {
            setValidatedCustomerInfo(true);
            setValidationsCustomerInfoFields(true);
            setCustomerInfoErrors([])
        } else {
            setValidatedCustomerInfo(false);
            setCustomerInfoErrors({tab: "Customer Information", errors: validator.errors});
        }
    }

    const isValidatedTradingInfo = (validator) => {
        if (validator.valid) {
            setValidatedTradingInfo(true);
            setValidationsTradingInfoFields(true);
            setTradingInfoErrors([]);
        } else{
            setValidatedTradingInfo(false);
            setTradingInfoErrors({tab: "Trading Information", errors: validator.errors});
        }
    }

    const closeAlertOK = () => {
        setShowAlert(false);
        setAlertDetails({alertMessage: '', alertTitle: ''})
        props.backLink();
    }

    const closeAlertError = () => {
        setValidationsCustomerInfoFields(true);
        setValidationsTradingInfoFields(true);
        setShowAlertError(false);
        setAlertDetails({alertMessage: '', alertTitle: ''})
    }

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >
                <button className="uk-button uk-button-text uk-margin-right  min-width-0" onClick={handleBackLink}>
                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                </button> 
                Customer
            </h3>
            <ul uk-tab="">
                <li><a href="#">Customer Information</a></li>
                <li><a href="#">Trading Information</a></li>
                {/* <li><a href="#">Comments</a></li> */}
            </ul>
            <ul className="uk-switcher uk-margin">
                <li>
                    <CustomerInformationForm getCustomerInfo={getCustomerInfo} isValid={isValidatedCustomerInfo} validations={validationsCustomerInfoFields}/>
                </li>
                <li>
                    <TradingInformationForm getTradingInfo={getTradingInfo} isValid={isValidatedTradingInfo} validations={validationsTradingInfoFields}/>
                </li>
                {/* <li><CommentsForm/></li> */}
            </ul>
            <button disabled={true} className="uk-button uk-button-green" onClick={handleAddCustomer}>Save</button>
            <AlertBox open={showAlert} onClose={closeAlertOK} title={alertDetails.alertTitle} message={alertDetails.alertMessage} type="Ok" okClick={closeAlertOK}/>
            <AlertBox open={showAlertError} onClose={closeAlertError} title={alertDetails.alertTitle} message={alertDetails.alertMessage} messageBoldList={[customerInfoErrors, tradingInfoErrors]} type="Ok" okClick={closeAlertError}/>
        </div>
    )
}

export default AddCustomerScreen;
