import React from 'react';

import {searchCountries} from '../../../../networking/NetworkingCountries';
import {searchCurrencies} from '../../../../networking/NetworkingCurrencies';
import {getAllLimitProfilesByCustomerID, getLimitProfile, saveLimitProfile, deleteCustomerLimitProfile} from '../../../../networking/NetworkingCustomers';

import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import DynamicSelect from '../../../shared/DynamicSelect';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import {getDefaultPagingNumberOfItems} from '../../../../helpers/PackageJsonHelper';

import AlertBox from '../../../shared/AlertBox';
import { getStyle } from '../../../../styles/styles';

import NumberInput from '../../../shared/NumberInput';
import { formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';

var style = getStyle();

export default class LimitProfilesScreen extends React.Component {

    state = {
        customerLimitProfile: {
            CustomerLimitProfileID: 0,
            CustomerID: 0,
            CurrencyID: "",
            BuySell: "",
            CountryId: "",
            LowerLimit: 0,
            UpperLimit: 0,
            Frequency: 0,
            CreatedBy: "",
            CreatedOn: "",
            UpdatedBy: "",
            UpdatedOn: "",
            UpdateToken: ""
        },

        buysell: [{
            value: "Buy",
            name: "Buy"
        }, {
            value: "Sell",
            name: "Sell"
        }],
        currencies: [],
        countries: [],
        side: "",
        currencyId: "",
        currencyName: '',
        countryId: "",
        countryIdName: "",
        lowerLimit: 0,
        upperLimit: 0,

        pages: 1,
        totalPages: 1,
        newSearch: true,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        limitProfiles: [],
        customerId: 0,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteObj: {},
        validations: {
            buySell: true,
            country: true,
            currency: true,
            lowerLimit: true,
            upperLimit: true
        },
        errors: []
    }

    componentDidMount() {  
         this.setState({
             customerId: this.props.id
         }); 

        //load limit profiles
        this.updateGrid();
    }

    changePage = (value) => {
        this.setState({
            pages: value
        });
    }

    handleRowClick = (row) => {
        console.group("Limit Profile handleRowClick")
        console.log(row);
        console.groupEnd();
        getLimitProfile(row['CustomerLimitProfileId']).then(
            (value) => {
                console.table(value);
                this.setState({
                    customerLimitProfile: value,
                    side: value.BuySell,
                    lowerLimit: value.LowerLimit,
                    upperLimit: value.UpperLimit,
                    currencyId: value.CurrencyID,
                    countryId: value.CountryID,
                    countryIdName: value.CountryName,
                    validations: {
                        buySell: true,
                        country: true,
                        currency: true,
                        lowerLimit: true,
                        upperLimit: true
                    }
                }, ()=> this.search(this.state.currencyId));

            }
        );
    }
 
    handleDeleteClick = (obj) =>{
        console.group("Customer Limit Profile handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteObj: obj});
        this.handleClearClick();
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    handleGetValueBuySell = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                validations: {...this.state.validations, buySell: true},
                side: value.value 
            });
        }
    }

    handleGetValueCurrency = (value) => {
        console.log(value);
        this.setState({
            validations: {...this.state.validations, currency: true},
            currencyId: value.value
        });
    } 

    search = async (query) => { 
        const json = await searchCurrencies(query, 'active');
        var helper = [];
        if (query !== '') {
            if (json !== undefined) {
                for (var i = 0; i < json.length; i++) {
                    helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                }
                if (json.length > 1) {
                    this.setState({currencies: helper});
                } else {
                    this.setState({currencies: helper, currencyName: helper[0].name});
                }
            } else {
                this.setState({currencies: helper});
            }
        }
    }

    searchCountry = async (query) => {
        const json = await searchCountries(query);
        var helper = [];
        if (query !== '') {
            console.group('Search Countries');
            console.log('Query');
            console.table(query);
            console.log('Response');
            console.table(json);
            console.groupEnd();
            for (var i = 0; i < json.length; i++) {
                json[i].value = json[i].CountryId;
                json[i].name = json[i].CountryIdName;
                helper.push(json[i]);
            }
            console.log(helper);
            this.setState({
                countries: helper
            });
            if (helper.length === 1) {
                var value = helper[0];
                this.setState({
                    countryId: value.CountryId
                });
            }
        }
        console.table(this.state.countries)
    }

    getCountryValue = (value) => {
        if (value !== undefined) {
            this.setState({
                validations: {...this.state.validations, country: true},
                countryId: value.CountryId
            });
        }
    }

    getLowerLimitValue = (event) => {
        this.setState({
            validations: {...this.state.validations, lowerLimit: true},
            lowerLimit: event.target.value
        });
    }

    getUpperLimitValue = (event) => {
        this.setState({
            validations: {...this.state.validations, upperLimit: true},
            upperLimit: event.target.value
        });
    }

    handleClearClick = () => {
        this.setState({
            lowerLimit: 0,
            upperLimit: 0,
            side: '',
            currencyId: '',
            countryId: '',
            countryIdName: '',
            customerLimitProfile: {
                CustomerLimitProfileID: 0,
                CustomerID: 0,
                CurrencyID: "",
                BuySell: "",
                CountryId: "",
                LowerLimit: 0,
                UpperLimit: 0,
                Frequency: 0,
                CreatedBy: "",
                CreatedOn: "",
                UpdatedBy: "",
                UpdatedOn: "",
                UpdateToken: ""
            },
            validations: {
                buySell: true,
                country: true,
                currency: true,
                lowerLimit: true,
                upperLimit: true
            }
        });
    }

    handleSaveClick = () => {
        let userID = sessionStorage.getItem('UserID');
        var model = {
            CustomerLimitProfileID: this.state.customerLimitProfile.CustomerLimitProfileID,
            CustomerID: this.state.customerId,
            CurrencyID: this.state.currencyId,
            BuySell: this.state.side,
            CountryID: this.state.countryId,
            LowerLimit: this.state.lowerLimit,
            UpperLimit: this.state.upperLimit,
            UpdatedBy: userID,
            UpdateToken: this.state.customerLimitProfile.UpdateToken
        }

        console.table(model);
        
        if (this.validateRequiredInputs()) {
            saveLimitProfile(model).then((json) => {
                if (json.httpStatusCode  !== 200) {
                    this.setState({
                        showAlert: true,
                        alertMessage: 'An error occurred while trying to save the limit profile. Please try again.',
                        alertTitle: 'Error'
                    });
                } else {
                    this.setState({
                        x: {
                            ...this.state.customerLimitProfile,
                            UpdateToken: json.updateToken
                        },
                        showAlert: true,
                        alertTitle: 'Limit profile successfully saved.'
                    });
                    this.updateGrid();
                }
            })
        }
    }

    validateRequiredInputs = () => {
        const lowerLimitValidation = (lowerLimit) => {
            if (lowerLimit === 0 || lowerLimit > this.state.upperLimit) {
                return false;
            }
            return true;
        }

        const upperLimitValidation = (upperLimit) => {
            if (upperLimit === 0 || upperLimit < this.state.lowerLimit) {
                return false;
            }
            return true;
        }
        
        const fields = [
            {displayName: 'Buy/Sell', validateName: 'buySell', value: this.state.side, type: 'string'},
            {displayName: 'Country', validateName: 'country', value: this.state.countryId, type: 'string'},
            {displayName: 'Currency', validateName: 'currency', value: this.state.currencyId, type: 'string'},
            {displayName: 'Lower Limit', validateName: 'lowerLimit', value: this.state.lowerLimit, validation: lowerLimitValidation},
            {displayName: 'Upper Limit', validateName: 'upperLimit', value: this.state.upperLimit, validation: upperLimitValidation}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations});

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    updateGrid = async () => {
        const limitProfiles = await this.getAllLimitProfiles();
        const totalCount = limitProfiles.length;
        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
        this.setState({limitProfiles, totalPages});
    }

    getAllLimitProfiles = async () => {
        const json = await getAllLimitProfilesByCustomerID(this.props.id, 0, 0);
        if (json.limitProfiles !== undefined) {
            return json.limitProfiles;
        } else {
            return [];
        }
    }

    closeDeleteAlert = () =>{
        this.setState({showDeleteAlert: false});
    }

    okSuccessDeleteAlert = () => {
        this.setState({showSuccessDeleteAlert: false});
    }

    yesDeleteAlert = async () => {
        const value = await deleteCustomerLimitProfile(this.state.readyToDeleteObj['CustomerLimitProfileId']);
        console.log(value);
        this.setState({
            showDeleteAlert: false,
            showSuccessDeleteAlert: true
        });
        this.updateGrid();
    }

    render() {
        return (  <div>
            <div className="">
                <form className="uk-form-horizontal uk-margin">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Buy/Sell (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} 
                                className={formatValidSelect(this.state.validations.buySell)}
                                objValue={{value: this.state.side}}
                                getValue={this.handleGetValueBuySell} 
                                placeholder='Select a value'
                                data={this.state.buysell}
                                id="form-limitprofile-side" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect disabled={true} 
                                className={formatValidSelect(this.state.validations.country)} 
                                id="currency-list" 
                                objValue={{value: this.state.currencyId,name: this.state.currencyName}} 
                                placeholder="Enter a Currency..." 
                                searchFunction={this.search} 
                                data={this.state.currencies} 
                                getValue={this.handleGetValueCurrency}/>
                            </div>
                        </div>
                         <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Country (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect disabled={true} 
                                className={formatValidSelect(this.state.validations.country)} 
                                id="form-limitprofile-country" 
                                placeholder="Select a country" 
                                searchFunction={this.searchCountry} 
                                getValue={this.getCountryValue} 
                                data={this.state.countries} 
                                objValue = {{value: this.state.countryId, name: this.state.countryIdName}} 
                                /> 
                            </div>
                        </div>
                        <div className="uk-width-1-2">                            
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Lower Limit (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>                                
                                <NumberInput disabled={true} className={formatValidInput(this.state.validations.lowerLimit)} type="Integer" placeholder="" value={this.state.lowerLimit} 
                                onChange={this.getLowerLimitValue}/>
                        </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Upper Limit (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput disabled={true} className={formatValidInput(this.state.validations.upperLimit)} type="Integer" placeholder="" value={this.state.upperLimit}
                                onChange={this.getUpperLimitValue}/>
                                </div>
                        </div>
                         
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick} >Clear</button>
                    <button disabled={true} className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick} >Save</button>
                    <hr/>
                </div>
                <NewDynamicTable
                    hiddenHeaders={['CustomerLimitProfileId','Country ID']}
                    data={this.state.limitProfiles}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true} 
                    filterFunction={this.getAllLimitProfiles}/>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert}/>
                
                <AlertBox id="delete-limit-profile" title="Are you sure you want to delete this customer profile?" message="This action can not be undone." type="Yes/No"
                open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert}  yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
                
                <AlertBox id="confirmation-delete-limit-profile" title="Customer profile successfully removed." open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} message="" type="Ok" okClick={this.okSuccessDeleteAlert}/>
            </div>
        </div>)
    }
}