
import {genericGetWithParameters} from './Networking';


export async function GetGlobalSystemParameters(page, items){
    try{
        let params = {
            "NameLike":"",
            "Page":page,
            "Items" : items
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetList', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getParametersByName(parameterNames){
    try{
        let params = {
            "parameterNames":parameterNames,
        };

        let response = await genericGetWithParameters('GlobalSystemParameter/GetParametersByName', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}