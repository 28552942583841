import React from 'react';

import DealInformationForm from './DealInformationForm'
import IncomingPaymentForm from './IncomingPaymentForm'
import OutgoingFundsScreen from './../outgoingFunds/OutgoingFundsScreen'
import OtherSettlementFunds from './OtherSettlementFunds'

import {getDealHeaderInfo} from '../../networking/NetworkingDeals';
import {getParametersByName} from '../../networking/NetworkingGlobalSystemParameters';

import {formatToCurrency} from '../../helpers/FormatHelper';

import {getStyle} from '../../styles/styles'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
var style = getStyle();

export default class DealInformationScreen extends React.Component {
    
    state = {
        activeTab: "Deal Information",
        customerId: 0,
        defaultPaymentID: 0,
        dealHeaderId: 0,
        status: '',
        buyAmount: 0,
        buyCurrency: '',
        totalFees: 0,
        dealPaidStatus: '',
        amountToBeDisbursed: 0,
        sellAmount: 0,
        //
        loading: true,
        //incoming payments info
        activeCurrencies: [],
        incomingInstruments: [],
        dealHeaderInfo: {},
        listDealPayments: [],
        typeRecordsApply : 'Deal',
        //global parameters
        globalParameterNameCsv: 'VostroConversionDate,EnablePaymentConfirmationPrompt,EnableSavePendingPayments',
        globalParameters: [],
        lookUpTables: [],
        header: [],
        totalPaidByCustomer: 0,
    }
    
    componentDidMount() {
        this.getGlobalParameters();
        this.setState({
            dealHeaderId: this.props.data
        }, () => {
            this.loadDealInformation()
        });
    }
 
    loadDealInformation = () => {
        getDealHeaderInfo(this.state.dealHeaderId).then(
            (value) => {
                if (typeof value !== "undefined" && 
                    typeof value.dealHeaderInfo !== "undefined") {

                    var dealHeaderInfo = {};
                    if (value.dealHeaderInfo != null) {
                        dealHeaderInfo = value.dealHeaderInfo[0];
                        console.table(dealHeaderInfo);
                    }

                    var activeCurrencies = [];
                    if (value.activeCurrencies != null) {
                        activeCurrencies = value.activeCurrencies;
                    }

                    var incomingInstruments = [];
                    if (value.incomingInstruments != null) {
                        incomingInstruments = value.incomingInstruments;
                    }

                    let lookUpTables = [];
                    if (value.lookUpTables != null) {
                        lookUpTables = value.lookUpTables;
                    }
                    //alert(JSON.stringify(dealHeaderInfo));
                    this.setState({
                        customerId: dealHeaderInfo["Customer ID"],
                        status: dealHeaderInfo.Status,
                        dealHeaderInfo: dealHeaderInfo,
                        buyAmount: dealHeaderInfo.BuyAmount,
                        buyCurrency: dealHeaderInfo.BCur,
                        totalFees: dealHeaderInfo.TotalFees,
                        dealPaidStatus: dealHeaderInfo.DealPaidStatus,
                        amountToBeDisbursed: dealHeaderInfo.TotalDisbursed,
                        sellAmount: dealHeaderInfo.SellAmount,
                        activeCurrencies: activeCurrencies,
                        incomingInstruments: incomingInstruments,
                        lookUpTables: lookUpTables,
                        totalPaidByCustomer: dealHeaderInfo.TotalPaidByCustomer,
                        defaultPaymentID: dealHeaderInfo.DefaultPaymentID,
                    });
                }

                if (typeof value.dealPayments !== undefined 
                    && value.dealPayments != null) {
                    this.setState({
                        listDealPayments: value.dealPayments, loading: false
                    });
                } else {
                    this.setState({
                        listDealPayments: [], loading: false
                    });
                }
            }
        );
    }

    handleBackLink() {
        this.props.backLink();
    }

    //load data
    getGlobalParameters() {
        getParametersByName(this.state.globalParameterNameCsv).then(
            (response) => {
                if (typeof response.parameters !== "undefined") {
                    this.setState({
                        globalParameters: response.parameters
                    })
                }
            }
        )
    }

    onClickTab = (tabName) => {
        this.setState({activeTab: tabName});
    }

    render(){

        var title = '';
        if(this.state.activeTab === 'dealInfo'){
            title = '';
        } else if(this.state.activeTab === 'incomings'){
            title = 'Create Payment';
        } else if(this.state.activeTab === 'outgoings'){
            title = 'Create / Update Outgoing Fund'
        }

        if (this.state.loading) {
            return (<div></div>);
        }

        var classNameDealInfo = '', classNameIncoming = '', 
            classNameOutgoing = '', classNameOtherSettlements = '';

        var currentScreen;
        switch(this.state.activeTab){
            case 'dealInfo':
            default:
                classNameDealInfo = 'uk-active';
                currentScreen = (
                    <DealInformationForm dealHeaderId={this.props.data} 
                        dealHeaderInfo={this.state.dealHeaderInfo} 
                        lookUpTables={this.state.lookUpTables}
                        dealPayments={this.state.listDealPayments} 
                        reloadDealHeaderInfo={this.loadDealInformation}/>
                );
                break;
            case 'incomings':
                classNameIncoming = 'uk-active';
                currentScreen = (
                    <IncomingPaymentForm dealHeaderID={this.props.data}
                        customerID={this.state.customerId} 
                        buyCurrency={this.state.buyCurrency} 
                        activeCurrencies={this.state.activeCurrencies}
                        incomingInstruments={this.state.incomingInstruments} 
                        globalParameters={this.state.globalParameters}
                        reloadDealHeaderInfo={this.loadDealInformation}
                        typeRecordsApply={this.state.typeRecordsApply}
                        data={this.state.defaultPaymentID}
                        paymentOpenFromBoard={false}/>
                );
                break;
            case 'outgoings':
                classNameOutgoing = 'uk-active';
                currentScreen = (<OutgoingFundsScreen dealID={this.props.data}/>);
                break;
            case 'otherSettlements':
                classNameOtherSettlements = 'uk-active';
                currentScreen = (
                    <OtherSettlementFunds customerID={this.state.customerId}
                        reloadDealHeaderInfo={this.loadDealInformation}/>
                );
                break;
        }

        return(
            <div>
                <div>

                <div className="uk-grid uk-margin">
                        <div className="uk-width-1-3">
                            <h3 className="uk-heading-divider" style={style.titleStyle} >
                                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                                </button>
                                Deal Information
                            </h3>
                        </div>
                        <div className="uk-width-1-3 uk-heading-divider" style={{textAlign: "right", marginTop: 10}}>
                            <label className="uk-form-label" style={{fontSize: '1.75em'}}>Deal number #: {this.state.dealHeaderId}</label>
                        </div>
                        <div className="uk-width-1-3 uk-heading-divider" style={{marginTop: 10}}>                
                            <Table border="1" bordercolor="#E5DBD8">
                                <tr >
                                    <th>Deal Status</th>
                                    <th>Total Due</th>
                                    <th>Settlement Status</th>
                                    <th>Pending Disbursements</th> 
                                </tr>                        
                                <tr>
                                    <td align="middle">{this.state.status}</td>
                                    <td align="middle">{formatToCurrency(this.state.buyAmount + this.state.totalFees - this.state.totalPaidByCustomer)}</td>
                                    <td align="middle">{this.state.dealPaidStatus}</td>
                                    <td align="middle">{formatToCurrency(this.state.sellAmount - this.state.amountToBeDisbursed)}</td>
                                </tr>
                            </Table>
                        </div>
                    </div>    

                    
                    {/*<div className="uk-grid uk-margin"> 
                        <div className="uk-width-1-3">
                            <h3 style={style.titleStyle}>
                                {title}
                            </h3>  
                        </div>   
                        <div className="uk-width-1-3">
                            <h3 className="uk-heading-divider" style={{visibility:"hidden"}}>
                            </h3>  
                        </div>   
                        <div className="uk-width-1-3">                
                            <Table border="1" bordercolor="#E5DBD8">
                                <tr >
                                    <th>Deal Status</th>
                                    <th>Total Due</th>
                                    <th>Settlement Status</th>
                                    <th>Pending Disbursements</th> 
                                </tr>                        
                                <tr>
                                    <td align="middle">{this.state.status}</td>
                                    <td align="middle">{formatToCurrency(this.state.buyAmount + this.state.totalFees - this.state.totalPaidByCustomer)}</td>
                                    <td align="middle">{this.state.dealPaidStatus}</td>
                                    <td align="middle">{formatToCurrency(this.state.sellAmount - this.state.amountToBeDisbursed)}</td>
                                </tr>
                            </Table>
                        </div>  
                    </div>*/}

                    <ul className="uk-tab">
                        <li className={classNameDealInfo}><a onClick={ () => this.onClickTab('dealInfo')}>Deal Information</a></li>
                        <li className={classNameIncoming}><a onClick={ () => this.onClickTab('incomings')}>Incoming Payments</a></li>
                        <li className={classNameOutgoing}><a onClick={ () => this.onClickTab('outgoings')}>Outgoing Funds</a></li>
                        <li className={classNameOtherSettlements}><a onClick={ () => this.onClickTab('otherSettlements')}>Other Settlement Funds</a></li>
                    </ul>
                    {currentScreen}
                </div>
            </div>
        );
    }
}