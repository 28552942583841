import React from 'react';

import { getStyle, notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import NumberInput from '../../../shared/NumberInput';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTable, getLookUpTableByFilter } from '../../../../networking/Networking';
import { getRiskScoreRanges } from '../../../../networking/NetworkingCustomers';
import { getRiskScoreBeneDetails, getBeneficiaryRiskScore } from '../../../../networking/NetworkingBeneficiaries';
import DatePicker from '../../../shared/DatePicker';
import { formatDate, formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();

export default class BeneficiaryInformationForm extends React.Component {

    state = {
        beneficiaries: {
            notFirstLoad: false,
            customerName: '',
            salutation: '',
            firstName: '',
            lastName: '',
            middleName: '',
            shortName: '',
            customerType: '',
            status: '',
            companyName: '',
            preferredPaymentIns: '',
            preferredDeliveryMet: '',
            contactPhone: '',
            contactEmail: '',
            contactName: '',
            customerIndustrySect: '',
            industrySector: '',
            detailsPayments: '',
            paymentReference: '',
            limit: '',
            paymentFlow: '',
            bypassValidation: '',
            expiryByPassDate: '',
            beneficiaryRiskScore: '',
            emailIndicator: '',
            field4: '',
            beneficiaryID: '',
            updateToken: 0,
            isEmailValid: '',
            errors: []
        },
        validations: {
            customerName: true,
            companyName: true,
            shortName: true,
            firstName: true,
            lastName: true,
            customerType: true,
            status: true,
            preferredPaymentIns: true,
            preferredDeliveryMet: true,
            emailIndicator: true
        },
        isNew: false,
        showRiskCalculation: false,
        riskScoreDetails: [],
        dropdowns: {
            traders: [],
            "Beneficiary Status": [],
            "Salutation": [],
            "Beneficiary Type": [],
            "Industry Sectors": [],
            "Outgoing Instruments": [],
            "Beneficiary Payment Flow": [],
            "Beneficiary Email Indicator": [],
            "Payment Delivery Method": []
        },
        lookUpTables: [
            "Beneficiary Status",
            "Salutation",
            "Beneficiary Type",
            "Industry Sectors",
            "Outgoing Instruments",
            "Beneficiary Payment Flow",
            "Beneficiary Email Indicator"
        ],
        lookUpTablesByFilter: [
            "Payment Delivery Method"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        }
    }

    componentDidMount() {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, customerName: this.props.customerName, beneficiaryID: this.props.beneID } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        if (this.props.newOrUpdate === "visible") {
            this.setState({ isNew: true })
        }
        getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({ dropdowns: { ...this.state.dropdowns, traders: json.traders } });
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                    }
                }
                this.updateInputs();
            });

        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreBeneDetails(this.props.beneID).then(
                    (details) => {
                        if (details !== undefined) {
                            getBeneficiaryRiskScore(this.props.beneID).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Beneficiary");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        riskScore.Color = riskScore.Color.substring(0, 1) + riskScore.Color.substring(3);
                                        this.setState({
                                            riskScore: riskScore,
                                            riskScoreDetails: details.riskScoreDetails
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
    }

    updateInputs() {
        if (Object.entries(this.props.selectedBene).length !== 0) {
            var beneficiaries = this.props.selectedBene;
            var field, drpdnList, beneLimit, cindustrySect = '';
            console.log(beneficiaries);
            drpdnList = this.state.dropdowns['Outgoing Instruments'];
            for (var i = 0; i < drpdnList.length; i++) {
                if (drpdnList[i]['value'] == beneficiaries.PreferredMethodOfPayment) {
                    field = drpdnList[i]['Field4'];
                    this.setState({ field4: field });
                    this.getPaymentMethod(field);
                }
            }
            if (beneficiaries.CustomerIndustrySector !== '') { cindustrySect = beneficiaries.CustomerIndustrySector } else { cindustrySect = 'No select' }
            this.setState({
                beneficiaries: {
                    ...this.state.beneficiaries,
                    salutation: beneficiaries.Salutation,
                    firstName: beneficiaries.FirstName,
                    lastName: beneficiaries.LastName,
                    customerType: beneficiaries.BeneficiaryType,
                    shortName: beneficiaries.ShortName,
                    status: beneficiaries.Status,
                    companyName: beneficiaries.Name,
                    preferredPaymentIns: beneficiaries.PreferredMethodOfPayment,
                    preferredDeliveryMet: beneficiaries.PreferredDeliveryMethod,
                    contactPhone: beneficiaries.ContactPhone,
                    contactEmail: beneficiaries.ContactEmail,
                    contactName: beneficiaries.ContactName,
                    customerIndustrySect: cindustrySect,
                    industrySector: beneficiaries.IndustrySector,
                    detailsPayments: beneficiaries.DetailsOfPayment,
                    paymentReference: beneficiaries.CustomerRelationRisk,
                    limit: beneficiaries.ReceiveLimit,
                    paymentFlow: beneficiaries.PaymentFlow,
                    bypassValidation: beneficiaries.BypassValidation,
                    expiryByPassDate: beneficiaries.ExpiryByPassDate,
                    beneficiaryRiskScore: beneficiaries.BeneficiaryRiskScore,
                    emailIndicator: beneficiaries.EmailIndicator,
                    updateToken: beneficiaries.UpdateToken
                },
                validations: {
                    customerName: true,
                    companyName: true,
                    shortName: true,
                    firstName: true,
                    lastName: true,
                    customerType: true,
                    status: true,
                    preferredPaymentIns: true,
                    preferredDeliveryMet: true,
                    emailIndicator: true
                }
            }, () => {
                this.props.objFilterBeneInfo(this.state.beneficiaries)
            });
            if (this.validateEmail()) {
                this.isEmailValid(true)
            } else {
                this.isEmailValid(false)
            }
            this.props.isValid(this.validateRequiredInputs());
        } else {
            this.props.isValid(this.validateRequiredInputs());
        }
    }

    getPaymentMethod(field) {
        getLookUpTableByFilter(this.state.lookUpTablesByFilter.toString(), field).then(
            (json) => {
                console.log(json);
                var listLook = this.state.lookUpTablesByFilter;
                for (var i = 0; i < listLook.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(listLook[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: { ...this.state.dropdowns, [listLook[i]]: table } });
                    }
                }
            }
        );
    }

    toggleShowRiskCalculation() {
        this.setState({ showRiskCalculation: !this.state.showRiskCalculation });
    }

    handleUpdateCustomerType(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, customerType: value.value },
                    validations: { ...this.state.validations, customerType: true },
                }, () => {
                    if (this.validateCustomerType()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, customerType: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateCustomerType() {
        if (this.state.beneficiaries.customerType === "select" || this.state.beneficiaries.customerType === '' || this.state.beneficiaries.customerType === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateStatus(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, status: value.value },
                    validations: { ...this.state.validations, status: true }
                }, () => {
                    if (this.validateStatus()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, status: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateStatus() {
        if (this.state.beneficiaries.customerType === "select" || this.state.beneficiaries.customerType === '' || this.state.beneficiaries.customerType === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateCompanyName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, companyName: event.target.value },
            validations: { ...this.state.validations, companyName: true }
        },
            () => {
                if (this.validateCompanyName()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
            });
    }
    validateCompanyName() {
        if (this.state.beneficiaries.companyName === '') {
            return false;
        }
        return true;
    }
    handleUpdateShortName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, shortName: event.target.value },
            validations: { ...this.state.validations, shortName: true }
        }, () => {
            if (this.validateRequiredInputs()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateShortName() {
        if (this.state.beneficiaries.shortName === '') {
            return false;
        }
        return true;
    }
    handleUpdatePreferredPaymentIns(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: {
                        ...this.state.beneficiaries, field4: value.Field4,
                        preferredPaymentIns: value.value
                    },
                    validations: { ...this.state.validations, preferredPaymentIns: true }
                }, () => {
                    if (this.validatePreferredPaymentIns()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
                this.getPaymentMethod(value.Field4);
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, preferredPaymentIns: '' }
            },
                () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validatePreferredPaymentIns() {
        if (this.state.beneficiaries.preferredPaymentIns === "select" || this.state.beneficiaries.preferredPaymentIns === '' || this.state.beneficiaries.preferredPaymentIns === undefined) {
            return false;
        }
        return true;
    }
    handleUpdatePreferredDeliveryMet(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, preferredDeliveryMet: value.value },
                    validations: { ...this.state.validations, preferredDeliveryMet: true }
                }, () => {
                    if (this.validatePreferredDeliveryMet()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, preferredDeliveryMet: '' }
            }, () => { this.props.objFilterBeneInfo(this.state.beneficiaries) });
        }
    }
    validatePreferredDeliveryMet() {
        if (this.state.beneficiaries.preferredDeliveryMet === "select" || this.state.beneficiaries.preferredDeliveryMet === '' || this.state.beneficiaries.preferredDeliveryMet === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateContactPhone(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactPhone: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateContactEmail(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactEmail: event.target.value } },
            () => {
                if (this.validateEmail()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(true);
                } else {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(false);
                }
            });
    }
    isEmailValid(validator) {
        if (validator) {
            this.setState({ beneficiaries: { ...this.state.beneficiaries, isEmailValid: true } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({ beneficiaries: { ...this.state.beneficiaries, isEmailValid: false } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.beneficiaries.contactEmail.match(regex)) {
            return false;
        }
        return true;
    }
    handleUpdateContactName(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, contactName: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateCustomerIndustrySect(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, customerIndustrySect: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateIndustrySector(value) {
        if (value !== undefined) {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, industrySector: value.value }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, industrySector: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }

    }
    handleUpdateDetailsPayments(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, detailsPayments: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdatePaymentReference(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, paymentReference: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateLimit(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, limit: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdatePaymentFlow(value) {
        if (value !== undefined) {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, paymentFlow: value.value }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, paymentFlow: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    handleOnCheck(event) {
        console.log(event.target.checked);
        this.setState({ beneficiaries: { ...this.state.beneficiaries, bypassValidation: event.target.checked } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateBeneficiaryRiskScore(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, beneficiaryRiskScore: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateEmailIndicator(value) {
        if (value !== undefined) {
            if (value.value !== undefined) {
                this.setState({
                    beneficiaries: { ...this.state.beneficiaries, emailIndicator: value.value },
                    validations: { ...this.state.validations, emailIndicator: true }
                }, () => {
                    if (this.validateEmailIndicator()) {
                        this.props.objFilterBeneInfo(this.state.beneficiaries);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                });
            }
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, emailIndicator: '' }
            }, () => { this.props.objFilterBeneInfo(this.state.beneficiaries) });
        }
    }
    validateEmailIndicator() {
        if (this.state.beneficiaries.emailIndicator === "select" || this.state.beneficiaries.emailIndicator === '' || this.state.beneficiaries.emailIndicator === undefined) {
            return false;
        }
        return true;
    }
    handleUpdateExpiryByPassDate(date) {
        if (date !== undefined) {
            var dateEx = formatDate(date)
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, expiryByPassDate: dateEx }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, expiryByPassDate: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    handleUpdateFirstName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, firstName: event.target.value },
            validations: { ...this.state.validations, firstName: true }
        }, () => {
            if (this.validateFirstName()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateFirstName() {
        if (this.state.beneficiaries.firstName === '') {
            return false;
        }
        return true;
    }
    handleUpdateLastName(event) {
        this.setState({
            beneficiaries: { ...this.state.beneficiaries, lastName: event.target.value },
            validations: { ...this.state.validations, lastName: true }
        }, () => {
            if (this.validateLastName()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries);
                this.props.isValid(this.validateRequiredInputs());
            } else {
                this.props.isValid({ valid: false, errors: [] });
            }
        });
    }
    validateLastName() {
        if (this.state.beneficiaries.lastName === '') {
            return false;
        }
        return true;
    }
    handleUpdateMiddleName(event) {
        this.setState({ beneficiaries: { ...this.state.beneficiaries, middleName: event.target.value } }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateSalutation(value) {
        if (value !== undefined) {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, salutation: value.value }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        } else {
            this.setState({
                beneficiaries: { ...this.state.beneficiaries, salutation: '' }
            }, () => this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    validateRequiredFields() {
        const fields = [
            { displayName: 'Customer Name', validateName: 'customerName', value: this.state.beneficiaries.customerName, type: 'string' },
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.beneficiaries.shortName, type: 'string' },

            { displayName: 'Beneficiary Type', validateName: 'customerType', value: this.state.beneficiaries.customerType, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.beneficiaries.status, type: 'string' },
            { displayName: 'Preferred Payment Instrument', validateName: 'preferredPaymentIns', value: this.state.beneficiaries.preferredPaymentIns, type: 'string' },
            { displayName: 'Preferred Delivery Method', validateName: 'preferredDeliveryMet', value: this.state.beneficiaries.preferredDeliveryMet, type: 'string' },
            { displayName: 'Email Indicator', validateName: 'emailIndicator', value: this.state.beneficiaries.emailIndicator, type: 'string' },
        ]

        if (this.state.beneficiaries.customerType.includes("Personal")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.beneficiaries.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.beneficiaries.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.beneficiaries.companyName, type: 'string' })
        }

        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'Customer Name', validateName: 'customerName', value: this.state.beneficiaries.customerName, type: 'string' },
            { displayName: 'Short Name', validateName: 'shortName', value: this.state.beneficiaries.shortName, type: 'string' },

            { displayName: 'Beneficiary Type', validateName: 'customerType', value: this.state.beneficiaries.customerType, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.beneficiaries.status, type: 'string' },
            { displayName: 'Preferred Payment Instrument', validateName: 'preferredPaymentIns', value: this.state.beneficiaries.preferredPaymentIns, type: 'string' },
            { displayName: 'Preferred Delivery Method', validateName: 'preferredDeliveryMet', value: this.state.beneficiaries.preferredDeliveryMet, type: 'string' },
            { displayName: 'Email Indicator', validateName: 'emailIndicator', value: this.state.beneficiaries.emailIndicator, type: 'string' },
        ]

        if (this.state.beneficiaries.customerType.includes("Personal")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.beneficiaries.firstName, type: 'string' },);
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.beneficiaries.lastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.beneficiaries.companyName, type: 'string' })
        }
        const [valid, validations, errors] = validate(fields);

        return { valid, errors };
    }
    formatVisibilityIndividual(type) {
        if (type != undefined) {
            return type.includes("Personal") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    formatVisibilityCorporate(type) {
        if (type != undefined) {
            return !type.includes("Personal") ? "visible" : "hidden";
        } else { return "hidden"; }
    }

    render() {
        var section = (
            <div className="uk-width-1-2">
                <label className="uk-form-label" htmlFor="form-beneficiary-customerName">Customer(*)</label>
                <div className="uk-form-controls">
                    <label id="customerName">{this.state.beneficiaries.customerName}</label>
                </div>
            </div>
        )

        var corporate1 = ('')
        var corporate2 = ('')
        var individual1 = ('')
        var individual2 = ('')
        var individual3 = ('')
        if (this.state.isNew) {
            section = ('')
        }
        if (this.formatVisibilityIndividual(this.state.beneficiaries.customerType) !== 'hidden') {
            individual1 = (
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-salutation">Salutation</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className='uk-select' objValue={{ value: this.state.beneficiaries.salutation }} getValue={this.handleUpdateSalutation.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Salutation"]} id="form-beneficiary-salutation" />
                    </div>
                </div>
            )
            individual2 = (
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-firstName" style={style.boldLabel}>First Name (*)</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className={formatValidInput(this.state.validations.firstName)} id="firstName" type="text" value={this.state.beneficiaries.firstName} onChange={this.handleUpdateFirstName.bind(this)} />
                    </div>
                </div>
            )
            individual3 = (
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-lastName" style={style.boldLabel}>Last Name (*)</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className={formatValidInput(this.state.validations.lastName)} id="lastName" type="text" value={this.state.beneficiaries.lastName} onChange={this.handleUpdateLastName.bind(this)} />
                    </div>
                </div>
            )
        }
        if (this.formatVisibilityCorporate(this.state.beneficiaries.customerType) !== 'hidden') {
            corporate1 = (
                <div className="uk-width-1-2">
                    <label className="uk-form-label" style={style.boldLabel}>Company Name (*)</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className={formatValidInput(this.state.validations.companyName)} id="companyName" type="text" value={this.state.beneficiaries.companyName} onChange={this.handleUpdateCompanyName.bind(this)} />
                    </div>
                </div>
            )
            corporate2 = (
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-contactName">Contact Name</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className='uk-input' id="contactName" type="text" value={this.state.beneficiaries.contactName} onChange={this.handleUpdateContactName.bind(this)} />
                    </div>
                </div>
            )
        }
        return (
            <form className="uk-form-stacked uk-grid">
                {section}
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-status" style={style.boldLabel}>Status (*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.status)} objValue={{ value: this.state.beneficiaries.status }} getValue={this.handleUpdateStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Status"]} id="form-beneficiary-status" />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-customerType" style={style.boldLabel}>Type (*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.customerType)} objValue={{ value: this.state.beneficiaries.customerType }} getValue={this.handleUpdateCustomerType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Type"]} id="form-beneficiary-type" />
                    </div>
                </div>
                {/*TYPE ==> PERSONAL / INDIVIDUAL */}
                {individual1}
                {individual2}
                {individual3}
                <div className="uk-width-1">
                </div>
                {/*TYPE ==> CORPORATE */}
                {corporate1}
                {/*---TAMBIEN VA EN PERSONAL/INDIVIDUAL ESTE INPUT DE SHORT NAME-- */}
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-shortName" style={style.boldLabel}>Short Name (*)</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className={formatValidInput(this.state.validations.shortName)} id="shortName" type="text" value={this.state.beneficiaries.shortName} onChange={this.handleUpdateShortName.bind(this)} />
                    </div>
                </div>
                {/*----- */}
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-preferredPaymentIns" style={style.boldLabel}>Preferred Payment Instrument (*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.preferredPaymentIns)} objValue={{ value: this.state.beneficiaries.preferredPaymentIns }} getValue={this.handleUpdatePreferredPaymentIns.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Outgoing Instruments"]} id="form-beneficiary-preferredPaymentIns" />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-preferredDeliveryMet" style={style.boldLabel}>Preferred Delivery Method (*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.preferredDeliveryMet)} objValue={{ value: this.state.beneficiaries.preferredDeliveryMet }} getValue={this.handleUpdatePreferredDeliveryMet.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Payment Delivery Method"]} id="form-beneficiary-preferredDeliveryMet" />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-contactPhone">Contact Phone</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className='uk-input' id="contactPhone" type="text" value={this.state.beneficiaries.contactPhone} onChange={this.handleUpdateContactPhone.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-contactEmail">Contact Email</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className='uk-input' id="contactEmail" type="text" value={this.state.beneficiaries.contactEmail} onChange={this.handleUpdateContactEmail.bind(this)} />
                    </div>
                </div>
                {corporate2}
                <div className="uk-width-1-2" style={{ visibility: 'hidden' }}>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-customerIndustrySect">Customer Industry Sector</label>
                    <div className="uk-form-controls">
                        {/*<input className={classNameInputCustomerIndustrySect} id="customerIndustrySect" type="text" value={this.state.beneficiaries.customerIndustrySect} onChange={this.handleUpdateCustomerIndustrySect.bind(this)}/>
                    */}<label id="customerIndustrySect">{this.state.beneficiaries.customerIndustrySect}</label>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-industrySector">Industry Sector</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className='uk-select' objValue={{ value: this.state.beneficiaries.industrySector }} getValue={this.handleUpdateIndustrySector.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Industry Sectors"]} id="form-beneficiary-industrySector" />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-detailsPayments">Details of Payment</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className='uk-input' id="detailsPayments" type="text" value={this.state.beneficiaries.detailsPayments} onChange={this.handleUpdateDetailsPayments.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-paymentReference">Payment Reference</label>
                    <div className="uk-form-controls">
                        <input disabled={true} className='uk-input' id="paymentReference" type="text" value={this.state.beneficiaries.paymentReference} onChange={this.handleUpdatePaymentReference.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-limit">Limit</label>
                    <div className="uk-form-controls">
                        <NumberInput disabled={true} className="uk-input" placeholder="0" id="limit" type="Currency" value={this.state.beneficiaries.limit} onChange={this.handleUpdateLimit.bind(this)} />
                        {/*<input className={classNameInputLimit} id="limit" type="text" value={this.state.beneficiaries.limit} onChange={this.handleUpdateLimit.bind(this)}/>*/}
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-paymentFlow">Payment Flow</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className='uk-select' objValue={{ value: this.state.beneficiaries.paymentFlow }} getValue={this.handleUpdatePaymentFlow.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Payment Flow"]} id="form-beneficiary-paymentFlow" />
                    </div>
                </div>
                <div className="uk-width-1-2 uk-margin">
                    <label className="uk-form-label" htmlFor="form-beneficiary-bypassValidation"><input disabled={true} className="uk-checkbox" id="bypassValidation" type="checkbox" checked={this.state.beneficiaries.bypassValidation} onChange={this.handleOnCheck.bind(this)} /> Bypass Validation(*)</label>
                    <div className="uk-form-controls">

                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-expiryByPassDate">Expiry bypass date</label>
                    <div className="uk-width-1-2">{/*after="2025/01/10" before="2025/01/30" */}
                        <DatePicker disabled={true} className='uk-input' value={this.state.beneficiaries.expiryByPassDate} onDayChange={this.handleUpdateExpiryByPassDate.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" style={style.boldLabel}>Beneficiary Risk Score(*)</label>
                    <div className="uk-form-controls" style={style.inputStyle}>
                        <label disabled={true} onClick={this.toggleShowRiskCalculation.bind(this)} className="" style={{ color: this.state.riskScore.Color }} id="beneficiaryRiskScore">{this.state.riskScore.Description}</label>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-emailIndicator" style={style.boldLabel}>Email Indicator(*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.emailIndicator)} objValue={{ value: this.state.beneficiaries.emailIndicator }} getValue={this.handleUpdateEmailIndicator.bind(this)} listHasPlaceholder={false} placeholder={"Select an email..."} data={this.state.dropdowns["Beneficiary Email Indicator"]} id="form-beneficiary-emailIndicator" />
                    </div>
                </div>
                <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails} open={this.state.showRiskCalculation} close={this.toggleShowRiskCalculation.bind(this)} />
            </form>
        );
    }
}