import React from 'react';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import DynamicSelect from '../../../shared/DynamicSelect';
import {regularSelectClass} from '../../../../styles/styles';
import NumberInput from '../../../shared/NumberInput';
import AlertBox from '../../../shared/AlertBox';

import {formatDateToDDMMYYYY, getHoursDate, formatValidInput} from '../../../../helpers/FormatHelper';

import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {getCommentById, saveComment} from '../../../../networking/NetworkingComments';
import {getSearchByEntity, GetResultsByWatchListsSearchId} from '../../../../networking/NetworkingGlobalWatchListSearch';
import {getComplianceInfo} from '../../../../networking/NetworkingDeals';
import {getCustomerByID, updateCustomer} from '../../../../networking/NetworkingCustomers'
import { validate } from '../../../../helpers/ValidateHelper';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  

export default class ComplianceScreen extends React.Component {

    constructor(props)
    {
        super(props);
        var customer = this.props.customer;
        console.log("customer obj: "+JSON.stringify(customer))

        var dealStatus = ""
        if(customer.DealStatus !== null || customer.DealStatus !== '')
        {
            dealStatus = customer.DealStatus
        }

        this.state = { 
            customerObj             : customer,
            globalListWatch         : [],
            dealStatusSelected      : dealStatus,
            pastDealsSearchByRiskScore  : [],
            showModal               : false,
            globalListWatchResults  : [],
            //modal size
            fullWidth               : true,
            maxWidth                : 'md',
            commentList             : [],
            //Comment
            commentTextArea         : '',
            //Get Past Deals
            daysBack                : '',
            fromRiskScore           : '', 
            toRiskScore             : '', 
            //Alert
            showAlert               : false, 
            alertMessage            : '', 
            alertTitle              : '',
            //ValidationsComments
            validationsComments: {
                comments: true
            },
            validationsPastDealsSearch: {
                daysBack: true,
                fromRiskScore: true,
                toRiskScore: true
            },
            errors: [],
            pages: 1,
            totalPages: 0
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount()
    {
        getCommentById(this.state.customerObj.PersonID, 'Compliance').then(
            (value) => {
                console.log("getCommentById: "+ JSON.stringify(value))
                if(value.comments !== undefined && value.comments !== [])
                {
                    this.setState({
                        commentList: value.comments
                    });
                }
            }
        );

        getSearchByEntity('Customer', this.state.customerObj.CustomerID).then(
            (value) => {
                if (value !== undefined && value.globalListWatch !== undefined) {
                    console.log("getSearchByEntity: "+ JSON.stringify(value))
                    const totalCount = value.globalWatchList.length;
                    const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({
                        globalListWatch: value.globalWatchList,
                        totalPages
                    });
                }
            }
        );
    }

    handleClick()
    {
        let userName = sessionStorage.getItem('UserName');
        if(this.validateComments())
        {
            var model = {
                PersonId    : this.state.customerObj.PersonID,
                Content     : this.state.commentTextArea,
                User        : userName,
                CommentType : "Compliance"
            }

            saveComment(model).then(
                (jsonComment) =>{
                    //console.log("saveComment: " + JSON.stringify(jsonComment));
                    if(jsonComment.Comment !== undefined && jsonComment.Comment.length === 1)
                    {
                        var actualList = this.state.commentList
                        actualList.unshift(jsonComment.Comment[0]);
                        this.setState({
                            commentList: actualList
                        });
                    }else
                    {
                        var errorText = "";
                        if(jsonComment.Message && jsonComment.Message !== "")
                        {
                            errorText = jsonComment.Message;
                        }

                        if(jsonComment.ErrorMessage && jsonComment.ErrorMessage !== "")
                        {
                            errorText  =jsonComment.ErrorMessage;
                        }

                        if(errorText === "")
                        {
                            errorText = "Something went wrong. Please try again later."
                        }

                        this.setState({
                            showAlert: true, 
                            alertTitle:'Error', 
                            alertMessage: errorText,
                        });
                    }
                }
            );
        }
    }

    validateComments = () => 
    {
        const fields = [
            {displayName: 'Comment', validateName: 'comments', value: this.state.commentTextArea, type: 'string'}
        ];

        const [valid, validations, errors] = validate(fields);

        this.setState({
            validationsComments: validations
        });
        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    validatePastDealsSearch = () => 
    {
        const fields = [
            {displayName: 'Days back', validateName: 'daysBack', value: this.state.daysBack},
            {displayName: 'From Risk Score', validateName: 'fromRiskScore', value: this.state.fromRiskScore, type: 'number'},
            {displayName: 'To Risk Score', validateName: 'toRiskScore', value: this.state.fromRiskScore, type: 'number'},
        ];

        const [valid, validations, errors] = validate(fields);

        this.setState({
            validationsPastDealsSearch: validations
        });

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }


    handleClickSearch = () =>
    {
        if (this.validatePastDealsSearch()) {
            console.log("getComplianceInfo: "+ JSON.stringify(this.state.daysBack))
            getComplianceInfo(
                parseInt(this.state.daysBack),
                parseInt(this.state.fromRiskScore), 
                parseInt(this.state.toRiskScore), 
                this.state.customerObj.CustomerID).then(
                (value) => {
                    console.log("getComplianceInfo: "+ JSON.stringify(value))
                    if(value.deals)
                    {
                        this.setState({
                            pastDealsSearchByRiskScore: value.deals
                        });
                    }
                }
            );
        }
    }

    onchangeValueOfDealStatus = (obj) => 
    {
        if(obj !== null)
        {
            if(obj.value !== undefined)
            {
                this.setState({
                    dealStatusSelected: obj.value
                });            
            }
        }
        
    }

    handleRowClick = (obj) =>{
        console.log("Obj clicked: " + JSON.stringify(obj));

        if(obj.Results >0)
        {
            GetResultsByWatchListsSearchId(obj['WatchListsSearch Id']).then(
                (value) => {
                    console.log("GetResultsByWatchListsSearchId: "+ JSON.stringify(value))
    
                    if(value.globalWatchListsSearchResults !== undefined && value.globalWatchListsSearchResults !== [])
                    {
                        this.setState({
                            globalListWatchResults: value.globalWatchListsSearchResults
                        });
                    }
                }
            );
    
            this.setState({
                showModal: true
            });
        }
    }

    changePage = (value) => 
    {
        this.setState({pages: value});
    }

    getGlobalWatchList = async () => 
    {
        const value = await getSearchByEntity('Customer', this.state.customerObj.CustomerID);
        return value.globalWatchList;
    }

    closeModal = () =>
    {
        this.setState({
            showModal: false,
            globalListWatchResults:[]
        })
    }

    updateCommentText = (event) =>
    {
        this.setState({
            validationsComments: {...this.state.validationsComments, comments: true},
            commentTextArea: event.target.value
        });
    }

    handleUpdateDaysBack = (event) =>
    {
        this.setState({
            validationsPastDealsSearch: {...this.state.validationsPastDealsSearch, daysBack: true},
            daysBack: event.target.value
        });
    }

    handleUpdateFromRiskScore = (event) =>
    {
        this.setState({
            validationsPastDealsSearch: {...this.state.validationsPastDealsSearch, fromRiskScore: true},
            fromRiskScore: event.target.value
        });
    }

    handleUpdateToRiskScore = (event) =>
    {
        this.setState({
            validationsPastDealsSearch: {...this.state.validationsPastDealsSearch, toRiskScore: true},
            toRiskScore: event.target.value
        });
    }

    saveDealStatus = (event) => 
    {
        getCustomerByID(this.state.customerObj.CustomerID).then(
            (jsonResponse) =>
            {
                console.log("getCustomerByID: " + JSON.stringify(jsonResponse));

                if(jsonResponse.CustomerID)
                {
                    var newCustomerObj = jsonResponse
                    console.log("BEFORE newCustomerObj.DealStatus: " + JSON.stringify(newCustomerObj.DealStatus));
                    newCustomerObj.DealStatus = this.state.dealStatusSelected
                    console.log("AFTER newCustomerObj.DealStatus: " + JSON.stringify(newCustomerObj.DealStatus));

                    updateCustomer(newCustomerObj).then(
                        (jsonResponseUpdateCustomer)=>{

                            if(jsonResponseUpdateCustomer.updateToken)
                            {
                                if(jsonResponseUpdateCustomer.updateToken !== null || jsonResponseUpdateCustomer.updateToken !== "")
                                {
                                    this.setState({
                                        showAlert: true, 
                                        alertTitle:'Saved', 
                                        alertMessage: 'Customer successfully saved.',
                                    });
                                }
                            }
                        }
                    );
                }
            }
        );

    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    render() 
    {
        var dataTable = this.state.commentList
        return(
            <div>
                <ul id="accordion-compliance" uk-accordion="multiple: true" >
                    {/*Compliance Comments*/}
                    <li className="uk-open">
                        <button className="uk-accordion-title">Compliance Comments</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-bank-status">Note</label>
                                <p style={{marginTop: 0}}>To add a new comment, type in the box below and press Add when done.</p>
                                <div className="uk-flex" style={{paddingBottom: 15}}>
                                    <div className="uk-width-2-3">
                                        <textarea disabled={true} 
                                            className={`uk-width-1-1 ${formatValidInput(this.state.validationsComments.comments)}`}
                                            style={{fontSize:16, height: 70}}
                                            value={this.state.commentTextArea}
                                            onChange={this.updateCommentText}
                                        >{this.state.commentTextArea}</textarea>
                                    </div>
                                    <div 
                                        className="uk-width-1-3"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <button disabled={true} className="uk-button uk-button-green"onClick={this.handleClick}>Add</button>
                                    </div>
                                </div>
                                <div
                                    className="uk-width-1-1"
                                    style={{
                                        //backgroundColor: 'yellow',
                                        //paddingTop: 15,
                                        overflowY: 'scroll',
                                        height: 150,
                                        //width: '33%',
                                        border:'1px solid #9DA99E',
                                    }}
                                >
                                    {this.state.commentList.length > 0 && <table 
                                        className="uk-table uk-table-hover uk-table-divider"
                                        style={{
                                            marginBottom: 0
                                        }}
                                    >
                                        <tbody>
                                            {dataTable.map((item, i) => {
                                                return (
                                                <tr 
                                                    key={item.CommentId} 
                                                    //onClick={this.currencyChangeColorLeft(item.id)}
                                                    //onDoubleClick={this.currencyAddItemSelected.bind(this)}
                                                    style={{
                                                        backgroundColor: 'white', 
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            //color: 'white', 
                                                            cursor: 'pointer'
                                                        }}
                                                    >{item.Content + ' - ' + formatDateToDDMMYYYY(item['Comment Date']) + ' ' +  getHoursDate(item['Comment Date']) + ' (' + item['User'] + ')'}</td>
                                                </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>}
                                </div>
                            </div>
                        </div>
                    </li>
                    {/*Global Watch List Audit Trail*/}
                    <li>
                        <button className="uk-accordion-title">Global Watch List Audit Trail</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                                <NewDynamicTable
                                    id="gwlat-table"
                                    data={this.state.globalListWatch} 
                                    hiddenHeaders={['WatchListsSearch Id']} 
                                    enableClick={true} 
                                    clickFunction={this.handleRowClick}
                                    usePaginator={true}
                                    changePage={this.changePage}
                                    numberOfPages={this.state.totalPages}
                                    pageNumber={this.state.pages}
                                    numberPerPage={getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}
                                    useFilter={true} 
                                    filterFunction={this.getGlobalWatchList}
                                />
                            </div>
                        </div>
                    </li>
                    {/*Deal Status Override*/}
                    <li>
                        <button className="uk-accordion-title">Deal Status Override</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div className="uk-margin">
                                <p>If you wish, you can override the status in which a deal is saved in. Even if the deal would normally be accepted (i.e. It is not deemed/scored as high risk), you can force the deal to be saved in 'Pending' status by selecting that status from the list.</p>
                                
                                <div className="uk-flex" style={{paddingBottom: 15}}>
                                    <div className="uk-width-1-4" 
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <label>Deal Status</label>
                                    </div>
                                    <div 
                                        className="uk-width-1-4"
                                        /*style={{
                                            backgroundColor: 'red',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}*/
                                    >
                                        
                                            <DynamicSelect 
                                                disabled        = {true} 
                                                className       = {regularSelectClass} 
                                                objValue        = {{value: this.state.dealStatusSelected}}
                                                getValue        = {this.onchangeValueOfDealStatus} 
                                                listHasPlaceholder  = {false}
                                                placeholder     = 'Select a Status...'
                                                data            = {[
                                                    //{name:  "Select a Status...",   value:  "Any Status"    },
                                                    {name:  "Any Status",   value:  "Any Status"    },
                                                    {name:  "Accepted"  ,   value:  "Accepted"      },
                                                    {name:  "Pending"   ,   value:  "Pending"       }
                                                ]}
                                                id              = 'deal-status-select'
                                            />
                                        
                                    </div>
                                    <div className="uk-width-1-4" 
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <button disabled={true} 
                                            className="uk-button uk-button-green" 
                                            onClick={this.saveDealStatus}
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/*Past Deals Search By Risk Score*/}
                    <li>
                        <button className="uk-accordion-title">Past Deals Search By Risk Score</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <p>You can search past deals using the parameters below.</p>
                            <div className="uk-flex" style={{paddingBottom: 15}}>
                                <div className="uk-width-1-4" style={{paddingRight: 15}}>
                                    <label className="uk-form-label">Days back</label>
                                    <NumberInput disabled={true} value={this.state.daysBack} onChange={this.handleUpdateDaysBack} className={`${formatValidInput(this.state.validationsPastDealsSearch.daysBack)}`} type="Integer" placeholder="" />
                                </div>
                                <div className="uk-width-1-4" style={{paddingRight: 15}}>
                                    <label className="uk-form-label">From Risk Score</label>
                                    <NumberInput disabled={true} value={this.state.fromRiskScore} onChange={this.handleUpdateFromRiskScore} className={`${formatValidInput(this.state.validationsPastDealsSearch.fromRiskScore)}`} type="Integer" placeholder="" />
                                </div>
                                <div className="uk-width-1-4" style={{paddingRight: 15}}>
                                    <label className="uk-form-label">To Risk Score</label>
                                    <NumberInput disabled={true} value={this.state.toRiskScore} onChange={this.handleUpdateToRiskScore} className={`${formatValidInput(this.state.validationsPastDealsSearch.toRiskScore)}`} type="Integer" placeholder="" />
                                </div>
                                <div 
                                    className="uk-width-1-4"
                                    style={{
                                        display         : 'flex',
                                        justifyContent  : 'center',
                                        alignItems      : 'flex-end'                                    
                                    }}
                                >
                                    <button disabled={true} className="uk-button uk-button-green"onClick={this.handleClickSearch}>Search</button>
                                </div>
                            </div>
                            <div style={{backgroundColor: 'white'}}>
                                <NewDynamicTable
                                    id="past-deals-search-by-risk-score-table"
                                    //hiddenHeaders={['Bank ID','CustomerID']} 
                                    data={this.state.pastDealsSearchByRiskScore} 
                                    //enableClick={true} 
                                    //clickFunction={this.handleRowClick} 
                                    //newSearch={this.state.newSearch} 
                                    //useDeleteButton={true} 
                                    //deleteFunction={this.handleDeleteClick} 
                                    //useFilter={true} 
                                    //filterFunction={this.getAllBanks}
                                    //usePaginator={true}
                                    //changePage={this.changePage}
                                    //numberOfPages={this.state.totalPages}
                                    //pageNumber = {this.state.currentPage}
                                    //numberPerPage = {getDefaultPagingNumberOfItems()}
                                    //enableSortByHeader = {true}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
                <Dialog 
                    open={this.state.showModal} 
                    onClose={this.closeModal}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    //aria-labelledby="customized-dialog-title"
                >
                    <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                        Global Watch List
                    </DialogTitle>
                    <DialogContent dividers>
                    <DialogContentText>
                        Results
                    </DialogContentText>
                    <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                        <NewDynamicTable
                            id="gwlatr-table"
                            data={this.state.globalListWatchResults} 
                            hiddenHeaders={['WatchListsSearchId','ResultId']} 
                        />
                    </div>
                    </DialogContent>
                </Dialog>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }
}