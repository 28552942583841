import React from 'react'; 
import { getStyle } from '../../../styles/styles';
import { getFeeStructure,getFeeStructureByID,deleteFeeStructure,getFeeStructureDetails,GetFeeStructureDetailById,DeleteFeeStructureDetails } from '../../../networking/NetworkingFeeStructure';
import FeeStructureForm from './FeeStructureForm';
import FeeStructureDetailForm from './FeeStructureDetailForm';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';

var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();

export default class FeeStructureScreen extends React.Component {
    state={        
        feeStructureList: [],
        newSearch: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDelete: {},
        objFeeStructure: [],
        selectedFeeStructure: {},
        selectedFeeStructureDetail: {},
        feeStructureDetailList: [],
        step:1,
        clearFee: false,
        clearForm: false,
        clearDetail: false,
        totalPagesStructure:0,
        pagesStructure:1,
        totalPagesDetails:0,
        pagesDetails:1,
        //ALERT BOX
        alertTitle:'',
        alertMessage:''
    }
    componentDidMount(){
        getFeeStructure().then(
            (json) => {
                console.log(json)
                let feeStructures = json.feeStructures || []
                var feestructure = this.transformFeeStructureList(feeStructures);
                var helper = this.changeDefaultLabels(feestructure);
                var totalCount = feeStructures.length;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({feeStructureList: helper, totalPagesStructure: pages, newSearch: false})
            }
        );
    }
    changePageStructure = (value) =>{
        this.setState({pagesStructure: value, newSearch: false});
    }

    getAllFee = async () => {
        var json = await getFeeStructure();
        let allFee
        if (json !== undefined) {
            allFee = this.transformFeeStructureList(json.feeStructures);
        } else {
            allFee = []
        }
        return allFee;
    }

    changePageDetails = (value) =>{
        this.setState({pagesDetails: value, newSearch: false});
    }
    getAllDetails = async () =>{
        var json = await getFeeStructureDetails(this.state.selectedFeeStructure.FeeStructureID);
        var allDetails = this.transformSpotList(json.feeStructureDetails);
        return allDetails;
    }

    //#region Fee Structure List
    changeDefaultLabels(collection){
        var helper = collection;
        for(var i=0; i<collection.length;i++){
            if(collection[i]['Default']){
                collection[i]['Default'] = 'True';
            }else{
                collection[i]['Default'] = 'False';
            }
            if(collection[i]['Cumulative by tier']){
                collection[i]['Cumulative by tier'] = 'True';
            }else{
                collection[i]['Cumulative by tier'] = 'False';
            }
        }
        return helper;
    }
    transformFeeStructureList(list){
        list = list || []
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({
                'FeeStructureID': list[i]['FeeStructureID'],
                'Description': list[i]['Description'],
                'Default': list[i]['Default'],
                'Buy / Sell': list[i]['BuySell'],
                'Cumulative by tier': list[i]['CumulativeByTier'],
                'Related Fee': list[i]['RelatedFeeDescription']
            });
        }
        return helper;
    }
    //#endregion 
        
    onClickStepTab(value){
        this.setState({step: value});
    }

    //#region Dynamic Table Fee Structure
    handleRowClick(obj){
        console.group("FeeStructure handleRowClick")
        console.log(obj);
        console.groupEnd();
        getFeeStructureByID(obj['FeeStructureID']).then(
            (value)=>{
                console.log(value.feeStructure[0]);
                this.setState({selectedFeeStructure: value.feeStructure[0],selectedFeeStructureDetail: value.feeStructure[0]});
            }
        );
    }
    
    handleRowTableClick(obj){
        console.group("FeeStructure handleRowTableClick")
        console.log(obj);
        console.groupEnd();
        GetFeeStructureDetailById(obj['FeeStructureDetailId']).then(
            (value)=>{
                console.log(value.feeStructureDetail[0]);
                this.setState({selectedFeeStructureDetail: value.feeStructureDetail[0]});
            }
        );
    }
    
    handleDeleteClick(obj){
        console.group("FeeStructure handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDelete: obj});
    }
        
    handleDeleteDetailClick(obj){
        console.group("FeeStructure handleDeleteDetailClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDelete: obj});
    }
    
    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDelete: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    okSuccessAlert(){
        this.setState({showSuccessDeleteAlert: false});
    }
    
    yesDeleteAlert(){
        deleteFeeStructure(this.state.readyToDelete['FeeStructureID']).then(
            (value)=>{
                if(value.httpStatusCode!==200){
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Error", alertMessage:'First, must to delete Fee Structure Detail'})
                }else{
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Success", alertMessage:"Fee Structure successfully removed.",clearFee: true},()=>this.updateTable());
                }
            }
        );
    }
    
    yesDeleteDetailAlert(){
        DeleteFeeStructureDetails(this.state.readyToDelete['FeeStructureDetailId']).then(
            (value)=>{
                if(value.httpStatusCode!==200){
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Error", alertMessage:value.Message, clearForm: true});
                }else{
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Success", alertMessage:"Fee Structure successfully removed.", clearForm: true},()=>this.updateFeeStructureDetailsTable(this.state.readyToDelete['FeeStructureID']));
                }                
            }
        );
    }

    handleSelection(obj){        
        this.setState({objFeeStructure: obj});
    }

    updateTable(){
        getFeeStructure().then(
            (value) => {
                var feestructure = this.transformFeeStructureList(value.feeStructures);
                console.log(feestructure);                
                var helper = this.changeDefaultLabels(feestructure);
                this.setState({feeStructureList: helper});
            }
        );
    }
    //#endregion

    updateFeeStructureDetailsTable(value){
        this.updateFeeStructureDetailList(value);
    }
    
    outClearForm(value){
        this.setState({clearForm: value});
    }
    isClearForm = () =>{
        this.setState({selectedFeeStructure:[]});
    }
    isClearDetail = (value) =>{
        this.setState({clearDetail: value, selectedFeeStructureDetail:[],totalPagesDetails:0,pagesDetails:1,});
    }
    outClearFee(value){
        this.setState({clearFee: value});
    }

    updateFeeStructureDetailList(value){
        if(value===undefined || value===''){
            this.setState({feeStructureDetailList: []});            
        }else{
            getFeeStructureDetails(value).then(
                (json) =>{
                    this.setState({feeStructureID: value});
                    if(json.feeStructureDetails!== undefined){
                    var feestructuredetails = this.transformSpotList(json.feeStructureDetails);
                    console.log(feestructuredetails);
                    var totalCount = feestructuredetails.length;
                    var pages = Math.ceil(totalCount/itemsPerPage);
                    this.setState({feeStructureDetailList: feestructuredetails, totalPagesDetails: pages, newSearch: false})
                    }else{
                        this.setState({feeStructureDetailList: []});
                    }
                }
            );
        }
       
    }
    
    transformSpotList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push(
                {                    
                    'FeeStructureDetailId': list[i]['FeeStructureDetailId'],
                    'FeeStructureID': list[i]['FeeStructureID'],
                    'Instrument': list[i]['Instrument'],
                    'Delivery Method': list[i]['DeliveryMethod'],
                    'Foreign CCY': list[i]['FgnCurrencyID'], 
                    'Percent / Flat': list[i]['PercentFlat'],
                    'Amount From': list[i]['Percentage'],
                    'Lower Tier': list[i]['LowerTier'],
                    'Upper Tier': list[i]['UpperTier'],
                    'Minimum Fee': list[i]['Minimum'],
                    'Maximum Fee': list[i]['Maximum'],
                }
            );
        }
        return helper;
    }

    render(){        
        var stepScreen = [];

        var classNameStep1, classNameStep2 = "";
        
        switch(this.state.step){
            case 1:
                classNameStep1 = "uk-active"
                stepScreen.push(
                    <FeeStructureForm key="fee-structure-form" isClearForm = {this.isClearForm} updateCategoryTable={this.updateTable.bind(this)} selectedFeeStructure={this.state.selectedFeeStructure}  clearForm={this.state.clearFee} outClearForm={this.outClearFee.bind(this)}/>,
                    <NewDynamicTable key="fee-structure-screen" 
                                    data={this.state.feeStructureList} 
                                    enableClick={true} 
                                    clickFunction={this.handleRowClick.bind(this)} 
                                    newSearch={this.state.newSearch} 
                                    useDeleteButton={true} 
                                    deleteFunction={this.handleDeleteClick.bind(this)}
                                    usePaginator={true}
                                    changePage={this.changePageStructure}
                                    numberOfPages={this.state.totalPagesStructure}
                                    pageNumber = {this.state.pagesStructure}
                                    numberPerPage = {getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}
                                    useFilter={true} 
                                    filterFunction={this.getAllFee}/>,
                    <AlertBox id="delete-fee-structure" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Fee Structure?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                );
                break;
            case 2:
                stepScreen.pop();
                classNameStep2 = "uk-active"
                stepScreen.push(
                <FeeStructureDetailForm key="fee-structure-detail-form" feeSelectValue={this.updateFeeStructureDetailList.bind(this)} updateFeeStructureDetailsTable={this.updateFeeStructureDetailsTable.bind(this)} selectedFeeStructureDetail={this.state.selectedFeeStructureDetail} isClearDetail = {this.isClearDetail} clearForm={this.state.clearForm} outClearForm={this.outClearForm.bind(this)}/>,
                <NewDynamicTable hiddenHeaders={['FeeStructureID']} 
                                key="fee-structure-detail-screen" 
                                data={this.state.feeStructureDetailList} 
                                enableClick={true} 
                                clickFunction={this.handleRowTableClick.bind(this)} 
                                newSearch={this.state.newSearch} 
                                useDeleteButton={true} 
                                deleteFunction={this.handleDeleteDetailClick.bind(this)}
                                usePaginator={true}
                                changePage={this.changePageDetails}
                                numberOfPages={this.state.totalPagesDetails}
                                pageNumber = {this.state.pagesDetails}
                                numberPerPage = {getDefaultPagingNumberOfItems()}
                                enableSortByHeader={true}
                                useFilter={true} 
                                filterFunction={this.getAllDetails}/>,
                <AlertBox id="delete-fee-structure" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Fee Structure?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteDetailAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                );
                break;
            default:
                classNameStep1 = "uk-active"
                stepScreen.push(
                    <FeeStructureForm key="fee-structure-form" isClearForm = {this.isClearForm} updateCategoryTable={this.updateTable.bind(this)} selectedFeeStructure={this.state.selectedFeeStructure} />,
                    <NewDynamicTable key="fee-structure-screen" 
                                    data={this.state.feeStructureList} 
                                    enableClick={true} 
                                    clickFunction={this.handleRowClick.bind(this)} 
                                    newSearch={this.state.newSearch} 
                                    useDeleteButton={true} 
                                    deleteFunction={this.handleDeleteClick.bind(this)}
                                    usePaginator={true}
                                    changePage={this.changePageStructure}
                                    numberOfPages={this.state.totalPagesStructure}
                                    pageNumber = {this.state.pagesStructure}
                                    numberPerPage = {getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}
                                    useFilter={true} 
                                    filterFunction={this.getAllFee}/>,
                    <AlertBox id="delete-fee-structure" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Fee Structure?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                );
                break;
        }
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Fee Structure</h3>
                <ul className="uk-tab">
                    <li className={classNameStep1}><a onClick={this.onClickStepTab.bind(this, 1)}>Fee Structure Maintenance</a></li>
                    <li className={classNameStep2}><a onClick={this.onClickStepTab.bind(this, 2)}>Fee Structure Detail Maintenance</a></li>
                </ul>
                {stepScreen}
            </div>
        );
    }
}