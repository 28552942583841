import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody, decrypt } from './Networking';


export async function getCommentById(id, type){
    try {
        let params = {
            'Id': id,
            'Type': type
        }
        let response = await genericGetWithParameters('Comment/GetCommentById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveComment(model) {
    try {
        var method = 'POST';
        var apiName = 'Comment/SaveComment'

        let responseJWT = await genericCallWithBody(method, apiName, model);
        if(responseJWT.httpStatusCode === 200)
        {
            let response = await decrypt(responseJWT);
            return response;
        }else
        {
            return responseJWT
        }
    } catch (error) {
        console.error(error);
    }
}