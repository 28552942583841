import React from 'react';

import FeeMaintenanceForm from './FeeMaintenanceForm';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getAllFees, getFeeById } from '../../../networking/NetworkingFees';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
import { getStyle } from '../../../styles/styles'
const style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();

export default class FeeMaintenanceScreen extends React.Component {
    state = {
        fees: [],
        selectedFee: {},
        totalPages: 0,
        pages: 1,
        newSearch: false,
        filter: false
    }

    componentDidMount() {
        getAllFees('', sessionStorage.getItem('AgentCustomerID'), 0, 0).then(
            (json) => {
                if (json !== undefined && json.totalCount > 0) {
                    const totalCount = json.totalCount;
                    const totalPages = Math.ceil(totalCount / itemsPerPage);
                    this.setState({ fees: json.fees, totalPages, newSearch: true, filter: true });
                } else {
                    this.setState({ newSearch: true, filter: false });
                }
            }
        );
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    updatePage = async () => {
        const fees = await this.getFeeList();
        const totalCount = fees.length;
        const totalPages = Math.ceil(totalCount / itemsPerPage);
        this.setState({ fees, totalPages, newSearch: true, filter: true });
    }

    handleRowClick = async (obj) => {
        const value = await getFeeById(obj['ID']);
        this.setState({ selectedFee: value });
    }

    getFeeList = async () => {
        const json = await getAllFees('', sessionStorage.getItem('AgentCustomerID'), 0, 0);
        if (json !== undefined && json.fees !== undefined) {
            return json.fees;
        } else {
            return [];
        }
    }

    render() {
        return (
            <div uk-grid="true">
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Fee Maintenance</h3>
                    <FeeMaintenanceForm selectedFee={this.state.selectedFee} updateFunction={this.updatePage} />
                </div>
                <div className="uk-width-1">
                    <hr />
                    <NewDynamicTable
                        data={this.state.fees}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={false}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={itemsPerPage}
                        enableSortByHeader={true}
                        useFilter={this.state.filter}
                        filterFunction={this.getFeeList} />
                </div>
            </div>
        );
    }
}