import {genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody} from './Networking';

export async function getEntityProperties(entityID, entityType) {
    try{
        let params = {
            'EntityType': entityType,
            'EntityId': entityID,
            'Page': 0,
            'Items': 0,
        };
        let response = await genericGetWithParameters('EntityProperty/GetPropertiesByEntityId', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveEntityProperty(model){
    var method = 'POST';
    var apiName = 'EntityProperty/SaveByEntityId';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function deleteEntityProperty(id){
    let params = {
        'entityPropertyId': id,
    };
    let response = await genericDeleteWithParameters('EntityProperty/Delete',params);
    return response;
}