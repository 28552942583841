import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody } from './Networking';


export async function getContactsByCustomer(customerID,page,items){
    try {
        let params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items,
        }

        let response = await genericGetWithParameters('Contact/GetContactsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getContactByID(contactID){
    try {
        let params = {
            'ContactID': contactID
        }
        let response = await genericGetWithParameters('Contact/GetContactInformationByContactID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveContact(model){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Contact/SaveContact'
    let response = await genericCallWithBody(method, apiName, model)
    return response;
}

export async function getContactOnlineInfo(customerID, personID,emailAddress){
    try {
        let params = {
            "CustomerId":customerID,
            "PersonId":personID,
            "EmailAddress":emailAddress
        };

        let response = await genericGetWithParameters('Contact/GetContactOnlineInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerContactsMin(customerID){
    try {
        let params = {
            "CustomerId":customerID,
        };

        let response = await genericGetWithParameters('Contact/GetCustomerContacts_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRelatedCustomerRoles(relatedCustomerId, email, contactId, roles)
{
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Role/SaveRelatedCustomerRoles'
    //try{
        var request = {
            relatedCustomerId: relatedCustomerId,
            email: email,
            applicationName: 'ForeignExchange',
            contactId: contactId,
            roles: roles,
        }
        let response = await genericCallWithBody(method, apiName, request)
        return response;
    /*} catch (error) {
        console.error(error);
    }*/
}