import React from 'react';

import DealConfirmationForm from './DealConfirmationForm'
import DealEmailLogForm from './DealEmailLogForm'
import EntityProperties from '../shared/EntityProperties';

import {getDealFees, updateDealFee} from '../../networking/NetworkingDealFees';
import {completedIncomingOutgoingStatus} from '../../networking/NetworkingFunds'
import {applyPaymentsToDeal} from '../../networking/NetworkingPayments'

import DatePicker from '../shared/DatePicker'
import NumberInput from '../shared/NumberInput'
import AlertBox from '../shared/AlertBox';
import LoadingBox from '../shared/LoadingBox'

import {DealType} from '../../constants/EntityTypeNames';

import {formatToCurrency} from '../../helpers/FormatHelper';

import {getStyle} from '../../styles/styles';
import PlusIcon from '../../css/custom-icons/plus.svg';
import MinusIcon from '../../css/custom-icons/minus.svg';

var style = getStyle(); 

export default class DealInformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.handlePaymentAmountToUse = this.handlePaymentAmountToUse.bind(this);
    }
    
    state = {
        dealHeaderInfoProp: {},
        dealHeaderId: 0,
        customerName: "",
        customerId: 0,
        valueDate: "",
        buyAmount: 0,
        sellAmount: 0,
        customerRate: 0,
        BCur: "",
        SCur: "",
        type: "",
        status: "",
        customerType: "",
        customerRefNo: "",
        dealPaidStatus: "",
        totalPaidByCustomer: 0,
        totaSentToBeneficiaries: 0,
        totalFees: 0,
        updateToken: 0,

        listDealPayments: [],
        listDealPaymentsView: [], // the list with the filter applied
        dealFeeList: [],
        listPaymentsToApply: [],

        showAppliedPayments: false,
        showAvailablePayments: false,

        feesSectionExpanded: false,

        //alerts
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        showApplyPaymentsAlert: false,
        showAmountExceedsBalanceAlert: false,
        showAmountToApplyExceedsBuyBalance: false,
        showConfirmDealAlert: false,

        //loading
        loading: false,

        //navigation
        showDealConfirmation: false,
        showDealEmailLog: false,

        //
        entityPropertiesSaveProcess: false,
        updateFeesOnBlur: false
    }

    componentDidMount() {
        console.log(this.props)
        if (typeof this.props !== "undefined" && this.props !== null && typeof this.props.dealHeaderId != "undefined") {
            this.setState({
                dealHeaderId: this.props.dealHeaderId,
                dealHeaderInfoProp: this.props.dealHeaderInfo,
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments,
                //
                buyAmount: this.props.dealHeaderInfo.BuyAmount,
                BCur: this.props.dealHeaderInfo.BCur,
                sellAmount: this.props.dealHeaderInfo.SellAmount,
                SCur: this.props.dealHeaderInfo.SCur,
                status: this.props.dealHeaderInfo.Status,
                valueDate: this.props.dealHeaderInfo.ValueDate,
                customerRate: this.props.dealHeaderInfo.CustomerRate,
                customerRefNo: this.props.dealHeaderInfo.CustomerRefNo,
                totalPaidByCustomer: this.props.dealHeaderInfo.TotalPaidByCustomer,
                //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                totaSentToBeneficiaries: this.props.dealHeaderInfo.TotalDisbursed,
                totalFees: this.props.dealHeaderInfo.TotalFees,
                dealPaidStatus: this.props.dealHeaderInfo.DealPaidStatus,
                updateToken: this.props.dealHeaderInfo.UpdateToken,
                customeName: this.props.dealHeaderInfo.Name,
                customerId: this.props.dealHeaderInfo["Customer ID"],
                        //
                type: this.props.dealHeaderInfo.type,
                customerType: this.props.dealHeaderInfo.CustomerType
            }, 
            () => this.getDealFees()// this.handleLoadClick()
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dealHeaderInfo !== this.props.dealHeaderInfo) {
            this.setState({
                dealHeaderId: this.props.dealHeaderId,
                dealHeaderInfoProp: this.props.dealHeaderInfo,
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments,
                //
                buyAmount: this.props.dealHeaderInfo.BuyAmount,
                BCur: this.props.dealHeaderInfo.BCur,
                sellAmount: this.props.dealHeaderInfo.SellAmount,
                SCur: this.props.dealHeaderInfo.SCur,
                status: this.props.dealHeaderInfo.Status,
                valueDate: this.props.dealHeaderInfo.ValueDate,
                customerRate: this.props.dealHeaderInfo.CustomerRate,
                customerRefNo: this.props.dealHeaderInfo.CustomerRefNo,
                totalPaidByCustomer: this.props.dealHeaderInfo.TotalPaidByCustomer,
                //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                totaSentToBeneficiaries: this.props.dealHeaderInfo.TotalDisbursed,
                totalFees: this.props.dealHeaderInfo.TotalFees,
                dealPaidStatus: this.props.dealHeaderInfo.DealPaidStatus,
                updateToken: this.props.dealHeaderInfo.UpdateToken,
                customeName: this.props.dealHeaderInfo.Name,
                customerId: this.props.dealHeaderInfo["Customer ID"],
                        //
                type: this.props.dealHeaderInfo.type,
                customerType: this.props.dealHeaderInfo.CustomerType
            }, () => this.getDealFees())
        }
        if (prevProps.dealPayments !== this.props.dealPayments) {
            this.setState({
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments
            })
        }
    }

    /* handleLoadClick() {
        this.setState({loading: true});

        console.group("handleLoadClick")
        console.groupEnd();
        getDealHeaderInfo(this.state.dealHeaderId).then(
            (value) => {
                if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                    var dealHeaderInfo = value.dealHeaderInfo[0]
                    console.table(dealHeaderInfo);
                    this.setState({
                        DealHeaderID: dealHeaderInfo.DealHeaderID,
                        buyAmount: dealHeaderInfo.BuyAmount,
                        BCur: dealHeaderInfo.BCur,
                        sellAmount: dealHeaderInfo.SellAmount,
                        SCur: dealHeaderInfo.SCur,
                        Status: dealHeaderInfo.Status,
                        ValueDate: dealHeaderInfo.ValueDate,
                        CustomerRate: dealHeaderInfo.CustomerRate,
                        customerRefNo: dealHeaderInfo.CustomerRefNo,
                        totalPaidByCustomer: dealHeaderInfo.TotalPaidByCustomer,
                        //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                        totaSentToBeneficiaries: dealHeaderInfo.TotalDisbursed,
                        totalFees: dealHeaderInfo.TotalFees,
                        dealPaidStatus: dealHeaderInfo.DealPaidStatus,
                        updateToken: dealHeaderInfo.UpdateToken,
                        customeName: dealHeaderInfo.Name,
                        customerId: dealHeaderInfo["Customer ID"]
                    });
                }

                if (typeof value.dealPayments !== undefined && value.dealPayments != null) {
                    this.setState({
                        listDealPayments: value.dealPayments,
                        listDealPaymentsView: value.dealPayments
                    });
                } else {
                    this.setState({
                        listDealPayments: [],
                        listDealPaymentsView: []
                    });
                }
            }
        );

        //Get deal fees
        this.getDealFees();
        
        this.setState({
            loading: false
        });
    } */

    hideConfirmationButton() {
        let hideButton = true
        if (this.state.customerType === 'Bank') {
            hideButton = false
        }
        return hideButton
    }

    getDealFees() {
        var dealFees;
        var dealFeesCalc = 0;
        if (dealFees !== undefined) {
            this.setState({
                dealFeeList: dealFees
            });
        }
        else {
            getDealFees(this.state.dealHeaderId, '', 'Backoffice').then((json) => {
                if (typeof json !== "undefined" && typeof json.dealFees !== "undefined") {
                    let jsonDealFees = json.dealFees;
                    var helper = [];
                    for (var i = 0; i < jsonDealFees.length; i++) {
                        jsonDealFees[i].FeeDescription = jsonDealFees[i].Description;
                        helper.push(jsonDealFees[i]);
                        //
                        dealFeesCalc += jsonDealFees[i].FeeAmount;
                    }
                    this.setState({
                        dealFeeList: helper, totalFees: dealFeesCalc
                    });
                }
            });
        }
    }

    handleDeleteClick(obj){ 
    }

    getBuyAmountValue(event) {
        this.setState({
            buyAmount: event.target.value
        });
    }

    getSellAmountValue(event) {
        this.setState({
            sellAmount: event.target.value
        });
    }

    //delete
    getDealTestIdValue(event) {
        this.setState({
            //dealTestId: event.target.value,
            dealHeaderId: event.target.value
        });
    }

    handleOnCheckApplied(event) {
        let checked = event.target.checked
        this.setState({
            showAppliedPayments: checked
        })
        if (checked) {
            this.setState({
                showAvailablePayments: false,
                listDealPaymentsView: this.state.listDealPayments.filter(function (element) {
                    return element['Applied'] === 1;
                })
            })
        } else {
            this.setState({
                listDealPaymentsView: this.state.listDealPayments
            })
        }
    }

    handleOnCheckAvailable(event){
        let checked = event.target.checked
        this.setState({
            showAvailablePayments: checked
        })
        if (checked) {
            this.setState({
                showAppliedPayments: false,
                listDealPaymentsView: this.state.listDealPayments.filter(function (element) {
                    return (element['Balance Available'] > 0 || element['Applied'] === 0);
                })
            })
        } else {
            this.setState({
                listDealPaymentsView: this.state.listDealPayments
            })
        }
    }

    handleOnChangeDealFee = (event) => {
        let dealFeeTemp = this.state.dealFeeList;
        let controlID = event.target.id;
        let index = controlID.substring(controlID.indexOf('-') + 1);
        let amount = event.target.value;

        if (dealFeeTemp[index].FeeAmount === amount) {
            return;
        } else {
            dealFeeTemp[index].FeeAmount = amount;

            var totalFeesCalc = 0;
            for (var i = 0; i < dealFeeTemp.length; i++) {
                totalFeesCalc += Number(dealFeeTemp[i].FeeAmount);
            }

            this.setState({
                dealFeeList: dealFeeTemp,
                totalFees: totalFeesCalc,
                updateFeesOnBlur: true
            });
        }
    }

    handleUpdateDealFee = (index) => {
        
        var dealFeeTemp = this.state.dealFeeList;

        if (this.state.updateFeesOnBlur) 
        {
            let sessionUserID = sessionStorage.getItem('UserID');
            var model = {
                FeeAmount: dealFeeTemp[index].FeeAmount,
                FeeDescription: dealFeeTemp[index].Description,
                FeeStructureID: dealFeeTemp[index].FeeStructureID,
                UserID: sessionUserID,
                SourceID: this.state.dealHeaderId,
                SourceTable: "E",
                UpdateToken: dealFeeTemp[index].UpdateToken == null ? 0 : dealFeeTemp[index].UpdateToken
            }

            updateDealFee(model).then((json) => {
                if (json.httpStatusCode === 200) {
                    this.props.reloadDealHeaderInfo();
                    
                } else {
                    this.setState({
                        showAlert: true,
                        alertMessage: 'An error occurred while trying to update de fees. Please try again.',
                        alertTitle: 'Error'
                    });
                }
            })
        }       
    }

    handlePaymentAmountToUse(data, event) { 
        
        let tempPaymentsToApply = this.state.listPaymentsToApply;
        let amountToUse = event.target.value;
        let balanceAvailable = data.balanceAvailable; 

        if (amountToUse > balanceAvailable) {
            this.setState({showAmountExceedsBalanceAlert: true});
            return
        }
        
        //remove if exist
        if (tempPaymentsToApply.length > 0) {
            tempPaymentsToApply = tempPaymentsToApply.filter(item => item.PaymentId !== data.paymentId)
        }

        //add if amount > 0
        if (amountToUse > 0) {
            tempPaymentsToApply.push({
                PaymentId: data.paymentId,
                AmountToUse: amountToUse
            })
        }

        //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
        if (tempPaymentsToApply.length > 0) {
            var amountTotalToApply = tempPaymentsToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
            if (amountTotalToApply + this.state.totalPaidByCustomer > this.state.buyAmount + this.state.totalFees) {
                this.setState({
                    showAmountToApplyExceedsBuyBalance: true
                });
                return
            }
        }

        var paymentChanged = this.state.listDealPaymentsView.filter(item => item['Payment ID'] === data.paymentId)[0]
        paymentChanged['Amount To Use'] = amountToUse

        this.setState({
            listPaymentsToApply: tempPaymentsToApply
        });
    }

    handleApplyPayments(){
        this.setState({showApplyPaymentsAlert: true});
    }

    //alerts

    yesApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
        let userID = sessionStorage.getItem('UserID');
        var model = {
            DealHeaderId: this.state.dealHeaderId,
            UserId: userID,
            payments: this.state.listPaymentsToApply 
        }
        applyPaymentsToDeal(model).then((json) => {
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlert: true,
                    alertMessage: 'An error occurred while trying to apply the payments. Please try again.',
                    alertTitle: 'Error'
                });
            } else {
                this.setState({
                    listPaymentsToApply: [],
                    showAlert: true,
                    alertTitle: 'Payments applied successfully.'
                }, () => this.props.reloadDealHeaderInfo());
                
            }
        });
    }

    noApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
    }

    okAmountExceedsBalanceAlert()
    {
        this.setState({showAmountExceedsBalanceAlert: false});
    }

    okAmountToApplyExceedsBuyBalance() {
        this.setState({showAmountToApplyExceedsBuyBalance: false});
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    noConfirmDealAlert(){
        this.setState({showConfirmDealAlert: false});
    }

    yesConfirmDealAlert(){
        this.setState({showConfirmDealAlert: false});
        let userID = sessionStorage.getItem('UserID');
        var  model = {
            DealId: this.state.dealHeaderId,
            CreatedBy: userID,
            UpdateToken: this.state.updateToken
          }
        completedIncomingOutgoingStatus(model).then((json) => {
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlert: true,
                    alertMessage: 'An error occurred while trying to confirm. Please try again.',
                    alertTitle: 'Error'
                });
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Confirmation completed successfully.'
                }, () => this.props.reloadDealHeaderInfo());
                
            }
        });
    }
    //alerts end

    //navigation

    handleConfirmDeal(){
        this.setState({showConfirmDealAlert: true});        
    }

    handlePrintSendDealConfirmation() {
        this.setState({showDealConfirmation: true});
    }

    handleViewSentEmails(){
        this.setState({showDealEmailLog: true});
    }

    handleBackLink() {
        this.setState({
            showDealConfirmation: false,
            showDealEmailLog: false
        });
    }

    //navigation end

    //addtional properties
    handleSaveProperties(){
        this.setState({entityPropertiesSaveProcess: true});
    }

    onFinishEntityPropertiesSaveProcess = () => {
        this.setState({entityPropertiesSaveProcess: false});
    }    
    //
    handlefeesSectionClick = (event) => {
        this.setState({feesSectionExpanded: !this.state.feesSectionExpanded});
    }

    render() {

        if (this.state.showDealConfirmation) {
            return (
                <DealConfirmationForm backLink={this.handleBackLink.bind(this)} dealHeaderInfo={this.state.dealHeaderInfoProp} dealHeaderId={this.state.dealHeaderId} 
                lookUpTables={this.props.lookUpTables} />
            );
        }

        if (this.state.showDealEmailLog) {
            return (
                <DealEmailLogForm backLink={this.handleBackLink.bind(this)} dealHeaderId={this.state.dealHeaderId} />
            );
        }

        //fees section
        var dealFeeRows = [];
         
        for(var i=0; i<this.state.dealFeeList.length; i++){
            let index = i;
            var disabled = '';
            if(this.state.dealFeeList[i].ReadOnly === true){
                disabled = 'disabled';
            }
            dealFeeRows.push(
                <tr key={i}>
                    <td>
                        {this.state.dealFeeList[i].Description}
                    </td>
                    <td>
                    <NumberInput id={"dealfee-" + i} type="Currency" className="uk-input" 
                        value={this.state.dealFeeList[i].FeeAmount} 
                        onChange={this.handleOnChangeDealFee}
                        onBlur = {() => this.handleUpdateDealFee(index)}
                        disabled={disabled}/>
                    </td>
                </tr>
            );
        }

        //new dynamic table with editable cell
   /*       var headers = [];
        var rows = [];

        headers.push(<th key='1'>Payment ID</th>);
        headers.push(<th key='2'>Payment Amount</th>);
        headers.push(<th key='3'>Incoming Payment Status</th>);
        headers.push(<th key='4'>Amount Applied</th>);
        headers.push(<th key='5'>Balance Available</th>);
        headers.push(<th key='6'>Amount To Use</th>); 

        var index=0;
      this.state.listDealPaymentsView.forEach(element => {
            index++;
            rows.push(<tr key={index}>
                <td>{element['Payment ID']}</td>
                <td>{formatToCurrency(element['Payment Amount'])}</td>
                <td>{element['Incoming Payment Status']}</td>
                <td>{formatToCurrency(element['Amount Applied'])}</td>
                <td>{formatToCurrency(element['Balance Available'])}</td>
                <td><NumberInput id={'paymentamounttouse-' + index} type="Currency" value={element['Amount To Use']} className="uk-input"
                onChange={this.handlePaymentAmountToUse.bind(this, 
                    {
                        paymentId: element['Payment ID'],
                        balanceAvailable: element['Balance Available']
                    }
                )}/>
                </td>                   
            </tr>);
        });
*/
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfPaymentsToApply = 0;
        this.state.listPaymentsToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;
            numberOfPaymentsToApply++;
        });
        amountTotalToApply += this.state.BCur;

        //Deal fees sections
        let dealFeesSection = (
            <div>
                <h5 className="uk-heading-divider" 
                    uk-toggle="target : #feeSection;"
                    style={{cursor: 'pointer'}}>Total Fees &nbsp; {formatToCurrency(this.state.totalFees)} <i id="feeSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="feeSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                </h5>
                <div id="feeSection" hidden className="uk-accordion-content border-table">
                        <table className="uk-table uk-table-divider uk-table-hover">
                            <thead>
                                <tr>
                                    <th>Fee</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dealFeeRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            );        

        return (       
                 
            <form>         
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1-2">
                        <div className="uk-grid uk-margin">
                            
                            {/*Line1*/}                          
                            <div className="uk-width-1-2 uk-margin-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label">We Buy</label>
                                    </div>
                                    <div className="uk-width-1-2" style={{ textAlign: 'right'}}>
                                        {formatToCurrency(this.state.buyAmount)}  {this.state.BCur}              
                                    </div>
                                    
                                </div>                                
                            </div>

                            <div className="uk-width-1-2 uk-margin-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label">Total paid by customer</label>
                                    </div>
                                    <div className="uk-width-1-2" style={{ textAlign: 'right'}}>
                                        {formatToCurrency(this.state.totalPaidByCustomer)}  {this.state.BCur}  
                                    </div>
                                </div>                                
                            </div>

                            {/*Line2*/}
                            <div className="uk-width-1-2 uk-margin-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label" >We Sell</label>
                                    </div>
                                    <div className="uk-width-1-2" style={{ textAlign: 'right'}}>
                                        {formatToCurrency(this.state.sellAmount)}  {this.state.SCur}                            
                                    </div>
                                </div>                                
                            </div>

                            <div className="uk-width-1-2 uk-margin-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label" >Total sent to beneficiaries</label>
                                    </div>
                                    <div className="uk-width-1-2" style={{ textAlign: 'right'}}>
                                        {formatToCurrency(this.state.totaSentToBeneficiaries)}  {this.state.SCur}                   
                                    </div>
                                </div>                                
                            </div>

                            {/*Line3*/}                
                            <div className="uk-width-1-2 uk-margin-small-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2"><label className="uk-form-label" >Customer Rate</label></div>
                                    <div className="uk-width-1-2" style={{ textAlign: 'right'}}>{parseFloat(this.state.customerRate).toFixed(8).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-margin-small-bottom"></div>

                             {/*Line4*/}
                             <div className="uk-width-1-2 uk-margin-small-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2"><label className="uk-form-label" >Value Date</label></div>
                                    <div className="uk-width-1-2">{this.state.valueDate}</div>
                                </div>
                            </div>                            
                            <div className="uk-width-1-2 uk-margin-small-bottom"></div>

                            {/*Line5*/}
                            <div className="uk-width-1-2 uk-margin-small-bottom">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2"><label className="uk-form-label" >Customer Ref No</label></div>
                                    <div className="uk-width-1-2">
                                        {this.state.customerRefNo}
                                        {/*<input className="uk-input" type="text" id="form-dealinformation-customerRefNo" value={this.state.customerRefNo} readOnly/>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 uk-margin-small-bottom"></div>
                            
                        </div>
                    </div>
                   
                    {dealFeesSection}

                </div>

                <div className="uk-grid uk-margin">                

                </div>

                {/*row*/}              

                <div> 
                    <button className="uk-button uk-button-default uk-button-green" type="button" onClick = {this.handleConfirmDeal.bind(this)}
                    disabled={true} hidden={this.hideConfirmationButton()}>Confirm</button>
                    <button className="uk-button uk-button-default uk-button-green" type="button" onClick = {this.handlePrintSendDealConfirmation.bind(this)} 
                    disabled={true}>Print/Send Deal Conf.</button>
                    <button className="uk-button uk-button-green" type="button" onClick = {this.handleViewSentEmails.bind(this)}
                    disabled={true}>View Sent Emails</button>
                </div>

                <hr className="uk-hr"></hr>

                {/*Payment actions */}
                {/*<div className="uk-grid">
                <div className="uk-width-1-4">
                    <label className="uk-form-label">Show applied payments</label>
                    <div className="uk-form-controls">
                        <input className="uk-checkbox" type="checkbox" checked={this.state.showAppliedPayments} onChange={this.handleOnCheckApplied.bind(this)}/>
                    </div>
                </div>
                <div className="uk-width-1-4">
                    <label className="uk-form-label" >Show available payments</label>
                    <div className="uk-form-controls">
                        <input className="uk-checkbox" type="checkbox" checked={this.state.showAvailablePayments} onChange={this.handleOnCheckAvailable.bind(this)}/>
                    </div>
                </div>
                </div>

                <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>

                <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>
 
                <div> 
                    <button className={numberOfPaymentsToApply === 0 ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                    onClick = {this.handleApplyPayments.bind(this)} disabled={numberOfPaymentsToApply === 0}>Apply payments</button>
                </div>
 
                <hr></hr >
            */}
                {/*<div className="uk-width-1-1 uk-margin">
                    <EntityProperties entityType={DealType} 
                        entityID={this.state.dealHeaderId} 
                        runSaveProcess={this.state.entityPropertiesSaveProcess} 
                        finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                </div>
                <div className="uk-width-1-1 uk-margin">
                    <button className="uk-button uk-button-green" type="button" onClick = {this.handleSaveProperties.bind(this)}>Save Properties</button>
                </div>
            */}

                <h5 className="uk-heading-divider properties-tasks-category-title-new1" 
                    uk-toggle="target : #propertiesSection;"
                    style={{...getStyle().titleStyle, cursor: 'pointer'}}>Additional Properties
                    <img 
                        id="propertiesSection"
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{position: 'absolute',
                        paddingTop: '16px',
                        right: '30px', color: 'black'}}
                    />
                    <img 
                        id="propertiesSection" 
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{position: 'absolute',
                        paddingTop: '16px',
                        right: '30px', color: 'black'}} 
                        hidden
                    />
                </h5>
                <div id="propertiesSection" hidden>
                    <div className="uk-width-1-1 uk-margin">
                        <EntityProperties entityType={DealType}
                            entityID={this.state.dealHeaderId}
                            fullScreen={true}
                            runSaveProcess={this.state.entityPropertiesSaveProcess}
                            finishSaveProcess={this.onFinishEntityPropertiesSaveProcess}/>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick = {this.handleSaveProperties.bind(this)}>Save Properties</button>
                    </div>
                </div>                

                <AlertBox id="close-alert" open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                
                <AlertBox id="apply-payments-alert"
                    open={this.state.showApplyPaymentsAlert} 
                    title="Please confirm" 
                    message = {"You will apply: " + amountTotalToApply + " from " + numberOfPaymentsToApply + " payments"}
                    type = "Yes/No"
                    yesClick = {this.yesApplyPaymentsAlert.bind(this)}
                    noClick = {this.noApplyPaymentsAlert.bind(this)}
                    />

                <AlertBox id="apply-payments-amountexceedsbalance-alert"
                    open={this.state.showAmountExceedsBalanceAlert} 
                    title="Error" 
                    message = {"The amount exceeds the balance"}
                    type = "Ok"
                    okClick = {this.okAmountExceedsBalanceAlert.bind(this)}
                    />    

                 <AlertBox id="apply-payments-amountexceedsbuyamountplusfees-alert"
                    open={this.state.showAmountToApplyExceedsBuyBalance} 
                    title="Error" 
                    message = {"The amount to apply exceeds the buy amount plus fees"}
                    type = "Ok"
                    okClick = {this.okAmountToApplyExceedsBuyBalance.bind(this)}
                    /> 

                 <AlertBox id="deal-confirmation-alert"
                    open={this.state.showConfirmDealAlert} 
                    type = "Yes/No"
                    title="Please confirm" 
                    message = {"The funds in and funds out statuses will be set to completed. Are you sure you wish to continue?"}
                    yesClick = {this.yesConfirmDealAlert.bind(this)}
                    noClick = {this.noConfirmDealAlert.bind(this)}
                    /> 
                
                <LoadingBox loading={this.state.loading}/>
            
            </form> 
            
            );
    }
}