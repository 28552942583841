import React from 'react';
import preval from 'preval.macro';

import {getCurrentVersionNumber} from '../../helpers/PackageJsonHelper';
import {login} from '../../networking/Networking';
import AlertBox from '../shared/AlertBox';
import { sendForgotPasswordEmail } from '../../networking/NetworkingUsers';

export default class LoginForm extends React.Component {
    state = {
        username: '',
        password: '',
        loading: false,
        forgotPassword: false,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
    }

    handleLoginClick = () => {
        this.setState({loading: true});
        login(this.state.username,this.state.password).then(
            (value) => {
                this.setState({loading: false});
                if(value != null) {
                    if(value.SessionID != null) {
                        this.props.loginSuccess();
                    } else if (value.status !== undefined) {
                        if (value.status === 401) {
                            this.setState({showAlert: true, alertMessage: 'Incorrect Username or Password', alertTitle: 'Error', username: '', password:''});
                        }
                    } else {
                        this.setState({showAlertError: true, alertMessage: 'Check your Internet Connection', alertTitle: 'Error'});
                    }
                } else {
                    this.setState({showAlertError: true, alertMessage: 'Check your Internet Connection', alertTitle: 'Error'});
                }
            }
        );
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: '', forgotPassword: false, username: '', password:''});
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: ''});
    }

    handleResetPasswordClick = (event) => {
        event.preventDefault();
        if (this.state.username !== "") {
            sendForgotPasswordEmail("ForeignExchange", this.state.username).then(
                (json) => {
                    if (json !== undefined) {
                        if (json.httpStatusCode !== 200) {
                            this.setState({showAlertError: true, alertMessage: 'An error ocurred while sending the email', alertTitle: 'Error'});
                        } else {
                            this.setState({showAlert: true, alertTitle: 'Email sent ', alertMessage: 'Please check your inbox' , username: '', password:''});
                        }
                    }
                }
            );
        }
    }

    handleForgotPasswordClick = (event) => {
        event.preventDefault();
        this.setState({ forgotPassword: !this.state.forgotPassword });
    }

    handleUpdateUsername = (event) => {
        this.setState({username: event.target.value});
    }

    handleUpdatePassword = (event) => {
        this.setState({password: event.target.value});
    }

    render() {
        var form = (
            <form>
                <div className="uk-margin">
                    <input className="uk-input" type="text" placeholder="User Name" onChange={this.handleUpdateUsername}  value={this.state.username}/>
                </div>
                <div className="uk-margin">
                    <input className="uk-input" type="password" placeholder="Password" onChange={this.handleUpdatePassword} value={this.state.password}/>
                </div>
                <div className="uk-margin-top">
                    <button type="button" className="uk-button uk-button-link" onClick={this.handleForgotPasswordClick}>Forgot your password?</button>
                </div>
                <div className="uk-margin">
                    <button className="uk-button uk-button-green uk-width-1-1" onClick={this.handleLoginClick}>Login</button>
                </div>
            </form>
        );
        
        if (this.state.loading) {
            form = (<div className="uk-position-center" uk-spinner="ratio: 3"></div>);
        }

        if (this.state.forgotPassword) {
            form = (
                <form>
                    <div className="uk-margin">
                        <input className="uk-input" type="text" placeholder="Username" onChange={this.handleUpdateUsername}  value={this.state.username}/>
                    </div>
                    <div className="uk-margin">
                        <button type="button" className="uk-button uk-button-link" onClick={this.handleForgotPasswordClick}>Return to Login page</button>
                    </div>
                    <button className="uk-button uk-button-green uk-width-1-1" onClick={this.handleResetPasswordClick}>Reset password</button>
                </form>
            );
        }

        return(
            <div>
                <div className="uk-position-center uk-box-shadow-large">
                    <div className="uk-card uk-card-default uk-card-large uk-width-large">
                        <div className="uk-card-badge uk-label">{getCurrentVersionNumber()}</div>
                        <div className="uk-card-header">
                            <h3 className="uk-card-title">e2e Foreign Exchange</h3>
                            <h6> Build Date: {preval`module.exports = new Date().toLocaleString();`}</h6>
                        </div>
                        <div className="uk-card-body">
                            {form}
                        </div>
                    </div>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError}/>
            </div>
        );
    }
}