import {genericGetWithParameters, genericCallWithBody, genericGetWithParametersNoEncrypted} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getOutgoingInformationByID(outgoingID){
    try {
        let params = {
            OutgoingFundsID: outgoingID
        }
        let response = await genericGetWithParameters('Funds/GetOutgoingInformationByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateOutgoingFund2(model){
    try{
        var request = {
            OutgoingFundsID: model.OutgoingFundsID,
            DealHeaderID: model.DealHeaderID,
            BeneficiaryBankAccountID: model.BeneficiaryBankAccountID,
            BeneficiaryID: model.BeneficiaryID,
            NostroAccount: model.NostroAccountID,
            VersionNo: model.VersionNo,
            PaymentInstrument: model.PaymentInstrument,
            ValueDate: model.ValueDate,
            PaymentAmount: model.PaymentAmount,
            DeliveryRefNo: model.DeliveryRefNo,
            BeneRefNumber: model.DetailsOfPayment,
            Status: model.Status,
            WireItemFee: model.WireItemFee,
            WireItemFeeCurrencyID: model.WireItemFeeCurrencyID,
            CreatedBy: sessionStorage.getItem('UserID'),
            BeneficiaryType: model.BeneficiaryType,
            PaymentReference: model.PaymentReference,
            Comments: model.Comments,
            DeliveryMethod: model.DeliveryMethod,
            UpdateToken: model.UpdateToken,
        }
        console.group("UpdateRequest");
        console.log(model);
        console.groupEnd();
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Funds/Put', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(request),
        });
        let responseJson = await response.json();
        console.group("updateOutgoingFund", model.OutgoingFundsID);
        console.log(responseJson);
        console.groupEnd();
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function updateOutgoingFund(model){
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'Funds/Put'
    var request = {
        OutgoingFundsID: model.OutgoingFundsID,
        DealHeaderID: model.DealHeaderID,
        BeneficiaryBankAccountID: model.BeneficiaryBankAccountID,
        BeneficiaryID: model.BeneficiaryID,
        NostroAccount: model.NostroAccountID,
        VersionNo: model.VersionNo,
        PaymentInstrument: model.PaymentInstrument,
        ValueDate: model.ValueDate,
        PaymentAmount: model.PaymentAmount,
        DeliveryRefNo: model.DeliveryRefNo,
        BeneRefNumber: model.DetailsOfPayment,
        Status: model.Status,
        WireItemFee: model.WireItemFee,
        WireItemFeeCurrencyID: model.WireItemFeeCurrencyID,
        CreatedBy: sessionStorage.getItem('UserID'),
        BeneficiaryType: model.BeneficiaryType,
        PaymentReference: model.PaymentReference,
        Comments: model.Comments,
        DeliveryMethod: model.DeliveryMethod,
        UpdateToken: model.UpdateToken,
    }
    
    let response = await genericCallWithBody(method,apiName,request);
    return response;
}

export async function completedIncomingOutgoingStatus(model) {

    let method = 'POST';
    let apiName = 'Funds/CompletedIncomingOutgoingStatus';
    
    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutgoings(dealHeaderID, page, items){
    try {
        let params = {
            'DealHeaderID': dealHeaderID,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Funds/GetList',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateRejectedFeeAmount(model) {
    let method = 'PUT';
    let apiName = 'Funds/UpdateRejectedFeeAmount';

    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAmountInBaseCurrency(dealID, paymentAmount){
    try{
        let params = {
            DealID: dealID,
            PaymentAmount: paymentAmount
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/GetAmountInBaseCurrency',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getBalanceDueByDealID(dealID){
    try{
        let params = {
            DealID: dealID
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/GetBalanceDueByDealID',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID){
    try {
        let params = {
            'CustomerID': CustomerID,
            'CurrencyID': CurrencyID
        }

        let response = await genericGetWithParameters('Funds/GetCustomerFundOnHoldBalanceByCurrencyJson',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createIncomingFund(model) {
    let method = 'POST';
    let apiName = 'Funds/CreateIncomingFund';

    try {
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidIncomingFund(incomingFundsID, userID, incomingUpdateToken, paymentUpdateToken, dealUpdateToken){
    try {
        let params = {
            'incomingFundsID': incomingFundsID,
            'userID': userID,
            'incomingUpdateToken': incomingUpdateToken,
            'paymentUpdateToken': paymentUpdateToken,
            'dealUpdateToken': dealUpdateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Funds/VoidIncomingFund',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createOutgoingFund(objOutgoingFund) {
    let method = 'POST';
    let apiName = 'Funds/CreateOutgoingFund';
    try {
        let response = await genericCallWithBody(method, apiName, objOutgoingFund);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRefunds(sourceTable, sourceID){
    try {
        let params = {
            'sourceTable': sourceTable,
            'sourceID': sourceID,
        }
        let response = await genericGetWithParameters('Funds/GetRefunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidRefund(outgoingFundsID, userID, updateToken){
    try {
        let params = {
            'outgoingFundsID': outgoingFundsID,
            'userID': userID,
            'updateToken': updateToken,
        }
        let response = await genericGetWithParametersNoEncrypted('Funds/VoidRefund',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}