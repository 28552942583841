import React from 'react';

import AlertBox from '../../../shared/AlertBox';
import { getStyle, notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import NumberInput from '../../../shared/NumberInput';
import { getActiveTradersMin } from '../../../../networking/NetworkingTrader';
import { getLookUpTable, getLookUpTableByFilter } from '../../../../networking/Networking';
import DatePicker from '../../../shared/DatePicker';
import { formatDate } from '../../../../helpers/FormatHelper';
import { getCustomerByID } from '../../../../networking/NetworkingCustomers';
var style = getStyle();

export default class BeneficiaryInformationForm extends React.Component {
    
    state = {
        beneficiaries:{
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        customerName:'',
        salutation:'',
        firstName:'',
        lastName:'',
        middleName:'',
        shortName:'',
        customerType:'',
        companyName:'',
        contactPhone: '',
        contactEmail:'',
        contactName:'',
        customerIndustrySect:'',
        industrySector:'',
        paymentReference:'',
        emailIndicator:'',
        beneficiaryID:'',
        updateToken:0},
        showRiskCalculation: false,
        dropdowns: {
            traders: [],
            "Salutation":[],
            "Beneficiary Type": [],
            "Industry Sectors": [],
            "Outgoing Instruments":[],
            "Beneficiary Payment Flow":[],
            "Beneficiary Email Indicator":[]
        },
        lookUpTables: [
            "Salutation",
            "Beneficiary Type",
            "Industry Sectors",
            "Outgoing Instruments",
            "Beneficiary Payment Flow",
            "Beneficiary Email Indicator"
        ]
    }

    componentDidMount(){
       getActiveTradersMin().then(
            (json) => {
                for (var i = 0; i < json.traders.length; i++) {
                    json.traders[i].name = json.traders[i].Trader;
                    json.traders[i].value = json.traders[i].TraderID;
                }
                this.setState({dropdowns: {...this.state.dropdowns, traders: json.traders}});
            }
        );
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        });
        getCustomerByID(this.props.objNewBeneKey.CustomerId).then(
            (value) => {
                this.setState({beneficiaries:{...this.state.beneficiaries,customerName: value.Name}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
            }
        );
    }

    componentDidUpdate(prevProps){
        if(prevProps.notFirstLoad !== this.props.notFirstLoad) {
            this.setState({beneficiaries:{...this.state.beneficiaries,notFirstLoad: this.props.notFirstLoad}});
        }
    }
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }

    toggleShowRiskCalculation() {
        this.setState({showRiskCalculation: !this.state.showRiskCalculation});
    }

    handleUpdateCustomerType(value){
        if(value!==undefined){
            this.setState({
                beneficiaries:{...this.state.beneficiaries,customerType: value.value}
            },
            ()=> {
                if (this.isCustomerType()) {
                    if(value.value==='Personal'&& this.state.beneficiaries.firstName!==''&& this.state.beneficiaries.lastName!==''){
                        this.props.objFilterBeneInfo(this.state.beneficiaries)
                        this.props.isValid(true);
                    }else if(value.value==='Corporate' && this.state.beneficiaries.companyName!==''){
                        this.props.objFilterBeneInfo(this.state.beneficiaries)
                        this.props.isValid(true);
                    }else{this.props.isValid(false);}
                }else{
                    this.props.isValid(false);
                }});
                }else{
                    this.setState({
                        beneficiaries:{...this.state.beneficiaries,customerType: ''}
                    },()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
                }
    }
    handleUpdateCompanyName(event){
        if(this.state.beneficiaries.customerType==='Corporate'){
            this.setState({beneficiaries:{...this.state.beneficiaries,companyName: event.target.value}},
            ()=> {if (this.isCompanyName()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries)
                this.props.isValid(true);
            }else{
                this.props.isValid(false);
            }});
        }else{this.props.isValid(true);}
    }
    handleUpdateShortName(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,shortName: event.target.value}},
            ()=> {if (this.validateRequiredInputs()) {
                this.props.objFilterBeneInfo(this.state.beneficiaries)
                this.props.isValid(true);
            }else{
                this.props.isValid(false);
            }});
    }
    handleUpdatePreferredDeliveryMet(value){
        this.setState({
            beneficiaries:{...this.state.beneficiaries,preferredDeliveryMet: value.value}
        },
        ()=> {if (this.isPreferredDeliveryMet()) {
            this.props.objFilterBeneInfo(this.state.beneficiaries)
            this.props.isValid(true);
        }else{
            this.props.isValid(false);
        }});
    }
    handleUpdateContactPhone(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,contactPhone: event.target.value}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateContactEmail(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,contactEmail: event.target.value}},
            ()=> {
                if (this.validateEmail()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(true);
                } else {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.isEmailValid(false);
                }
            });
    }
    isEmailValid(validator){
        if(validator){
            this.setState({beneficiaries:{...this.state.beneficiaries,isEmailValid: true}},()=>this.props.objFilterBeneInfo(this.state.beneficiaries));
        }else{
            this.setState({beneficiaries:{...this.state.beneficiaries,isEmailValid: false}},()=>this.props.objFilterBeneInfo(this.state.beneficiaries));
        } 
    }    
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.beneficiaries.contactEmail.match(regex)) {
            return false;
        }
        return true;
    }
    handleUpdateContactName(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,contactName: event.target.value}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateCustomerIndustrySect(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,customerIndustrySect: event.target.value}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateIndustrySector(value){
        if(value!==undefined){
            this.setState({
                beneficiaries:{...this.state.beneficiaries, industrySector: value.value}
            },()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
        }else{
            this.setState({
                beneficiaries:{...this.state.beneficiaries, industrySector: ''}
            },()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
        
    }
    handleUpdatePaymentReference(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,paymentReference: event.target.value}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateEmailIndicator(value){
        this.setState({
            beneficiaries:{...this.state.beneficiaries,emailIndicator: value.value}
        },
        ()=> {if (this.isEmailIndicator()) {
            this.props.objFilterBeneInfo(this.state.beneficiaries)
            this.props.isValid(true);
        }else{
            this.props.isValid(false);
        }});
    }
    handleUpdateFirstName(event){
        if(this.state.beneficiaries.customerType==='Personal'){
            this.setState({beneficiaries:{...this.state.beneficiaries,firstName: event.target.value}},
                ()=> {if (this.validateRequiredInputs()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.props.isValid(true);
                }else{
                    this.props.isValid(false);
                }});
        }else{
            this.props.isValid(true);
        }
    }
    handleUpdateLastName(event){
        if(this.state.beneficiaries.customerType==='Personal'){
            this.setState({beneficiaries:{...this.state.beneficiaries,lastName: event.target.value}},
                ()=> {if (this.validateRequiredInputs()) {
                    this.props.objFilterBeneInfo(this.state.beneficiaries)
                    this.props.isValid(true);
                }else{
                    this.props.isValid(false);
                }});
            }else{
                this.props.isValid(true);
            }
    }
    handleUpdateMiddleName(event){
        this.setState({beneficiaries:{...this.state.beneficiaries,middleName: event.target.value}},()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
    }
    handleUpdateSalutation(value){
        if(value!==undefined){
            this.setState({
                beneficiaries:{...this.state.beneficiaries,salutation: value.value}
            },()=> this.props.objFilterBeneInfo(this.state.beneficiaries));
        }
    }
    
    //Inputs Validations
    validateRequiredInputs(){
        if(this.state.beneficiaries.customerName === ''){
            return false;
        }
        if(this.state.beneficiaries.companyName === ''){
           return false;
        }
        if(this.state.beneficiaries.shortName === ''){
           return false;
        }
        if(this.state.beneficiaries.customerType === null ||this.state.beneficiaries.customerType === "select" || this.state.dropdowns["Beneficiary Type"].find(e => e.value === this.state.beneficiaries.customerType) === undefined){
            return false;
        }if(this.state.beneficiaries.firstName === ''){
            return false;
        }
        if(this.state.beneficiaries.lastName === ''){
            return false;
        }
        if(this.state.beneficiaries.emailIndicator === null ||this.state.beneficiaries.emailIndicator === "select" || this.state.dropdowns["Beneficiary Email Indicator"].find(e => e.value === this.state.beneficiaries.emailIndicator) === undefined){
            return false;
        }
        return true;
    }
    isCustomerType(){
        if(this.state.beneficiaries.customerType === null ||this.state.beneficiaries.customerType === "select" || this.state.dropdowns["Beneficiary Type"].find(e => e.value === this.state.beneficiaries.customerType) === undefined){
            return false;
        }else{return true};
    }
    isEmailIndicator(){
        if(this.state.beneficiaries.emailIndicator === null ||this.state.beneficiaries.emailIndicator === "select" || this.state.dropdowns["Beneficiary Email Indicator"].find(e => e.value === this.state.beneficiaries.emailIndicator) === undefined){
            return false;
        }else{return true};
    }
    isCompanyName(){
        if(this.state.beneficiaries.companyName === null ||this.state.beneficiaries.companyName === ''){
            return false;
        }else{return true};
    }
    render() {
        //for required inputs
        var classNameInputCustomerName = regularInputClass;
        var classNameInputCompanyName = regularInputClass;
        var classNameInputShortName = regularInputClass;
        var classNameInputContactPhone = regularInputClass;
        var classNameInputContactEmail = regularInputClass;
        var classNameInputContactName = regularInputClass;
        var classNameInputCustomerIndustrySect = regularInputClass;
        var classNameInputPaymentReference = regularInputClass;
        var classNameInputFirstName = regularInputClass;
        var classNameInputLastName = regularInputClass;
        var classNameInputSalutation = regularSelectClass;
        var classNameInputMiddleName = regularInputClass;
        var classNameInputCustomerType = regularSelectClass;
        var classNameInputIndustrySector = regularSelectClass;
        var classNameInputEmailIndicator = regularSelectClass;
        
        if((this.state.beneficiaries.firstName === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputFirstName=notFilledSelectClass;
        }
        if((this.state.beneficiaries.lastName === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputLastName = notFilledInputClass;
        }
        if((this.state.beneficiaries.customerName === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputCustomerName=notFilledSelectClass;
        }
        if((this.state.beneficiaries.companyName === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputCompanyName = notFilledInputClass;
        }
        if((this.state.beneficiaries.shortName === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputShortName = notFilledInputClass;
        }
        if((this.state.beneficiaries.customerType === "select" ||this.state.beneficiaries.customerType === undefined||this.state.beneficiaries.customerType === '' )&& this.state.beneficiaries.notFirstLoad){
            classNameInputCustomerType = notFilledSelectClass;
        }if((this.state.beneficiaries.emailIndicator === "select" ||this.state.beneficiaries.emailIndicator === undefined||this.state.beneficiaries.emailIndicator === '') && this.state.beneficiaries.notFirstLoad){
            classNameInputEmailIndicator = notFilledSelectClass;
        }

        var customerIndustrySect = ''
        if(this.state.beneficiaries.industrySector!==''){
            customerIndustrySect = this.state.beneficiaries.industrySector
        }else{
            customerIndustrySect = 'No Select'
        }

        var divPersonal1 = ('');
        var divPersonal2 = ('');
        var divPersonal3 = ('');
        var divCorporate1 = ('');
        var divCorporate2 = ('');
        //--VALIDATIONS VISIBLE
        switch (this.state.beneficiaries.customerType) {
            case 'Personal':
                divPersonal1 = (
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-beneficiary-salutation">Salutation</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={classNameInputSalutation} objValue={{value: this.state.beneficiaries.salutation}} getValue={this.handleUpdateSalutation.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Salutation"]} id="form-beneficiary-salutation" />
                        </div>
                    </div>
                );
                divPersonal2 =(
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" htmlFor="form-beneficiary-firstName">First Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={classNameInputFirstName} id="firstName" type="text" value={this.state.beneficiaries.firstName} onChange={this.handleUpdateFirstName.bind(this)}/>
                            </div>
                        </div>
                        );
                divPersonal3 = (
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" htmlFor="form-beneficiary-lastName">Last Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={classNameInputLastName} id="lastName" type="text" value={this.state.beneficiaries.lastName} onChange={this.handleUpdateLastName.bind(this)}/>
                            </div>
                        </div>
                );
                break;
            case 'Corporate':
                    divCorporate1 = (
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Company Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={classNameInputCompanyName} id="companyName" type="text" value={this.state.beneficiaries.companyName} onChange={this.handleUpdateCompanyName.bind(this)}/>
                            </div>
                        </div>
                    );
                    divCorporate2 = (
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" htmlFor="form-beneficiary-contactName">Contact Name</label>
                            <div className="uk-form-controls">
                                <input className={classNameInputContactName} id="contactName" type="text" value={this.state.beneficiaries.contactName} onChange={this.handleUpdateContactName.bind(this)}/>
                            </div>
                        </div>
                    )
                    break;
        }

        return (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-customerName">Customer(*)</label>
                    <div className="uk-form-controls">
                        <label id="customerName">{this.state.beneficiaries.customerName}</label>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-customerType">Type(*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className={classNameInputCustomerType} objValue={{value: this.state.beneficiaries.customerType}} getValue={this.handleUpdateCustomerType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Beneficiary Type"]} id="form-beneficiary-type"/>
                    </div>
                </div>
                {/*TYPE ==> PERSONAL / INDIVIDUAL */}
                {divPersonal2}
                {divPersonal1}
                {divPersonal3}
                {/*TYPE ==> CORPORATE */}
                {divCorporate1}
                {/*---TAMBIEN VA EN PERSONAL/INDIVIDUAL ESTE INPUT DE SHORT NAME-- */}
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-shortName">Short Name(*)</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputShortName} id="shortName" type="text" value={this.state.beneficiaries.shortName} onChange={this.handleUpdateShortName.bind(this)}/>
                    </div>
                </div>
                {/*----- */}
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-contactPhone">Contact Phone</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputContactPhone} id="contactPhone" type="text" value={this.state.beneficiaries.contactPhone} onChange={this.handleUpdateContactPhone.bind(this)}/>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-contactEmail">Contact Email</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputContactEmail} id="contactEmail" type="text" value={this.state.beneficiaries.contactEmail} onChange={this.handleUpdateContactEmail.bind(this)}/>
                    </div>
                </div>
                {divCorporate2}

                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-customerIndustrySect">Customer Industry Sector</label>
                    <div className="uk-form-controls">
                        <label id="customerIndustrySect">{customerIndustrySect}</label>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-industrySector">Industry Sector</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className={classNameInputIndustrySector} objValue={{value: this.state.beneficiaries.industrySector}} getValue={this.handleUpdateIndustrySector.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Industry Sectors"]} id="form-beneficiary-industrySector" />
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-paymentReference">Payment Reference</label>
                    <div className="uk-form-controls">
                        <input className={classNameInputPaymentReference} id="paymentReference" type="text" value={this.state.beneficiaries.paymentReference} onChange={this.handleUpdatePaymentReference.bind(this)}/>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-beneficiary-emailIndicator">Email Indicator(*)</label>
                    <div className="uk-form-controls">
                        <DynamicSelect className={classNameInputEmailIndicator} objValue={{value: this.state.beneficiaries.emailIndicator}} getValue={this.handleUpdateEmailIndicator.bind(this)} listHasPlaceholder={false} placeholder={"Select an email..."} data={this.state.dropdowns["Beneficiary Email Indicator"]} id="form-beneficiary-emailIndicator" />
                    </div>
                </div>
                <AlertBox open={this.state.beneficiaries.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.beneficiaries.alertTitle} message={this.state.beneficiaries.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </form>
        );
    }
}