import React from 'react';
import NumberInput from '../../shared/NumberInput';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';
import Paginator from '../../shared/Paginator';

import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'

import { getLookUpTable } from '../../../networking/Networking';
import { getOutstandingForwardsMin } from '../../../networking/NetworkingDeals';
import {getCurrenciesByStatus} from '../../../networking/NetworkingCurrencies';
import {getDepositsByCustomer, saveDeposit, getDepositByID, getIncomingFundsForDeposit} from '../../../networking/NetworkingDeposits';
import {getParametersByName} from '../../../networking/NetworkingGlobalSystemParameters'
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';
import {getCustomerByID} from '../../../networking/NetworkingCustomers'

import {getStyle, notFilledInputClass, regularInputClass, regularSelectClass, notFilledSelectClass} from '../../../styles/styles';
import RefundForm from '../RefundForm';
var style = getStyle();

export default class DepositInfoScreen extends React.Component {

    state={
        customerID : 0,
        customerName : '',
        dealHeaderID : 0,
        depositID : 0,
        amount : 0,
        currencyID: '',
        currencies : [],
        amountReceived : 0,
        receivedStatus : '',
        disbursedStatus : '',
        depositType : 'MarginCall',
        forwardList : [],
        createdOn : '',
        percentage : 0,
        issuedOn : '',
        listCustomers : [],
        listDeposits : [],
        listFunding : [],
        newSearch: false,
        newSearchFunding: false,
        notFirstLoad: false,
        showAlert : false,
        updateToken : '',
        showZero : false,
        loading: false,
        currentPage : 1,
        currentPageFunding : 1,
        totalPages: 0,
        totalPagesFunding: 0,
        listReceivedStatus : [],
        listDisbursedStatus : [],
        "Deposit Received Status" : [],
        "Deposit Disbursed Status" : [],
        lookUpTables: [
            "Deposit Received Status",
            "Deposit Disbursed Status"],
        refundMode: false,
    }
   
    componentDidMount()
    {        
        if(this.props.objCustomer !== undefined)
        {
            this.setState({customerID : this.props.objCustomer["Customer ID"]});
            
            if(this.props.objCustomer.DefaultDepositPercent$ !== undefined)
            {
                this.setState({percentage : this.props.objCustomer.DefaultDepositPercent$});
            } 
            this.getDepositsByCustomerID(this.props.objCustomer["Customer ID"], false);
        }

        if(this.props.currencies === undefined)
        {
            getCurrenciesByStatus("Active").then(
                (json) => {
                    if (json.currencies !== undefined) {

                        json.currencies.forEach(element => {
                            element.value = element.CurrencyID;
                            element.name = element.Description;
                        });
                        this.setState({
                            currencies: json.currencies
                        });
                    }                
                }
            );
        }
        else
        {
           this.setState({currencies: this.props.currencies}); 
        }

        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
                this.setState({receivedStatus : 'Outstanding',
                disbursedStatus : 'Outstanding'});

                if(this.props.data !== undefined)
                {
                    this.getDepositByID(this.props.data);
                }
        });
        
    }

    getDepositsByCustomerID(customerID, showZero){
        alert('getDepositsByCustomerID');
        if(customerID !== undefined && customerID > 0)
        {
            this.setState({loading: true});
            getDepositsByCustomer(customerID,showZero, this.state.currentPage,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if(value !== undefined && value.totalCount > 0)
                    {
                        var deposits = this.transformDepositList(value.result);
                        this.setState({listDeposits: deposits});
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                        this.setState({totalPages: pages, newSearch:true, loading:false});
                    }
                    else{
                        this.setState({listDeposits: [], newSearch:true, loading:false});
                    }
                }
            );
        }
    }

    transformDepositList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Deposit ID': list[i]['DepositID'],
            'Amount': list[i]['Amount'],
            'Amount Received': list[i]['AmountReceived'], 
            'Refunded Amount': list[i]['RefundedAmount'], 
            'Currency': list[i]['CurrencyID'],
            'Outgoing Balance': list[i]['OutgoingBalance'],
            'Received Status': list[i]['ReceivedStatus'],
            'Disbursed Status': list[i]['DisbursedStatus'],
        
        });
        }
        return helper;
    }

    getDepositByID(depositID)
    {
        this.setState({loading: true,
                        currentPageDeals : 1});
        if(depositID !== undefined && depositID !== 0)
        {
            getDepositByID(depositID).then(
                (json) =>
                {
                    if(json !== undefined)
                    {
                        this.setState({
                            depositID: json.DepositID,
                            customerID : json.CustomerID,
                            dealHeaderID : json.DealHeaderID,
                            amount : json.Amount,
                            amountReceived : json.AmountReceived,
                            disbursedAmount : json.DisbursedAmount,
                            refundedAmount : json.RefundedAmount,
                            currencyID : json.CurrencyID,
                            receivedStatus: json.ReceivedStatus,
                            disbursedStatus: json.DisbursedStatus,
                            depositType: json.DepositType,
                            createdOn : json.CreatedOn,
                            issuedOn: json.IssueDate,
                            updateToken : json.UpdateToken
                        });

                        this.loadForwards(json.dealHeaderID, json.CustomerID);
                        this.getIncomingFundsForDeposit(depositID);
                        if(this.props.data !== undefined)
                        {
                            getCustomerByID(json.CustomerID).then(
                                (c) => {
                                    if(c !== undefined && c.CustomerID !== undefined)
                                    {
                                        this.setState({
                                            customerName : c.Name,
                                            percentage : c.DefaultDepositPercent
                                        });
                                    }
                                }
                            );
                        }
                    }
                }
            )
        }
        this.setState({loading: false});
    }

    handleRowClick = (obj)=>
    {
        if(obj !== undefined)
        {
            this.getDepositByID(obj["Deposit ID"]);
            if(this.props.onDepositSelected !== undefined){
                this.props.onDepositSelected(obj, 4);
            }
        }
    }

    changePage = (value) =>
    {
        this.setState({currentPage: value},() =>
        this.getDepositsByCustomerID(this.state.customerID, this.state.showZero));
    }

    handleFundingRowClick = (obj)=>
    {
        
    }

    getIncomingFundsForDeposit(depositID){
        if(depositID !== undefined && depositID > 0)
        {
            getIncomingFundsForDeposit(depositID, this.state.currentPageFunding, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if(value !== undefined && value.totalCount > 0)
                    {
                        var deals = this.transformFundList(value.result);
                        this.setState({listFunding: deals});
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                        this.setState({totalPagesFunding: pages, newSearchFunding:true});
                    }
                    else{
                        this.setState({listFunding: [], newSearchFunding:true});
                    }
                }
            );
        }
    }

    transformFundList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Payment ID': list[i]['PaymentID'],
            'Forward Deal ID': list[i]['ForwardDealID'],
            'Instrument': list[i]['Instrument'],
            'Date': list[i]['Date'], 
            'Amount': list[i]['Amount'], 
            'Audit': list[i]['Audit']        
        });
        }
        return helper;
    }

    changePageFunding = (value) =>
    {
        this.setState({currentPageFunding: value});
    }

    handleUpdateshowZero = (event) =>
    {
        this.setState({showZero: event.target.checked})
        this.getDepositsByCustomerID(this.state.customerID, event.target.checked);
    }

    handleBackLink = ()=> {
        this.props.backLink();
    }

    handleUpdatecurrencyID = (value) =>
    {
        if(value !== undefined){
            this.setState({currencyID: value.CurrencyID});
        }        
    }

    handleUpdateFordward = (value) =>
    {
        if(value !== undefined){
            this.setState({dealHeaderID: value.LookUpTableDetail});
        }        
    }
    
    getAmount = (event) =>{
        this.setState({amount: event.target.value});
    }

    getAmountReceived = (event) =>{
        this.setState({amountReceived: event.target.value});
    }
    
    getReceivedStatusSelect = (value) =>{
        this.setState({receivedStatus: value.LookUpTableDetail});
    }

    getDisbursedStatusSelect = (value) =>{
        this.setState({disbursedStatus: value.LookUpTableDetail});
    }

    handleSaveClick = () =>
    {
        this.setState({loading : true});
        if(this.validateRequiredInputs()){
            var valid = false;
            getParametersByName(GlobalSystemParametersNames.SystemDate).then((value) =>
            {
                if(value.parameters !== undefined){
                    
                    let systemDate = sessionStorage.getItem(GlobalSystemParametersNames.SystemDate);
                    if(value.parameters[0].Value <= systemDate)
                    {
                        valid = true;
                    }
                }
            
                if(valid)
                {
                    let userID = sessionStorage.getItem('UserID');
                    var model = {
                        DepositID : this.state.depositID,
                        CustomerID : this.state.customerID,
                        DealHeaderID : this.state.dealHeaderID,
                        Amount : this.state.amount,
                        AmountReceived : this.state.amountReceived,
                        DisbursedAmount : this.state.disbursedAmount,
                        RefundedAmount : this.state.refundedAmount,
                        CurrencyID : this.state.currencyID,
                        ReceivedStatus : this.state.receivedStatus,
                        DisbursedStatus : this.state.disbursedStatus,
                        DepositType : this.state.depositType,
                        UpdatedBy : userID
                    }

                    saveDeposit(model).then((json)=>
                    {
                        if (json.httpStatusCode !== 200){
                            this.setState({showAlert: true, 
                                alertMessage: 'An error occurred while trying to save the deposit. Please try again.', alertTitle: 'Error'});
                        } else {
                            this.setState({updateToken: json.updateToken, depositID: json.depositID, 
                                showAlert: true, 
                                alertTitle: 'Success',
                                alertMessage: 'Deposit successfully saved.'});
                            this.changePage(this.state.currentPage);
                        }
                    });
                
                }

            });
            } 
            else {
                this.setState({notFirstLoad: true});
            }
        this.setState({loading : false});
    }

    validateRequiredInputs(){
        
        if(this.state.currencyID === undefined){
            return false;
        }
        if(this.state.amount === '' || this.state.amount === 0){
            return false;
        }
        if(this.state.amountReceived === ''){
            return false;
        }

        return true;
    }

    handleClearClick = () => 
    {
        this.setState({
            depositID: 0,
            dealHeaderID : 0,
            amount : 0,
            amountReceived : 0,
            disbursedAmount : 0,
            refundedAmount : 0,
            currencyID : '',
            receivedStatus: 'Outstanding',
            disbursedStatus: 'Outstanding',
            depositType: 'MarginCall',
            updateToken : '',
            createdOn: '',
            issuedOn: '',
            forwardList: [],
            listDeals: []
        });
    }

    handleRefundClick = () => {
        this.setState({refundMode: true});
    }

    closeAlert =() =>{
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    loadForwards(dealHeaderID, customerID)
    {
        getOutstandingForwardsMin(dealHeaderID, customerID).then(
            (json) =>
            {
                if(json !== undefined && json.forwards !== undefined)
                {
                    var helper = [];
                    for(var i=0; i<json.length; i++){
                        json[i].value = json[i].DealHeaderID;
                        json[i].name = json[i].Description;
                        helper.push(json[i]);
                        //helper.push({value: json[i].CountryId, name: json[i].CountryIdName})
                    }
                    this.setState({forwardList: helper});                    
                }
                else
                {
                    this.setState({forwardList: []});   
                }
            }
        );
    }

    onRefundBackClick = () => {
        this.getDepositByID(this.state.depositID);
        this.setState({refundMode: false});
    }

    render()
    {
        var backButton;
        var customer = "";

        if(this.props.data !== undefined)
        { 
            backButton = (<i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'}} onClick={this.handleBackLink}></i>);
            customer = <h4>Customer: {this.state.customerID} - {this.state.customerName}</h4>        
        }

        var classNameAmount = regularInputClass;
        var classNameAmountReceived = regularInputClass; 
        var classNameCurrency = regularSelectClass;

        if((this.state.currencyID === undefined || this.state.currencyID === '') && this.state.notFirstLoad){
            classNameCurrency = notFilledSelectClass;
        }

        if((this.state.amount === '' || this.state.amount === 0) && this.state.notFirstLoad){
            classNameAmount = notFilledInputClass;
        }
        if(this.state.amountReceived === '' && this.state.notFirstLoad){
            classNameAmountReceived = notFilledInputClass;
        }

        //Refund button
        var refundButton;
        if(this.state.amountReceived > 0){
            refundButton = (<button className="uk-button uk-button-green" type="button" onClick={this.handleRefundClick}>Refunds</button>);
        }

        //Disabled controls
        var amountControlDisabled = false, currencyControlDisabled = false;
        if(this.state.amountReceived > 0){
            amountControlDisabled = currencyControlDisabled = true;
        }

        var currentScreen =
        (            
        <div>
            {backButton}
            <h3 className="uk-heading-divider" style={style.titleStyle}>Deposit Info</h3>
            <div>
                <div>
                    {customer}
                </div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-amount">Amount *</label>
                            <div className="uk-form-controls">
                                <NumberInput className={classNameAmount} placeholder="Amount" id="form-amount" type="Currency" value={this.state.amount} onChange={this.getAmount} disabled={amountControlDisabled}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-deposit-currency">Currency</label>
                            <div className="uk-form-controls">
                            <DynamicSelect           
                                className={classNameCurrency}                     
                                placeholder="Select a currency..."
                                data={this.state.currencies}
                                objValue={{value:this.state.currencyID}}
                                getValue={this.handleUpdatecurrencyID}
                                disabled={currencyControlDisabled}/>
                            </div>
                    </div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-amount-received">Amount Received </label>
                            <div className="uk-form-controls">
                                <NumberInput className={classNameAmountReceived} disabled={true} placeholder="Amount Received" id="form-amount-received" type="Currency"  value={this.state.amountReceived} onChange={this.getAmountReceived}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2"></div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-total-refunded">Total Refunded </label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" disabled={true} placeholder="Total Refunded" id="form-total-refunded" type="Currency"  value={this.state.refundedAmount}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-available-balance">Available Balance</label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" disabled={true} placeholder="Available Balance" id="form-available-balance" type="Currency" value={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-received-status">Received Status</label>
                            <div className="uk-form-controls">
                            <DynamicSelect getValue={this.getReceivedStatusSelect} disabled={true} listHasPlaceholder={true} data={this.state["Deposit Received Status"]} id="form-received-status" objValue={{value: this.state.receivedStatus, LookUpTableDetail: this.state.receivedStatus}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-disbursed-status">Disbursed Status</label>
                            <div className="uk-form-controls">
                            <DynamicSelect getValue={this.getDisbursedStatusSelect} disabled={true} listHasPlaceholder={true} data={this.state["Deposit Disbursed Status"]} id="form-disbursed-status" objValue={{value: this.state.disbursedStatus, LookUpTableDetail: this.state.disbursedStatus}}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-1 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-forward">Forward</label>
                            <div className="uk-form-controls">
                            <DynamicSelect                                
                                placeholder="Select a Forward..."
                                data={this.state.forwardList}
                                objValue={{value:this.state.dealHeaderID}}
                                getValue={this.handleUpdateFordward}/>
                            </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-created-on">Created On  {this.state.createdOn}</label>                           
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-disbursed-status">Customer Deposit Percentage  {this.state.percentage} %</label>                           
                        </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-issued-on">Issued On  {this.state.issuedOn}</label>                           
                        </div>
                    </div>

                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick}>Save</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick}>Clear</button>
                        {refundButton}
                    </div>

                    <div className="uk-width-1-1">
                        <ul uk-tab="">
                            <li><a href="#">Deposit Funding</a></li>
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            <li>
                            <div>
                                <NewDynamicTable id="ndt-deposit-funding" data={this.state.listFunding} enableClick={true} 
                                clickFunction={this.handleFundingRowClick} />
                                <Paginator id="ndt-deposit-funding-paginator" 
                                    changePage={this.changePageFunding} 
                                    numberOfPages={this.state.totalPagesFunding} 
                                    newSearch={this.state.newSearchFunding}/>                                
                            </div>
                            </li>
                        </ul>

                        <ul uk-tab="">
                            <li><a href="#">Deposits</a></li>
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            <li>
                            <div>
                                <label>
                                    <input className="uk-checkbox" value={this.state.showZero} checked={this.state.showZero} onChange={this.handleUpdateshowZero} type="checkbox"/>Show fully applied deposits
                                </label>
                            </div>
                            <div>
                                <NewDynamicTable id="ndt-deposits" data={this.state.listDeposits} 
                                enableClick={true} clickFunction={this.handleRowClick} 
                                 />
                                <Paginator id="ndt-deposits-paginator" 
                                    changePage={this.changePage} 
                                    numberOfPages={this.state.totalPages} 
                                    newSearch={this.state.newSearch}/> 
                                
                            </div>
                            </li>
                        </ul>
                        
                        <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                        <LoadingBox loading={this.state.loading}/>
                    </div>
                </form>
            </div>
        </div>
        );

        if(this.state.refundMode){
            currentScreen = (<RefundForm sourceTable="Deposit"
                sourceID={this.state.depositID}
                customerID={this.state.customerID}
                balance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                currencyID={this.state.currencyID}
                onBackClick={this.onRefundBackClick}/>);
        }

        return currentScreen;
    }
}