import React from 'react';
import { getStyle, regularInputClass, regularSelectClass, notFilledInputClass, notFilledSelectClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getCurrencyList, searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import { getListOfBanks } from '../../../../networking/NetworkingBanks';
import { getBranchesByBankID } from '../../../../networking/NetworkingBankBranches';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
var style = getStyle();

export default class LocalAccountForm extends React.Component {

    state = {
        localAccount: {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: ''
        },
        dropdowns: {
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: []
        },
        validations: {
            bank: true,
            accountNo: true,
            currency: true
        }
    }

    componentDidMount() {
        getListOfBanks(false).then( // SWIFT
            (json) => {
                for (var i = 0; i< json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                this.setState({dropdowns: {...this.state.dropdowns, banksSwift: json.banks}});
            }
        )

        getListOfBanks(true).then( // BANKS
            (json) => {
                for (var i = 0; i< json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                this.setState({dropdowns: {...this.state.dropdowns, banks: json.banks}});
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validated !== this.props.validated && !this.props.validated ) {
            this.validateRequiredFields();
        }
    }

    getBranches(bankID) {
        getBranchesByBankID(bankID, 1, 50).then(
            (json) => {
                if (json !== undefined) {
                    if (json.branches !== undefined) {
                        for (var i = 0; i< json.branches.length; i++) {
                            json.branches[i].value = json.branches[i]["Branch ID"];
                            json.branches[i].name = json.branches[i].Branch;
                        }
                        this.setState({dropdowns: {...this.state.dropdowns, branches: json.branches}});
                    }
                }
            }
        )
    }

    validateRequiredFields() {
        var currency = true, bankID =  true, accountNo = true;
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            currency = false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            bankID = false;
        }
        if (this.state.localAccount.AccountNo === '') {
            accountNo = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID,
                accountNo: accountNo,
                currency: currency
            }
        })
    }

    validateRequiredInputs() {
        if (this.state.localAccount.Swift === "select" || this.state.localAccount.Swift === '' || this.state.localAccount.Swift === undefined) {
            return false;
        }
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    validateBank() {
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        return true;
    }

    validateCurrency() {
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        return true;
    }

    validateAccountNo() {
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setState({
                localAccount: {...this.state.localAccount, Currency: value.value, CurrencyName: value.name},
                validations: { ...this.state.validations, currency: true}
            },
                () => {
                    if (this.validateCurrency()) {
                        this.props.getLocalAccount(this.state.localAccount);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(false);
                    }
                }
            );
        }
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                this.setState({
                    localAccount: {...this.state.localAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name},
                    validations: { ...this.state.validations, bank: true}
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            } else {
                this.setState({
                    localAccount: {...this.state.localAccount, Swift: value.name, BankID: value.BankID},
                    validations: { ...this.state.validations, bank: true}
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: {...this.state.localAccount, Swift: "", BankID: "", BankName: ""},
                validations: { ...this.state.validations, bank: true}
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                localAccount: {...this.state.localAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "": value.SWIFT},
                dropdowns: {...this.state.dropdowns, branches: []},
                validations: { ...this.state.validations, bank: true}
            }, () => {
                if (this.validateBank()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(false);
                }
                if (value.value !== '' && value.value !== "select") {
                    this.getBranches(this.state.localAccount.BankID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: {...this.state.localAccount, BankID: "", BankName: "", Swift: ""},
                validations: { ...this.state.validations, bank: true}
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined) {
            this.setState({
                localAccount: {...this.state.localAccount, BranchID: value.value}
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            localAccount: {...this.state.localAccount, AccountNo: event.target.value},
            validations: { ...this.state.validations, accountNo: true}
        },
            () => {
                if (this.validateAccountNo()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(false);
                }
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            localAccount: {...this.state.localAccount, Swift: event.target.value}
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            localAccount: {...this.state.localAccount, ABANo: event.target.value}
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            localAccount: {...this.state.localAccount, TransitNo: event.target.value}
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }
    search = (query) =>{
        searchCurrencies(query,'active').then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    if(json!==undefined){
                        for(var i=0; i<json.length; i++){
                            helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                        }
                        if(json.length>1){
                            this.setState({dropdowns: {...this.state.dropdowns,currencies: helper}},
                                () => {
                                    this.props.getLocalAccount(this.state.localAccount);
                                }
                            );
                        }else{
                            this.setState({dropdowns: {...this.state.dropdowns,currencies: helper}, localAccount:{...this.state.localAccount, Currency: helper[0].name}},
                                () => {
                                    this.props.getLocalAccount(this.state.localAccount);
                                }
                            );
                        }
                    }
            }}
        );
    }

    render() {        
        return(
            <div>
                <div className="">
                    <form className="uk-form-horizontal uk-margin-large">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">SWIFT</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect className="uk-select" objValue={{value: this.state.localAccount.BankID}} getValue={this.handleGetValueBankSwift} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banksSwift} id="form-banks-swift" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}>Bank (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect className={this.formatValidSelect(this.state.validations.bank)} objValue={{value: this.state.localAccount.BankID}} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Bank Branch</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect className="uk-select" objValue={{value: this.state.localAccount.BranchID}} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSearchSelect className={this.formatValidSelect(this.state.validations.currency)} id="form-currencies" objValue={{value: this.state.localAccount.Currency,name: this.state.localAccount.CurrencyName}} placeholder={"Select an Item..."} searchFunction={this.search.bind(this)} data={this.state.dropdowns.currencies} getValue={this.handleGetValueCurrency}/>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}>Account Number / IBAN (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className={this.formatValidInput(this.state.validations.accountNo)} type="text" placeholder="" value={this.state.localAccount.AccountNo} onChange={this.handleUpdateAccountNumber} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Routing Code / Transit No</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">SWIFT / BIC</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.localAccount.Swift} onChange={this.handleUpdateSwift} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Institution/ABA No.</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input className="uk-input" type="text" placeholder="" value={this.state.localAccount.ABANo} onChange={this.handleUpdateABANo} />
                                </div>
                            </div>
                            {
                                this.state.localAccount.CreatedBy !== "" && this.state.localAccount.CreatedBy !== undefined ? 
                                <div className="uk-width-1-2">
                                    <p>Created by {this.state.localAccount.CreatedBy} on {this.state.localAccount.CreatedOn}</p>
                                </div>
                                :""
                            }
                            <div className="uk-width-1-2">
                            </div>
                            {
                                this.state.localAccount.UpdatedBy !== "" && this.state.localAccount.UpdatedBy !== undefined ?
                                <div className="uk-width-1-2">
                                    <p>Updated by {this.state.localAccount.UpdatedBy} on {this.state.localAccount.UpdatedOn}</p>
                                </div>
                                :""
                            }
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}