import React from 'react';

import BusinessHoursScreen from './countryAddtionalSettings/BusinessHoursScreen';
import InstrumentsScreen from './countryAddtionalSettings/InstrumentsScreen';
import CurrenciesScrenn from './countryAddtionalSettings/CurrenciesScreen';

import {getStyle} from '../../../styles/styles'
var style = getStyle();

export default class CountryAdditionalSettingsScreen extends React.Component {
    handleBackLink = ()=> {
        this.props.backLink();
    }

    componentDidMount = () => {
        console.log(this.props.data);
    }

    render(){
        return(
            <div>
                <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-heading-divider" style={style.titleStyle} >
                        <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink}>
                            <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                        </button>
                        {this.props.data.Country + ' - ' + this.props.data['Country Name']}
                    </h3>
                    <ul uk-tab="">
                        <li><a href="#">Instruments</a></li>
                        <li><a href="#">Business Hours</a></li>
                        <li><a href="#">Currencies</a></li>
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li><InstrumentsScreen countryID={this.props.data.Country}/></li>
                        <li><BusinessHoursScreen countryID={this.props.data.Country}/></li>
                        <li><CurrenciesScrenn countryID={this.props.data.Country}/></li>
                    </ul>
                </div>
            </div>
        );
    }
}
