/* eslint-disable default-case */
import React from 'react';


import { getLookUpTable } from '../../../../networking/Networking';
import { getCurrenciesByCountry, saveCurrenciesByCountry, getCurrencyList } from '../../../../networking/NetworkingCurrencies';

import DynamicSelect from '../../../shared/DynamicSelect';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';

const textCanPayUs = 'Currencies the customer can pay us';
const textCanPurchaseFromUs = 'Currencies the customer can purchase from us';

export default class CurrenciesScreen extends React.Component {
    state = {
        loading: false,
        errorMessage: '',
        showErrorAlert: false,

        currencyTypeSelected        : '',

        //Currencies
        listOfCurrencyType          : [
            {value:'Settlement' , name:'Settlement'},
            {value:'Payment'    , name: 'Payment'}
          ],
         //Currencies
         selectedIDRowLeftCurrency   : '',   //Seleccionado de lista Izquierda
         selectedIDRowRightCurrency  : '',   //Seleccionado de lista Derecha
         currencyListHaveShow        : [],   
         currencyListHaveSettlement  : [],
         currencyListHavePayment     : [],
         currencyListWantShow        : [],
         currencyListWantSettlement  : [],
         currencyListWantPayment     : [],
    }

    componentDidMount = () => {

        this.setState({
            textToShowNextToSelect : textCanPayUs,
            currencyTypeSelected : 'Settlement',
        })

        //Para obtener los [Currencies] del tipo [Settlement] y [Payment]
        getCurrenciesByCountry(this.props.countryID).then(
            (jsonResponseGetCurrenciesByCustomer) => {

                if(jsonResponseGetCurrenciesByCustomer.currencies!== undefined){
                    this.setState({
                        currencyListByCustomer: jsonResponseGetCurrenciesByCustomer.currencies
                    });

                    var listCurrenciesByCustomer = jsonResponseGetCurrenciesByCustomer.currencies

                    var listOfSettlement =  listCurrenciesByCustomer.filter(function(currency){
                        return currency.Type == "Settlement";
                    });

                    var listOfPayment =  listCurrenciesByCustomer.filter(function(currency){
                        return currency.Type == "Payment";
                    });
                    
                    this.setState({
                        currencyListWantSettlement : listOfSettlement,
                        currencyListWantShow: listOfSettlement,
                        currencyListWantPayment    : listOfPayment,
                    });
                }
            }
        );

        //Para obtener los Available [Currencies - Settlement]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Settlement"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Settlement"}
                var wantlistSettlement = this.state.currencyListWantSettlement

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistSettlement.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistSettlement[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHaveSettlement: currencies,
                });

            }
        );

        //Para obtener los Available [Currencies - Payment]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Payment"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Payment"}
                var wantlistPayment = this.state.currencyListWantPayment

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistPayment.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistPayment[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHavePayment: currencies,
                });

            }
        );
    }


    onchangeValueOfCurrencyType = (typeSelected) =>
    {
        if(typeSelected != undefined)
        {
            switch(typeSelected.value)
            {
                case 'Settlement':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPayUs,
                    });
                    break;
                case 'Payment':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPurchaseFromUs,
                    });
                    break;
                default:
                    break;
            }
            this.setState({
                currencyTypeSelected: typeSelected.value
            })
        }
    }

    // Currency
    currencyChangeColorLeft = selectedIDRowLeftCurrency => e => {
        if (selectedIDRowLeftCurrency !== undefined) {
          this.setState({ selectedIDRowLeftCurrency  });
        }
    };
    
    currencyChangeColorRight = selectedIDRowRightCurrency => e => {
        if (selectedIDRowRightCurrency !== undefined) {
          this.setState({ selectedIDRowRightCurrency  });
        }
    };

    //Currency - BUTTONS -------------------------------------------------------------
    currencyAddItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                {listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
                break;}
            case 'Payment': 
                {listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
                break;}
        }
        
        var idSelected = this.state.selectedIDRowLeftCurrency;
        var add = true
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((currency, index) => {
                    if(currency.CurrencyID == idSelected)
                    {
                        listRight.push(currency)
                        listLeft.splice(index,1)
                    }

                    switch(this.state.currencyTypeSelected)
                    {
                        case 'Settlement':
                            {this.setState({
                                currencyListWantSettlement:  listRight,
                                currencyListHaveSettlement: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                        case 'Payment': 
                            {this.setState({
                                currencyListWantPayment:  listRight,
                                currencyListHavePayment: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                    }
                });
                
            }else
            {
                listRight.forEach(rightCurrency => {
                    if(rightCurrency.CurrencyID == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftCurrency, index) => {
                            if(leftCurrency.CurrencyID == idSelected)
                            {
                                listRight.push(leftCurrency)
                                listLeft.splice(index,1)

                                switch(this.state.currencyTypeSelected)
                                {
                                    case 'Settlement':
                                    {
                                        this.setState({
                                            currencyListWantSettlement:  listRight,
                                            currencyListHaveSettlement: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                    case 'Payment': 
                                    {
                                        this.setState({
                                            currencyListWantPayment:  listRight,
                                            currencyListHavePayment: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }

    currencyRemoveItemSelected = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }

        var idSelected = this.state.selectedIDRowRightCurrency
        var add = true
        if(idSelected != '')
        {
            listRight.forEach((rightCurrency, index) => {
                if(rightCurrency.CurrencyID == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightCurrency)
                }
            });

            switch(this.state.currencyTypeSelected)
            {
                case 'Settlement':
                    {this.setState({
                        currencyListWantSettlement:  listRight,
                        currencyListHaveSettlement: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
                case 'Payment': 
                    {this.setState({
                        currencyListWantPayment:  listRight,
                        currencyListHavePayment: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
            }
        }
    }

    currencyAddAllItems = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listRight,listLeft)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  listRight,
                    currencyListHaveSettlement: [],
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  listRight,
                currencyListHavePayment: [],
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }
    
    currencyRemoveAll = () =>
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listLeft,listRight)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  [],
                    currencyListHaveSettlement: listLeft,
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  [],
                currencyListHavePayment: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }

    handleSaveClick = (event) => {
        event.preventDefault();
        var currencySettList    = this.state.currencyListWantSettlement;
        var currencyPayList     = this.state.currencyListWantPayment;
        var CurrenciesJson      = [];

        for (var i = 0; i < currencySettList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencySettList[i].CurrencyID,
                'Type': 'Settlement'});
        }
        for (var i = 0; i < currencyPayList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencyPayList[i].CurrencyID,
                'Type': 'Payment'});
        }
        var objFilter={
            'CountryID': this.props.countryID,
            'CurrenciesJson': CurrenciesJson,
            'UserID': sessionStorage.getItem('UserID'),
        }
        saveCurrenciesByCountry(objFilter).then(
            (json)=>{
                console.log(json);
                if(json.httpStatusCode!==200){
                    this.setState({showAlert: true, alertTitle: 'An error occurred while trying to save the Country Currencies.'})
                }else{
                    this.setState({showAlert: true,alertTitle:'Successfully', alertMessage: 'Country Currencies successfully saved.'});
                }
            }
        );
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    render(){
        var losQueHayAhora  = []
        var losQueQuiero    = [];
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                losQueHayAhora = this.state.currencyListHaveSettlement
                losQueHayAhora.sort(this.dynamicSort("CurrencyID"));
                losQueQuiero = this.state.currencyListWantSettlement
                losQueQuiero.sort(this.dynamicSort("CurrencyID"));
                break;
            }
            case 'Payment': 
            {
                losQueHayAhora = this.state.currencyListHavePayment
                losQueHayAhora.sort(this.dynamicSort("CurrencyID"));
                losQueQuiero = this.state.currencyListWantPayment
                losQueQuiero.sort(this.dynamicSort("CurrencyID"));
                break;
            }
        }

        return(
            <div>
                <div  style={{
                            display: 'flex',
                        }}
                    >
                        <div className="uk-width-1-3">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-bank-status">Currency Type</label>
                                <div className="uk-form-controls">
                                    {/*<select className={regularSelectClass} />*/}
                                    <DynamicSelect 
                                        className       = "uk-select" 
                                        objValue        = {'value'}
                                        getValue        = {this.onchangeValueOfCurrencyType.bind(this)} 
                                        listHasPlaceholder  = {false} 
                                        data            = {this.state.listOfCurrencyType}
                                        id              = 'select-currency-type'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                                <div className="uk-form-controls"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}    
                                >
                                    <label style={{
                                        fontSize: 16
                                    }}>{this.state.textToShowNextToSelect}</label>
                                </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <h3 class="uk-width-1-3" style={{marginLeft: 20}}>Available Currencies</h3>
                        <h3 class="uk-width-1-3"></h3>
                        <h3 class="uk-width-1-3">Assigned Currencies</h3>
                    </div>
                    <div
                        className=""
                        style={{
                            //backgroundColor: 'red',
                            alignItems: 'center',
                            display: 'flex',
                            border:'1px solid #9DA99E'
                        }}
                    >
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                <tbody>
                                    {losQueHayAhora.map((item, i) => {
                                        return (
                                        <tr 
                                            key={item.CurrencyID} 
                                            onClick={this.currencyChangeColorLeft(item.CurrencyID)}
                                            onDoubleClick={this.currencyAddItemSelected.bind(this)}
                                            style={
                                                this.state.selectedIDRowLeftCurrency === item.CurrencyID ? 
                                                {backgroundColor: '#8bc34a', cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'} 
                                            }
                                        >
                                            <td
                                                style={
                                                    this.state.selectedIDRowLeftCurrency === item.CurrencyID ? 
                                                    {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                }
                                            >{item.CurrencyIDName}</td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div 
                            className="uk-card-body uk-width-1-3"
                            style={{
                                //backgroundColor: 'blue',
                                textAlign:'center',
                                //width: '33%',
                                borderRight:'1px solid #9DA99E',
                                borderLeft:'1px solid #9DA99E'
                            }}
                        >
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.currencyAddItemSelected.bind(this)}
                            >
                                Add
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.currencyAddAllItems.bind(this)}
                            >
                                Add all
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2"
                                style={{
                                    marginBottom: 20,
                                }}
                                onClick={this.currencyRemoveItemSelected.bind(this)}
                            >
                                Remove
                            </button>
                            <button 
                                className="uk-button uk-button-green uk-width-1-2" /*onClick={this.handleLoginClick.bind(this)}*/
                                style={{
                                    
                                }}
                                onClick={this.currencyRemoveAll.bind(this)}
                            >
                                Remove all
                            </button>
                        </div>
                        <div
                            className="uk-width-1-3"
                            style={{
                                //backgroundColor: 'yellow',
                                overflowY: 'scroll',
                                height: 300,
                                paddingLeft: 0,
                                //width: '33%',
                            }}
                        >
                            <table 
                                className="uk-table uk-table-hover uk-table-divider"
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                    <tbody>
                                        {losQueQuiero.map((item, i) => {
                                            return (
                                            <tr 
                                                key={item.CurrencyID} 
                                                onClick={this.currencyChangeColorRight(item.CurrencyID)} 
                                                onDoubleClick={this.currencyRemoveItemSelected.bind(this)}
                                                style={
                                                    this.state.selectedIDRowRightCurrency === item.CurrencyID ? 
                                                    {backgroundColor: '#c3824a', cursor: 'pointer'} : {backgroundColor: 'white', cursor: 'pointer'}
                                                }
                                            >
                                                <td
                                                    style={
                                                        this.state.selectedIDRowRightCurrency === item.CurrencyID ? 
                                                        {color: 'white', cursor: 'pointer'} : {color: 'black', cursor: 'pointer'} 
                                                    }
                                                >{item.CurrencyIDName}</td>
                                            </tr>
                                            );
                                        })}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                <div>
                    <button className="uk-button uk-button-green"style={{marginTop: 20}} onClick={this.handleSaveClick} >Save</button>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }
}