import React from 'react';

import {GlobalSystemParametersNames} from '../../constants/GlobalSystemParametersNames'

import {getLookUpTable,getLookUpTableByFilter} from '../../networking/Networking';
import {getNostroBankAccountsForFunds} from '../../networking/NetworkingBankAccounts';
import {getListOfBanks} from '../../networking/NetworkingBanks';
import {getBeneficiariesByCustomerFormatedMin} from '../../networking/NetworkingBeneficiaries';
import {getBankAccountsByCurrency} from '../../networking/NetworkingCustomers'
import {getCurrencyList} from '../../networking/NetworkingCurrencies';
import {getParametersByName} from '../../networking/NetworkingGlobalSystemParameters';
import {getListOfPaymentsForCustomer, savePayment, getPayment, voidPayment, applyPayment, getPaymentsWithBalanceByCustomerCurrency} from '../../networking/NetworkingPayments';
import { getDealsForPayments } from '../../networking/NetworkingDeals';
import { getCustomerFundOnHoldBalanceByCurrencyJson } from '../../networking/NetworkingFunds';
import {voidIncomingFund} from '../../networking/NetworkingFunds';
import {getInstrumentsByCustomer} from '../../networking/NetworkingInstruments';

import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import NewDynamicTable from '../shared/NewDynamicTable';
import NumberInput from '../shared/NumberInput';
import Paginator from '../shared/Paginator';

import {formatDate, formatToCurrency} from '../../helpers/FormatHelper';
import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';

import { getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../styles/styles';
import { set, format } from 'date-fns';
import LoadingBox from '../shared/LoadingBox';
import RefundForm from '../back_office/RefundForm';
var style = getStyle(); 
const inputStyle= {
    paddingBottom:"15px"
}

export default class IncomingPaymentForm extends React.Component {

    state = {
        balanceIsZero: false,
        customerID: 0,
        firstLoad: true,
        incomingFundsIDToVoid: 0,
        listActiveCurrencies: [],
        listBanks: [],
        listCustomerBankAccounts: [],
        listCustomerPayments: [],
        listDeliveryMethods: {},
        listIncomingInstruments: [],
        listNostroAccounts: [],
        listThirdPartyBenes: [],
        listToApply: [],
        listToApplyView: [],
        
        //payment data
        amount: 0,
        amountUsedInDeals: 0,
        beneficiaryId: 0,
        certified: false,
        chequeBankId: 0,
        chequeDate: '',
        chequeNumber: '',
        comments: '',
        createdOn: '',
        currencyID: '',
        customerBankAccountId: 0,
        dateReceived: '',
        deliveryMethod: '',
        disbursedAmount: 0,
        nostroBankAccountId: 0,
        originalAmount: 0,
        paymentID: 0,
        paymentReferenceNo: '',
        paymentStatus: '',
        refundedAmount: 0,
        settlementInstrument: '',
        thirdPartyBeneficiaryPayment: false,
        updatedOn: '',
        updateToken: 0,
        vostroIssueDate: '',
        
        //paginator
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        loading: false,
        saveInPendingStatus: false,
        validations: {
            currencyId: true,
            amount: true,
            dateReceived: true,
            settlementInstrument: true,
            deliveryMethod: true,
            customerBankAccountId: true,
            nostroAccountId: true
        },
        //show fields
        showETFFields: false,
        showDirectDebitFields: false,

        //alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        showAlertSave: false,
        alertMessageSave: '',
        showApplyPaymentsAlert: false,
        showVoidIncomingAlert: false,
        
        //session values
        systemDate: '',
        sessionLastClosingDate: '',

        //global parameters:
        enablePaymentConfirmationPrompt: false,
        vostroConversionDate: undefined,
        enableSavePendingPayments: false,

        //Payments filters
        /*showAppliedPayments: false,
        showAvailablePayments: false,*/
        showFullyApplied: false,

        hideButton : false,
        hideButtonApplySection : false,
        typeRecordsApply : '',
        //Balance
        customerPaymentsBalance: 0,
        combinedBalance: 0,
        currentPaymentBal: 0,
        fundOnHoldBalance:0,

        //Other parameters
        refundMode: false,
    }

    componentDidMount() {
        //Sessions
        let sessionSystemDate = sessionStorage.getItem(GlobalSystemParametersNames.SystemDate);
        let sessionLastClosingDate = sessionStorage.getItem(GlobalSystemParametersNames.LastClosingDate);

        //Load global system parameters
        var enablePaymentConfirmationPrompt = false;
        var vostroConversionDate = undefined;
        var enableSavePendingPayments = false;
        if (this.props.globalParameters !== undefined) {
            let globalParameters = this.props.globalParameters;
            let global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.EnablePaymentConfirmationPrompt);
            if (global.length === 1) {
                enablePaymentConfirmationPrompt = global[0].Value === 'true';
            }
            global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.VostroConversionDate);
            if (global.length === 1) {
                vostroConversionDate =  global[0].Value;
            }
            global = globalParameters.filter(item => item.Name === GlobalSystemParametersNames.EnableSavePendingPayments);
            if (global.length === 1) {
                enableSavePendingPayments = global[0].Value === 'true';
            }
        }

        if(!this.props.paymentOpenFromBoard){
            let customerID = this.props.customerID;
            let listActiveCurrencies = this.props.activeCurrencies;
            listActiveCurrencies.forEach(element => {
                element.value = element.CurrencyID;
                element.name = element.CurrencyIDName;
            });
            var currencyID = '';
            if(this.props.buyCurrency !== undefined){
                currencyID = this.props.buyCurrency;
            }

            //Load instrument
            this.loadCustomerInstruments(customerID);

            //Load payments
            this.loadPaymentsForCustomer(customerID, true);

            //Load payment if any
            var paymentID = 0;
            if(Number(this.props.data) > 0){//PaymentID
                paymentID = this.props.data;
                this.loadPayment(paymentID, false, false);
            }

            //Set state
            this.setState({
                currencyID: currencyID,
                customerID: customerID,
                enablePaymentConfirmationPrompt: enablePaymentConfirmationPrompt,
                enableSavePendingPayments: enableSavePendingPayments,
                listActiveCurrencies: listActiveCurrencies,
                paymentID: paymentID,
                sessionLastClosingDate: sessionLastClosingDate,
                sessionSystemDate: sessionSystemDate,
                vostroConversionDate: vostroConversionDate,
            });
        }
        else{
            this.setState({
                enablePaymentConfirmationPrompt: enablePaymentConfirmationPrompt,
                enableSavePendingPayments: enableSavePendingPayments,
                sessionLastClosingDate: sessionLastClosingDate,
                sessionSystemDate: sessionSystemDate,
                vostroConversionDate: vostroConversionDate,
            });

            this.loadCurrencyList().then(() => {
                if(Number(this.props.data) > 0){//PaymentID
                    this.loadPayment(this.props.data, true, true);
                }
            });
        }
    }

    loadPaymentsForCustomer(customerID, newSearch) {
        var withAvailableBalance = false;
        if(this.props.dealHeaderID !== undefined){
            withAvailableBalance = true;
        }

        var currencyID = '';
        if(this.props.buyCurrency !== undefined){
            currencyID = this.props.buyCurrency;
        }
        getListOfPaymentsForCustomer(customerID, currencyID, this.props.dealHeaderID, withAvailableBalance, this.state.showFullyApplied, this.state.currentPage, getDefaultPagingNumberOfItems()).then(
            response => {
                if (response !== undefined) {
                    var listCustomerPayments = [];
                    if(response.customerPayments !== undefined){
                        listCustomerPayments = response.customerPayments;
                    }
                    var totalCount = response.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    this.setState({
                        listCustomerPayments: listCustomerPayments,
                        totalPages: pages,
                        newSearch: newSearch
                    });
                }
            }
        );
    }

    loadBeneficiariesByCustomerFormatedMin() {
        getBeneficiariesByCustomerFormatedMin(this.state.customerID, this.state.currencyID, '').then((response) => {
            if (typeof response !== "undefined" && typeof response.beneficiaries !== "undefined") {
                var benes = response.beneficiaries;
                benes.forEach(element => {
                        element.value = element.BeneficiaryID;
                        element.name = element.Name;
                    });
                this.setState({listThirdPartyBenes: benes});
            } else {
                this.setState({listThirdPartyBenes: []});
            }
        });
    }

    loadBankAccountsByCurrency() {
        let loadCustomerAccount = !this.state.thirdPartyBeneficiaryPayment;
        if (loadCustomerAccount) {
            getBankAccountsByCurrency(this.state.customerID, this.state.currencyID).then(
                (response) => {
                    if (typeof response !== "undefined" && typeof response.accounts !== "undefined") {
                        let bankAccounts = response.accounts;
                        bankAccounts.forEach(element => {
                            element.value = element.BankAccountID;
                            element.name = element.Description;
                        });

                        this.setState({listCustomerBankAccounts: bankAccounts});
                    } else {
                        this.setState({listCustomerBankAccounts: []});
                    }
                }
            );
        } else {
            getBeneficiariesByCustomerFormatedMin(this.state.customerID, this.state.currencyID, '').then((response) => {
                if (typeof response !== "undefined" && typeof response.beneficiaries !== "undefined") {
                    let beneAccounts = response.beneficiaries;
                    beneAccounts.forEach(element => {
                            element.value = element.BankAccountID;
                            element.name = element.Name;
                        });
                    this.setState({listCustomerBankAccounts: beneAccounts});
                } else {
                    this.setState({listCustomerBankAccounts: []});
                }
            });
        }
    }

    loadPayment(paymentID, loadPayments, loadCustomerInstruments) {
        getPayment(paymentID).then(
            (response) => {
                console.table(response);
                if (typeof response !== "undefined") {
                    this.setState({
                        amount: response.Amount,
                        beneficiaryId: response['Beneficiary ID'],
                        chequeBankId: response['ChequeBank ID'],
                        chequeNumber: response.ChequeNumber,
                        currencyID: response['Currency ID'],
                        customerBankAccountId: response.CustomerBankAccount,
                        dateReceived: response['Date Received'],
                        deliveryMethod: response.DeliveryMethod,
                        originalAmount: response.Amount,
                        paymentID: response['Payment ID'],
                        paymentReferenceNo: response['Payment Ref No'],
                        paymentStatus: response.Status,
                        settlementInstrument: response.SettlementInstrument,
                        thirdPartyBeneficiaryPayment: response.ThirdPartyBeneficiaryPayment,
                        
                        chequeDate: response.ChequeDate,
                        nostroBankAccountId: response['NostroBankAccount ID'],
                        certified: response.Certified,
                        disbursedAmount: response.DisbursedAmount,
                        refundedAmount: response.RefundedAmount,
                        vostroIssueDate: response.VostroIssueDate,
                        createdOn: response.CreatedOn,
                        updatedOn: response.UpdatedOn,
                        comments: response.Comments,
                        updateToken: response.UpdateToken,
                        customerID: response['Customer ID'],
                        amountUsedInDeals: response['AmountUsedInDeals'],
                    }, () => {
                        if (this.state.settlementInstrument === 'Electronic Funds Transfer') {
                            this.setState({showETFFields: true});
                        } else {
                            this.setState({showETFFields: false});
                        }
                        if (this.state.settlementInstrument === 'Direct Debit') {
                            this.setState({showDirectDebitFields: true});
                        } else {
                            this.setState({showDirectDebitFields: false});
                        }
                        if (this.state.settlementInstrument === 'Cheque') {
                            this.loadListOfBanks();
                        } else {
                            this.setState({listBanks: []});
                        }

                        //let instrumentObject = this.state.listIncomingInstruments.filter(x => x.name === this.state.settlementInstrument)[0];
                        //this.loadLookUpTableByFilter(instrumentObject);
                        this.loadBankAccountsByCurrency()
                        this.loadBeneficiariesByCustomerFormatedMin();
                        this.loadNostroBankAccounts();
                        this.getDealsForPayments(response['Customer ID'], response['Currency ID'], response['Payment ID']);
                        this.getCustomerFundOnHoldBalanceByCurrencyJson(response['Customer ID'], response['Currency ID']);
                        this.getPaymentsWithBalanceByCustomerCurrency(response['Customer ID'], response['Currency ID']);
                        if(loadPayments){
                            this.loadPaymentsForCustomer(response['Customer ID'], true);
                        }
                        if(loadCustomerInstruments){
                            this.loadCustomerInstruments(response['Customer ID']);
                        }                     
                    });
                }
            });
    }

    loadNostroBankAccounts() {
        getNostroBankAccountsForFunds(this.state.currencyID).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.nostroBankAccounts !== "undefined") {
                    let nostroAcounts = response.nostroBankAccounts;
                    nostroAcounts.forEach(element => {
                        element.value = element.BankAccountID;
                        element.name = element.BankName;
                    });
                    this.setState({
                        listNostroAccounts: nostroAcounts
                    });
                } else {
                    this.setState({listNostroAccounts: []});
                }
            }
        );
    }

    async loadCurrencyList() {
        getCurrencyList().then(
            (response) => {
                if (typeof response !== "undefined") {
                    let currencies = response.currencies;
                    currencies.forEach(element => {
                        element.value = element.CurrencyID;
                        element.name = element.CurrencyIDName;
                    });
                    this.setState({
                        listActiveCurrencies: currencies
                    });
                } 
            }
        );
    }

    voidPayment = () => {
        let userID = sessionStorage.getItem('UserID'); 
        
        var model = {
            paymentID: this.state.paymentID,
            UserID: userID
        }

        console.table(model);

        //IsValidApplicationDate?
        getParametersByName(GlobalSystemParametersNames.LastClosingDate).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.parameters !== "undefined" && response.parameters.length > 0) {
                    
                    let currentSessionLastClosingDate = response.parameters[0].Value;

                    if (new Date(currentSessionLastClosingDate) > new Date(this.state.sessionLastClosingDate)) {
                        this.setState({showAlert: true, alertMessage: 'You should restart the session. The application date is not correct.', alertTitle: 'Error'});
                        return false
                    }

                    if(this.state.refundedAmount > 0){
                        this.setState({showAlert: true, alertMessage: 'This payment cannot be voided as there are completed refunds linked to it. Please void the refunds first, then void this payment.', 
                        alertTitle: 'Error'});
                        return false
                    }

                    if (this.state.disbursedAmount > 0 && this.state.paymentStatus !== 'Pending') {
                        this.setState({showAlert: true, alertMessage: 'This payment cannot be voided as it has already been partially or fully applied.', 
                        alertTitle: 'Error'});
                        return false
                    }

                    this.setState({loading: true});
                    voidPayment(model).then((response) => {
                        if (response.httpStatusCode === 400) {

                            let httpStatusText = "An error occurred while trying to void the payment. Please try again. (Bad Request)";
                            this.setState({showAlert: true, 
                                alertMessage: httpStatusText, 
                                alertTitle: 'Error',
                                loading: false,
                            })
                        
                        } else if (response.httpStatusCode !== 200) {
                            var alertMessage = 'An error occurred while trying to void the payment. Please try again.'            
                            if(response.Message !== undefined){
                                alertMessage = response.Message;
                            }
                            this.setState({showAlert: true, 
                                alertMessage: alertMessage, 
                                alertTitle: 'Error',
                                loading: false,
                            })
            
                        } else {
                            this.handleClearClick();
                            this.loadPaymentsForCustomer(this.state.customerID, true);
                            this.loadPayment(this.state.paymentID, false, false);
                            if(this.props.reloadDealHeaderInfo !== undefined){
                                this.props.reloadDealHeaderInfo();
                            }
                            this.setState({showAlert: true, 
                                alertTitle: 'Success',
                                alertMessage: 'Payment voided successfully',
                                loading: false,
                            });
                        }
                    })
                    
                }
            }
        )        

    }

    loadCustomerInstruments(customerID){
        getInstrumentsByCustomer(customerID).then(
            (json) => {
                //alert(JSON.stringify(json.instruments));
                let jsonInstruments = json.instruments;
                if(jsonInstruments !== undefined){
                    var incomingHelper = [];
                    var listDeliveryMethods = {};
                    for(var i=0; i<jsonInstruments.length; i++){
                        var instrumentElement = {value:jsonInstruments[i].Instrument,
                            name:jsonInstruments[i].Instrument};
                        if(jsonInstruments[i].InstrumentType === 'Incoming'){
                            incomingHelper.push(instrumentElement);
                        }

                        //Load delivery methods
                        var deliveryMethods = [];
                        if(jsonInstruments[i].DeliveryMethods !== ''){
                            //alert(jsonInstruments[i].Instrument + '-' + JSON.stringify(jsonInstruments[i].DeliveryMethods));
                            var methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                            for(var j=0; j<methodsList.length; j++){
                                var item = {value:methodsList[j], name:methodsList[j]};
                                deliveryMethods.push(item);
                            }
                            listDeliveryMethods[jsonInstruments[i].Instrument] = deliveryMethods;
                        }
                        
                    }
                    this.setState({listIncomingInstruments: incomingHelper,
                        listDeliveryMethods: listDeliveryMethods,
                    });
                }
            }
        );
    }
v
    loadListOfBanks() {
        getListOfBanks(true).then(
            (response) => {
                if (typeof response !== "undefined" && response.banks !== "undefined") {
                    let banks = response.banks;
                    banks.forEach(element => {
                        element.value = element.BankID;
                        element.name = element.Name;
                    });
                    this.setState({listBanks: response.banks})
                } else {
                    this.setState({listBanks: []})
                }
            }
        )
    }

    hideSaveInPendingButton() {
        if (this.state.enableSavePendingPayments && this.state.paymentID > 0 
            && ['Pending', 'Not Applied'].includes(this.state.paymentStatus)) {
            return false
        } else {
            return true;
        }
    }

    hideVoidButton(){
        if (this.state.paymentID !== "undefined" && this.state.paymentID > 0) {
            return false
        } else {
            return true
        }
    }

    hideChequeInfo(){
        if (this.state.settlementInstrument === 'Cheque') {
            return false
        } else {
            return true
        }
    }

    //events
    getAmountValue(event) {
        this.setState({amount: event.target.value});
    }

    handleActiveCurrencies = (value) => {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({
                currencyID: value.value
            }, () => {
                this.loadBeneficiariesByCustomerFormatedMin();
                this.loadBankAccountsByCurrency();
                this.loadNostroBankAccounts();
                this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerID, value.value);
                this.getPaymentsWithBalanceByCustomerCurrency(this.state.customerID, value.value);
                
            })
        }
    }

    
    handleIncomingInstrument = (value) => {
        if(typeof value !== "undefined" && value !== null && typeof value.value !== "undefined"){
            this.setState({
                settlementInstrument: value.value
            }, () => {
                //this.loadLookUpTableByFilter(value);
                if (this.state.settlementInstrument === 'Electronic Funds Transfer') {
                    this.setState({showETFFields: true});
                } else {
                    this.setState({showETFFields: false});
                }
    
                if (this.state.settlementInstrument === 'Direct Debit') {
                    this.setState({showDirectDebitFields: true});
                } else {
                    this.setState({showDirectDebitFields: false});
                }
    
                if (this.state.settlementInstrument === 'Cheque') {
                    this.loadListOfBanks();
                } else {
                    this.setState({listBanks: []});
                }
            });
        }        
    } 

    handleDeliveryMethod = (value) => {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({deliveryMethod: value !== null ? value.value : ''});
        }
    }

    handleCustomerBankAccount(value) {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({customerBankAccountId: value !== null ? value.value: 0});
        }
    }

    handleDateReceived(date) {
        if (date !== undefined) {
            this.setState({
                dateReceived: formatDate(date)
            });
        } else {
            this.setState({
                dateReceived: ''
            });
        }
    }

    handleOnCheckThirdPartyBeneficiaryPayment(event) {
        let checked = event.target.checked
        this.setState({
            thirdPartyBeneficiaryPayment: checked
        }, () => {
            if (checked) {
                this.loadBeneficiariesByCustomerFormatedMin();
                this.loadBankAccountsByCurrency();
            } else {
                this.setState({beneficiaryId: 0})
            }
        });
    }

    handleBeneficiaryId(value) {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({ beneficiaryId: value !== null ? value.value : 0 })
        }
    }   

    handleChequeNumber(event) {
        this.setState({chequeNumber: event.target.value});
    }

    handleChequeDate(date) {
        if (date !== undefined) {
            this.setState({chequeDate: formatDate(date)
            });
        } else {
            this.setState({chequeDate: ''});
        }
    }

    handlePaymentReferenceNo(event) {
        this.setState({paymentReferenceNo: event.target.value});
    }    

    handleDeleteClick(obj){ 
    }

    handleChequeBank(value){
        if (typeof value !== "undefined" && value != null && typeof value.value !== "undefined") {
            this.setState({chequeBankId: value !== null ? value.value : 0})
        }
    }

    handleNostroBankAccount(value) {
        if (typeof value !== "undefined" && value !== null && typeof value.value !== "undefined") {
            this.setState({nostroBankAccountId: value !== null ? value.value: 0});
        }
    }

    //paginator
    changePage(value) { 
        this.setState({
            currentPage: value
        }, () => {
            this.loadPaymentsForCustomer(this.state.customerID, false);
        });
    }

    //validations
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    validateRequiredFields(){
        var valid = true;

        var vCurrencyId = true,
            vAmount = true,
            vSettlementInstrument = true,
            vDeliveryMethod = true,
            vDateReceived = true,
            vCustomerBankAccountId = true,
            vNostroAccountId = true;

        if (this.state.amount == null || Number(this.state.amount) <= Number(0)) {
            vAmount = false;
        }

        if (this.state.currencyID == null || this.state.currencyID === '') {
            vCurrencyId = false;
        }

        if (this.state.dateReceived == null || this.state.dateReceived === '') {
            vDateReceived = false;
        }

        if (this.state.settlementInstrument == null || this.state.settlementInstrument === '' || this.state.settlementInstrument.includes('Select')) {
            vSettlementInstrument = false;
        }

        var validateDeliverMethod = false;

        /*var validateDeliverMethod = (this.state.settlementInstrument === 'Direct Debit' ||
            this.state.settlementInstrument === 'Draft' || this.state.settlementInstrument === 'Electronic Funds Transfer')
            //changed by to use the settlementInstrument field4
            */

        let instrumentObject = this.state.listIncomingInstruments.filter(x => x.name === this.state.settlementInstrument)[0];
        
        if (typeof instrumentObject !== "undefined") {            
            if (instrumentObject.Field4 !== null && instrumentObject.Field4 !== '') {
                validateDeliverMethod = true
            }
        }
        
        if (validateDeliverMethod &&
            (this.state.deliveryMethod == null || this.state.deliveryMethod === '' || this.state.deliveryMethod.includes('Select'))) {
            vDeliveryMethod = false;
        }

        var validateCustomerBankAccountId = (this.state.settlementInstrument === 'Direct Debit');
        if (validateCustomerBankAccountId &&
            (this.state.customerBankAccountId == null || this.state.customerBankAccountId === '')) {
            vCustomerBankAccountId = false;
        }
        
        if (this.state.nostroBankAccountId == null || this.state.nostroBankAccountId === '') {
            vNostroAccountId = false;
        }

        valid = vCurrencyId && vAmount && vSettlementInstrument && vDeliveryMethod && vDateReceived && vCustomerBankAccountId && vNostroAccountId;

        this.setState({
            validations: {
                ...this.state.validations,
                currencyId: vCurrencyId,
                amount: vAmount,
                settlementInstrument: vSettlementInstrument,
                deliveryMethod: vDeliveryMethod,
                dateReceived: vDateReceived,
                customerBankAccountId: vCustomerBankAccountId,
                nostroAccountId: vNostroAccountId
            }
        })
        return valid;
    }
    //actions
    handleRowClick(row) {
        this.handleClearClick();
        this.loadPayment(row['Payment ID'], false, false); 
    }

    handleSaveClick(event) {

        let userID = sessionStorage.getItem('UserID');
        let paymentStatus = "Not Applied";
        
        var model = {
            paymentID: this.state.paymentID,
            Amount: this.state.amount,
            BeneficiaryID: this.state.beneficiaryId !== null ? this.state.beneficiaryId : 0,
            ChequeBankID: this.state.chequeBankId !== null ? this.state.chequeBankId : 0,
            ChequeDate: this.state.chequeDate,
            ChequeNumber: this.state.chequeNumber,
            CurrencyID: this.state.currencyID,
            CustomerBankAccountID: this.state.customerBankAccountId !== null ? this.state.customerBankAccountId : 0,
            CustomerID: this.state.customerID,
            DateReceived: this.state.dateReceived,
            DeliveryMethod: this.state.deliveryMethod,
            NostroBankAccountID: this.state.nostroBankAccountId !== null ? this.state.nostroBankAccountId : 0,
            PaymentReferenceNo: this.state.paymentReferenceNo,
            SettlementInstrument: this.state.settlementInstrument,
            Status: paymentStatus,
            VostroIssueDate: this.state.vostroIssueDate,
            ThirdPartyBeneficiaryPayment: this.state.thirdPartyBeneficiaryPayment,
            UserID: userID,
            UpdateToken: this.state.updateToken,
            SaveInPendingStatus: this.state.saveInPendingStatus
        }

        console.table(model);

        this.setState({showAlertSave: false});

        savePayment(model).then((response) => {
            if (response.httpStatusCode === 400) {
                let httpStatusText = "An error occurred while trying to save the payment. Please try again. (Bad Request)";
                this.setState({
                    showAlert: true,
                    alertMessage: httpStatusText,
                    alertTitle: 'Error',
                    saveInPendingStatus: false
                })
            } else if (response.httpStatusCode !== 200) {
                var alertMessage = 'An error occurred while trying to save the payment. Please try again.'

                alert(JSON.stringify(response));

                if(response.Message !== undefined){
                    alertMessage = response.Message;
                }
                this.setState({
                    showAlert: true,
                    alertMessage: alertMessage,
                    alertTitle: 'Error',
                    saveInPendingStatus: false
                });
            } else {
                let paymentID = response.paymentId;
                this.loadPaymentsForCustomer(this.state.customerID, true);
                this.setState({paymentID: paymentID,
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: 'Payment successfully saved!',
                    saveInPendingStatus: false,
                }, () => this.loadPayment(paymentID, false, false));
                if(this.props.reloadDealHeaderInfo !== undefined){
                    this.props.reloadDealHeaderInfo();
                }
            }
        }) 
    }

    handleClearClick(event) {
        this.setState({
            paymentID: 0,
            currencyID: '',
            amount: 0,
            status: 'Not Applied',
            settlementInstrument: '',
            deliveryMethod: '',
            dateReceived: '',
            thirdPartyBeneficiaryPayment: false,
            beneficiaryId: 0,
            customerBankAccountId: 0,
            originalAmount: 0,
            paymentReferenceNo: '',
            chequeNumber: '',
            chequeBankId: 0,
            chequeDate: '',
            nostroBankAccountId: 0,
            certified: false,
            disbursedAmount: 0,
            refundedAmount: 0,
            createdOn: '',
            updatedOn: '',
            vostroIssueDate: '',
            comments: '',
            updateToken: 0,
            listToApplyView : [],
            listToApply : [],
            validations: {
                ...this.state.validations,
                currencyId: true,
                amount: true,
                dateReceived: true,
                settlementInstrument: true,
                deliveryMethod: true,
                customerBankAccountId: true,
                nostroAccountId: true
            },
            mainFieldsEnable: {
                currency: true,
                bankAccount: true,
                cbDeliveryMethod: true,
                cbNostroAccounts: true,
                cbSettInstrument: true,
                txtAmount: true,
                lblNostroAccount: true,
                cbChequeBank: true,
                dpChequeDate: true
            },
            showETFFields: false,
            showDirectDebitFields: false,
        });
    }

    handleShowFullyAppliedUpdate = (event) => {
        let checked = event.target.checked
        this.setState({showFullyApplied: checked}, 
            () => 
            this.loadPaymentsForCustomer(this.state.customerID, true));
    }
	
    /*handleOnCheckAvailable = (event) => {
        let checked = event.target.checked
        this.setState({showAvailablePayments: checked},
            () => 
            this.loadPaymentsForCustomer(this.state.customerID, true, false, this.state.showAppliedPayments, this.state.showAvailablePayments)) 
    }*/
    //alerts
    handleSaveClickAskConfirmation = (event, saveInPendingStatus) => { 
        event.preventDefault();
        this.setState({
            saveInPendingStatus: saveInPendingStatus
        }, () => {
            if (this.validateRequiredFields()) {

                //IsValidApplicationDate?
                getParametersByName(GlobalSystemParametersNames.LastClosingDate).then(
                    (response) => {
                        if (typeof response !== "undefined" && typeof response.parameters !== "undefined" && response.parameters.length > 0) {
                            
                            let currentSessionLastClosingDate = response.parameters[0].Value;
    
                            if (new Date(currentSessionLastClosingDate) > new Date(this.state.sessionLastClosingDate)) {
                                this.setState({showAlert: true, alertMessage: 'You should restart the session. The application date is not correct.', alertTitle: 'Error'});
                                return false
                            }
    
                            let showChequeValidationMessage = false;
                            if (this.state.settlementInstrument === 'Cheque') {
                                if (typeof this.state.chequeDate !== "undefined" || this.state.chequeDate !== '') {
                                    let chequeDate = new Date(this.state.chequeDate);
                                    let systemDate = new Date(this.state.systemDate);
                                    if (chequeDate > systemDate) {
                                        showChequeValidationMessage = true;
                                    }
                                }
                            }
    
                            let paymentconfirmMessage = "Are you sure you want to save the amount of " + formatToCurrency(this.state.amount) + " " +
                                this.state.currencyID + " for this payment from customer " + this.state.customerID + "?";
    
                            if (showChequeValidationMessage) {
                                paymentconfirmMessage = 'Please note this is a post dated cheque. ' + paymentconfirmMessage
                            }
    
                            if (this.state.enablePaymentConfirmationPrompt) {
                                this.setState({
                                    showAlertSave: true,
                                    alertMessageSave: paymentconfirmMessage
                                })
                            } else {
                                this.handleSaveClick()
                            }
                        }
                    }
                )
    
            }
        });
    }

    closeAlert = () => {
        this.setState({showAlert: false,
            saveInPendingStatus: false});
    }

    closeAlertSave(){
        this.setState({showAlertSave: false});
    }

    handleHideButtonClick = () =>{
        if(this.state.hideButton){
            this.setState({hideButton: false})
        }else{
            this.setState({hideButton: true})
        }        
    }

    handleHideButtonApplySectionClick = () =>{
        if(this.state.hideButtonApplySection){
            this.setState({hideButtonApplySection: false})
        }else{
            this.setState({hideButtonApplySection: true})
        }        
    }

    getDealsForPayments(customerID, currencyID, paymentID){
        if(paymentID !== undefined && paymentID > 0)
        {
            getDealsForPayments(customerID, currencyID, 'P', paymentID, this.state.typeRecordsApply, 1, 100).then(
                (value) => {
                    //alert(JSON.stringify(value));
                    var deals = [];
                    if(value !== undefined && value.totalCount > 0)
                    {
                        if(this.props.dealHeaderID !== undefined){
                            value.result.forEach(element => {
                                if((element.TypeRecord === 'Deal' &&
                                    Number(element.RecordID) === Number(this.props.dealHeaderID)) ||
                                    Number(element.IncomingFundsID) > 0){
                                    deals.push(element);
                                }
                            });
                        }
                        else{
                            deals = value.result;
                        }
                    }
                    this.setState({listToApplyView: deals, listToApply: []});
                }
            );
        }
    }

    handleBackLink = () => {
        this.props.backLink();
    }

    //alerts
    handleApplyPayment = () =>{
        this.setState({showApplyPaymentsAlert: true});
    }

    yesApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
        let userID = sessionStorage.getItem('UserID');
        var model = {
            PaymentID: this.state.paymentID,
            UserId: userID,
            Records: this.state.listToApply 
        }
        applyPayment(model).then((response) => {
            //alert(JSON.stringify(response));
            switch(Number(response.httpStatusCode)){
                case 200:
                    this.setState({
                        listPaymentsToApply: [],
                        showAlert: true,
                        alertTitle: 'Success',
                        alertMessage:  'Payment applied successfully.',
                    });
                    this.loadPayment(this.state.paymentID, false, false);
                    this.loadPaymentsForCustomer(this.state.customerID, true);
                    if(this.props.reloadDealHeaderInfo !== undefined){
                        this.props.reloadDealHeaderInfo();
                    }
                    break;
                case 409:
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: 'Deal has been updated by other user. Please refresh the information and try again.',
                    });
                    break;
                default:
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: 'An error occurred while trying to apply payment. Please try again.',
                    });
                    break;
            }
        });
    }
    
    noApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
    }

    handleAmountToUse(data, event) { 
        //alert(JSON.stringify(data));
        let tempToApply = this.state.listToApply;
        let amountToUse = event.target.value;
        var paymentChanged = this.state.listToApplyView.filter(item => item['RecordID'] === data.recordID)[0]
        var valid = true;
        if(amountToUse < 0)
        {            
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }

        if(amountToUse > data.pendingAmount)
        {
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
       //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
          if (tempToApply.length > 0) {
                var amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
                if (amountTotalToApply > this.state.amount - this.state.disbursedAmount - this.state.refundedAmount) {
                    this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error'});
                    paymentChanged['AmountToUse'] = 0;
                    valid = false;
                }
            }
    //remove if exist
    if (tempToApply.length > 0) {
        tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
    }

    if(valid && amountToUse > 0)
    {
        //add if amount > 0
            if (amountToUse > 0) {
                tempToApply.push({
                    RecordID: data.recordID,
                    AmountToUse: amountToUse,
                    TypeRecord: data.typeRecord,
                    DealUpdateToken: data.dealUpdateToken,
                })
            }

            paymentChanged['AmountToUse'] = amountToUse;          
        
    }
    this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerID, this.state.currencyID);
       
    this.setState({
        listToApply: tempToApply
    });
}

    getPaymentsWithBalanceByCustomerCurrency(CustomerID, CurrencyID)
    {
        var balance = 0;
        getPaymentsWithBalanceByCustomerCurrency(CustomerID, CurrencyID, true, true).then(
            (json)=>{
                if(json !== undefined && json.payments !== undefined)
                {
                    for(var i = 0; i < json.payments.length ; i++)
                    {
                        balance += json.payments[i].Amount - json.payments[i].DisbursedAmount - json.payments[i].RefundedAmount;
                    }
                }
                this.setState({customerPaymentsBalance : balance});
            }
        );
    }

    getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID)
    {
        getCustomerFundOnHoldBalanceByCurrencyJson(CustomerID, CurrencyID).then(
            (json)=>{
                if(json !== undefined)
                {                    
                    if(json.Amount !== undefined)
                    {
                        this.setState({fundOnHoldBalance : json.Amount});
                    }
                    else
                    {
                        this.setState({fundOnHoldBalance : 0});
                    }
                }
                else
                {
                    this.setState({fundOnHoldBalance : 0});
                }
                this.calculateBalance();
            }
        );
    }

    calculateBalance()
    {
        /*var userAmount = 0;
        var incomingAmount = 0;
        var paymentBalance = 0;
        var vostroBalance = 0;
        var combinedBalance = 0;
        var listToApplyView = this.state.listToApplyView;
        let enableFundOnHoldBalance = sessionStorage.getItem(GlobalSystemParametersNames.EnableFundsOnHoldInPaymentScreen);

        for(var d = 0; d< listToApplyView.length; d++)
        {
            if (listToApplyView[d].AmountToUse !== undefined )
            {
                if (listToApplyView[d].IncomingFundsID !== undefined && listToApplyView[d].IncomingFundsID > 0)
                {
                    if (listToApplyView[d].Amount !== undefined && listToApplyView[d].Amount > 0)
                    {
                        incomingAmount += listToApplyView[d].Amount;
                        userAmount += listToApplyView[d].AmountToUse - listToApplyView[d].Amount;
                    }
                    else
                    {
                        incomingAmount += listToApplyView[d].AmountToUse;
                    }                        
                }
                else
                {
                    userAmount += listToApplyView[d].AmountToUse;
                }
            }  
        }

        if (this.state.paymentStatus !== "Pending" && this.state.paymentStatus !== "NSF")
        {
            paymentBalance = this.state.amount - incomingAmount - this.state.refundedAmount;
        } 

        if(enableFundOnHoldBalance)
        {
            if (this.state.fundOnHoldBalance > 0)
            {
                vostroBalance = this.state.fundOnHoldBalance;
            }
        }
        
        if (userAmount <= vostroBalance)
        {
            vostroBalance = vostroBalance - userAmount;
        }
        else
        {
            paymentBalance = paymentBalance - (userAmount - vostroBalance);
            vostroBalance = 0;
        }

        if (this.state.fundOnHoldBalance > 0)
        {                
            combinedBalance = paymentBalance + vostroBalance;
        }*/

        let paymentBalance = this.state.amount - this.state.disbursedAmount - this.state.refundedAmount;
        let vostroBalance = this.state.fundOnHoldBalance;
        let combinedBalance = paymentBalance + vostroBalance;

        this.setState({
            combinedBalance: combinedBalance,
            currentPaymentBal: paymentBalance,
            fundOnHoldBalance: vostroBalance
        });
    }

    handleUnlinkPayment = (incomingFundsID, canBeVoided, typeRecord) => {
        if(canBeVoided){
            this.setState({showVoidIncomingAlert: true,
                incomingFundsIDToVoid: incomingFundsID,
            });
        }
        else{
            var message = '';
            switch(typeRecord){
                case 'Deposit':
                    message = 'This payment application cannot be voided. Deposit was already used.';
                    break;
                default:
                    message = 'This payment application cannot be voided. Please contact administrator.'
                    break;
            }
            this.setState({alertTitle: 'Error',
                alertMessage: message,
                showAlert: true,
            })
        }
    }

    yesVoidIncomingFund = () => {
        voidIncomingFund(this.state.incomingFundsIDToVoid, sessionStorage.getItem('UserID'), null, null, null).then(
            (response) => {
                if(Number(response.httpStatusCode) === 200){
                    let currencyID = this.state.currencyID;
                    let paymentID = this.state.paymentID;
                    if(currencyID !== undefined &&
                        currencyID !== null &&
                        currencyID !== '' &&
                        paymentID > 0){
                        this.loadPayment(paymentID, false, false);
                        this.getDealsForPayments(this.state.customerID, currencyID, paymentID);
                    }
                    
                    this.loadPaymentsForCustomer(this.state.customerID, true);
                    if(this.props.reloadDealHeaderInfo !== undefined){
                        this.props.reloadDealHeaderInfo();
                    }
                    this.setState({showVoidIncomingAlert: false,
                        incomingFundsIDToVoid: 0,
                        alertTitle: 'Success',
                        alertMessage: 'Payment application voided!',
                        showAlert: true,
                    });
                }
                else{
                    this.setState({showVoidIncomingAlert: false,
                        incomingFundsID: 0,
                        alertTitle: 'Error',
                        alertMessage: 'Payment application not voided! Please try again or contact administrator.',
                        showAlert: true,
                    });
                }
            }
        );
    }

    closeVoidIncomingAlert = () => {
        this.setState({showVoidIncomingAlert: false});
    }

    onRefundClick = () => {
        this.setState({refundMode: true});
    }

    onRefundBackClick = () => {
        this.loadPayment(this.state.paymentID, false, false);
        this.loadPaymentsForCustomer(this.state.customerID, true);
        this.setState({refundMode: false});
    }

    render() {
        var headerMessage = "";//"Create Payment";
        if (this.state.paymentID > 0) {
            headerMessage = 'Payment ID: ' + this.state.paymentID
        }

        let saveButtonText = "Save";
        if (this.state.paymentStatus === "Pending") {
            saveButtonText = "Confirm"
        }

        var section = (
            <div className="uk-width-1-6 uk-padding-remove-left">
                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "0px"}} onClick={this.handleHideButtonClick} type="button" uk-toggle="target: #payments-component; animation: uk-animation-fade">▽</button>
            </div>
        )

        if(this.state.hideButton){
            section = (            
                <div className="uk-width-1-6 uk-padding-remove-left">
                    <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "0px"}} onClick={this.handleHideButtonClick} type="button" uk-toggle="target: #payments-component; animation: uk-animation-fade">△</button>
                </div>
            );
        }

        var sectionApply = (
            <div className="uk-width-1-6 uk-padding-remove-left">
                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "0px"}} onClick={this.handleHideButtonApplySectionClick} type="button" uk-toggle="target: #payments-apply-component; animation: uk-animation-fade">▽</button>
            </div>
        )

        if(this.state.hideButtonApplySection){
            sectionApply = (            
                <div className="uk-width-1-6 uk-padding-remove-left">
                    <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "0px"}} onClick={this.handleHideButtonApplySectionClick} type="button" uk-toggle="target: #payments-apply-component; animation: uk-animation-fade">△</button>
                </div>
            );
        }

        //new dynamic table with editable cell
        var headers;
        var rows = [];

        headers = (
            <tr>
                <th>Record Number</th>
                <th>Type</th>
                <th>Value Date</th>
                <th>Buy Amount</th>
                <th>Complete Amount</th>
                <th>Pending Amount</th>
                <th>Amount To Use</th>
                <th>Actions</th>
            </tr>
        );

        var index=0;
        if(this.state.listToApplyView !== undefined)
        {
        this.state.listToApplyView.forEach(element => {
            index++;
            var voidAction;
            var amountControl;
            if(Number(element.IncomingFundsID) > 0){
                voidAction = (<i className="fa fa-chain-broken" onClick={() => this.handleUnlinkPayment(element.IncomingFundsID, element.CanBeVoided, element.TypeRecord)} style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>);
                amountControl = (formatToCurrency(element['AmountToUse']));
            }
            else{
                amountControl = (
                    <NumberInput id={'amounttouse-' + index} 
                        type="Currency" disabled={true}
                        value={element['AmountToUse']} 
                        className="uk-input"
                        onChange={this.handleAmountToUse.bind(this, 
                            {
                                recordID: element['RecordID'],
                                typeRecord: element['TypeRecord'],
                                pendingAmount: element['PendingAmount'],
                                incomingFundsID: element['IncomingFundsID'],
                                amount: element['BuyAmount'],
                                dealUpdateToken: element['DealUpdateToken'],
                            })}
                    />
                );
            }
            rows.push(<tr key={index}>
                <td>{element['RecordNo']}</td>
                <td>{element['Description']}</td>
                <td>{element['ValueDate']}</td>
                <td>{formatToCurrency(element['BuyAmount'])}</td>
                <td>{formatToCurrency(element['CompleteAmount'])}</td>
                <td>{formatToCurrency(element['PendingAmount'])}</td>
                <td>
                    {amountControl}
                </td>
                <td align="center">
                    {voidAction}
                </td>
            </tr>);
        });
    }
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfRecordsToApply = 0;
        this.state.listToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;   
            numberOfRecordsToApply++;         
        });

        var validToApply = false;
        if(this.state.paymentStatus === "Not Applied" || 
            this.state.paymentStatus === "Partially Applied")
        {            
            if(numberOfRecordsToApply > 0)
            {
                validToApply = true;
            }
        }

        var returnButton = "";
        if(this.props.backLink !== undefined)
        { 
            returnButton = <i className="fa fa-arrow-left" onClick={this.handleBackLink} style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>;
        }

        //Amount control
        var amountControlDisable = false;
        if((this.state.disbursedAmount > 0 || this.state.refundedAmount > 0) 
            && this.state.paymentStatus !== 'Pending'){
            amountControlDisable = true;
        }

        //Currency control
        var currencyControlDisabled = false;
        if(this.state.paymentID > 0){
            currencyControlDisabled = true;
        }

        //Delivery methods
        var deliveryMethods = [];
        if(this.state.listDeliveryMethods[this.state.settlementInstrument] !== undefined){
            deliveryMethods = this.state.listDeliveryMethods[this.state.settlementInstrument];
        }

        //Show payments filters
        var hidePaymentsFilter = false;
        if(Number(this.props.dealHeaderID) > 0){
            hidePaymentsFilter = true;
        }

        //Refund button
        var refundButton;
        if(this.state.paymentStatus !== 'Pending'){
            refundButton = (
                <button className="uk-button uk-button-green" disabled={true} type="button" onClick={this.onRefundClick}>Refund</button>
            );
        }

        var regularScreen = ( 
        <form>
            <div className="uk-heading-divider" uk-grid="true" style={{verticalAlign: "center"}}>
                <div className="uk-width-auto">
                    {returnButton}
                </div>
                <div className="uk-width-expand">
                    <h3  style={{paddingLeft: "0x",paddingTop: "5px",paddingRight: "25px", paddingBottom: "10px"}}>
                        {headerMessage}
                    </h3>
                </div>
                <div className="" style={{textAlign: "right",paddingTop: "10px"}}>
                    <label className="uk-margin-right" style={{fontSize: '1.10em', fontWeight:700}} hidden={this.state.paymentID === 0}>Payment Status</label>
                    <label hidden={this.state.paymentID === 0}>{this.state.paymentStatus}</label>                
                </div>              
            </div>
            
            
            <div className="uk-grid uk-margin">

            {/*Line1*/}
            <div className="uk-width-1-4">
                <label className="uk-form-label">Settlement amount</label>            
                <NumberInput id="form-incomingpaymentform-amount"
                    className={this.formatValidInput(this.state.validations.amount)} 
                    type="Currency"  disabled={true}
                    placeholder="0" 
                    value={this.state.amount}
                    onChange={this.getAmountValue.bind(this)}/>
            </div>
            <div className="uk-width-1-4">
                <label className="uk-form-label">Currency</label>
                <DynamicSelect id="form-incomingpaymentform-currency"  disabled={true}
                    className={this.formatValidSelect(this.state.validations.currencyId)}  
                    placeholder='Select a currency' 
                    data={this.state.listActiveCurrencies} 
                    objValue={{value: this.state.currencyID}} 
                    getValue={this.handleActiveCurrencies} />
            </div>  
            
            <div className="uk-width-1-4"></div>

            <div className="uk-width-1-4" style={{textAlign: "right"}}>
                {/*<label className="uk-form-label uk-margin-right" style={{fontSize: '1.20em'}} hidden={this.state.paymentID === 0}>Payment Status</label>
                <label style={style.boldLabel} hidden={this.state.paymentID === 0}>{this.state.paymentStatus}</label>*/}                
            </div>
            
            {/*Line2*/}
            <div className="uk-width-1-4">
                <label className="uk-form-label">Settlement Instrument</label>
                <DynamicSelect id="form-incomingpaymentform-settlementintrument"  disabled={true}
                    className={this.formatValidSelect(this.state.validations.settlementInstrument)}                     
                    data={this.state.listIncomingInstruments} 
                    objValue={{value: this.state.settlementInstrument}}
                    placeholder="Select an Instrument..." 
                    getValue={this.handleIncomingInstrument} />
            </div>

            <div className="uk-width-1-3 uk-background-muted uk-margin-top uk-margin-left uk-padding-small uk-padding-remove-top uk-padding-remove-bottom" 
                style={{borderWidth:1, borderColor:'lightgrey', borderStyle:'solid', borderRightWidth:0, borderBottomWidth:0}} >                
                <div className="uk-form-controls" style={{ paddingTop: '0.5em' }} hidden={!this.state.showETFFields && !this.state.showDirectDebitFields} >
                    <input className="uk-checkbox" disabled={true} type="checkbox" id="form-incomingpaymentform-thirdPartyBeneficiaryPayment" checked={this.state.thirdPartyBeneficiaryPayment} 
                    onChange={this.handleOnCheckThirdPartyBeneficiaryPayment.bind(this)}/>                            
                    <label className="uk-form-label">Third Party?</label>
                </div>     
                
                <label className="uk-form-label" hidden={this.hideChequeInfo()}>Cheque number</label>
                <div className="uk-form-controls" hidden={this.hideChequeInfo()}>
                <input className={regularInputClass} disabled={true} type="text" id="form-incomingpaymentform-chequeNumber" value={this.state.chequeNumber} 
                onChange={this.handleChequeNumber.bind(this)} hidden={this.hideChequeInfo()}/> 
                </div>      
            </div>

            <div className="uk-width-1-3 uk-background-muted uk-margin-top uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            style={{borderWidth:1, borderColor:'lightgrey', borderStyle:'solid', borderLeftWidth:0, borderBottomWidth:0}}>
                <label className="uk-form-label" hidden={!this.state.thirdPartyBeneficiaryPayment}>Third party entity</label>
                <div className="uk-form-controls" hidden={!this.state.thirdPartyBeneficiaryPayment}>
                    <DynamicSelect disabled={true} className={regularInputClass} id="form-incomingpaymentform-beneficiaryId" placeholder='Select a Beneficiary...'
                    data={this.state.listThirdPartyBenes} objValue={{value: this.state.beneficiaryId}} getValue={this.handleBeneficiaryId.bind(this)} />
                </div>

                <label className="uk-form-label" hidden={this.hideChequeInfo()}>Cheque issue date</label>
                <div className="uk-form-controls" hidden={this.hideChequeInfo()}>
                    <DatePicker disabled={true} className={regularInputClass} id="form-incomingpaymentform-chequeIssueDate" value={this.state.chequeDate} 
                    onDayChange={this.handleChequeDate.bind(this)}/>
                </div> 
            </div>            
            
            {/*Line3*/}

            <div className="uk-width-1-4">
                <label className="uk-form-label">Delivery method</label>
                <DynamicSelect id="form-incomingpaymentform-deliverymethod"  disabled={true}
                    className={this.formatValidSelect(this.state.validations.deliveryMethod)} 
                    data={deliveryMethods} 
                    objValue={{value: this.state.deliveryMethod}} 
                    placeholder="Select a Delivery Method..."
                    getValue={this.handleDeliveryMethod}/>                
            </div>

            <div className="uk-width-2-3 uk-background-muted uk-margin-left uk-padding-small uk-padding-remove-top"
            style={{borderWidth:1, borderColor:'lightgrey', borderStyle:'solid', borderTopWidth:0}}>                
                <label className="uk-form-label" hidden={!this.state.showDirectDebitFields} > Customer bank account</label>
                <div className="uk-form-controls uk-width-4-5" hidden={!this.state.showDirectDebitFields}>
                    <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.customerBankAccountId)} id="form-incomingpaymentform-customerbankaccount"  placeholder='Select an Account...'
                    data={this.state.listCustomerBankAccounts} 
                    objValue={{value: this.state.customerBankAccountId}} getValue={this.handleCustomerBankAccount.bind(this)} 
                    />
                </div>

                <label className="uk-form-label" hidden={this.hideChequeInfo()}>Bank</label>
                <div className="uk-form-controls uk-width-4-5" hidden={this.hideChequeInfo()}>
                <DynamicSelect disabled={true} className={regularInputClass} id="form-incomingpaymentform-banks" placeholder='Select a Bank...'
                 data={this.state.listBanks} objValue={{value: this.state.chequeBankId}} getValue={this.handleChequeBank.bind(this)} />
                </div>
            </div>

            {/*Line4*/}
            <div className="uk-width-1-4">
                <label className="uk-form-label" >Date received</label>
                <DatePicker disabled={true} className={this.formatValidInput(this.state.validations.dateReceived)} id="form-incomingpaymentform-dateReceived" value={this.state.dateReceived} 
                onDayChange={this.handleDateReceived.bind(this)} />                
            </div>

            <div className="uk-width-1-4">
                <label className="uk-form-label">Payment reference no</label>
                <input className={regularInputClass} disabled={true} type="text" id="form-incomingpaymentform-paymentReferenceNo" value={this.state.paymentReferenceNo} onChange={this.handlePaymentReferenceNo.bind(this)}/>
            </div>

            <div className="uk-width-1-4">
                               
            </div>

            <div className="uk-width-1-4"></div>

            
            {/*Line5*/}
            <div className="uk-width-1-2">
            <label className="uk-form-label">Nostro account</label>
                <div className="uk-form-controls">
                <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.nostroAccountId)} id="form-incomingpaymentform-nostroaccounts" placeholder='Select an Account...' 
                data={this.state.listNostroAccounts} objValue={{value: this.state.nostroBankAccountId}} getValue={this.handleNostroBankAccount.bind(this)} />
                </div>
            </div>

            <div className="uk-width-1-2"></div>

            {/*end*/}

            </div>

            <div hidden> 
                <button disabled={true} className="uk-button uk-button-green" type="button" onClick={e => this.handleSaveClickAskConfirmation(e, false)}>
                    {saveButtonText}</button>
                <button disabled={true} className="uk-button uk-button-green" type="button" onClick={e => this.handleSaveClickAskConfirmation(e, true)}
                 hidden={this.hideSaveInPendingButton()}>Save In Pending</button>
                <button disabled={true} className="uk-button uk-button-green" type="button" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                <button disabled={true} className="uk-button uk-button-green" type="button" onClick={this.voidPayment} hidden={this.hideVoidButton()}>Void</button>
                {refundButton}
            </div>

            <hr></hr> {/* Apply */}

            {/*<div className="uk-width-1-1 uk-margin-right">                      
                <div className="uk-grid uk-margin" style={inputStyle}>
                    <div className="uk-width-1-5">
                    <h4>Payment application</h4>
                    </div>
                    {sectionApply} 
                </div>     
                              
            </div>  
            
           
            <div className="uk-width-1-1" id="payments-apply-component" hidden>
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1-2">
                            <label className="uk-form-label">Customer Payments Balance: {formatToCurrency(this.state.customerPaymentsBalance)} {this.state.currencyID}</label>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label uk-align-right">Combined Balance: {formatToCurrency(this.state.combinedBalance)}</label>
                    </div>            
                
                    <div className="uk-width-1-1">
                        <label className="uk-form-label uk-align-right">(Current Payment Bal: {formatToCurrency(this.state.currentPaymentBal)} + Fund On Hold Bal: {formatToCurrency(this.state.fundOnHoldBalance)})</label>
                    </div>
                </div>
                <div className="uk-width-1-1 border-table">
                    <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                        <thead>
                            {headers}
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
                <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>

                <div className="uk-width-1-1"> 
                    <button className={!validToApply ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                    onClick = {this.handleApplyPayment} disabled={!validToApply}>Apply payment</button>
                </div>
            </div>*/}

            <ul id="accordion-compliance" uk-accordion="multiple: true" >
                {/*Payment application*/}
                <li>
                    <button className="uk-accordion-title">Payment application</button>
                    <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                        <div id="payment-application">
                            <div className="uk-width-1-1" id="payments-apply-component">
                                <div className="uk-grid uk-margin">
                                    <div className="uk-width-1-2">
                                            <label className="uk-form-label">Customer Payments Balance: {formatToCurrency(this.state.customerPaymentsBalance)} {this.state.currencyID}</label>
                                    </div>
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label uk-align-right">Combined Balance: {formatToCurrency(this.state.combinedBalance)}</label>
                                    </div>            

                                    <div className="uk-width-1-1">
                                        <label className="uk-form-label uk-align-right">(Current Payment Bal: {formatToCurrency(this.state.currentPaymentBal)} + Fund On Hold Bal: {formatToCurrency(this.state.fundOnHoldBalance)})</label>
                                    </div>
                                </div>
                                <div className="uk-width-1-1 border-table">
                                    <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                                        <thead>
                                            {headers}
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>

                                <div className="uk-width-1-1"> 
                                    <button disabled={true} className={!validToApply ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                                    onClick = {this.handleApplyPayment} disabled={!validToApply}>Apply payment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                {/*Available payments*/}
                <li>
                    <button className="uk-accordion-title">Available payments</button>
                    <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                        <div id="available-payments">
                            <div className="uk-width-1-1" id="payments-component">
                                <div className="uk-grid uk-margin" hidden={hidePaymentsFilter}>
                                    <div className="uk-width-1-4">
                                        <div className="uk-form-controls">
                                            <label><input className="uk-checkbox" disabled={true} type="checkbox" checked={this.state.showFullyApplied} onChange={this.handleShowFullyAppliedUpdate}/> Show fully applied payments 
                                            </label>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4">
                                        <div className="uk-form-controls">
                                            <label>
                                                {/*<input className="uk-checkbox" type="checkbox" checked={this.state.showAvailablePayments} onChange={this.handleOnCheckAvailable.bind(this)}/> Show available payments */}
                                            </label>
                                        </div>
                                    </div>
                                </div>                

                                <NewDynamicTable id="alert-incomingpaymentform-table" disabled={true}
                                data={this.state.listCustomerPayments} 
                                enableClick={true} 
                                clickFunction={this.handleRowClick.bind(this)}
                                hiddenHeaders={["SettlementInstrument", "CustomerBankAccount", "Beneficiary ID", "ThirdPartyBeneficiaryPayment", "DeliveryMethod", "NeedBalanceAdjustment", "Funds Cleared On", "Funds Cleared By", "Payment Ref No"]}
                                hidden={this.props.paymentOpenFromBoard} 
                                enableSortByHeader={true} 
                                newSearch={this.state.newSearch}
                                formats={[{header:'Amount', formatFunction: formatToCurrency},
                                        {header:'Outstanding Balance', formatFunction:formatToCurrency}]}
                                />

                                <Paginator id="alert-incomingpaymentform-paginator" changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <hr></hr> {/* Payments */}
            {/*<div className="uk-width-1-1 uk-margin-right">                      
                <div className="uk-grid uk-margin" style={inputStyle}>
                    <div className="uk-width-1-5">
                    <h4>Available payments</h4>
                    </div>
                    {section} 
                </div>                       
            </div>  
            
            <div className="uk-width-1-1" id="payments-component" hidden>
                <div className="uk-grid uk-margin" hidden={hidePaymentsFilter}>
                    <div className="uk-width-1-4">
                        <div className="uk-form-controls">
                            <label><input className="uk-checkbox" type="checkbox" checked={this.state.showFullyApplied} onChange={this.handleShowFullyAppliedUpdate}/> Show fully applied payments 
                            </label>
                        </div>
                    </div>
                    <div className="uk-width-1-4">
                        <div className="uk-form-controls">
                            <label>
                                {/*<input className="uk-checkbox" type="checkbox" checked={this.state.showAvailablePayments} onChange={this.handleOnCheckAvailable.bind(this)}/> Show available payments 
                            </label>
                        </div>
                    </div>
                </div>                
            
                <NewDynamicTable id="alert-incomingpaymentform-table"
                data={this.state.listCustomerPayments} 
                enableClick={true} 
                clickFunction={this.handleRowClick.bind(this)}
                hiddenHeaders={["SettlementInstrument", "CustomerBankAccount", "Beneficiary ID", "ThirdPartyBeneficiaryPayment", "DeliveryMethod", "NeedBalanceAdjustment", "Funds Cleared On", "Funds Cleared By", "Payment Ref No"]}
                hidden={this.props.paymentOpenFromBoard} 
                enableSortByHeader={true} 
                newSearch={this.state.newSearch}
                formats={[{header:'Amount', formatFunction: formatToCurrency},
                        {header:'Outstanding Balance', formatFunction:formatToCurrency}]}
                />

                <Paginator id="alert-incomingpaymentform-paginator" changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>
            </div>*/}
            <AlertBox id="global-Alert"
                type="Ok" 
                open={this.state.showAlert} 
                title={this.state.alertTitle} 
                message={this.state.alertMessage}
                okClick={this.closeAlert}
                onClose={this.closeAlert}/>

            <AlertBox id="alert-void-incoming"
                type="Yes/No"
                open={this.state.showVoidIncomingAlert}
                title="Confirm"
                message="Are you sure you want to void this payment application?"
                yesClick={this.yesVoidIncomingFund} 
                noClick={this.closeVoidIncomingAlert} 
                onClose={this.closeVoidIncomingAlert}/> 
            
            <AlertBox id="alert-incomingpaymentform-save" 
                open={this.state.showAlertSave} 
                title="Confirm" 
                message={this.state.alertMessageSave}
                type="Yes/No" 
                yesClick={this.handleSaveClick.bind(this)} 
                noClick={this.closeAlertSave.bind(this)} 
                onClose={this.closeAlertSave.bind(this)}/> 
            
            <AlertBox id="apply-payments-alert"
                    open={this.state.showApplyPaymentsAlert} 
                    title="Please confirm" 
                    message = {"You will apply: " + formatToCurrency(amountTotalToApply) + ' ' + this.state.currencyID}
                    type = "Yes/No"
                    yesClick = {this.yesApplyPaymentsAlert.bind(this)}
                    noClick = {this.noApplyPaymentsAlert.bind(this)}
                    />
            <LoadingBox loading={this.state.loading}/>
        </form>
        );

        var refundForm = (
            <RefundForm sourceTable="Payment"
                sourceID={this.state.paymentID}
                customerID={this.state.customerID}
                balance={this.state.amount - this.state.disbursedAmount - this.state.refundedAmount}
                currencyID={this.state.currencyID}
                listNostroAccounts={this.state.listNostroAccounts}
                onBackClick={this.onRefundBackClick}/>
        );

        if(this.state.refundMode){
            return refundForm;
        }
        else{
            return regularScreen;
        }        
    }
}