import React from 'react';
import { getStyle } from '../../../../styles/styles';
import AuditEntity from './AuditEntity';
var style = getStyle();
export default class AuditOutgoingFundScreen extends React.Component {

    handleBackLink() {
        this.props.backLink();
    }

    render() {
        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    Audit Outgoing Funds
                </h3>
                <AuditEntity entityID={this.props.outgoingFundID} categoryName={"Audit - Outgoing Funds"} />
            </div>
        );
    }
}