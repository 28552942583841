export const validate = (fields) => {
    let valid = true;
    let errorList = [];
    let validations = {};
    fields.forEach(
        (field) => {
            validations[field.validateName] = true;
    
            if (field.validation !== undefined) {
                if (!field.validation(field.value)) {
                    valid = false;
                    errorList.push(field.displayName);
                    validations[field.validateName] = false;
                }
            } else {
                switch(field.type) {
                    default:
                    case 'string': {
                        if (field.value === undefined || field.value === 'select' || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'number': {
                        if (field.value === undefined || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'email': {
                        if (!validateEmail(field.value)) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                }
            }
        }
    )

    return [valid, validations, errorList];
}

const validateEmail = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.includes(';')) {
        const emails = email.split(';');
        if (emails.some(e => !e.match(regex))) {
            return false;
        }
    } else {
        if (!email.match(regex)) {
            return false;
        }
    }
    return true;
}