import { genericGetWithParameters,genericDeleteWithParameters } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getFeeStructure(){
    try {
        let agentCustomerID = await sessionStorage.getItem('AgentCustomerID');
        let params = {AgentCustomerId: agentCustomerID}
        let response = await genericGetWithParameters('FeeStructure/AgentGet',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeeStructureByID(id){
    try {
        let params = {
            'feeStructureID': id
        }
        let response = await genericGetWithParameters('FeeStructure/GetFeeStructureById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeesMin(){
    try {
        let params = {}
        let response = await genericGetWithParameters('Fees/GetFeesMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveFeeStructure(FeeStructure) {
    try {
        let agentCustomerID = await sessionStorage.getItem('AgentCustomerID');
        FeeStructure.AgentCustomerId = agentCustomerID;
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'FeeStructure/AgentSave',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(FeeStructure),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function deleteFeeStructure(feeStructureID) {
    try{
        let params = {
            'feeStructureID': feeStructureID
        };
        let response = await genericDeleteWithParameters('FeeStructure/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeeStructureMin(){
    try {
        let agentCustomerID = await sessionStorage.getItem('AgentCustomerID');
        let params = {
            AgentCustomerId: agentCustomerID,
        }
        let response = await genericGetWithParameters('FeeStructure/GetAgentFeeStructureMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFeeStructureDetails(id){
    try {
        let params = {
            'feeStructureID': id
        }
        let response = await genericGetWithParameters('FeeStructure/GetFeeStructureDetails',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveFeeStructureDetails(FeeStructureDetail) {
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'FeeStructure/SaveFeeStructureDetails',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(FeeStructureDetail),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function GetFeeStructureDetailById(id){
    try {
        let params = {
            'FeeStructureDetailId': id
        }
        let response = await genericGetWithParameters('FeeStructure/GetFeeStructureDetailById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function DeleteFeeStructureDetails(FeeStructureDetailId) {
    try{
        let params = {
            'FeeStructureDetailId': FeeStructureDetailId
        };
        let response = await genericDeleteWithParameters('FeeStructure/DeleteFeeStructureDetails',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}