import React from 'react';
import AlertBox from '../../../shared/AlertBox';
import { getContactOnlineInfo, saveRelatedCustomerRoles } from '../../../../networking/NetworkingContacts';
import { getCustomersAscendantsAndDescendants } from '../../../../networking/NetworkingCustomers';
import { getRolesByCustomerAndContact } from '../../../../networking/NetworkingRoles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { regularInputClass, regularSelectClass } from '../../../../styles/styles';
import NumberInput from '../../../shared/NumberInput';

const enableContactImpersonation = process.env.REACT_APP_ENABLE_CONTACT_IMPERSONATION;

const inputStyle= {
    paddingBottom:"15px"
}

export default class AccountInformationForm extends React.Component {
    state = {
        contact:{
            isConfirmEmailValidated: false,
            isEmailValidated: false,
            emailAddress: '',
            onlineConfirmEmail: '',
            password: '',
            confirmPassword: '',
            forwardDays: 0,
            onlineAccess: '',
            showChallenge: '',
            accountLocked: '',
            resetPassword: '',
            doublePassword:'',
            doubleEmail:'',

            //
            passwordExpire:'',
            listPasswordExpire:[
                {name:'0',value:'0'},
                {name:'15',value:'15'},
                {name:'30',value:'30'},
                {name:'45',value:'45'},
                {name:'60',value:'60'},
                {name:'75',value:'75'},
                {name:'90',value:'90'},
                {name:'180',value:'180'}
            ],
            previousPassword:'',
            listPreviousPassword:[
                {name:'0',value:'0'},
                {name:'1',value:'1'},
                {name:'2',value:'2'},
                {name:'3',value:'3'},
                {name:'4',value:'4'},
                {name:'5',value:'5'},
                {name:'6',value:'6'},
                {name:'7',value:'7'},
                {name:'8',value:'8'},
                {name:'9',value:'9'}
            ],
            selContact: {},
            
            //For tables
            listOnlineUserProfiles:[],
            onlineUserProfiles:'',
            rolesAvailableForOnlineUsers: [],
            listTestProfile:[],
            selectIDLeft:'',
            selectIDRight:'',
            listHave: [],//originLists
            listWant: [],//originLists
            available:'',
            relatedCustomerId: 0
        },
        //For related customers
        alertMessage: '',
        alertTitle: '',
        contactId: 0,
        customerId: 0,
        relatedCustomers: [],
        showAlert: false,
        test: []
    }

    closeAlert(){
        this.setState({showAlert: false});
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedContact !== {} && prevProps.selectedContact !== this.props.selectedContact){
            var av, email, onlinEmail, doub= '';
            this.setState({selContact: this.props.selectedContact, 
                contactId: this.props.selectedContact['Contact ID'], 
                customerId: this.props.selectedContact['Customer ID']
            })
            var contact = this.props.selectedContact;
            if(contact['Online Access']){av=false}else{av=true}
            if(contact['Email']!==contact['Online Confirmation Email']){
                doub= false;
            }else{
                doub= true;
            }
            var emailValidated = false;
            if(contact['Email']===null){
                email='';
                emailValidated = false;
            }else{
                email=contact['Email'];
                emailValidated = true;
            }
            var confirmEmailValidated = false;
            if(contact['Online Confirmation Email']===null){
                onlinEmail='';
                confirmEmailValidated = false;
            }else{
                onlinEmail=contact['Online Confirmation Email'];
                confirmEmailValidated = true;
            }
            var customerId = contact['Customer ID'];
            this.setState({contact:{...this.state.contact,
                doubleEmail: doub,
                doublePassword: doub,
                emailAddress: email,
                onlineConfirmEmail: onlinEmail,
                passwordExpire: contact.noDaysPasswordExpires,
                previousPassword: contact.noPreviousPwds,
                forwardDays: contact['Max Open Forwards Days'],
                onlineAccess: contact['Online Access'],
                showChallenge: contact['Show Challenge Questions'],
                accountLocked: contact.Islock,
                resetPassword: contact.ResetPassword,
                available: av,
                isEmailValidated: emailValidated,
                isConfirmEmailValidated: confirmEmailValidated,
                relatedCustomerId: customerId,
                }
            },()=> this.props.getAccountInfo(this.state.contact))
            if(customerId !== 'undefined' && customerId > 0){
                this.fillRelatedCustomerDropDown(customerId);
            }
        }
    }
    componentDidMount(){
        var contact = this.state.contact.selContact;
        var customerId = contact['Customer ID'];
        var contactId = contact['Contact ID'];
        var list = []
        this.setState({customerId: customerId});
        if(contactId !== 'undefined' && contactId > 0){
            this.setState({contactId: contactId});
        }
        getContactOnlineInfo(contact['Contact ID'],contact['PersonID'],contact['Email']).then(
            (json) =>{
                var helper = this.transformList(json.onlineUserProfiles);
                if(this.props.selectedContact.Roles=== null||this.props.selectedContact.Roles===undefined){list = []}else{list = this.props.selectedContact.Roles}
                this.setState({roles:json.rolesAvailableForOnlineUsers, contact:{...this.state.contact,
                    rolesAvailableForOnlineUsers: json.rolesAvailableForOnlineUsers,
                    listHave:json.rolesAvailableForOnlineUsers,
                    listWant: list,
                    listOnlineUserProfiles: helper}},()=> this.props.getAccountInfo(this.state.contact))
            }
        );
        if(customerId !== 'undefined' && customerId > 0){
            this.fillRelatedCustomerDropDown(customerId);
        }
    }

    fillRelatedCustomerDropDown(customerId){        
        getCustomersAscendantsAndDescendants(customerId).then(
            (json) => {
                this.setState({contact: {...this.state.contact, relatedCustomerId: json.customers[0].CustomerId}})
                for (var i = 0; i < json.customers.length; i++){
                    json.customers[i].name = json.customers[i].Customer;
                    json.customers[i].value = json.customers[i].CustomerId;
                }
                this.setState({relatedCustomers: json.customers});
                this.getRolesForRelatedCustomers(customerId);
            }
        )
    }

    //listOnlineUserProfiles
    transformList(list){
        var helper = [];
        var listTest={};
        for(var i = 0; i<list.length; i++){
            helper.push({'name': list[i].OnlineUserProfileName,
            'value': list[i].OnlineUserProfileID});
            listTest = list[i].onlineUserProfilesDetails
        }
        this.setState({contact:{...this.state.contact,listTestProfile: listTest}},()=> this.props.getAccountInfo(this.state.contact));
        return helper;
    }

    handleClearClick(){
        this.setState({contact:{...this.state.contact,
            emailAddress: '',
            onlineConfirmEmail: '',
            password: '',
            confirmPassword: '',
            forwardDays: '',
            onlineAccess: '',
            showChallenge: '',
            accountLocked: '',
            resetPassword: '',
            }          
        });
    }
    
    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
          this.setState({contact:{...this.state.contact,selectIDLeft}},()=> this.props.getAccountInfo(this.state.contact));
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
          this.setState({contact:{...this.state.contact,selectIDRight}},()=> this.props.getAccountInfo(this.state.contact));
        }
    };
    
    handleAddClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var idSelected = this.state.contact.selectIDLeft;
        var add = true
        if(idSelected !== '')
        {
            if(listRight.length === 0)
            {
                listLeft.forEach((role, index) => {
                    if(role.RoleName === idSelected)
                    {
                        listRight.push(role)
                        listLeft.splice(index,1)
                        this.setState({contact:{...this.state.contact,
                            listWant:  listRight,
                            listHave: listLeft,
                            selectIDLeft: ''}
                        },()=> this.props.getAccountInfo(this.state.contact));
                    }
                });
                
            }else
            {
                listRight.forEach(rightRole => {
                    if(rightRole.RoleName === idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftRole, index) => {
                            if(leftRole.RoleName === idSelected)
                            {
                                listRight.push(leftRole)
                                listLeft.splice(index,1)
                                this.setState({contact:{...this.state.contact,
                                    listWant:  listRight,
                                    listHave: listLeft,
                                    selectIDLeft: ''}
                                },()=> this.props.getAccountInfo(this.state.contact));
                            }
                        });
                    }
            }
        }
    }

    handleAddAllClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        
        Array.prototype.push.apply(listRight,listLeft)
        this.setState({contact:{...this.state.contact,
            listWant:  listRight,
            listHave: [],
            selectIDLeft: '',
            selectIDRight: ''}
        },()=> this.props.getAccountInfo(this.state.contact));
    }

    handleRemoveClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var idSelected = this.state.contact.selectIDRight;

        if(idSelected !== '')
        {
            listRight.forEach((rightRole, index) => {
                if(rightRole.RoleName === idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightRole)
                }
                this.setState({contact:{...this.state.contact,
                        listWant:  listRight,
                        listHave: listLeft,
                        selectIDRight: ''}
                    },()=> this.props.getAccountInfo(this.state.contact));
            });
        }
    }

    handleRemoveAllClick(event){
        event.preventDefault();
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        
        Array.prototype.push.apply(listLeft,listRight)
        this.setState({contact:{...this.state.contact,
            listWant:  [],
            listHave: listLeft,
            selectIDLeft: '',
            selectIDRight: ''}
        },()=> this.props.getAccountInfo(this.state.contact));
    }

    handleAddRolesClick(event){
        event.preventDefault();
        if(this.state.contact.onlineUserProfiles==='TEST Profile'){
            if(this.state.contact.listTestProfile!==undefined){
                this.setState({contact:{...this.state.contact,listWant: this.state.contact.listTestProfile}},()=> this.props.getAccountInfo(this.state.contact));
            }else{
                this.setState({contact:{...this.state.contact,listWant: []}},()=> this.props.getAccountInfo(this.state.contact));
            }
        }
    }
    handleUpdateEmailAddress(event){
        this.setState({contact:{...this.state.contact,emailAddress: event.target.value}},
            ()=> {
                if (this.validateEmail()) {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailConfirmValid(true);
                } else {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailConfirmValid(false);
                }}
            );
    }

    handleUpdateOnlineConfirmEmail(event){
        if(this.state.contact.emailAddress===event.target.value){
            this.setState({contact:{...this.state.contact, onlineConfirmEmail: event.target.value, doubleEmail: true}},()=> 
            {
                if (this.validateConfirmEmail()) {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailValid(true);
                } else {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailValid(false);
                }
            });
        }else{
            this.setState({contact:{...this.state.contact, onlineConfirmEmail: event.target.value, doubleEmail: false}},()=> 
            {
                if (this.validateConfirmEmail()) {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailValid(true);
                } else {
                    this.props.getAccountInfo(this.state.contact)
                    this.isEmailValid(false);
                }
            });
        }
    }
    validateEmail() {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.contact.emailAddress.match(regex)) {
            return false;
        }
        return true;
    }
    isEmailValid(validator){
        if(validator){
            this.setState({contact:{...this.state.contact,isEmailValidated: true}},()=>this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,isEmailValidated: false}},()=>this.props.getAccountInfo(this.state.contact));
        } 
    }
    validateConfirmEmail() {
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.contact.onlineConfirmEmail.match(regex)) {
            return false;
        }
        return true;
    }
    isEmailConfirmValid(validator){
        if(validator){
            this.setState({contact:{...this.state.contact,isConfirmEmailValidated: true}},()=>this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,isConfirmEmailValidated: false}},()=>this.props.getAccountInfo(this.state.contact));
        } 
    }
    handleUpdatePassword(event){
        this.setState({contact:{...this.state.contact, password: event.target.value}},()=> this.props.getAccountInfo(this.state.contact));
    }

    handleUpdateConfirmPassword(event){
        if(this.state.contact.password===event.target.value){
            this.setState({contact:{...this.state.contact,confirmPassword: event.target.value, doublePassword:true}},()=> this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,confirmPassword: event.target.value, doublePassword:false}},()=> this.props.getAccountInfo(this.state.contact));
        }
    }

    handleUpdateForwardDays(event){
        this.setState({contact:{...this.state.contact,forwardDays: event.target.value}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleUpdatePasswordExpire(value){
        this.setState({contact:{...this.state.contact,passwordExpire: value.value}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleUpdatePreviousPassword(value){
        this.setState({contact:{...this.state.contact,previousPassword: value.value}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleUpdateOnlineProfileName(value){
        this.setState({contact:{...this.state.contact,onlineUserProfiles: value.name}},()=> this.props.getAccountInfo(this.state.contact));
    }

    //checkboxes
    handleOnlineAccess(event){
        if(event.target.checked){
            this.setState({contact:{...this.state.contact,onlineAccess: event.target.checked,available: false}},()=> this.props.getAccountInfo(this.state.contact));
        }else{
            this.setState({contact:{...this.state.contact,onlineAccess: event.target.checked,available: true}},()=> this.props.getAccountInfo(this.state.contact));
        }
    }
    handleShowChallenge(event){
        this.setState({contact:{...this.state.contact,showChallenge: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleAccountLocked(event){
        this.setState({contact:{...this.state.contact,accountLocked: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }
    handleResetPassword(event){
        this.setState({contact:{...this.state.contact,resetPassword: event.target.checked}},()=> this.props.getAccountInfo(this.state.contact));
    }

    //related customers
    handleGetRelatedCustomers(value){
        this.setState({contact:{...this.state.contact, relatedCustomerId: parseInt(value.value)}},()=> this.props.getAccountInfo(this.state.contact));
        if(value.value !== undefined){
            this.getRolesForRelatedCustomers(parseInt(value.value));
        }
    }

    getRolesForRelatedCustomers(relatedCustomerId){        
        getRolesByCustomerAndContact(relatedCustomerId, this.state.contactId).then(
            (json) => {
                this.setState({test: json});
                var listLeft = this.state.contact.rolesAvailableForOnlineUsers;
                var listRight = json;
                
                if(listRight.length === 0)
                {
                    this.setState({contact:{...this.state.contact,
                        listWant: [],
                        listHave: listLeft}
                    });
                    
                }else
                {
                    var helper = [];
                    var rolesHave = [];
                    var rolesWant = [];
                    for(var k = 0; k < listRight.length; k++){
                        helper.push(listRight[k]);
                    }
                    for (let i = helper.length - 1; i >= 0; i--)
                     {
                         for( var j = 0; j < listLeft.length; j++)
                         {
                             if(helper[i] === listLeft[j].RoleName)
                             {
                                rolesWant.push(listLeft[j]);
                             }
                         }
                     }
                     for(let i = 0; i < listLeft.length; i++)
                     {
                        var result = rolesWant.find(role => role.RoleName === listLeft[i].RoleName)
                        if(result === undefined || result === '')
                        {
                            rolesHave.push(listLeft[i]);
                        }
                     }
                     this.setState({contact:{...this.state.contact, 
                        listHave: rolesHave,
                        listWant: rolesWant}, test: rolesHave
                    },()=> this.props.getAccountInfo(this.state.contact));

                }
            }
        )
    }

    formatRelatedCustomerVisibility() {
        if(enableContactImpersonation !== undefined){
            if(enableContactImpersonation){
                return "flex";
            }
        }else{return "none";}
    }

    handleSaveRolesClick(event){
        if(this.state.contactId !== 'undefined' && this.state.contactId > 0){
            var roles = [];
            for(var i = 0; i < this.state.contact.listWant.length; i++){
                roles.push(this.state.contact.listWant[i].RoleName);
            }
            saveRelatedCustomerRoles(this.state.contact.relatedCustomerId, this.state.contact.emailAddress, this.state.contactId, roles).then(
                (json) => {
                    console.log(json);
                    if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                        this.setState({showAlert: true, alertMessage: json.Message, alertTitle: 'Error'}); 
                    }
                    else{
                        this.setState({showAlert: true, alertMessage: 'Roles Added!', alertTitle: 'Success'}); 
                    }
                }
            )
        }
    }

    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass;
        var listLeft = this.state.contact.listHave;
        var listRight = this.state.contact.listWant;
        var available = this.state.contact.available;
        return (
            <div>
                <ul id="accordion-online-access" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <button className="uk-accordion-title">User Information</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="user-information">
                                <form className="uk-grid">
                                    <div className="uk-width-1-2" style={{marginTop: '-10px'}}>
                                        <label className="uk-form-label">Email Address</label>
                                        <div className="uk-form-controls">
                                            <input disabled={true} className={classNameInput} id="emailAddress" type="text" placeholder="" value={this.state.contact.emailAddress} onChange={this.handleUpdateEmailAddress.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2" style={{marginTop: '-10px'}}>
                                        <label className="uk-form-label">Online Confirmation Email</label>
                                        <div className="uk-form-controls">
                                            <input disabled={true} className={classNameInput} id="onlineConfirmEmail" type="text" placeholder="" value={this.state.contact.onlineConfirmEmail} onChange={this.handleUpdateOnlineConfirmEmail.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label">Password</label>
                                        <div className="uk-form-controls">
                                            <input disabled={true} className={classNameInput} id="password" type="password" placeholder="" value={this.state.contact.password} onChange={this.handleUpdatePassword.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2">
                                        <label className="uk-form-label">Confirm Password</label>
                                        <div className="uk-form-controls">
                                            <input disabled={true} className={classNameInput} id="confirmPassword" type="password" placeholder="" value={this.state.contact.confirmPassword} onChange={this.handleUpdateConfirmPassword.bind(this)} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open">
                        <button className="uk-accordion-title">Permissions and Account Configuration</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="permissions-account-configuration">
                                <form className="uk-grid">
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input disabled={true} className="uk-checkbox" id="onlineAccess" type="checkbox" checked={this.state.contact.onlineAccess} onChange={this.handleOnlineAccess.bind(this)} />Online Access
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input disabled={true} className="uk-checkbox" id="showChallenge" type="checkbox" checked={this.state.contact.showChallenge} onChange={this.handleShowChallenge.bind(this)} />Show Challenge Questions
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input disabled={true} className="uk-checkbox" id="accountLocked" type="checkbox" checked={this.state.contact.accountLocked} onChange={this.handleAccountLocked.bind(this)} /> Account Locked
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">
                                            <input disabled={true} className="uk-checkbox" id="resetPassword" type="checkbox" checked={this.state.contact.resetPassword} onChange={this.handleResetPassword.bind(this)} /> Reset password on next login
                                        </label>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">Password expire every</label>
                                        <div className="uk-form-controls">
                                            <DynamicSelect disabled={true} id="expire-every" className={classNameSelect} getValue={this.handleUpdatePasswordExpire.bind(this)} listHasPlaceholder={true} data={this.state.contact.listPasswordExpire} objValue={{ value: this.state.contact.passwordExpire }} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label"># of previous password to store</label>
                                        <div className="uk-form-controls">
                                            <DynamicSelect disabled={true} id="previous-password" className={classNameSelect} getValue={this.handleUpdatePreviousPassword.bind(this)} listHasPlaceholder={true} data={this.state.contact.listPreviousPassword} objValue={{ value: this.state.contact.previousPassword }} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4" style={{marginTop: '10px'}}>
                                        <label className="uk-form-label">Forward Days</label>
                                        <div className="uk-form-controls">
                                            <NumberInput disabled={true} id="forwardDays" type="Integer" className="uk-input" placeholder="__0" value={this.state.contact.forwardDays} onChange={this.handleUpdateForwardDays.bind(this)} />
                                        </div>
                                    </div>
                                </form>
                                <form className="uk-grid" style={{ display: this.formatRelatedCustomerVisibility() }}>
                                    <div className="uk-grid uk-width-2-3">
                                        <div className="uk-width-3-5">
                                            <label className="uk-form-label">Related Customers</label>
                                            <DynamicSelect disabled={true} id="related-customers" className="uk-select" getValue={this.handleGetRelatedCustomers.bind(this)} data={this.state.relatedCustomers} objValue={{ value: this.state.contact.relatedCustomerId }} />                                            
                                        </div>
                                        <div className="uk-width-2-5" style={{marginTop: '20px'}}>
                                            <button disabled={true} className="uk-button uk-button-green" type="button" onClick={this.handleSaveRolesClick.bind(this)}>Save Roles</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open">
                        <button className="uk-accordion-title">Roles</button>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="roles">
                                <form className="uk-grid">
                                    <div className="uk-width-1-1">
                                        <div className="uk-margin" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                            <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 500 }}>
                                                <table disabled={true} className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                    <tbody>
                                                        {listLeft.map((item) => {
                                                            return (
                                                                <tr disabled={true} key={item.RoleName} onClick={this.roleChangeColorLeft(item.RoleName)} style={this.state.contact.selectIDLeft === item.RoleName ? { backgroundColor: '#8bc34a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                    <td disabled={true} style={this.state.contact.selectIDLeft === item.RoleName ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                        {item.RoleName}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="uk-card-body uk-width-1-3" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                                <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.handleAddClick.bind(this)}>Add</button>
                                                <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.handleAddAllClick.bind(this)}>Add all</button>
                                                <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.handleRemoveClick.bind(this)}>Remove</button>
                                                <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.handleRemoveAllClick.bind(this)}>Remove all</button>
                                                <DynamicSelect disabled={true} id="online-user" className="uk-select uk-width-1-2" getValue={this.handleUpdateOnlineProfileName.bind(this)} listHasPlaceholder={false} placeholder="Select a Profile Name" data={this.state.contact.listOnlineUserProfiles} objValue={{ value: this.state.contact.onlineUserProfiles }} />
                                                <button disabled={true} className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20, marginTop: 20 }} onClick={this.handleAddRolesClick.bind(this)}>Add Roles</button>
                                            </div>
                                            <div className="uk-width-1-3" style={{ overflowY: 'scroll', height: 500, paddingLeft: 0 }}>
                                                <table disabled={true} className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                    <tbody>
                                                        {listRight.map((item) => {
                                                            return (
                                                                <tr disabled={true} key={item.RoleName} onClick={this.roleChangeColorRight(item.RoleName)} style={this.state.contact.selectIDRight === item.RoleName ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                    <td disabled={true} style={this.state.contact.selectIDRight === item.RoleName ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                        {item.RoleName}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}