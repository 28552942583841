const pjson = require('../../package.json');

export function getCurrentVersionNumber(){
    return pjson.version;
}

export function getCurrentCompanyName(){
    return pjson.companyName;
}

export function getDefaultPagingNumberOfItems(){
    return pjson.defaultPagingNumberOfItems;
}

export function getEODParameters(){
    return pjson.eodParameters;
}