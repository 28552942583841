import React from 'react';

import IncomingPaymentForm from './IncomingPaymentForm'
import WizardSearchCustomerForm from '../trade/wizard/WizardSearchCustomerForm';

import {getCurrencyList} from '../../networking/NetworkingCurrencies';
import {getLookUpTable} from '../../networking/Networking';

import {getStyle} from '../../styles/styles'
var style = getStyle();



export default class PaymentScreen extends React.Component {
    state = {
        paymentID: 0,
        objCustomer: {},
        step: 1,
        listIncomingInstruments : [],
        listActiveCurrencies : [],
        typeRecordsApply : ''
    }

    componentDidMount() {
        console.log(this.props)
        if(this.props.data !== undefined && this.props.data > 0)
        {
        this.setState({
            paymentID: this.props.data
        });
        } 
        else
        {
            this.loadCurrencyList();
            this.loadIncomingInstruments();
        }
    }

    handleBackLink() {
        this.props.backLink();
    }

    onClickStepTab = (value) => {
        if(this.state.step === 1){
            //Nothing at all
        }
        else{
            this.setState({step: value});            
        }
    }

    ///Customer screen
    handleCustomerSelection(obj, nextStep){
        if(nextStep === 2){            
            this.setState({step: nextStep, 
                objCustomer: obj
            });            
        }  
    }

    loadCurrencyList() {
        getCurrencyList().then(
            (response) => {
                if (typeof response !== "undefined") {
                    let currencies = response.currencies;
                    currencies.forEach(element => {
                        element.value = element.CurrencyID;
                        element.name = element.CurrencyIDName;
                    });
                    this.setState({
                        listActiveCurrencies: currencies
                    });
                }
            }
        );
    }

    loadIncomingInstruments() {
        getLookUpTable('Incoming Instruments').then(
            (response) => {
                if (typeof response !== "undefined" && response.lookUpTables !== "undefined") {
                    let instruments = response.lookUpTables[0];
                    instruments.lookUpTableDetails.forEach(element => {
                        element.value = element.LookUpTableDetail;
                        element.name = element.LookUpTableDetail;
                    });
                    var listInstruments = [];
                    listInstruments.push(instruments);
                    this.setState({
                        listIncomingInstruments: listInstruments
                    });                    
                }
            });
    }

    render(){

        var stepScreen = [];

        var classNameStep1 = '', classNameStep2 = '';//, classNameStep4 = '';

        switch(this.state.step){
            case 1:
            default:
                classNameStep1 = 'uk-active';
                stepScreen.push(<WizardSearchCustomerForm key="customer-form"
                    onCustomerSelected={this.handleCustomerSelection.bind(this)}/>);
                break;
            case 2:
                classNameStep2 = 'uk-active';
                stepScreen.push(<IncomingPaymentForm key="payment-form"
                customerID={this.state.objCustomer["Customer ID"]}
                activeCurrencies={this.state.listActiveCurrencies}
                incomingInstruments={this.state.listIncomingInstruments}
                typeRecordsApply = {this.state.typeRecordsApply}
                paymentOpenFromBoard={false}/>);
                break;
        }

        return(
            <div>
                
                    <h3 className="uk-heading-divider" style={style.titleStyle} >
                        <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink.bind(this)}>
                            <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                        </button> 
                            Payment Information
                    </h3>
                <div>
                    <ul className="uk-tab">
                    <li className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>
                    <li className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Payment</a></li>
                   
                </ul>
                {stepScreen}
                </div>
                
            </div>
        );
    }
}