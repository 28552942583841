import React from 'react';
import { getAddressByPersonID, getAddressByID, saveAddress, deleteAddress } from '../../../../networking/NetworkingAddresses';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import AlertBox from '../../../shared/AlertBox';
import AddressForm from '../../addresses/AddressForm';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

export default class AddressesScreen extends React.Component {

    state = {
        address: null,
        addresses: [],
        newSearch: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteAddress: {},
        pages: 1,
        totalPages: 0,
        isValidatedAddress: false,
        validateAddressFields: true,
        clearFields: false,
        errors: []
    }

    componentDidMount() {
        this.handleUpdateGrid();
    }

    handleGetAddressInfo = (address) => {
        this.setState({ address: address });
    }

    handleIsValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                errors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                errors: validator.errors
            });
        }
    }

    handleRowClick = (row) => {
        getAddressByID(row["Address ID"]).then(
            (address) => {
                address.PersonID = this.props.data;
                this.setState({
                    address: address
                });
            }
        )
    }

    handleUpdateGrid = async () => {
        const addresses = await this.getAllAddresses();
        if (addresses !== undefined) {
            const totalCount = addresses.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ addresses, totalPages });
        }
    }

    handleClearFields = () => {
        this.setState({
            address: null,
            clearFields: true
        }, () => {
            this.setState({ clearFields: false });
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getAllAddresses = async () => {
        const json = await getAddressByPersonID(this.props.data);
        return json.addresses;
    }

    handleSaveAddressClick = async (event) => {
        event.preventDefault();
        if (this.state.isValidatedAddress) {
            const model = {
                PersonID: this.props.data,
                AddressID: this.state.address.AddressId,
                AddressType: this.state.address.AddressType,
                AddressValue: this.state.address.AddressValue,
                CountryID: this.state.address.CountryId,
                StateID: this.state.address.StateId,
                City: this.state.address.City,
                Address2: this.state.address.Address2,
                ZipCode: this.state.address.ZipCode,
                OtherState: this.state.address.OtherState,
                DefaultAddress: this.state.address.DefaultAddress,
                SinceWhen: this.state.address.SinceWhen,
                Status: this.state.address.Status,
                District: this.state.address.District,
                POBox: this.state.address.POBox,
                UpdatedBy: sessionStorage.getItem('UserID'),
                UpdateToken: this.state.address.UpdateToken
            }
            const json = await saveAddress(model);
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlertError: true, alertMessage: 'An error occurred while trying to save the address. Please try again.', alertTitle: 'Error'
                })
            } else {
                this.setState({
                    showAlert: true, alertTitle: 'Success', alertMessage: 'Address successfully saved.'
                });
                this.handleClearFields();
            }
            this.handleUpdateGrid();
        } else {
            this.setState({
                showAlertError: true,
                alertMessage: 'Please fill all mandatory fields',
                alertTitle: 'Error',
                validateAddressFields: false
            });
        }
    }

    handleClearClick = (event) => {
        event.preventDefault();
        this.handleClearFields();
    }

    handleDeleteClick = (obj) => {
        this.setState({ showDeleteAlert: true, readyToDeleteAddress: obj });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false, validateAddressFields: true });
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteAddress: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert = async () => {
        const json = await deleteAddress(this.state.readyToDeleteAddress['Address ID']);
        console.log(json);
        this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
        this.handleUpdateGrid();
    }

    okSuccessAlert = () => {
        this.handleClearFields();
        this.setState({ showSuccessDeleteAlert: false });
    }

    render() {
        return (
            <div>
                <div className="">
                    <AddressForm selectedAddress={this.state.address} getAddressInfo={this.handleGetAddressInfo} isValid={this.handleIsValidated} validations={this.state.validateAddressFields} clearFields={this.state.clearFields} />
                    <div>
                        <button disabled={true} className="uk-button uk-button-green" onClick={this.handleSaveAddressClick} >Save</button>
                        <button disabled={true} className="uk-button uk-button-green" onClick={this.handleClearClick} >Clear</button>
                        <hr />
                    </div>
                    <NewDynamicTable
                        id="ndt-addresses-screen"
                        data={this.state.addresses}
                        hiddenHeaders={["Address ID"]}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        //useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        useFilter={true}
                        filterFunction={this.getAllAddresses} />
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />
                    <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this address?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Address successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                </div>
            </div>
        );
    }
}