import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import AlertBox from '../../shared/AlertBox';
import NumberInput from '../../shared/NumberInput';
import { getLookUpTable } from '../../../networking/Networking';
import { getCurrencyList, searchCurrencies } from '../../../networking/NetworkingCurrencies';
import { getFeeStructureMin,saveFeeStructureDetails } from '../../../networking/NetworkingFeeStructure';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import { validate } from '../../../helpers/ValidateHelper';
import { formatValidSelect } from '../../../helpers/FormatHelper';
import { getStyle } from '../../../styles/styles';
const style = getStyle();

export default class FeeStructureForm extends React.Component {
    state = {
        feeStructureDetailList: [],
        instrumentList: [],
        deliveryMethodList: [],
        foreignCurrencyList: [],
        percentFlatList: [],
        feeSelectValue:'',
        feeSelectName:'',
        instrumentValue:'',
        instrumentName:'',
        deliveryMethodValue:'',
        deliveryMethodName:'',
        foreignCurrencyValue:'',
        foreignCurrencyName:'',
        feeStructureDetailId: '',
        percentage:'',
        lowerTier:'',
        upperTier:'',
        minimumFee:'',
        maximumFee:'',
        percentFlatValue:'',
        percentFlatName:'',
        updateToken:'',
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        notFirstLoad:false,
        errors:[],
        validations: {
            feeSelectValue: true,
            foreignCurrencyValue: true,
            instrumentValue: true,
            deliveryMethodValue: true,
            percentFlatValue: true
        }
    }
    
    componentDidMount(){ 
        getFeeStructureMin().then(
            (value) => {
                var feesStructureMin = this.transformFeeStructureDetailList(value.feeStructures|| []);
                var helper = [];
                for(var i=0;i<feesStructureMin.length;i++){
                    feesStructureMin[i].value = feesStructureMin[i]['FeeStructureID'];
                    feesStructureMin[i].name = feesStructureMin[i]['Description'];
                    helper.push(feesStructureMin[i]);
                }
                console.log(helper);
                this.setState({feeStructureDetailList: helper},()=>{
                    if(this.props.selectedFeeStructureDetail.FeeStructureID !== undefined){
                        var FeeStructureDetail = this.props.selectedFeeStructureDetail;
                        this.setState({feeSelectValue:FeeStructureDetail.FeeStructureID},()=>this.props.feeSelectValue(FeeStructureDetail.FeeStructureID))
                    }
                });
            }
        );        
        getLookUpTable('Outgoing Instruments').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                }
                console.log(helper);
                this.setState({instrumentList: helper});
            }
        );
        getLookUpTable('Charge Types').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                }
                console.log(helper);
                this.setState({percentFlatList: helper});
            }
        ); 
        getLookUpTable('Payment Delivery Method').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                }
                console.log(helper);
                this.setState({deliveryMethodList: helper});
            }
        ); 
        /*getCurrencyList().then(
            (value) => {
                var feesStructureMin = this.transformCurrencyList(value.currencies);
                var helper = [];
                for(var i=0;i<feesStructureMin.length;i++){
                    feesStructureMin[i].value = feesStructureMin[i]['CurrencyID'];
                    feesStructureMin[i].name = feesStructureMin[i]['CurrencyIDName'];
                    helper.push(feesStructureMin[i]);
                }
                console.log(helper);
                this.setState({foreignCurrencyList: helper});
            }
        );*/
    }

    
    componentDidUpdate(prevProps){
        if(prevProps.selectedFeeStructureDetail !== this.props.selectedFeeStructureDetail){
            var FeeStructureDetail = this.props.selectedFeeStructureDetail;
            var feeStructureDetailId = 0;
            
            if(FeeStructureDetail.FeeStructureDetailId !== null){
                feeStructureDetailId = FeeStructureDetail['FeeStructureDetailId'];
            }

            if(FeeStructureDetail['FgnCurrencyID'] !== null)
            {
                this.search(FeeStructureDetail['FgnCurrencyID']);
            }

            console.log(FeeStructureDetail);
            this.setState({
                feeStructureDetailId : feeStructureDetailId,
                feeSelectValue: FeeStructureDetail['FeeStructureID'],
                instrumentValue: FeeStructureDetail['Instrument'],
                deliveryMethodValue: FeeStructureDetail['DeliveryMethod'],
                percentFlatValue: FeeStructureDetail['PercentFlat'],
                percentage: FeeStructureDetail['Percentage'],
                lowerTier: FeeStructureDetail['LowerTier'],
                upperTier: FeeStructureDetail['UpperTier'],
                minimumFee: FeeStructureDetail['Minimum'],
                maximumFee: FeeStructureDetail['Maximum'],
                foreignCurrencyValue: FeeStructureDetail['FgnCurrencyID'],
                updateToken: FeeStructureDetail['UpdateToken']
            });
        };
        if(this.props.clearForm !== {} && this.props.clearForm === true){
            this.setState({
                feeStructureDetailId:'',
                feeSelectValue:'',
                instrumentValue:'',
                deliveryMethodValue:'',
                foreignCurrencyValue:'',
                percentage:'',
                lowerTier:'',
                upperTier:'',
                minimumFee:'',
                maximumFee:'',
                percentFlatValue:'',
                updateToken:''           
            },()=>this.props.outClearForm(false));
        }
    }

    transformFeeStructureDetailList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'FeeStructureID': list[i]['FeeStructureID'],
            'Description': list[i]['Description']});
        }
        return helper;
    }

    transformCurrencyList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'CurrencyID': list[i]['CurrencyID'],
            'CurrencyIDName': list[i]['CurrencyIDName']});
        }
        return helper;
    }

    search(query){ 
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    if(json!==undefined){
                        for(var i=0; i<json.length; i++){
                            helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                        }
                        if(json.length>1){
                            this.setState({foreignCurrencyList: helper});
                        }else{
                            this.setState({validations: {...this.state.validations, foreignCurrencyValue: true},foreignCurrencyList: helper, foreignCurrencyName: helper[0].name});
                        }
                    }else{
                        this.setState({foreignCurrencyList: helper});
                    }                    
            }}
        );
    }

    getFeeSelect(value){
        console.log(value);    
        if(value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({feeSelectValue: ''});
            this.props.feeSelectValue(undefined);
        }
        else{
            this.setState({validations: {...this.state.validations, feeSelectValue: true},feeSelectValue: value.value});
            this.props.feeSelectValue(value.value);    
        }        
    }
    
    getInstrumentSelect(value){
        console.log(value);        
        if(value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({instrumentValue: ''});
        }
        else{
            this.setState({validations: {...this.state.validations, instrumentValue: true},instrumentValue: value.value});
        }        
    }

    getDeliveryMethodSelect(value){
        console.log(value);
        if(value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({deliveryMethodValue: ''});   
        }
        else{
            this.setState({validations: {...this.state.validations, deliveryMethodValue: true},deliveryMethodValue: value.value});        
        }
    }    

    getForeignCurrencySelect(value){
        console.log(value);        
        if(value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({foreignCurrencyValue: ''});        
        }
        else{
            this.setState({validations: {...this.state.validations, foreignCurrencyValue: true},foreignCurrencyValue: value.value});        
        }
    }
    
    getPercentFlatSelect(value){
        console.log(value);        
        if(value.value === undefined || value.value.toLowerCase().includes('select')){
            this.setState({percentFlatValue: ''});        
        }
        else{
            this.setState({validations: {...this.state.validations, percentFlatValue: true},percentFlatValue: value.value});        
        }
    }

    handleClearClick = (event) => {
        event.preventDefault();
        this.setState({
            feeStructureDetailId:'',
            feeSelectValue:'',
            instrumentValue:'',
            deliveryMethodValue:'',
            deliveryMethodName:'',
            foreignCurrencyValue:'',
            foreignCurrencyName:'',
            percentage:'',
            lowerTier:'',
            upperTier:'',
            minimumFee:'',
            maximumFee:'',
            percentFlatValue:'',
            updateToken:'',
            validations: {
                feeSelectValue: true,
                foreignCurrencyValue: true,
                instrumentValue: true,
                deliveryMethodValue: true,
                percentFlatValue: true
            }         
        },()=>{
            this.props.feeSelectValue('')
            this.props.isClearDetail(true);
            });
    }

    handleSaveClick(event){
        event.preventDefault();
        let UserID = sessionStorage.getItem('UserID');
        //var objFeeStructureDetail = this.props.selectedFeeStructureDetail;
        var feeStructureDetailId=this.state.feeStructureDetailId;
        if(feeStructureDetailId ==''){
            feeStructureDetailId = 0;
        }
        var objectFilter = {
            FeeStructureDetailId: feeStructureDetailId,
            FeeStructureID: this.state.feeSelectValue,
            Instrument:this.state.instrumentValue,
            DeliveryMethod: this.state.deliveryMethodValue,
            PercentFlat: this.state.percentFlatValue,
            Percentage: this.state.percentage,
            LowerTier:this.state.lowerTier,
            UpperTier:this.state.upperTier,
            Minimum:this.state.minimumFee,
            Maximum:this.state.maximumFee,
            FgnCurrencyID:this.state.foreignCurrencyValue,
            UpdateToken:this.state.updateToken,            
            userID: UserID
        }
        if(this.validateRequiredInputs()){
            console.log(objectFilter);
            if(this.validateMaxMin()){
                saveFeeStructureDetails(objectFilter).then(
                    (json)=>{          
                        if (json.status !== 200){
                            this.setState({showAlert: true, alertMessage: 'An error occurred while trying to save the Fee Structure Details. Please try again.', alertTitle: 'Error'});
                        } else {
                            this.setState({
                                feeStructureDetailId:'',
                                instrumentValue:'',
                                deliveryMethodValue:'',
                                foreignCurrencyValue:'',
                                foreignCurrencyName:'',
                                percentage:'',
                                lowerTier:'',
                                upperTier:'',
                                minimumFee:'',
                                maximumFee:'',
                                percentFlatValue:'',
                                updateToken:'',
                                showAlert: true, alertTitle: 'Fee Structure Detail Successfully saved.'});
                            this.props.updateFeeStructureDetailsTable(this.state.feeSelectValue);
                        }
                    }
                    );
            }
        }
    }

    //Inputs Validations
    validateRequiredInputs(){
        const fields = [
            {displayName:'Fee', validateName: 'feeSelectValue', value:this.state.feeSelectValue, type: 'string'},
            {displayName:'Foreign Currency', validateName: 'foreignCurrencyValue', value:this.state.foreignCurrencyValue, type: 'string'},
            {displayName:'Instrument', validateName: 'instrumentValue', value: this.state.instrumentValue, type: 'string'},
            {displayName:'Delivery Method', validateName: 'deliveryMethodValue', value: this.state.deliveryMethodValue, type: 'string'},
            {displayName:'Percent / Flat', validateName: 'percentFlatValue', value:this.state.percentFlatValue, type: 'string'}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    validateMaxMin(){
        var maxFee=this.state.maximumFee;
        var minFee=this.state.minimumFee;
        var maxTier=this.state.upperTier;
        var minTier=this.state.lowerTier;
        if(maxFee===''){maxFee=0}
        if(minFee===''){minFee=0}
        if(maxTier===''){maxTier=0}
        if(minTier===''){minTier=0}        
        if(maxTier<minTier){
            this.setState({showAlert: true, alertTitle: 'Error', alertMessage: "Lower Tier can't be higher than Upper Tier."});
            return false;
        }
        if(maxFee<minFee){
            this.setState({showAlert: true, alertTitle: 'Error', alertMessage: "Minimum fee can't be higher than maximum fee."});
            return false;
        }
        return true;
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }

    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }
    handleUpdatePercentage(event){
        this.setState({percentage: event.target.value});
    }

    handleUpdateLowerTier(event){
        this.setState({lowerTier: event.target.value});
    }

    handleUpdateUpperTier(event){
        this.setState({upperTier: event.target.value});
    }

    handleUpdateMinimumFee(event){
        this.setState({minimumFee: event.target.value});
    }

    handleUpdateMaximumFee(event){
        this.setState({maximumFee: event.target.value});
    }

    render(){ 
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-fee" style={style.boldLabel}>Fee (*)</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.feeSelectValue)} id="form-fee-structure-detail-fee" getValue={this.getFeeSelect.bind(this)} placeholder="Select a Fee Structure..." data={this.state.feeStructureDetailList} 
                                objValue={{value: this.state.feeSelectValue, name: this.state.feeSelectName}}/>  
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-instrument" style={style.boldLabel}>Instrument (*)</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.instrumentValue)} id="form-fee-structure-detail-instrument" getValue={this.getInstrumentSelect.bind(this)} data={this.state.instrumentList}
                                objValue={{value: this.state.instrumentValue, name: this.state.instrumentName}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-deliverymethod" style={style.boldLabel}>Delivery Method (*)</label>
                            <div className="uk-form-controls">                                    
                                <DynamicSelect className={formatValidSelect(this.state.validations.deliveryMethodValue)} id="form-fee-structure-detail-deliverymethod" getValue={this.getDeliveryMethodSelect.bind(this)} data={this.state.deliveryMethodList}
                                objValue={{value: this.state.deliveryMethodValue, name: this.state.deliveryMethodName}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-fcurrency" style={style.boldLabel}>Foreign Currency (*)</label>
                            <div className="uk-form-controls">                               
                                {/*<DynamicSelect className={classNameInputFCurrency} id="form-fee-structure-detail-fcurrency" getValue={this.getForeignCurrencySelect.bind(this)} placeholder="Select a Currency..." data={this.state.foreignCurrencyList}
                                objValue={{value: this.state.foreignCurrencyValue, name: this.state.foreignCurrencyName}}/>*/}
                                <DynamicSearchSelect className={formatValidSelect(this.state.validations.foreignCurrencyValue)} id="form-fee-structure-detail-fcurrency" objValue={{value: this.state.foreignCurrencyValue,name: this.state.foreignCurrencyName}} placeholder="Select a Currency..." searchFunction={this.search.bind(this)} data={this.state.foreignCurrencyList} getValue={this.getForeignCurrencySelect.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-percentage">Percentage</label>
                            <div className="uk-form-controls">                            
                                <NumberInput className="uk-input" id="form-fee-structure-detail-percentage" placeholder="0,00" type="Rate" scale={2} value={this.state.percentage} onChange={this.handleUpdatePercentage.bind(this)}/>
                            </div>
                        </div>
                    </div>       
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-percentflat" style={style.boldLabel}>Percent / Flat (*)</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className={formatValidSelect(this.state.validations.percentFlatValue)} id="form-fee-structure-detail-percentflat" getValue={this.getPercentFlatSelect.bind(this)} data={this.state.percentFlatList}
                                objValue={{value: this.state.percentFlatValue, name: this.state.percentFlatName}}/>                                
                            </div>
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-lowertier">Lower Tier</label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" id="form-fee-structure-detail-lowertier" placeholder="0" type="Integer" value={this.state.lowerTier} onChange={this.handleUpdateLowerTier.bind(this)}/>
                            </div>
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-uppertier">Upper Tier</label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" id="form-fee-structure-detail-uppertier" placeholder="0" type="Integer" value={this.state.upperTier} onChange={this.handleUpdateUpperTier.bind(this)}/>
                            </div>
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-minimumfee">Minimum Fee</label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" id="form-fee-structure-detail-minimumfee" placeholder="0" type="Integer" value={this.state.minimumFee} onChange={this.handleUpdateMinimumFee.bind(this)}/>
                            </div>
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-fee-structure-detail-maximumfee">Maximum Fee</label>
                            <div className="uk-form-controls">
                                <NumberInput className="uk-input" id="form-fee-structure-detail-maximumfee" placeholder="0" type="Integer" value={this.state.maximumFee} onChange={this.handleUpdateMaximumFee.bind(this)}/>
                            </div>
                        </div>
                    </div>      
                    <div className="uk-width-1-2 uk-form-stacked"></div>
                    <div className="uk-width-2-2 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <hr/>
                    </div>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>
                </form>
            </div>
        );
    }
}