

export const ActionName = {
    EditCustomerInfo : "EditCustomerInfo",
    ShowDealInfo : "ShowDealInfo",
    ShowDepositInfo : 'ShowDepositInfo',
    ShowCustomerInfo : 'ShowCustomerInfo',
    DealVersions : "Deal Versions",
    BeneficiaryInfo : "Beneficiary Info",
    AddPayment : "AddPayment",
    AddDeposit : "AddDeposit",
    AddRequest : "AddRequest",
    ShowPaymentInfo : "ShowPaymentInfo",
    ShowCustomerPaymentScreen: "ShowCustomerPaymentScreen",
    ShowCountryAdditionalSettings: "ShowCountryAdditionalSettings",
}
