import React from 'react';


export default class CustomersSearchForm extends React.Component {
    state = {
        customerName: '',
    }

    componentDidMount() {
        var name = this.props.searchedString;
        this.setState({customerName: name});
    }

    handleClearClick() {
        this.setState({customerName: ''});
        this.props.clearData();
    }

    handleSearchClick() {
        this.props.updateTable();
    }

    handleAddCustomerClick() {
        this.props.addCustomer();
    }

    handleUpdateSearchString(event){
        this.setState({customerName: event.target.value});
        this.props.updateSearchedCustomerName(event.target.value);
    }

    render() {
        return(
            <div className="uk-grid uk-margin">
                <div className="uk-width-1-2">
                    <div className="uk-margin"><input className="uk-input" type="text" placeholder="Customer Name" onChange={this.handleUpdateSearchString.bind(this)}  value={this.state.customerName}/></div>
                </div>
                <div className="uk-width-1-2" style={{marginTop: '-5px'}}>
                    <button className="uk-button uk-button-green" onClick={this.handleSearchClick.bind(this)}>Search</button>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear</button>
                    <button disabled={true} className="uk-button uk-button-green" onClick={this.handleAddCustomerClick.bind(this)}>Add</button>
                </div>
            </div>
        );
    }
}