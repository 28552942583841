import React from 'react';

import './App.scss';
import './css/font-awesome.min.css'

import LoginForm from './components/login/LoginForm';
import ResetPasswordForm from './components/login/ResetPasswordForm';
import SideNav, {NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './css/react-sidenav.css';

import styled from 'styled-components';

import {logout, validateSession} from './networking/Networking';

import CustomersScreen from './components/pre_trade/customers/CustomersScreen';
import FeeMaintenanceScreen from './components/maintenance/fee_maintenance/FeeMaintenanceScreen';
import PricingScreen from './components/maintenance/pricing/PricingScreen';
import DealSearchScreen from './components/deal/DealSearchScreen';
import FeeStructureScreen from './components/maintenance/feeStructure/FeeStructureScreen';
import {getParametersByName} from './networking/NetworkingGlobalSystemParameters';

import AlertBox from './components/shared/AlertBox';
import LoadingBox from './components/shared/LoadingBox';
import NewBeneficiaryMaintenance from './components/pre_trade/customers/newBeneficiary/NewBeneficiaryMaintenance';
import CustomerOverviewScreen from './components/back_office/CustomerOverviewScreen';


const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 20px;
    margin-left: ${props => (props.expanded ? 240 : 64)}px;
`;

const StyledSideNav = styled(SideNav)`
  background-color: #8bc34a;
  position: fixed;
  list-style: none;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

export default class extends React.PureComponent {
  state = {
    expanded: false,
    notLogin: false,
    sessionValid: false,
    showSessionInvalidAlert: false,
    loading: false,
  };

  componentDidMount(){
    var session = setInterval(this.sessionTick.bind(this),60000);
    this.setState({sessionFunc: session});
    
    let alreadyLogin = sessionStorage.getItem('AlreadySessionCreated');
    if(alreadyLogin){
      let sessionCreatedOn = sessionStorage.getItem('SessionCreatedOn');
      let now = Date.now();
      let timeDiff = (now-sessionCreatedOn)/1000;
      if (timeDiff < 3600){
        let screenPermisions = JSON.parse(sessionStorage.getItem('ScreenShowPermissions'));
        this.setState({sessionValid: true, notLogin: true});
        this.setState(screenPermisions);
      } else {
        sessionStorage.clear();
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.state.sessionFunc);
  }

  onToggle = (expanded) => {
    this.setState({expanded: expanded});
  };

  handleLoginClick(){
    this.setState({sessionValid: true},() => {
      this.setState({notLogin: true, loading: true},
        () => {
          getParametersByName('SystemDate,CutoffTime,LastClosingDate,NumberOfDaysToCreateBaseDDForEOD,NumberOfDaysToCreateNoneBaseDDForEOD,BaseCurrency,EnableFundsOnHoldInPaymentScreen').then(
            (value) => {
              if(value.parameters !== undefined){
                for(var i=0; i<value.parameters.length; i++){
                  sessionStorage.setItem(value.parameters[i].Name, value.parameters[i].Value);
                }
              }
              this.setState({loading: false});
            }
          );
        });
    })
  }

  closeSessionAlert(){
    this.setState({showSessionInvalidAlert:false, sessionValid: false, notLogin: false});
  }

  sessionTick() {
    let stopGettingSession = (sessionStorage.getItem('StopGettingSession') === 'true') || false;

    if (this.state.sessionValid && !stopGettingSession) {
      validateSession().then(
        (value) => {
          if (value !== undefined) {
            if (value.httpStatusCode !== 200) {
              this.setState({showSessionInvalidAlert: true, sessionValid: false});
            }
          } else {
            this.setState({showSessionInvalidAlert: true, sessionValid: false});
          }
          let alreadyLogin = sessionStorage.getItem('AlreadySessionCreated');
          if(!alreadyLogin){
            this.setState({showSessionInvalidAlert: true, sessionValid: false});
          }
        }
      )
    }
  }

  handleLogoutClick(history){
    logout().then(
        (value) => {
            if(value != null){
              this.setState({notLogin:false, showSessionInvalidAlert: false, sessionValid: false});
              history.push('/');
            } else {
                window.alert("Check your Internet Connection.");
            }
        }
    );
  }

  render(){
    const {expanded} = this.state;
    if (window.location.pathname.substring(0, 15) === "/resetPassword/") {
      return(
        <div>
            <Router>
              <Route exact={true} path="/" component={() => <div>Test</div>}/>
              <Route path="/resetPassword/:token" component={({match, history}) => <ResetPasswordForm match={match} history={history} />} />
            </Router>
        </div>
      );
    }
    if (window.location.pathname.substring(0, "/NewBeneficiary".length) === "/NewBeneficiary") {
      return(
        <div>
            <Router>
              <Route exact={true} path="/" component={() => <div>New Beneficiary Test</div>}/>
              <Route path="/:key" component={({match, history}) => <NewBeneficiaryMaintenance match={match} history={history}/>} />
            </Router>
        </div>
      );
    }
    if(!this.state.notLogin){
      return(
        <div className="App">
          <LoginForm loginSuccess={this.handleLoginClick.bind(this)}/>
        </div>        
      );
    } else {
      return(
        <div>
        <Router>
            <Route render={({ location, history }) => (
                <React.Fragment>
                    <StyledSideNav
                    onSelect={(selected) => {
                        const to = '/' + selected;
                        if (location.pathname !== to) {
                            history.push(to);
                        }
                        if(selected === "logout"){
                            this.handleLogoutClick(history);
                            //history.push('/');
                        }
                    }}
                    onToggle = {this.onToggle}
                    >
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected="root">
                        <NavItem eventKey="pretrade">
                          <NavIcon>
                            <i className="fa fa-fw fa-users" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                          </NavIcon>
                          <NavText>
                            Pre Trade
                          </NavText>
                          <NavItem eventKey="pretrade/customers">
                            <NavText style={{ paddingLeft: 40 }}>Customers</NavText>
                          </NavItem>
                          <NavItem eventKey="pretrade/customer_overview">
                            <NavText style={{ paddingLeft: 40 }}>Customer 360</NavText>
                          </NavItem>
                        </NavItem>
                        <NavItem eventKey="deal">
                            <NavIcon>
                                <i className="fa fa-fw fa-briefcase" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}/>
                            </NavIcon>
                            <NavText>
                                  Back Office
                            </NavText>
                            <NavItem eventKey="deal/search">
                              <NavText style={{paddingLeft: 40}}>Deal Search</NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="rate_pricing">
                          <NavIcon>
                            <i className="fa fa-fw fa-dollar" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                          </NavIcon>
                          <NavText>Rates & Pricing</NavText>
                          <NavItem eventKey="rate_pricing/pricing">
                            <NavText style={{ paddingLeft: 40 }}>Pricing</NavText>
                          </NavItem>
                        </NavItem>
                        <NavItem eventKey="maintenance">
                          <NavIcon>
                            <i className="fa fa-fw fa-wrench" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                          </NavIcon>
                          <NavText>
                            Maintenance
                          </NavText>
                          <NavItem eventKey="maintenance/feeStructure">
                            <NavText style={{ paddingLeft: 40 }}>Fee Structure</NavText>
                          </NavItem>
                        </NavItem>
                        <NavItem eventKey="logout">
                            <NavIcon>
                                <i className="fa fa-fw fa-sign-out" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }} />
                            </NavIcon>
                            <NavText>
                                Logout
                            </NavText>
                        </NavItem>
                    </SideNav.Nav>
                </StyledSideNav>
                <Main expanded={expanded}  className="e2e">
                    <Route path="/pretrade/customers" component={props => <CustomersScreen/>} />
                    <Route path="/pretrade/customer_overview" component={props => <CustomerOverviewScreen/>} />
                    <Route path="/deal/Search" component={props => <DealSearchScreen/>} />
                    <Route path="/maintenance/pricing" component={props => <PricingScreen/>} />
                    <Route path="/maintenance/fee_maintenance" component={props => <FeeMaintenanceScreen/>} />                    
                    <Route path="/maintenance/feeStructure" component={props => <FeeStructureScreen/>} />                    
                    <Route path="/rate_pricing/pricing" render={props => <PricingScreen  {...props}  />} />
                </Main>
            </React.Fragment>
            )}/> 
        </Router>
        <LoadingBox loading={this.state.loading}/>
        <AlertBox key="sessionAlert" id="session-invalid" open={this.state.showSessionInvalidAlert} title="Invalid Session" message="The current session is invalid. Please log in again." type="Ok" okClick={this.closeSessionAlert.bind(this)}/>
        </div>
      );
    }
  }
}

//export default App;
/* 
<Route path="/" exact component={props => <Root/>} />
<Route path="/contents" component={props => <Contents/>} />
<Route path="/devices" component={props => <Devices/>} />
<Route path="/engines" component={props => <Engines/>}/>
<Route path="/allocations" component={props => <Allocations/>}/>
<Route path="/libraries" component={props => <Libraries/>}/>

*/