import React from 'react';
import LocalAccountForm from './LocalAccountForm';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import GenericBankAccountForm from './GenericBankAccountForm';
import { getBankAccountsByAccountID, saveBankAccount } from '../../../../networking/NetworkingBankAccounts';
import BankAccountPropertiesForm from './BankAccountPropertiesForm';
var style = getStyle();

export default class BankAccountScreen extends React.Component {
    state = {
        customer : {},
        activeTab: "localAccount",
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        bankAccounts: [],
        localAccount: null,
        intermediary: null,
        correspondent: null,
        loading: true,
        isValidatedLocalAccount: false,
        isValidatedIntermediaryAccount: true,
        validateLocalAccountFields: true,
        validateIntermediaryAccountFields: true,
        saveProperties: false,
        bankAccountID: 0,
        localAccountErrors: [],
        intermediaryAccountErrors: []
    }

    componentDidMount() {
        if (this.props.data !== null && this.props.data.BankAccountID !== undefined) {
            getBankAccountsByAccountID(this.props.data.BankAccountID).then(
                (json) => {
                    if (json !== undefined && json["#results"] !== undefined) {
                        var localAccount = {
                            ABANo: json["#results"][0]["ABA No"],
                            AccountNo: json["#results"][0]["Account No"],
                            BankID: json["#results"][0]["Bank ID"],
                            BankName: json["#results"][0]["Bank Name"],
                            BranchID: json["#results"][0]["Branch ID"],
                            BranchName: json["#results"][0]["Branch Name"],
                            CreatedBy: json["#results"][0].CreatedBy,
                            CreatedOn: json["#results"][0].CreatedOn,
                            Currency: json["#results"][0].Currency,
                            DefaultAccount: json["#results"][0].DefaultAccount,
                            Description: json["#results"][0].Description,
                            Status: json["#results"][0].Status,
                            Swift: json["#results"][0].SWIFT,
                            TransitNo: json["#results"][0]["Transit No"],
                            UpdateToken: json["#results"][0].UpdateToken,
                            UpdatedBy: json["#results"][0].UpdatedBy,
                            UpdatedOn: json["#results"][0].UpdatedOn,
                            UserID: sessionStorage.getItem('UserID')
                        };
                        var intermediary = JSON.parse(json["#results"][0].Intermediate);
                        var correspondent = JSON.parse(json["#results"][0].Correspondent);
                        correspondent = correspondent === null ? null : correspondent[0];
                        intermediary = intermediary === null ? null : intermediary[0];
                        if (intermediary !== null){
                            intermediary = {
                                ABANo: intermediary["ABA No"],
                                AccountNo: intermediary["Account No"],
                                BankAccountID: intermediary["BankAccount ID"],
                                BankID: intermediary["Bank ID"],
                                BankName: intermediary["Bank Name"],
                                BranchID: intermediary["Branch ID"],
                                BranchName: intermediary["Branch Name"],
                                Swift: intermediary["SWIFT"],
                                TransitNo: intermediary["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        if (correspondent !== null) {
                            correspondent = {
                                ABANo: correspondent["ABA No"],
                                AccountNo: correspondent["Account No"],
                                BankAccountID: correspondent["BankAccount ID"],
                                BankID: correspondent["Bank ID"],
                                BankName: correspondent["Bank Name"],
                                BranchID: correspondent["Branch ID"],
                                BranchName: correspondent["Branch Name"],
                                Swift: correspondent["SWIFT"],
                                TransitNo: correspondent["Transit No"],
                                UpdateToken: intermediary.UpdateToken
                            };
                        }
                        this.setState({
                            localAccount: localAccount,
                            intermediary: intermediary,
                            correspondent: correspondent,
                            bankAccountID: this.props.data.BankAccountID,
                            loading: false
                        });
                    } else {
                        this.setState({
                            bankAccountID: 0,
                            loading: false
                        });
                    }
                }
            )
        } else {
            this.setState({
                bankAccountID: 0,
                loading: false
            });
        }
    }

    handleBackLink = () => {
        this.setState({
            localAccount: null,
            intermediary: null,
            correspondent: null,
            loading: true
        }, () => this.props.backLink());        
    }

    handleSaveClick = (event) => {
        if (this.state.isValidatedLocalAccount && this.state.isValidatedIntermediaryAccount) {
            var intermediary = null;
            if (this.state.intermediary !== null && (this.state.intermediary.BankID !== "select" && this.state.intermediary.BankID !== '' && this.state.intermediary.BankID !== undefined)) {
                intermediary = {
                    ABANo: this.state.intermediary.ABANo,
                    AccountCurrencyID: null,
                    AccountType: "Intermediate",
                    Active: true,
                    BankAccount: this.state.intermediary.AccountNo,
                    BankAccountID: this.props.data === null ? null: this.state.intermediary.BankAccountID,
                    BankAccountParentID: null,
                    BankBranchID: this.state.intermediary.BranchID,
                    BankID: this.state.intermediary.BankID,
                    CreatedOn: null,
                    DefaultAccount: false,
                    DefaultDeliveryMethod: null,
                    Description: "",
                    GLAccountID: 0,
                    IsSettlementAccount: false,
                    PersonID: this.props.personID,
                    PublishOnline: false,
                    Swift: this.state.intermediary.Swift,
                    TransitNo: this.state.intermediary.TransitNo,
                    UpdateToken: this.state.intermediary.UpdateToken,
                    UserID: sessionStorage.getItem('UserID')
                }
            }
            var model = {
                localAccount: {
                    ABANo: this.state.localAccount.ABANo,
                    AccountCurrencyID: this.state.localAccount.Currency,
                    AccountType: "Actual / Local",
                    Active: this.state.localAccount.Status,
                    BankAccount: this.state.localAccount.AccountNo,
                    BankAccountID: this.props.data === null ? '': this.props.data.BankAccountID,
                    BankAccountParentID: null,
                    BankBranchID: this.state.localAccount.BranchID,
                    BankID: this.state.localAccount.BankID,
                    CreatedOn: this.state.localAccount.CreatedOn,
                    DefaultAccount: this.state.localAccount.DefaultAccount,
                    DefaultDeliveryMethod: null,
                    Description: this.state.localAccount.Description,
                    GLAccountID: 0,
                    IsSettlementAccount: false,
                    PersonID: this.props.personID,
                    PublishOnline: false,
                    Swift: this.state.localAccount.Swift,
                    TransitNo: this.state.localAccount.TransitNo,
                    UpdateToken: this.state.localAccount.UpdateToken,
                    UserID: sessionStorage.getItem('UserID')
                },
                intermediateAccount: intermediary,
                correspondantAccount: null
            }
            console.log(model);
            saveBankAccount(model).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        this.setState({showAlertError: true, alertMessage: 'An error occurred while trying to save the bank account. Please try again.', alertTitle: 'Error'});
                    } else {
                        this.setState({saveProperties: true},
                                () => {
                                    this.setState({
                                        showAlert: true, alertTitle: 'Success', alertMessage: 'Bank account successfully saved.',
                                        localAccount: {...this.state.localAccount, UpdateToken: json.updateToken}
                                    });
                                    this.props.updatePage();
                                }
                            );
                    }
                }
            )
        } else {
            if (!this.state.isValidatedLocalAccount && !this.state.isValidatedIntermediaryAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateLocalAccountFields: false,
                    validateIntermediaryAccountFields: false
                });
            } else if (!this.state.isValidatedLocalAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateLocalAccountFields: false
                });
            } else if (!this.state.isValidatedIntermediaryAccount) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    validateIntermediaryAccountFields: false
                });
            }
        }
    }

    isValidatedLocalAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedLocalAccount: true,
                validateLocalAccountFields: true,
                localAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedLocalAccount: false,
                localAccountErrors: {tab: "Main Account", errors: validator.errors}
            });
        }
    }

    isValidatedIntermediaryAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedIntermediaryAccount: true,
                validateIntermediaryAccountFields: true,
                intermediaryAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedIntermediaryAccount: false,
                intermediaryAccountErrors: {tab: "Intermediary Account", errors: validator.errors}
            });
        }
    }

    getLocalAccount = (localAccount) => {
        this.setState({localAccount: localAccount});
    }

    getIntermediaryAccount = (intermediaryAccount) => {
        this.setState({intermediary: intermediaryAccount});
    }

    getSaveProperties = (value) => {
        this.setState({saveProperties: value});
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''}, () => this.props.backLink());
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: '', validateLocalAccountFields: true, validateIntermediaryAccountFields: true});
    }

    render() {
        if (this.state.loading) {
            return (<div></div>);
        }

        return (
            <div>                
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    Bank Account
                </h3>
                <ul uk-tab="">
                    <li>
                        <a href="#">Main Account</a>
                    </li>
                    <li>
                        <a href="#">Intermediary</a>
                    </li>
                    <li>
                        <a href="#">Properties</a>
                    </li>
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li>
                        <LocalAccountForm data={this.state.localAccount} getLocalAccount={this.getLocalAccount} isValid={this.isValidatedLocalAccount} validated={this.state.validateLocalAccountFields} />
                    </li>
                    <li>
                        <GenericBankAccountForm data={this.state.intermediary} getBankAccount={this.getIntermediaryAccount} isValid={this.isValidatedIntermediaryAccount} validated={this.state.validateIntermediaryAccountFields} />
                    </li>
                    <li>
                        <BankAccountPropertiesForm getSaveProperties={this.getSaveProperties} bankAccountID={this.state.bankAccountID} saveProperties={this.state.saveProperties} />
                    </li>
                </ul>
                <button disabled={true} className="uk-button uk-button-green" onClick={this.handleSaveClick}>Save</button>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.localAccountErrors, this.state.intermediaryAccountErrors]} type="Ok" okClick={this.closeAlertError}/>
            </div>
        )
    }
}