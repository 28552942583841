import jwt from 'jsonwebtoken';
import {genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function searchCustomers(search){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let params = {
            'TextToSearch': search,
            'Page': 0,
            'Items': 10
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'CustomerSearch/Get?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        console.log(responseJson.customers);
        return responseJson.customers;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerByID(customerID){
    try {
        let params = {
            'customerID': customerID
        }

        let response = await genericGetWithParameters('Customer/GetCustomerById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchUserReferredByPerson(name){
    try {
        let params = {
            'Name': name
        }

        let response = await genericGetWithParameters('User/SearchUserReferredByPersonMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCustomer(customer) {
    try {
        let response = await genericCallWithBody('POST', "Customer/UpdateCustomer", customer)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createCustomer(customer){
    try {
        let response = await genericCallWithBody('POST', "Customer/CreateCustomer", customer)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRiskScoreRanges() {
    try {
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreRanges', {});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRiskScoreDetails(customerID){
    try {
        let params = {
            EntityType: "Customer",
            EntityID: customerID,
            checkBankScore: true
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetRiskScoreDetails', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerRiskScore(customerID){
    try {
        let params = {
            CustomerID: customerID
        }
        let response = await genericGetWithParameters('RiskScoreRange/GetCustomerRiskScore', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getScreenInformation(agentCustomerID) {
    try {
        const params = {
            'AgentCustomerID': agentCustomerID
        }
        let response = await genericGetWithParameters('Customer/GetAgentScreenInformation', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchCustomerByIdNameMin(customer){
    try {
        let params = {
            'customer': customer
        }
        debugger
        let response = await genericGetWithParameters('Customer/SearchCustomerByIdNameMinForAffiliate', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBankAccountsByCurrency(customerID, currencyID){
    try {
        let params = {"customerID":customerID,
            "currencyID": currencyID,
        };

        let response = await genericGetWithParameters('Customer/GetBankAccountsByCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllLimitProfilesByCustomerID(customerID, page, items) {
    try {
        let params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Customer/GetAllLimitProfilesByCustomerID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLimitProfile(id){
    try {
        let params = {
            'CustomerLimitProfileID': id
        }

        let response = await genericGetWithParameters('Customer/GetCustomerLimitProfileByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveLimitProfile(model) {
    try {
        var method = 'POST';
        var apiName = 'Customer/SaveCustomerLimitProfiles'

        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteCustomerLimitProfile(id){
    try{
        let params = {
            'CustomerLimitProfileID': id
        };
        let response = await genericDeleteWithParameters('Customer/DeleteCustomerLimitProfileByID',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getTradingInformationByCustomerID(customerID) {
    try{
        let params = {"customerID":customerID};

        let response = await genericGetWithParameters('Customer/GetTradingInformationByCustomerId', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCustomerTradingInformation(customer) {
    try {
        var request = {
            CustomerID: customer.CustomerID,
            TraderID: customer.TraderID,
            DefaultDepositPercent: customer.DefaultDepositPercent,
            MaximumExposureRatio: customer.MaximumExposureRatio,
            MaximumExposureLimit: customer.MaximumExposureLimit,
            ExposureLimitType: customer.ExposureLimitType,
            DefaultIncomingNostroBankID: customer.DefaultIncomingNostroBankID,
            DefaultOutgoingNostroBankID: customer.DefaultOutgoingNostroID,
            AverageTradeSize: customer.AverageTradeSize,
            ConfirmationType: customer.ConfirmationType,
            MonthlyTradingVolume: customer.MonthlyTradingVolume,
            ConfirmationLimit: customer.ConfirmationLimit,
            EmailConfirmations: customer.EmailConfirmations,
            FaxConfirmations: customer.FaxConfirmations,
            DealNotification: customer.DealNotification,
            SMSConfirmation: customer.SMSConfirmation,
            Email: customer.Email,
            MobilePhone: customer.MobilePhone,
            DefaultSettlementInstrument: customer.DefaultSettlementInstrument,
            DefaultDeliveryMethod: customer.DefaultDeliveryMethod,
            CreditLimit: customer.CreditLimit,
            ManualRateLimit: customer.ManualRateLimit,
            SpotLimit: customer.SpotLimit,
            ForwardLimit: customer.ForwardLimit,
            SpreadHistoryDays: customer.SpreadHistoryDays,
            SpotLimitWarningOrEnforce: customer.SpotLimitWarningOrEnforce,
            ForwardLimitWarningOrEnforce: customer.ForwardLimitWarningOrEnforce,
            BankFixInterface: customer.Interface,
            UpdatedBy: sessionStorage.getItem('UserID'),
            UpdateToken: customer.UpdateToken
        }
        console.group("UpdateRequest");
        console.log(customer);
        console.groupEnd();
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Customer/UpdateTradingInformation', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(request),
        });
        let responseJson = await response.json();
        console.group("updateTradingInformation", customer.CustomerID);
        console.log(responseJson);
        console.groupEnd();
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function getFundOnHoldByCustomerAndCurrency(customerID, currencyID) {
    try{
        let params = {"customerID": customerID,
            "currencyID": currencyID,
        };

        let response = await genericGetWithParameters('Customer/GetFundOnHoldByCustomerAndCurrency', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveOnlineAccess(model) {
    try {
        var method = 'POST';
        var apiName = 'Customer/SaveOnlineAccessInformation'

        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//ApprovalTiers
export async function deleteApprovalTier(model)
{
    try{
        let params = {
            'CustomerID': model.CustomerID,
            'EntityType': model['Entity Type'],
            'FromTier': model['From Tier'],
            'ToTier': model['To Tier'],
            'RequiredApprovals': model['Required Approvals'],
            'UpdatedBy': model.UpdatedBy,
            'UpdateToken': model.UpdateToken
        };
        let response = await genericCallWithBody('Delete','ApprovalTier/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getApprovalTiersByCustomerID(customerID, page, items) {
    try{
        let params = {
            "CustomerID": customerID,
            "Page": page,
            "Items": items
        };

        let response = await genericGetWithParameters('ApprovalTier/GetApprovalTiersByCustomerID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getApprovalTiersByEntityType(customerID, entityType, page, items) {
    try{
        let params = {
            "CustomerID": customerID,
            "EntityType": entityType,
            "Page": page,
            "Items": items
        };

        let response = await genericGetWithParameters('ApprovalTier/GetApprovalTiersByEntityType', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveApprovalTier(model) {
    try {
        var method = 'POST';
        var apiName = 'ApprovalTier/SaveApprovalTiersByCustomerID'

        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getApprovalTiersInfo(customerID, entityType, toTier, fromTier) {
    try{
        let params = {
            "CustomerID": customerID,
            "EntityType": entityType,
            "ToTier": toTier,
            "FromTier": fromTier
        };

        let response = await genericGetWithParameters('ApprovalTier/GetApprovalTierInfo', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOtherSettlementFunds(customerID, page, items){
    try {
        let params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items
        }
        
        let response = await genericGetWithParameters('Customer/GetOtherSettlementFunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerOverview(customerID){
    try {
        let params = {
            'customerID': customerID,
        }        
        let response = await genericGetWithParameters('Customer/GetOverview', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomersAscendantsAndDescendants(customerID){
    try{

        let params = {
            'CustomerID': customerID,
        }
        let response = await genericGetWithParameters('Customer/GetCustomersAscendantsAndDescendants_min', params)
        return response;
    } catch (error) {
        console.error(error);
    }
}