import React from 'react';
import NumberInput from '../shared/NumberInput';
import {formatDate, formatToCurrency} from '../../helpers/FormatHelper';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import {regularSelectClass, notFilledSelectClass, regularInputClass, notFilledInputClass} from '../../styles/styles'
import {createOutgoingFund} from '../../networking/NetworkingFunds';
import {getNostroBankAccountsForFunds} from '../../networking/NetworkingBankAccounts';

import {getBeneficiaryForDisbursementsScreen} from '../../networking/NetworkingBeneficiaries'
import {getInstrumentsByCustomer} from '../../networking/NetworkingInstruments';
import {getRefunds, voidRefund} from '../../networking/NetworkingFunds';

import AlertBox from '../shared/AlertBox';
import NewDynamicTable from '../shared/NewDynamicTable';

export default class RefundForm extends React.Component {
    state = {
        alertMessage: '',
        alertTitle: '',
        amount: 0,
        balance: 0,
        beneficiaries: '',
        beneficiaryID: 0,
        bankAccountID: 0,
        deliveryMethod: '',
        deliveryMethods: {},
        instrument: '',
        listNostroAccounts: [],
        nostroBankAccountID: 0,
        outgoingFundsID: 0,
        outgoingInstruments: [],
        recordToDelete: {},
        refunds: [],
        showAlert: false,
        showVoidAlert: false,
        validations:{
            amount: true,
            nostroAccount: true,
            beneficiary: true,
            valueDate: true,
            instrument: true
        },
        valueDate: undefined,
    }

    componentDidMount(){
        //Get customer instruments
        getInstrumentsByCustomer(this.props.customerID).then(
            (json) => {
                //alert(JSON.stringify(json));
                let jsonInstruments = json.instruments;
                if(jsonInstruments !== undefined){
                    var outgoingHelper = [];
                    var deliveryMethods = {};
                    for(var i=0; i<jsonInstruments.length; i++){
                        var instrumentElement = {value:jsonInstruments[i].Instrument,
                            name:jsonInstruments[i].Instrument};
                        if(jsonInstruments[i].InstrumentType === 'Outgoing'){
                            instrumentElement.deliveryMethods = jsonInstruments[i].DeliveryMethods;
                            instrumentElement.accountsOnly = jsonInstruments[i].AccountsOnly;
                            outgoingHelper.push(instrumentElement);

                            var list = [];
                            if(jsonInstruments[i].DeliveryMethods !== ''){
                                var methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                                for(var j=0; j<methodsList.length; j++){
                                    var item = {value:methodsList[j], name:methodsList[j]};
                                    list.push(item);
                                }
                            }
                            deliveryMethods[jsonInstruments[i].Instrument] = list;
                        }
                    }
                    this.setState({
                        outgoingInstruments: outgoingHelper,
                        deliveryMethods: deliveryMethods,
                    });
                }
            }
        );

        //Get beneficiaries
        getBeneficiaryForDisbursementsScreen(this.props.customerID, '').then(
            (response) => {
                response.beneficiaries.forEach(element => {
                    element.value = element.Value;
                    element.name = element.Description;
                });
                this.setState({beneficiaries: response.beneficiaries});
            }
        );

        //Load nostro accounts
        if(this.props.listNostroAccounts !== undefined){
            this.setState({listNostroAccounts: this.props.listNostroAccounts});
        }
        else{
            this.loadNostroAccounts();
        }

        //Get refunds
        this.loadRefunds();

        //Load state
        this.setState({balance: this.props.balance});
    }

    loadNostroAccounts = () => {
        getNostroBankAccountsForFunds(this.props.currencyID).then(
            (response) => {
                if (typeof response !== "undefined" && typeof response.nostroBankAccounts !== "undefined") {
                    let nostroAcounts = response.nostroBankAccounts;
                    nostroAcounts.forEach(element => {
                        element.value = element.BankAccountID;
                        element.name = element.BankName;
                    });
                    this.setState({
                        listNostroAccounts: nostroAcounts
                    });
                } else {
                    this.setState({listNostroAccounts: []});
                }
            }
        );
    }

    loadRefunds = () => {
        getRefunds(this.props.sourceTable, this.props.sourceID).then(
            (result) => {
                var refunds = [];
                if(result.refunds !== undefined){
                    refunds = result.refunds;
                }
                this.setState({refunds: refunds});
            }
        );
    }

    handleAmountUpdate = (event) => {
        this.setState({amount: event.target.value});
    }

    handleUpdateValueDate = (date) => {
        this.setState({valueDate: date});
    }

    handleNostroBankAccount = (obj) => {
        if(obj.value !== undefined){
            this.setState({nostroBankAccountID: obj.value});
        }
    }

    handleInstrumentUpdate = (obj) => {
        if(obj.value !== undefined){
            this.setState({instrument: obj.value});
        }
    }

    handleUpdateBeneficiary = (obj) => {
        if(obj.value !== undefined){
            let ids = obj.value.split('|');
            this.setState({beneficiaryID: ids[0],
                bankAccountID: ids[1],
            });
        }
    }

    handleDeliveryMethodUpdate = (obj) => {
        if(obj.value !== undefined){
            this.setState({deliveryMethod: obj.value});
        }
    }

    onBackClick = (event) => {
        if(this.props.onBackClick){
            this.props.onBackClick();
        }
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    validateSave = () => {
        var result = true;
        var validations = this.state.validations;

        validations.amount = 
        validations.nostroAccount = 
        validations.beneficiary = 
        validations.valueDate = 
        validations.instrument = true;

        if(this.state.amount === 0){
            validations.amount = false;
            result = false;
        }
        else{
            if(this.state.amount > this.state.balance){
                validations.amount = false;
                result = false;
            }
        }

        if(!(this.state.nostroBankAccountID > 0)){
            validations.nostroAccount = false;
            result = false;
        }

        if(!(this.state.beneficiaryID > 0)){
            validations.beneficiary = false;
            result = false;
        }

        if(this.state.valueDate === undefined){
            validations.valueDate = false;
            result = false;
        }

        if(this.state.instrument === undefined ||
            this.state.instrument === '' ||
            this.state.instrument === 'select'){
            validations.instrument = false;
            result = false;
        }

        this.setState({validations: validations});
        return result;
    }

    onSaveClick = (event) => {
        if(this.validateSave()){
            var sourceTable = '';
            switch(this.props.sourceTable){
                case 'Payment':
                default:
                    sourceTable = 'P';
                    break;
                case 'Deposit':
                    sourceTable = 'D';
                    break;
            }
            var objOutgoingFund = {
                BeneficiaryBankAccountID: this.state.bankAccountID,
                BeneficiaryType: 0,
                BeneficiaryID: this.state.beneficiaryID,
                CreatedBy: sessionStorage.getItem('UserID'),
                CreatedOn: formatDate(new Date()),
                DeliveryMethod: this.state.deliveryMethod,
                NostroAccount: this.state.nostroBankAccountID,
                PaymentAmount: this.state.amount,
                PaymentInstrument: this.state.instrument,
                SourceID: this.props.sourceID,
                SourceTable: sourceTable,
                Status: 'Completed',
                UpdatedBy: sessionStorage.getItem('UserID'),
                UpdateOn: formatDate(new Date()),
                ValueDate: formatDate(this.state.valueDate),
                VersionNo: 1,
            };
            
            createOutgoingFund(objOutgoingFund).then(
                (response) => {
                    //alert(JSON.stringify(response));
                    if(Number(response.httpStatusCode) === 200){
                        this.loadRefunds();
                        var balance = this.state.balance;
                        balance -= this.state.amount;
                        this.setState({showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Refund created successfully!',
                            outgoingFundsID: response.OutgoingFundsID,
                            balance: balance,
                        });
                    }
                    else{
                        this.setState({showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Refund not created. Please try again or contact administrator',
                        });
                    }
                }
            );
        }
    }

    onClearFieldsClick = () => {
        this.setState({
            amount: 0,
            beneficiaryID: 0,
            bankAccountID: 0,
            deliveryMethod: '',
            instrument: '',
            nostroBankAccountID: 0,
            outgoingFundsID: 0,
            valueDate: undefined,
        });
    }

    closeAlert = () => {
        this.setState({showAlert: false});
    }

    onRefundClick = (row) => {
        this.setState({
            amount: row['Refund Amount'],
            nostroBankAccountID: row['NostroAccount$'],
            valueDate: new Date(row['Value Date']),
            instrument: row['Instrument'],
            deliveryMethod: row['Delivery Method'],
            outgoingFundsID: row['Refund ID'],
            beneficiaryID: row['BeneficiaryID$'],
            bankAccountID: row['BeneficiaryBankAccountID$'],
        });
    }

    onDeleteRefundClick = (row) => {
        this.setState({
            recordToDelete: row,
            showVoidAlert: true,
        });
    }

    yesDeleteRefund = () => {
        this.setState({showVoidAlert: false});
        let recordToDelete = this.state.recordToDelete;
        voidRefund(recordToDelete['Refund ID'], sessionStorage.getItem('UserID'), recordToDelete['UpdateToken$']).then(
            (response) => {
                //alert(JSON.stringify(response));
                var alertTitle = '', alertMessage = '';
                var balance = this.state.balance;
                switch(Number(response.httpStatusCode)){
                    case 200:
                        alertTitle = 'Success';
                        alertMessage = 'Refund voided!';
                        balance += Number(recordToDelete['Refund Amount']);
                        this.loadRefunds();
                        break;
                    case 409:
                        alertTitle = 'Error';
                        alertMessage = 'Another user has modified this record information. Please reload the information and try again.';
                        this.loadRefunds();
                        break;
                    default:
                        alertTitle = 'Error';
                        alertMessage = 'Refund not voided. Try again or contact the administrator';
                        break;
                }
                this.setState({
                    alertTitle: alertTitle,
                    alertMessage: alertMessage,
                    balance: balance,
                    recordToDelete: {},
                    showAlert: true,
                });
                if(this.state.outgoingFundsID > 0){
                    this.onClearFieldsClick();
                }
            }
        );
    }

    noDeleteRefund = () => {
        this.setState({showVoidAlert: false});
    }

    render() {
        //Get delivery methods
        var deliveryMethods = [];
        if(this.state.instrument !== undefined &&
            this.state.instrument !== '' &&
            this.state.instrument !== 'select'){
            deliveryMethods = this.state.deliveryMethods[this.state.instrument];
        }

        //Save button
        var saveButton;
        if(this.state.outgoingFundsID === 0){
            saveButton = (<button className="uk-button uk-button-green" onClick={this.onSaveClick}>Save</button>);
        }

        //Disable fields
        var disableFields = false;
        if(this.state.outgoingFundsID > 0){
            disableFields = true;
        }

        return(
            <div className="uk-form-stacked">
                <i onClick={this.onBackClick} className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                <h3>Refund for {this.props.sourceTable} {this.props.sourceID}</h3>
                <div uk-grid="true">
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Balance</label>
                        {formatToCurrency(this.state.balance)}&nbsp;{this.props.currencyID}
                    </div>
                    <div className="uk-width-2-3"></div>

                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Amount*</label>
                        <NumberInput id="txt-amount" 
                            className={this.formatValidInput(this.state.validations.amount)}
                            type="Currency" 
                            value={this.state.amount}
                            onChange={this.handleAmountUpdate}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Nostro Account*</label>
                        <DynamicSelect id="select-nostro-accounts"
                            className={this.formatValidSelect(this.state.validations.nostroAccount)}
                            placeholder='Select an Account...' 
                            data={this.state.listNostroAccounts} 
                            objValue={{value: this.state.nostroBankAccountID}}
                            getValue={this.handleNostroBankAccount}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3"></div>

                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Beneficiary*</label>
                        <DynamicSelect id="select-beneficiary"
                            className={this.formatValidSelect(this.state.validations.beneficiary)}
                            placeholder="Select a Beneficiary..."
                            getValue={this.handleUpdateBeneficiary}
                            objValue={{value:this.state.beneficiaryID + '|' + this.state.bankAccountID}}
                            data={this.state.beneficiaries}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3"> 
                        <label className="uk-form-label">Value Date*</label>
                        <DatePicker id="dp-value-date"
                            className={this.formatValidInput(this.state.validations.valueDate)}
                            disabledDays={[0,6]} 
                            startDate={new Date()}  
                            onDayChange={this.handleUpdateValueDate}
                            value={this.state.valueDate}
                            readOnly={true}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3"></div>

                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Instrument*</label>
                        <DynamicSelect id="select-instrument"
                            className={this.formatValidSelect(this.state.validations.instrument)}
                            placeholder="Select an Instrument..."
                            getValue={this.handleInstrumentUpdate}
                            objValue={{value:this.state.instrument}}
                            data={this.state.outgoingInstruments}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Delivery Method</label>
                        <DynamicSelect id="select-delivery-method"
                            className="uk-select"
                            placeholder="Select a Delivery Method..."
                            getValue={this.handleDeliveryMethodUpdate}
                            objValue={{value:this.state.deliveryMethod}}
                            data={deliveryMethods}
                            disabled={disableFields}/>
                    </div>
                    <div className="uk-width-1-3"></div>
                </div>
                <div className="uk-margin-small-top">
                    {saveButton}
                    <button className="uk-button uk-button-green" onClick={this.onClearFieldsClick}>Clear Fields</button>
                </div>

                <NewDynamicTable data={this.state.refunds}
                    enableClick={true}
                    clickFunction={this.onRefundClick}
                    newSearch={true}
                    useDeleteButton={true}
                    deleteFunction={this.onDeleteRefundClick}
                    formats={[{header:'Refund Amount', formatFunction: formatToCurrency}]}/>

                <AlertBox id="global-Alert"
                    type="Ok" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert}/>

                <AlertBox id="delete-alert"
                    type="Yes/No"
                    open={this.state.showVoidAlert}
                    title="Confirmation"
                    message="Are you sure you want to void this refund?"
                    yesClick = {this.yesDeleteRefund}
                    noClick = {this.noDeleteRefund}/>
            </div>
        );
    }
}