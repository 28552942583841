export function getStyle(){
    return defaultStyle;
} 

const defaultStyle = {
    titleStyle: {
        paddingLeft: "0x",
        paddingTop: "5px",
        paddingRight: "25px"
    },
    inputStyle: {
        paddingBottom:"5px"
    },
    alignCenter: {
        //paddingTop: "10px",
        textAlign: "center"
    },
    boldLabel: {
        fontWeight: 800
    }
}

export const notFilledInputClass = "uk-input uk-form-danger";
export const regularInputClass = "uk-input";
export const notFilledSelectClass = "uk-select uk-form-danger";
export const regularSelectClass = "uk-select";
export const notFilledTextAreaClass = "uk-textarea uk-form-danger";
export const regularTextAreaClass = "uk-textarea";