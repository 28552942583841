import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody } from './Networking';


export async function getSearchByEntity(EntityType, EntityId){
    try {
        let params = {
            'EntityType': EntityType,
            'EntityId': EntityId
        }
        let response = await genericGetWithParameters('GlobalWatchListSearch/GetSearchByEntity', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetResultsByWatchListsSearchId(Id){
    try {
        let params = {
            'Id': Id
        }
        let response = await genericGetWithParameters('GlobalWatchListSearch/GetResultsByWatchListsSearchId', params);
        
        return response;
    } catch (error) {
        console.error(error);
    }
}