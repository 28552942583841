import jwt from 'jsonwebtoken';
import {genericCallWithBody, 
        genericGetWithParameters, 
        genericGetWithParametersNoEncrypted, 
        genericCallWithBodyAndResponseDecoded} from './Networking';

export async function saveDealHeader(objDealHeader){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Deals/Post';
    let response = await genericCallWithBody(method, apiName, objDealHeader);
    return response;
}

//DealTypes/GetAllActiveDealTypes_min
export async function getDealTypes(){
    try {
        let params = {}
        let response = await genericGetWithParameters('DealTypes/GetAllActiveDealTypes_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchDeals(objCriteria){
    try {
        let agentCustomerID = await sessionStorage.getItem('AgentCustomerID');
        objCriteria.AgentCustomerID = agentCustomerID;
        console.log(objCriteria);
        let response = await genericGetWithParameters('Deals/AgentSearchDeals',objCriteria);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealHeaderInfo(id){
    try {
        let params = {
            'dealHeaderId': id
        }

        let response = await genericGetWithParameters('Deals/GetDealHeaderInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFullDealHeader(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID
        }

        let response = await genericGetWithParameters('Deals/GetFullDealHeader',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidDealHeader(dealHeaderID, userID, updateToken){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': userID,
            'updateToken': updateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/Void',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function adjustDealHeader(dealHeaderID, userID, updateToken){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': userID,
            'updateToken': updateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/Adjust',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getConfirmation(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
        }
        let response = await genericGetWithParametersNoEncrypted('Deals/GetConfirmation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDrawdownsByForward(forwardID, page, items){
    try {
        let params = {
            'forwardID': forwardID,
            'page': page,
            'items': items,
        };
        let response = await genericGetWithParameters('Deals/GetDrawdownsByForward',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutstandingForwardsMin(forwardID, customerID){
    try {
        let params = {
            'forwardID': forwardID,
            'CustomerID': customerID
        };
        let response = await genericGetWithParameters('Deals/GetOutstandingForwardsMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealsForPayments(customerID, currencyID, sourceTable, sourceID, typeRecord, page, items){
    try {
        let params = {
            'currencyID': currencyID,
            'customerID': customerID,
            'sourceTable': sourceTable,
            'sourceID': sourceID,
            'typeRecord': typeRecord,
            'page': page,
            'items': items,
        };
        let response = await genericGetWithParameters('Deals/GetDealsForPayments',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendDealReportByEmail(sendDealReportByEmailRequest){ 
    let method = 'POST';
    let apiName = 'Deals/SendDealReportByEmail';
    let response = await genericCallWithBodyAndResponseDecoded(method, apiName, sendDealReportByEmailRequest);
    return response;
}

export async function getComplianceInfo(Days, RiskFrom, RiskTo, CustomerId){
    try {
        let params = {
            'Days': Days,
            'RiskFrom': RiskFrom,
            'RiskTo': RiskTo,
            'CustomerId': CustomerId
        }
        let response = await genericGetWithParameters('Deals/GetComplianceInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}