import { useState, useEffect } from "react";
import { getActiveTradersMin } from "../../../../../networking/NetworkingTrader";

const useActiveTraders = () => {
    const [traders, setTraders] = useState([]);
    
    useEffect(() => {
        const getActiveTraders = async () => {
            const json = await getActiveTradersMin();
            var formattedTraders = json.traders.map(t => ({...t, name: t.Trader, value: t.TraderID}));
            setTraders(formattedTraders);
        }
        getActiveTraders();
    }, []);
    return traders
}

export default useActiveTraders;
