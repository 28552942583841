import React from 'react';

import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';

import {saveBusinessHour} from '../../../../networking/NetworkingBusinessHoursByCountry';

const hours = [
    {value: '00:00', name: '00:00'},
    {value: '00:30', name: '00:30'},
    {value: '01:00', name: '01:00'},
    {value: '01:30', name: '01:30'},
    {value: '02:00', name: '02:00'},
    {value: '02:30', name: '02:30'},
    {value: '03:00', name: '03:00'},
    {value: '03:30', name: '03:30'},
    {value: '04:00', name: '04:00'},
    {value: '04:30', name: '04:30'},
    {value: '05:00', name: '05:00'},
    {value: '05:30', name: '05:30'},
    {value: '06:00', name: '06:00'},
    {value: '06:30', name: '06:30'},
    {value: '07:00', name: '07:00'},
    {value: '07:30', name: '07:30'},
    {value: '08:00', name: '08:00'},
    {value: '08:30', name: '08:30'},
    {value: '09:00', name: '09:00'},
    {value: '09:30', name: '09:30'},
    {value: '10:00', name: '10:00'},
    {value: '10:30', name: '10:30'},
    {value: '11:00', name: '11:00'},
    {value: '11:30', name: '11:30'},
    {value: '12:00', name: '12:00'},
    {value: '12:30', name: '12:30'},
    {value: '13:00', name: '13:00'},
    {value: '13:30', name: '13:30'},
    {value: '14:00', name: '14:00'},
    {value: '14:30', name: '14:30'},
    {value: '15:00', name: '15:00'},
    {value: '15:30', name: '15:30'},
    {value: '16:00', name: '16:00'},
    {value: '16:30', name: '16:30'},
    {value: '17:00', name: '17:00'},
    {value: '17:30', name: '17:30'},
    {value: '18:00', name: '18:00'},
    {value: '18:30', name: '18:30'},
    {value: '19:00', name: '19:00'},
    {value: '19:30', name: '19:30'},
    {value: '20:00', name: '20:00'},
    {value: '20:30', name: '20:30'},
    {value: '21:00', name: '21:00'},
    {value: '21:30', name: '21:30'},
    {value: '22:00', name: '22:00'},
    {value: '22:30', name: '22:30'},
    {value: '23:00', name: '23:00'},
    {value: '23:30', name: '23:30'},
    {value: 'Closed', name: 'Closed'},
]
export default class BusinessHoursForm extends React.Component {
    state = {
        businessHourId: 0,
        updateToken: 0,
        notFirstLoad: false,
        showAlert: false,
        openingHour: {value: 'Opening Hour', name: 'Opening Hour'},
        closingHour: {value: 'Closing Hour', name: 'Closing Hour'},
        isClosed: false,
        alertTitle: '',
        alertMessage: '',
        day: ''
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.selectedBusinessHour !== {} && prevProps.selectedBusinessHour !== this.props.selectedBusinessHour){
            let businessHour = this.props.selectedBusinessHour;
            let closed = false;

            if(businessHour['Open Hour'] === 'Closed'){
                closed = true;
            }

            let objOpeningHour = {value: businessHour['Open Hour'], name: businessHour['Open Hour']};
            let objClosingHour = {value: businessHour['Close Hour'], name: businessHour['Close Hour']};

            this.setState({
                day: businessHour.Day,
                businessHourId: businessHour.BusinessHoursByCountryId,
                updateToken: businessHour.UpdateToken,
                openingHour: objOpeningHour,
                closingHour: objClosingHour,
                isClosed: closed,
            });

        }
    }

    handleSaveClick = () => {
        let userID = sessionStorage.getItem('UserID');
        let model = {
            BusinessHoursByCountryID: this.state.businessHourId,
            OpenHour: this.state.openingHour.value,
            CloseHour: this.state.closingHour.value,
            UpdateToken: this.state.updateToken,
            UserID: userID,
        }

        saveBusinessHour(model).then((json) => {
            if (json.httpStatusCode !== 200){
                this.setState({showAlert: true, alertMessage: 'An error occurred while trying to save the Business Hour. Please try again.', alertTitle: 'Error'});
            } else {
                this.setState({updateToken: json.updateToken, businessHourId: json.BusinessHoursByCountryId, showAlert: true, alertTitle: 'Business Hour successfully saved.'});
                this.props.updateFunction();
            }
        })
    }

    handleOnCheck = (event) => {
        this.setState({
            isClosed: event.target.checked
        });

        if(event.target.checked){
            let objOpeningHour = {value: 'Closed', name: 'Closed'};
            let objClosingHour = {value: 'Closed', name: 'Closed'};
            this.setState({
                openingHour: objOpeningHour,
                closingHour: objClosingHour,
            });
        } else {
            let objOpeningHour = {value: '08:00', name: '08:00'};
            let objClosingHour = {value: '17:30', name: '17:30'};
            this.setState({
                openingHour: objOpeningHour,
                closingHour: objClosingHour,
            });
        }

    }

    handleUpdateOpeningHour = (value) => {

        if(this.state.isClosed && value.value !== 'Closed'){
            this.setState({isClosed: false, closingHour: {value: '17:30', name: '17:30'}});
        }

        this.setState({openingHour: value});
    }

    handleUpdateClosingHour = (value) => {
        if(this.state.isClosed && value.value !== 'Closed'){
            this.setState({isClosed: false, openingHour: {value: '08:00', name: '08:00'}});
        }

        this.setState({closingHour: value});
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    render(){
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-day-name">Day</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-day-name" value={this.state.day} type="text" disabled/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-margin">
                        <label className="uk-form-label" htmlFor="form-country-authorizedDeal">
                            Closed<input className="uk-checkbox" id="business-hour-closed" type="checkbox" checked={this.state.isClosed} onChange={this.handleOnCheck}/>
                        </label>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-opening-hour">Opening Hour</label>
                            <div className="uk-form-controls">
                                <DynamicSelect id="form-opening-hour" getValue={this.handleUpdateOpeningHour} placeholder="Opening Hour" data={hours} objValue={this.state.openingHour}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-closing-hour">Closing Hour</label>
                            <div className="uk-form-controls">
                                <DynamicSelect id="form-closing-hour" getValue={this.handleUpdateClosingHour} placeholder="Closing Hour" data={hours} objValue={this.state.closingHour}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick}>Save</button>
                    </div>
                </form>
                <AlertBox id="save-business-hour" open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
            </div>
        );
    }
}