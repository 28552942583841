import jwt from 'jsonwebtoken';
var CryptoJS = require("crypto-js");

const encryptKey = process.env.REACT_APP_ENCRYPTION_KEY;
const useEncryption = window.REACT_APP_USE_NEW_ENCRYPT;
const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function decrypt(cypher){
    let responseJSON;
    if(useEncryption){
        var cipher = cypher.data;

        var key = CryptoJS.enc.Utf8.parse(encryptKey);     // Use Utf8-Encoder. 
        var iv  = CryptoJS.enc.Utf8.parse('');                     // Use Utf8-Encoder

        var ciphertext = CryptoJS.enc.Base64.parse(cipher);    // Use Base64-Encoder.       
        var encryptedCP = CryptoJS.lib.CipherParams.create({
            ciphertext: ciphertext,
            formatter: CryptoJS.format.OpenSSL                                     // Optional, but required for encryptedCP.toString() 
        });
        var decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv});
        var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8); 
        responseJSON = await JSON.parse(decryptedUtf8);

    } else {
       var decoded = jwt.decode(cypher.data);
       responseJSON = decoded.response;
    }

    return responseJSON;
}

export async function login(username,password) {
    try {
        let userRequest = {
            UserName: username,
            Password: password
        }
        let response = await fetch(apiURL + 'Login/AgentValidateUserID',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,
            },
            body: JSON.stringify(userRequest),
        });

        if (response.status === 401) {
            return response;
        }

        var responseJSON;
        let responseJWT = await response.json();
        responseJSON = await decrypt(responseJWT);
        
        if(response.status === 200) {
            sessionStorage.setItem('SessionID', responseJSON.SessionID);
            sessionStorage.setItem('UserID', responseJSON.UserId);
            sessionStorage.setItem('UserName', username);
            sessionStorage.setItem('AgentCustomerID', responseJSON.AgentCustomerID);
            sessionStorage.setItem('TraderID', responseJSON.TraderID);
            sessionStorage.setItem('TraderName', responseJSON.OnlineUserSession.TraderName);
            sessionStorage.setItem('AlreadySessionCreated', true);
            sessionStorage.setItem('SessionCreatedOn', Date.now());
            localStorage.setItem('AlreadySessionCreated', true);
            localStorage.setItem('SessionCreatedOn', Date.now());
        }
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function logout() {
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Logout/Post', {
            method: 'POST',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        localStorage.removeItem('AlreadySessionCreated');
        sessionStorage.removeItem('AlreadySessionCreated')
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function searchCustomers(search){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let params = {
            'TextToSearch': search,
            'Page': 0,
            'Items': 10
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'CustomerSearch/Get?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        console.log(responseJson.customers);
        return responseJson.customers;
    } catch (error) {
        console.error(error);
    }
}

export async function newSearchCustomers(search, agentCustomerID, page, items) {
    try {
        let params = {
            'TextToSearch': search,
            'AgentCustomerID': agentCustomerID,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('CustomerSearch/AgentSearchCustomers', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTable(name) {
    try {
        let params = {
            LookUpTablesDescription: name,
        }
        let response = await genericGetWithParameters('LookUpTable/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTableByFilter(name,field4) {
    try {
        let params = {
            LookUpTablesDescription: name,
            field4: field4
        }
        let response = await genericGetWithParameters('LookUpTable/GetByFilter', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParameters(apiName, params){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let query = ''

        if(params)
        {
            query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        }
         
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var responseJSON = {};
        if(responseJWT.data !== undefined){
            responseJSON = await decrypt(responseJWT);
        } 
        if(responseJWT.Message !== undefined){
            httpErrorMessage = responseJWT.Message;
        }
        responseJSON.httpStatusCode = response.status;
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithParameters(apiName, params){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'DELETE',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBody(method, apiName, model){
    try {
        let sessionID = await sessionStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
        /*if(response.status === 200 ||
            response.status === 400){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            return response;
        }*/
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersNoEncrypted(apiName, params){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let query = '';
        if(params !== {}){
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}

export async function validateSession(){
    try {
        let response = await genericGetWithParametersNoEncrypted('Session/Get',{});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodyAndResponseDecoded(method, apiName, model){
    try {
        let sessionID = await sessionStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        let responseJWT = await response.json();
        let responseJSON = await decrypt(responseJWT);
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function getServerVersion() {
    let responseWeb = await fetch(apiURL + 'Version/Get', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}