import React from 'react';

import DealForm from '../../components/deal/DealForm'
import { getStyle} from '../../styles/styles';
import { searchDeals } from '../../networking/NetworkingDeals';
import NewDynamicTable from '../shared/NewDynamicTable';
import Paginator from '../shared/Paginator';
import {formatToCurrency} from '../../helpers/FormatHelper';
import {getScreen} from './../shared/ScreenHelper';
import {ActionName} from '../../constants/ActionNames';
var style = getStyle();

const itemsPerPage = 8;
export default class DealSearchScreen extends React.Component {

    constructor(props){
        super(props);
        this.handleBackLink = this.handleBackLink.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    state = {
        searchedCustomerName: '',
        isVisible: 'visible',
        customers: [],
        totalCriteria: {},
        updateCustomer: false,
        addCustomer: false,
        customerID: 0,
        totalPages: 0,
        newSearch: false,
        //deal info
        clickedRow: false,
        dealHeaderId: 0,
        returningFromAnotherWindow: false
    }

    componentDidMount(){
        
    }
    clearGrid(){
        this.setState({searchedCustomerName:'', customers:[], isVisible: 'hidden'});
    }
    searchDealsGrid(obj){
        this.setState({totalCriteria: obj});
        obj.Page = 1;
        searchDeals(obj).then(
            (json)=>{
                console.log(json);
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                if(json.deals){
                    this.setState({totalpages: pages, newSearch: true, customers: json.deals, isVisible:'visible'});
                } else {
                    this.setState({totalpages: pages, newSearch: true, customers: [], isVisible:'visible'});
                }
                
            }
        );
    }
    handleRowClick(obj){
        console.group("Deal Search handleRowClick")
        console.log(obj);
        console.groupEnd();
        //  
        if(this.props.onDealClick !== undefined){
            this.props.onDealClick(obj.DealNo);
        }
        else{
            this.setState({
                clickedRow: true, dealHeaderId: obj.DealNo
            });
        }
    }
 
    handleBackLink() {
        this.setState({
            clickedRow: false,
            returningFromAnotherWindow: true
        });
    }

    changePage(value){
        this.setState({totalCriteria:{...this.state.totalCriteria, Page: value}});
        //this.state.totalCriteria.Page = value;
        searchDeals(this.state.totalCriteria).then(
            (json)=>{
                console.log(json);
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({totalpages: pages, newSearch: true, customers: json.deals, isVisible:'visible'});
            }
        );
    }
    
    render() {
        var screen = [];
        if (this.state.clickedRow) {
            screen.push(getScreen(ActionName.ShowDealInfo,this.state.dealHeaderId,this.handleBackLink));
        } else {
            screen.push(
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Deal Search</h3>
                    <DealForm searchDealsGrid={this.searchDealsGrid.bind(this)} clearGrid={this.clearGrid.bind(this)}/>
                    <div style={{visibility: this.state.isVisible}}>
                        <NewDynamicTable formats={[{header: 'BuyAmount', formatFunction: formatToCurrency}, {header: 'SellAmount', formatFunction: formatToCurrency}]} 
                        key="dealSearch-screen" data={this.state.customers} enableClick={true} clickFunction={this.handleRowClick.bind(this)} 
                        newSearch={this.state.newSearch} useDeleteButton={false} useExportButton tableName="deal_information"/>
                        <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalpages} newSearch={this.state.newSearch}/>
                    </div>
                </div>
            );
        }
        return(<div>{screen}</div>);
    }
}