import React from 'react';

import ContactInformationForm from './ContactInformationForm';
import AddressInformationForm from './AddressInformationForm';
import AccountInformationForm from './AccountInformationForm';
import ContactPropertiesForm from './ContactPropertiesForm';
import ContactTasksForm from './ContactTasksForm';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import Paginator from '../../../shared/Paginator';
import { saveContact } from '../../../../networking/NetworkingContacts';
import { getContactsByCustomer, getContactByID } from '../../../../networking/NetworkingContacts';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import AlertBox from '../../../shared/AlertBox';
import { getStyle } from '../../../../styles/styles'
import AddressForm from '../../addresses/AddressForm';
import ContactsScreen from '../updateCustomer/ContactsScreen';
import AuditContactScreen from '../auditEntity/AuditContactScreen';
import ComplianceScreen from '../updateCustomer/ComplianceScreen';
var style = getStyle();
const enableContactImpersonation = process.env.REACT_APP_ENABLE_CONTACT_IMPERSONATION;

export default class ContactMaintenanceScreen extends React.Component {
    state = {
        contacts: [],
        contactInfo: {},
        accountInfo: {},
        addressInfo: null,
        additionalInfo: {},
        isValidatedContact: true,
        isValidatedAddress: true,
        contactInfoErrors: [],
        addressErrors: [],
        updateContact: false,
        pressedButton: false,
        saveProperties: false,
        saveTasks: false,
        contactID: 0,
        selectedContact: {},
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        contactUpdateToken: '',
        modelExistsContactInformation: false,
        modelExistsAddressInformation: false,
        notFirstLoad: true,
        notFirstLoadAdd: true,
        showAlertError: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
        loading: true,
        viewHistory: false
    }

    changeModelExistst(screen) {
        switch (screen) {
            case 'contact-information':
                this.setState({ modelExistsContactInformation: true })
                break;

            default:
                break;
        }
    }

    componentDidMount() {
        this.setState({ selectedContact: this.props.selectedContact, contactUpdateToken: this.props.contactUpdateToken });
        var contact = this.props.selectedContact;
        this.setState({
            contactInfo: {
                contactID: contact["Contact ID"],
                contactType: contact["Contact Type"],
                status: contact["Contact Status"],
                salutation: contact["Salutation"],
                firstName: contact["First Name"],
                middleName: contact["Name"],
                lastName: contact["Last Name"],
                phoneNumber: contact["Phone Number"],
                extension: contact["Phone Extension"],
                cellNumber: contact["Mobile Number"],
                position1: contact["Position"],
                tradeLimit: contact["Trade Limit"],
                tLWE: contact["Trade Limit Warning or Enforce"],
                dailyTradeLimit: contact["Daily Trade Limit"],
                dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                dateOfBirth: contact["Date of Birth"],
                preferredName: contact["Preferred Name"],
                language: contact["Language"],
                employerName: contact["Employer Name"],
                isMainContact: contact["Main Contact"],
                PEP: contact["Politically Exposed Person"],
                pepPosition: contact["PEP Position"],
                institutionType: contact["Institution Type"],
                appointmentDate: contact["Appointment Date"],
                isAuthorized: contact["Authorized Signing Officer"],
                bypassValidation: contact["ByPass Validation"],
                expiryDate: contact["Expiry ByPass Date"],
                updateToken: contact["UpdateToken"]
            },
            addressInfo: {
                AddressType: contact["Address Type"],
                AddressValue: contact["Address Value"],
                Status: contact["Address Status"],
                Address2: contact["Address2"],
                City: contact["City"],
                ZipCode: contact["Zip Code"],
                StateId: contact["State ID"],
                CountryId: contact["Country ID"],
                OtherState: contact["Other State"],
                DefaultAddress: contact["Default Address"],
                SinceWhen: contact["Since When"],
                District: contact["District"],
                POBox: contact["POBox"]
            }
        });
        getContactsByCustomer(this.props.customerID, 1, 100).then(
            (value) => {
                console.log(value);
                this.setState({ contacts: value.contacts });
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                console.log(pages);
                this.setState({ totalPages: pages, newSearch: true });
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedContact !== {} && prevProps.contactUpdateToken !== this.props.contactUpdateToken) {
            this.setState({ selectedContact: this.props.selectedContact, contactUpdateToken: this.props.contactUpdateToken });
        }
    }

    transformContactInfoToSelectedContact(contactInfo, addressInfo) {
        var selectedContact =
        {
            "Contact ID": contactInfo.contactID,
            "Contact Type": contactInfo.contactType,
            "Contact Status": contactInfo.status,
            "Salutation": contactInfo.salutation,
            "First Name": contactInfo.firstName,
            "Name": contactInfo.middleName,
            "Last Name": contactInfo.lastName,
            "Mobile Number": contactInfo.cellNumber,
            "Phone Number": contactInfo.phoneNumber,
            "Phone Extension": contactInfo.extension,
            "Position": contactInfo.position1,
            "Trade Limit": contactInfo.tradeLimit,
            "Trade Limit Warning or Enforce": contactInfo.tLWE,
            "Daily Trade Limit": contactInfo.dailyTradeLimit,
            "Daily Trade Limit Warning or Enforce": contactInfo.dTLWE,
            "Date of Birth": contactInfo.dateOfBirth,
            "Preferred Name": contactInfo.preferredName,
            "Language": contactInfo.language,
            "Employer Name": contactInfo.employerName,
            "Main Contact": contactInfo.isMainContact,
            "Politically Exposed Person": contactInfo.PEP,
            "PEP Position": contactInfo.pepPosition,
            "Institution Type": contactInfo.institutionType,
            "Appointment Date": contactInfo.appointmentDate,
            "Authorized Signing Officer": contactInfo.isAuthorized,
            "ByPass Validation": contactInfo.bypassValidation,
            "Expiry ByPass Date": contactInfo.expiryDate,
            "UpdateToken": contactInfo.updateToken,

            "Address Type": addressInfo.type,
            "Address Value": addressInfo.address1,
            "Address Status": addressInfo.status,
            "Address2": addressInfo.address2,
            "City": addressInfo.city,
            "Zip Code": addressInfo.postalCode,
            "State ID": addressInfo.stateCode,
            "Country ID": addressInfo.countryId,
            "Other State": addressInfo.other,
            "Default Address": addressInfo.defaultAddress,
            "Since When": addressInfo.sinceWhen,
            "District": addressInfo.district,
            "POBox": addressInfo.poBox
        };
        return selectedContact;
    }

    changePage(value) {
        getContactsByCustomer(this.props.customerID, value, 100).then(
            (value) => {
                this.setState({ contacts: value.contacts });
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({ totalPages: pages, newSearch: false });
            }
        );
    }

    updatePage() {
        getContactsByCustomer(this.props.customerID, this.state.currentPage, 100).then(
            (json) => {
                this.setState({ contacts: json.contacts });
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({ totalPages: pages, newSearch: false });
            }
        );
    }

    handleRowClick(obj) {
        console.log(obj);
        this.setState({
            updateContact: true,
            contactID: obj["Contact ID"]
        });
        getContactByID(obj["Contact ID"]).then(
            (value) => {
                console.log(value)
                this.setState({ contactUpdateToken: value.UpdateToken }, () => {
                    this.setState({ selectedContact: value });
                })
                //this.setState({selectedContact: value, contactUpdateToken: value["UpdateToken"]});
            }
        );
    }

    getContactInfoModel(model) {
        this.setState({ contactInfo: model });
    }

    getAddressInfoModel(model) {
        this.setState({ addressInfo: model });
    }

    getAccountInfo(value) {
        this.setState({ accountInfo: value });
    }

    getSaveProperties(value) {
        this.setState({ saveProperties: value });
    }

    getSaveTasks(value) {
        this.setState({ saveTasks: value });
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backFromCreateContact();
        this.props.backLink();
    }

    handleClearFieldsClick() {

    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick() {
        //this.setState({pressedButton: true});
        this.saveAllTabs()
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true });
    }

    handleReturnToContact() {
        this.setState({ viewHistory: false });
    }

    transformListRoles(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push(list[i].RoleName);
        }
        return helper;
    }

    saveAllTabs() {
        var date = new Date();
        let userID = sessionStorage.getItem('UserID');
        var roles = this.transformListRoles(this.state.accountInfo.listWant);
        var isContactImpersonation = false;
        if (enableContactImpersonation === "true") { isContactImpersonation = true }
        var model = {
            contactRequest: {
                ContactID: this.state.contactInfo.contactID,
                CustomerID: this.props.customerID,
                MainContact: this.state.contactInfo.isMainContact,
                Position: this.state.contactInfo.position1,
                TradeLimit: this.state.contactInfo.tradeLimit,
                DailyTradeLimit: this.state.contactInfo.dailyTradeLimit,
                PersonID: this.props.selectedContact.PersonID,
                BirthdayDate: this.state.contactInfo.dateOfBirth,
                PoliticallyExposedPerson: this.state.contactInfo.PEP,
                AuthorizedSigningOfficer: this.state.contactInfo.isAuthorized,
                PreferredName: this.state.contactInfo.preferredName,
                CreatedBy: userID,
                CreatedOn: date.toISOString(),
                ContactType: this.state.contactInfo.contactType,
                OnlineLanguage: this.state.contactInfo.language,
                PEPPosition: this.state.contactInfo.pepPosition,
                EmployerName: this.state.contactInfo.employerName,
                AppointmentDate: this.state.contactInfo.appointmentDate,
                InstitutionType: this.state.contactInfo.institutionType,
                Status: this.state.contactInfo.status,
                ExpiryByPassDate: this.state.contactInfo.expiryDate,
                ByPassValidation: this.state.contactInfo.bypassValidation,
                TradeLimitWarningOrEnforce: this.state.contactInfo.tLWE,
                DailyTradeLimitWarningOrEnforce: this.state.contactInfo.dTLWE,
                UpdateToken: this.state.contactInfo.updateToken,
                Name: this.state.contactInfo.lastName + ', ' + this.state.contactInfo.firstName,
                FirstName: this.state.contactInfo.firstName,
                LastName: this.state.contactInfo.lastName,
                Salutation: this.state.contactInfo.salutation,
                MobilePhone: this.state.contactInfo.cellNumber,
                PhoneExt: this.state.contactInfo.extension,
                PhoneNumber: this.state.contactInfo.phoneNumber,
                //Account Information
                OnlineAccess: this.state.accountInfo.onlineAccess,
                Admin: false,
                AllowForwards: false,
                Email: this.state.accountInfo.emailAddress,
                OneClickBuyAllowed: false,
                OnlineConfirmationEmail: this.state.accountInfo.onlineConfirmEmail,
                ShowChallengeQuestions: this.state.accountInfo.showChallenge,
                UserCanApprove: false,
                MaxOpenForwardDays: this.state.accountInfo.forwardDays,
                ReportingOnly: false,
                OnlineQuoteOnly: false
            },
            addressRequest: {
                AddressType: this.state.addressInfo.AddressType,
                AddressValue: this.state.addressInfo.AddressValue,
                CountryID: this.state.addressInfo.CountryId,
                StateID: this.state.addressInfo.StateId,
                City: this.state.addressInfo.City,
                Address2: this.state.addressInfo.Address2,
                ZipCode: this.state.addressInfo.ZipCode,
                OtherState: this.state.addressInfo.OtherState,
                DefaultAddress: this.state.addressInfo.DefaultAddress,
                SinceWhen: this.state.addressInfo.SinceWhen,
                Status: this.state.addressInfo.Status,
                District: this.state.addressInfo.District,
                POBox: this.state.addressInfo.POBox,
                UpdatedBy: userID,
                UpdateToken: this.state.contactInfo.updateToken
            },
            roles,
            contactUser: {
                ApplicationName: "ForeignExchange",
                UserID: this.props.selectedContact.PersonID,
                UserName: this.state.accountInfo.emailAddress,
                MobileName: "",
                Email: this.state.accountInfo.emailAddress,
                resetPassword: this.state.accountInfo.resetPassword !== undefined ? (this.state.accountInfo.resetPassword !== null ? this.state.accountInfo.resetPassword : false) : false,
                noDaysPasswordExpires: this.state.accountInfo.passwordExpire !== undefined ? (this.state.accountInfo.passwordExpire !== null ? this.state.accountInfo.passwordExpire : 0) : 0,
                noPreviousPwds: this.state.accountInfo.previousPassword !== undefined ? (this.state.accountInfo.previousPassword !== null ? this.state.accountInfo.previousPassword : 0) : 0,
                Islock: this.state.accountInfo.previousPassword !== undefined ? (this.state.accountInfo.previousPassword !== null ? this.state.accountInfo.accountLocked : false) : false,
                UserCommissionPercentage: 10.0,
                CommissionOverride: false,
                CountryID: this.state.addressInfo.CountryId,
                BusinessUnitID: 1,
                ReportsToUserID: null,
                FullName: this.state.contactInfo.middleName,
                ShowLocked: null,
                FirstName: this.state.contactInfo.firstName,
                LastName: this.state.contactInfo.lastName,
                PhoneNumber: this.state.contactInfo.phoneNumber,
                BranchID: null,
                DealsLimit: 1.0,
                RequireApproval: false,
                AllowPreviousDayVoid: false,
                CommissionPercentage: 1.0,
                AllowSameDayVoid: false,
                AllowSingleDealRoll: false,
                AllowTradeUpdates: false,
                RestrictCustomerAccess: false,
                CreatedBy: userID,
                CreatedOn: date.toISOString(),
                UpdatedBy: userID,
                UpdatedOn: date.toISOString()
            },
            contactClaim: {
                Questions: null,
                Password: this.state.accountInfo.password,
                NewPassword: this.state.accountInfo.confirmPassword,
                SecurityToken: "4e95799e-7e1f-4f0e-9ad3-d8fa12f1e4f4"
            },
            isContactImpersonation: isContactImpersonation,
            relatedCustomerId: this.state.accountInfo.relatedCustomerId
        };
        console.table(model);
        console.log("UpdateToken")
        console.log(this.state.contactInfo.updateToken)
        if (this.state.isValidatedContact && this.state.isValidatedAddress) {
            if (this.state.accountInfo.doublePassword && this.state.accountInfo.doubleEmail) {
                if (this.state.accountInfo.isEmailValidated && this.state.accountInfo.isConfirmEmailValidated) {
                    saveContact(model).then((json) => {
                        /*if (json.status !== 200){
                            this.setState({showAlertError: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error'});
                        }*/
                        if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                            this.setState({ showAlertError: true, alertMessage: json.Message, alertTitle: 'Error' });
                        }
                        else {
                            this.setState({ saveProperties: true, saveTasks: true }, () => {
                                this.setState({ contactInfo: { ...this.state.contactInfo, updateToken: json.updateToken, contactID: json.contactId }, showAlertOK: true, alertTitle: 'Contact successfully saved.' });
                                var newSelectedContact = this.transformContactInfoToSelectedContact(this.state.contactInfo, this.state.addressInfo);
                                this.setState({ contactUpdateToken: json.updateToken, selectedContact: { ...newSelectedContact, "UpdateToken": json.updateToken } })
                                this.updatePage();
                            }
                            );
                        }
                    })
                } else {
                    this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error' });
                }
            } else {
                this.setState({ showAlertError: true, alertMessage: 'Your email or password do not match. Please try again.', alertTitle: 'Error' });
            }
        } else {
            if (this.state.isValidatedContact && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadAdd: false
                });
            } else if (!this.state.isValidatedContact && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false,
                    notFirstLoadAdd: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            }
        }
    }
    isValidatedContactInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedContact: true,
                notFirstLoad: true,
                contactInfoErrors: []
            });
        } else {
            this.setState({
                isValidatedContact: false,
                contactInfoErrors: { tab: "Contact Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddressInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoadAdd: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoad: true, notFirstLoadAdd: true });
    }

    closeAlertOK() {
        this.setState({ showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true });
    }

    render() {
        var selectContact = {}
        selectContact.PersonID = this.props.selectedContact.PersonID
        selectContact.CustomerID = this.props.selectedContact['Customer ID']
        selectContact.DealStatus = null
        var section = (
            <h3 className="uk-heading-divider" style={style.titleStyle} >
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                    <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                </button>
                Contact - {this.props.selectedContact.Name} - {this.props.contactID}
            </h3>
        );
        if (this.props.newContact) {
            section = (
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right  min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    Create Contact {/*- {this.props.contactID}*/}
                </h3>
            );
        }
        if (this.state.updateContact) {
            return <ContactsScreen data={this.props.customerID} />
        }

        if (this.state.viewHistory) {
            return <AuditContactScreen backLink={this.handleReturnToContact.bind(this)} contactID={this.props.contactID} />
        }

        return (
            <div>
                <div>
                    {section}
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="contact-information">Contact Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information">Address Information</a>
                        </li>
                        {/*<li>
                            <a href="#" id="account-information">Account Information</a>
                        </li>*/}
                        <li>
                            <a href="#" id="contact-properties">Contact Properties</a>
                        </li>
                        {/*<li>
                            <a href="#" id="compliance">Compliance</a>
                        </li>
                        <li>
                            <a href="#" id="contact-tasks">Contact Tasks</a>
                        </li>*/}
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <ContactInformationForm isValid={this.isValidatedContactInfo.bind(this)} getContactInfo={this.getContactInfoModel.bind(this)} customerID={this.props.customerID} selectedContact={this.state.selectedContact} contactUpdateToken={this.state.contactUpdateToken} notFirstLoad={this.state.notFirstLoad} />
                        </li>
                        <li>
                            <AddressForm selectedAddress={this.state.addressInfo} getAddressInfo={this.getAddressInfoModel.bind(this)} isValid={this.isValidatedAddressInfo.bind(this)} validations={this.state.notFirstLoadAdd} />
                            {/* <AddressInformationForm getAddressInfo={this.getAddressInfoModel.bind(this)} selectedContact={this.state.selectedContact} contactUpdateToken={this.state.contactUpdateToken}/> */}
                        </li>
                        {/*<li>
                            <AccountInformationForm selectedContact={this.state.selectedContact} getAccountInfo={this.getAccountInfo.bind(this)} />
                        </li>*/}
                        <li>
                            <ContactPropertiesForm getSaveProperties={this.getSaveProperties.bind(this)} contactID={this.props.contactID} saveProperties={this.state.saveProperties} />
                        </li>
                        {/*<li>
                            <ComplianceScreen customer={selectContact} />
                        </li>
                        <li>
                            <ContactTasksForm getSaveTasks={this.getSaveTasks.bind(this)} contactID={this.props.contactID} saveTasks={this.state.saveTasks} />
                        </li>*/}
                    </ul>
                </div>
                <div>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">View History</button>
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleAttachmentsClick.bind(this)} type="button">Attachments</button>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleExitClick.bind(this)} type="button">Exit</button>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleResetAnswersClick.bind(this)} type="button">Reset Answers</button>*/}
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendOAEmailClick.bind(this)} type="button">Send Online Access Email</button>*/}
                </div>
                <div>
                    {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Contacts</h3>
                    <NewDynamicTable data={this.state.contacts} enableClick={false} clickFunction={this.handleRowClick.bind(this)} newSearch={this.state.newSearch} useDeleteButton={false}/>
                    <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                </div>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.contactInfoErrors, this.state.addressErrors]} type="Ok" okClick={this.closeAlertError.bind(this)} />
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)} />
            </div>
        );
    }
}