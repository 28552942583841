import jwt from 'jsonwebtoken';
import {decrypt, genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody, genericGetWithParametersNoEncrypted} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getCountryById(id){
    try {
        let params = {
            'countryId': id,
        }
        let response = await genericGetWithParameters('Country/GetCountryById',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchCountries(country){
    try {
        let sessionID = await sessionStorage.getItem('SessionID');
        let params = {
            'country': country
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'Country/GetCountryName?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            }
        });
        var helper = [];
        let responseJWT = await response.json();
        let responseFinal = await decrypt(responseJWT);

        for (var i = 0; i < responseFinal.totalCount; i++){
            helper.push({CountryId: responseFinal.countries[i].CountryId, CountryIdName: responseFinal.countries[i].CountryIdName});
        }
        if(responseFinal.totalCount > 0){
            return responseFinal.countries;
        } else {
            return [];
        }       
    } catch (error) {
        console.error(error);
    }
}

export async function getCountries(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        }

        let response = await genericGetWithParameters('Country/Get', params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function addCountry(objCountry) {
    try {
        let sessionID = await sessionStorage.getItem('SessionID');

        let response = await fetch(apiURL + 'Country/Post',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(objCountry),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function saveCountry(model){

    try{
        let response = await genericCallWithBody('POST','Country/SaveCountry',model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//Country/Delete
export async function deleteCountry(putCountry) {
    try{
        let params = {
            'countryId': putCountry
        };
        let response = await genericDeleteWithParameters('Country/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}