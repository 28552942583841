import React from 'react';
import PricingCategoryForm from './PricingCategoryForm';
import {getStyle} from '../../../styles/styles'
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getPricingCategory, getSpotbyCategoryID, deleteCategory, getPricingCategoryByID, getPricingSpotByID, getForwardbyCategoryID, getPricingForwardByID, deleteSpot, deleteForward } from '../../../networking/NetworkingPricing';
import SpotPricingDetailsForm from './SpotPricingDetailsForm';
import ForwardPricingDetailsForm from './ForwardPricingDetailsForm';
import AlertBox from '../../shared/AlertBox';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
const style = getStyle();

export default class PricingScreen extends React.Component {
    state = {
        categoryList:[],
        newSearch: true,
        pages: 1,
        totalPages: 0,
        categoryID:'',
        categoryName: '',
        spotList:[],
        forwardList:[],
        selectedCategory:{},
        selectedSpot:{},
        selectedForward:{},
        showDeleteAlert: false,
        readyToDeleteBank: {},
        showSuccessDeleteAlert: false,
        readyToDeleteCategory: {},
        categorySpotID:0,
        categoryForwardID:0,
        //forTabs
        step:1,
        objPricingCategory:[],
        objSpotPricing:[],
        objForwardPricing:[]
    }
    //For Tabs
    onClickStepTab(value){
        this.setState({step: value});
    }

    //Pricing Category Screen ==> default
    handleCategorySelection(obj){
        this.setState({objPricingCategory: obj});
    }
    
    //Spot Pricing Details Screen
    handleSpotSelection(obj){
        this.setState({step: 2, objSpotPricing: obj});
    }

    //Forward Pricing Details Screen
    handleForwardSelection(obj){
        this.setState({step: 3, objForwardPricing: obj});
    }

    componentDidMount(){
        getPricingCategory().then(
            (value) => {
                var category = this.transformCategoryList(value.pricingCategories);
                console.log(category);
                
                var helper = this.changeDefaultLabels(category);
                const totalCount = helper.length;
                const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                //pricingCategories
                this.setState({categoryList: helper, totalPages});
            }
        );
    }

    getSpotList(value) {
        getSpotbyCategoryID(value).then(
            (json) => {
                this.setState({ categorySpotID: value });
                if (json !== undefined && json.spotPricingDetails !== undefined) {
                    var spot = this.transformSpotList(json.spotPricingDetails);
                    console.log(spot);
                    const totalCount = spot.length;
                    const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    //currencies
                    this.setState({ spotList: spot, totalPages });
                } else {
                    this.setState({ spotList: [] });
                }
            }
        );
    }

    updateSpotList(value) {
        if (value === "select") {
            var helper = [];
            this.setState({ spotList: helper });
        } else {
            getSpotbyCategoryID(value).then(
                (json) => {
                    if (json !== undefined && json.spotPricingDetails !== undefined) {
                        var spot = this.transformSpotList(json.spotPricingDetails);
                        console.log(spot);
                        const totalCount = spot.length;
                        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        //currencies
                        this.setState({ spotList: spot, totalPages });
                    } else {
                        this.setState({ spotList: [] });
                    }
                }
            );
        }
    }

    getForwardList(value) {
        getForwardbyCategoryID(value).then(
            (json) => {
                this.setState({ categoryForwardID: value });
                if (json !== undefined && json.forwardPricingDetails !== undefined) {
                    console.log(json.forwardPricingDetails);
                    var frwd = this.transformForwardList(json.forwardPricingDetails);
                    console.log(frwd);
                    const totalCount = frwd.length;
                    const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    //currencies
                    this.setState({ forwardList: frwd, totalPages });
                } else {
                    this.setState({ forwardList: [] });
                }
            }
        );
    }

    updateForwardList(value) {
        console.log(value);
        if (value === "select") {
            var helper = [];
            this.setState({ forwardList: helper });
        } else {
            getForwardbyCategoryID(value).then(
                (json) => {
                    if (json !== undefined && json.forwardPricingDetails !== undefined) {
                        console.log(json.forwardPricingDetails);
                        var frwd = this.transformForwardList(json.forwardPricingDetails);
                        console.log(frwd);
                        const totalCount = frwd.length;
                        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        //currencies
                        this.setState({ forwardList: frwd, totalPages });
                    } else {
                        this.setState({ forwardList: [] });
                    }
                }
            );
        }
    }

    changeDefaultLabels(collection){
        var helper = collection;
        for(var i=0; i<collection.length;i++){
            if(collection[i]['Is Default?']){
                collection[i]['Is Default?'] = 'True';
            }else{
                collection[i]['Is Default?'] = 'False';
            }
        }
        return helper;
    }
    transformCategoryList(list){
        var helper = [];
        if(list){
            for(var i = 0; i<list.length; i++){
                helper.push({'Category ID': list[i]['Category ID'],
                'Category Name': list[i]['Category Name'], 
                'Is Default?': list[i]['Is Default?']});
            }
        }
        return helper;
    }
    
    transformSpotList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'PricingCategoryDetailID': list[i]['PricingCategoryDetailID'],
            'Home Currency': list[i]['Home Currency'],
            'Foreign Currency': list[i]['Foreign Currency'], 
            'Amount From': list[i]['Amount From'],
            'Amount To': list[i]['Amount To'],
            'Markup Factor': list[i]['Markup Factor'],
            'Is Percent or Point?': list[i]['Is Percent or Point?'],
        });
        }
        return helper;
    }
    
    transformForwardList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'PricingCategoryDetailID': list[i]['PricingCategoryDetailID'],
            'Home Currency': list[i]['Home Currency'],
            'Foreign Currency': list[i]['Foreign Currency'], 
            'Amount From': list[i]['Amount From'],
            'Amount To': list[i]['Amount To'],
            'Markup Factor': list[i]['Markup Factor'],
            'Period': list[i]['Period'],
            'Is Percent or Point?': list[i]['Is Percent or Point?'],
        });
        }
        return helper;
    }

    handleClearCategoryName = () => {
        this.setState({
            categoryName: '',
            categoryID: '',
            spotList: [],
            forwardList: []
        });
    }

    handleRowClick1(obj){
        console.group("Pricing Category handleRowClick1")
        console.log(obj);
        console.groupEnd();
        getPricingCategoryByID(obj['Category ID']).then(
            (value)=>{
                console.log(value.pricingCategories[0]);
                this.setState({selectedCategory: value.pricingCategories[0]});
            }
        );
        this.getSpotList(obj['Category ID']);
        this.getForwardList(obj['Category ID']);
        this.setState({ categoryID: obj['Category ID'], categoryName: obj['Category Name'] });
    }
    handleDeleteClick1(obj){
        console.group("Pricing Category handleDeleteClick1")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteCategory: obj});
    }
    
    handleRowClick2(obj){
        console.group("Pricing Spot handleRowClick")
        console.log(obj);
        console.groupEnd();
        getPricingSpotByID(obj['PricingCategoryDetailID']).then(
            (value)=>{
                console.log(value.spotPricingDetails[0]);
                this.setState({selectedSpot: value.spotPricingDetails[0]});
            }
        );
    }

    handleDeleteClick2(obj){
        console.group("Pricing Spot handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteCategory: obj});
    }

    handleRowClick3(obj){
        console.group("Pricing Forward handleRowClick")
        console.log(obj);
        console.groupEnd();
        getPricingForwardByID(obj['PricingCategoryDetailID']).then(
            (value)=>{
                console.log(value.forwardPricingDetails[0]);
                this.setState({selectedForward: value.forwardPricingDetails[0]});
            }
        );
    }
    
    handleDeleteClick3(obj){
        console.group("Pricing Forward handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteCategory: obj});
    }
    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDeleteCategory: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert(){
        let UserID = sessionStorage.getItem('UserID');
        deleteCategory(this.state.readyToDeleteCategory['Category ID'],UserID).then(
            (value)=>{
                this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                this.updateCategoryTable();
            }
        );
    }
    yesDeleteAlert2(){
        deleteSpot(this.state.readyToDeleteCategory['PricingCategoryDetailID']).then(
            (value)=>{
                this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                this.updateSpotList(this.state.categorySpotID);
            }
        );
    }
    yesDeleteAlert3(){
        deleteForward(this.state.readyToDeleteCategory['PricingCategoryDetailID']).then(
            (value)=>{
                this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                this.updateForwardList(this.state.categoryForwardID);
            }
        );
    }

    okSuccessAlert(){
        this.setState({showSuccessDeleteAlert: false});
    }
    changePage = (value) => {
        this.setState({pages: value});
    }

    updateCategoryTable(){
        getPricingCategory().then(
            (value) => {
                var category = this.transformCategoryList(value.pricingCategories);
                console.log(category);
                
                var helper = this.changeDefaultLabels(category);
                //pricingCategories
                this.setState({categoryList: helper});
            }
        );
    }
    getAllPricing = async () =>{
        var json = await getPricingCategory();
        var response = this.transformCategoryList(json.pricingCategories);
        var helper = this.changeDefaultLabels(response);
        return helper;
    }
    getAllSpots = async () =>{
        var json = await getSpotbyCategoryID(this.state.categorySpotID);
        var spot = this.transformSpotList(json.spotPricingDetails);
        return spot;
    }
    getAllForward = async () =>{
        var json = await getForwardbyCategoryID(this.state.categoryForwardID)
        var frwd = this.transformForwardList(json.forwardPricingDetails);
        return frwd;
    }

    render(){
        var stepScreen = [];

        var classNameStep1, classNameStep2, classNameStep3 = "";

        switch(this.state.step){
            default:
            case 1:
                classNameStep1 = "uk-active"
                stepScreen.push(<PricingCategoryForm key="category-form"
                                updateCategoryTable={this.updateCategoryTable.bind(this)} selectedCategory={this.state.selectedCategory}
                                onCategorySelected={this.handleCategorySelection.bind(this)} categoryName={this.state.categoryName} clearCategoryName={this.handleClearCategoryName}/>,
                                <NewDynamicTable key="category-screen" 
                                                data={this.state.categoryList} 
                                                hiddenHeaders={['Category ID']} 
                                                enableClick={true} 
                                                clickFunction={this.handleRowClick1.bind(this)} 
                                                newSearch={this.state.newSearch} 
                                                useDeleteButton={true} 
                                                deleteFunction={this.handleDeleteClick1.bind(this)} 
                                                onCategorySelected={this.handleCategorySelection.bind(this)}
                                                usePaginator={true}
                                                changePage={this.changePage}
                                                numberOfPages={this.state.totalPages}
                                                pageNumber={this.state.pages}
                                                numberPerPage={getDefaultPagingNumberOfItems()}
                                                enableSortByHeader={true}
                                                useFilter={true} 
                                                filterFunction={this.getAllPricing}/>,
                                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Category?" 
                                message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Category successfully removed." 
                                message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>);
                break;
            case 2:
                stepScreen.pop();
                classNameStep2 = "uk-active"
                stepScreen.push(<SpotPricingDetailsForm key="spot-form" 
                                onSpotSelected={this.handleSpotSelection.bind(this)} selectedSpot={this.state.selectedSpot} getSpotList={this.getSpotList.bind(this)} updateSpotList={this.updateSpotList.bind(this)} categoryID={this.state.categoryID}/>,
                                <NewDynamicTable key="spot-screen" 
                                                data={this.state.spotList} 
                                                enableClick={true} 
                                                hiddenHeaders={['PricingCategoryDetailID']} 
                                                clickFunction={this.handleRowClick2.bind(this)} 
                                                newSearch={this.state.newSearch} 
                                                useDeleteButton={true} 
                                                deleteFunction={this.handleDeleteClick2.bind(this)} 
                                                onSpotSelected={this.handleSpotSelection.bind(this)}
                                                enableSortByHeader={true}
                                                usePaginator={true}
                                                changePage={this.changePage}
                                                numberOfPages={this.state.totalPages}
                                                pageNumber={this.state.pages}
                                                numberPerPage={getDefaultPagingNumberOfItems()}
                                                useFilter={true} 
                                                filterFunction={this.getAllSpots}/>,
                                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Spot?" 
                                message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert2.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Spot Pricing successfully removed." 
                                message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>);
                break;
            case 3:
                stepScreen.pop();
                classNameStep3 = "uk-active"
                stepScreen.push(<ForwardPricingDetailsForm key="forward-form" 
                                onForwardSelected={this.handleForwardSelection.bind(this)} selectedForward={this.state.selectedForward} getForwardList={this.getForwardList.bind(this)} updateForwardList={this.updateForwardList.bind(this)} categoryID={this.state.categoryID}/>,
                                <NewDynamicTable key="forward-screen" 
                                                data={this.state.forwardList}
                                                hiddenHeaders={['PricingCategoryDetailID']}
                                                enableClick={true} 
                                                clickFunction={this.handleRowClick3.bind(this)} 
                                                newSearch={this.state.newSearch} 
                                                useDeleteButton={true} 
                                                deleteFunction={this.handleDeleteClick3.bind(this)}
                                                onForwardSelected={this.handleForwardSelection.bind(this)}
                                                enableSortByHeader={true}
                                                usePaginator={true}
                                                changePage={this.changePage}
                                                numberOfPages={this.state.totalPages}
                                                pageNumber={this.state.pages}
                                                numberPerPage={getDefaultPagingNumberOfItems()}
                                                useFilter={true} 
                                                filterFunction={this.getAllForward}/>,
                                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this Forward?" 
                                message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert3.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Forward Pricing successfully removed." 
                                message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>);
                break;
        }
        return(
            <div>
                <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Pricing</h3>
                    <ul className="uk-tab">
                        <li className={classNameStep1}><a onClick={this.onClickStepTab.bind(this, 1)}>Pricing Category</a></li>
                        <li className={classNameStep2}><a onClick={this.onClickStepTab.bind(this, 2)}>Spot Pricing Details</a></li>
                        <li className={classNameStep3}><a onClick={this.onClickStepTab.bind(this, 3)}>Forward Pricing Details</a></li>
                    </ul>
                    {stepScreen}
                </div>
            </div>
        );
    }
}