import React from 'react';
import EntityProperties from '../../../shared/EntityProperties';
import {CustomerBankAccountType} from '../../../../constants/EntityTypeNames';
import {getStyle} from '../../../../styles/styles'
var style = getStyle();

export default class BankAccountPropertiesForm extends React.Component {
    state = {
        bankAccountID: 0,
        entityPropertiesSaveProcess: false,
    }

    onFinishEntityPropertiesSaveProcess(){
        this.props.getSaveProperties(false);
    }

    render(){
        var section = (
            <form className="uk-form-stacked uk-grid">
                <div className="uk-width-1-1 uk-margin">
                    <EntityProperties fullScreen={true} entityType={CustomerBankAccountType} entityID={this.props.bankAccountID} runSaveProcess={this.props.saveProperties} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                </div>
            </form>
        )
        if(this.props.bankAccountID === 0){
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create bank account first.</h5>
                </div>
            )
        }
        return(
            <div>
                {section}
            </div>
        );
    }
}