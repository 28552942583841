import {genericGetWithParameters,genericDeleteWithParameters, genericCallWithBody} from './Networking';

export async function getBusinessHours(countryID) {
    try {
        let userID = await sessionStorage.getItem('UserID');
        let params = {
            'CountryId': countryID,
            'UserId': userID
        }
        let response = await genericGetWithParameters('BusinessHoursByCountry/GetList',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveBusinessHour(model){ 
    let response = await genericCallWithBody('PUT','BusinessHoursByCountry/Put',model);
    return response;
}