import React from 'react';
import AlertBox from '../shared/AlertBox';
import DynamicSelect from '../shared/DynamicSelect';
import { getLookUpTable, getLookUpTableByFilter } from '../../networking/Networking';
import { getBeneAccountByCustomerID, getNostroBankAccountsForFunds} from '../../networking/NetworkingBankAccounts';
import { getBeneficiaryForDisbursementsScreen } from '../../networking/NetworkingBeneficiaries'
import { getDealHeaderInfo } from '../../networking/NetworkingDeals';
import {getInstrumentsByCustomer} from '../../networking/NetworkingInstruments';
import { getOutgoingInformationByID, updateOutgoingFund, updateRejectedFeeAmount, getAmountInBaseCurrency, getBalanceDueByDealID } from '../../networking/NetworkingFunds';
import { getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../styles/styles';
import DatePicker from '../shared/DatePicker';
import NumberInput from '../shared/NumberInput';
import EntityProperties from '../shared/EntityProperties';
import { formatDate } from '../../helpers/FormatHelper';
import {OutgoingFundType} from '../../constants/EntityTypeNames';
import PlusIcon from '../../css/custom-icons/plus.svg';
import MinusIcon from '../../css/custom-icons/minus.svg';
import AuditOutgoingFundScreen from '../pre_trade/customers/auditEntity/AuditOutgoingFundScreen';
var style = getStyle();

export default class OutgoingFundsForm extends React.Component {
    state={
        outgoing: {
            OutgoingFundsID: 0,
            DealHeaderID: 0,
            CustomerID: 0,
            BeneficiaryBankAccountID: 0,
            BeneficiaryID: 0,
            BeneficiaryName: '',
            NostroAccountID: 0,
            NostroAccountName: '',
            PaymentInstrument: '',
            PaymentAmount: 0,
            DeliveryRefNo: '',
            PaymentReference: '',
            Status: '',
            VersionNo: 0,
            BeneficiaryType: 0,
            Comments: '',
            ValueDate: '',
            DeliveryMethod: '',
            WireItemFee: 0,
            WireItemFeeCurrencyID: '',
            DetailsOfPayment: '',
            CurrencyID: '',
            ContractBalance: '',
            CreatedBy: '',
            UpdateToken: ''
        },
        beneAccounts:{
            BeneficiaryID: 0,
            BankAccountID: 0,
            Name: '',
            AccountCurrencyID: '',
            ReceiveLimit: 0
        },
        beneficiaryAccountList: [],
        validations: {
            beneficiaryaccount: true,
            instrumenttodeliver: true,
            deliverymethod: true,
            valuedate: true,
            paymentamount: true,
            status: true,
            nostroaccount: true
        },
        dropdowns: {
            "Payment Delivery Method": [],
            "Outgoing Fund Status": [],
            "Outgoing Instruments": [],
            nostroBankAccounts: [],
            bankAccounts: [],
        },
        lookUpTables: [
            "Payment Delivery Method",
            "Outgoing Fund Status",
            "Outgoing Instruments"
        ],
        listDeliveryMethods: [],
        accountCcyID: '',
        alertMessage: '',
        alertTitle: '',
        amountInBaseCurrency: 0,
        balanceDue: 0,
        beneAccountID: 0,
        beneAccountName: '',
        beneID: 0,
        beneName: '',
        chosenWireTitle: '',
        custID: 0,
        dealId: 0,
        dealValueDate: '',
        deliveryMethod: '',
        deliveryMethodList: {},
        disable: true,
        disableControls: false,
        disableStatus: false,
        entityPropertiesSaveProcess: false,
        exec: false,
        isFirstLoad: true,
        outgoingInstrumentList: [],
        receiveLimit: 0,
        sellCcy: '',
        showAlert: false,
        showExceedsLimitAlert: false,
        showChosenWireAlert: false,
        showReleasePaymentAlert: false,
        showSendingFundsAlert: false,
        test: '',
    }

    yesExceedsLimitAlert = () => {
        var hasEntered = false;
        if(this.state.outgoing.Status === 'Completed' && this.state.balanceDue > 0){
            this.setState({showSendingFundsAlert: true});
            hasEntered = true;
        }
        if(this.state.dealValueDate > this.state.outgoing.ValueDate){
            this.setState({showReleasePaymentAlert: true});
            hasEntered = true;
        }

        if(!hasEntered){
            this.saveOutgoing();
        }
    }

    closeExceedsLimitAlert = () =>{
        this.setState({showExceedsLimitAlert: false, exec: false});
    }
    
    yesSendingFundsAlert = () => {
        var hasEntered = false;
           
            if(this.state.dealValueDate > this.state.outgoing.ValueDate){
                this.setState({showReleasePaymentAlert: true});
                hasEntered = true;
            }

        if(!hasEntered){
            this.saveOutgoing();
        }
    }

    closeSendingFundsAlert = () =>{
        this.setState({showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }
    
    yesReleasePaymentAlert = () => {
            this.saveOutgoing();
    }

    closeReleasePaymentAlert = () =>{
        this.setState({showReleasePaymentAlert: false, showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }
    
    yesChosenWireAlert = () => {
        this.updateOutgoingIntern();
    }

    closeChosenWireAlert = () =>{
        this.setState({showChosenWireAlert: false, showReleasePaymentAlert: false, showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }

    componentDidUpdate(prevProps){
        var custID = 0;
        var sellCcy = '';
        var buyccy = '';
        var dealId = 0;
        if(prevProps.sellCcy !== this.props.sellCcy)
        {
            sellCcy = this.props.sellCcy;
            this.setState({sellCcy: this.props.sellCcy, outgoing: {...this.state.outgoing, CurrencyID: sellCcy}})
            //this.setState({outgoing: {...this.state.outgoing, CurrencyID: sellCcy}});       
            
            if(sellCcy != null && sellCcy !== ''){                
                getNostroBankAccountsForFunds(sellCcy).then(
                    (json) => {
                        for (var i = 0; i < json.nostroBankAccounts.length; i++){
                            json.nostroBankAccounts[i].name = json.nostroBankAccounts[i].BankName;
                            json.nostroBankAccounts[i].value = json.nostroBankAccounts[i].BankAccountID;
                        }
                        this.setState({dropdowns: {...this.state.dropdowns, nostroBankAccounts: json.nostroBankAccounts}});
                    }
                )
            }
        }
        if(prevProps.dealId !== this.props.dealId)
        {            
            dealId = this.props.dealId;
            this.setState({dealId: this.props.dealId});	
            getDealHeaderInfo(this.props.dealId).then(
                (json) => {
                    for(var i= 0; i < json.dealHeaderInfo.length; i++){
                        this.setState({dealValueDate: json.dealHeaderInfo[i].ValueDate});
                        buyccy = json.dealHeaderInfo[i].BCur;
                        this.setState({
                            outgoing: {...this.state.outgoing, WireItemFeeCurrencyID: buyccy, CurrencyID: json.dealHeaderInfo[i].SCur, DealHeaderID: dealId}
                        });
                    }
                }
            );
        }
        if(prevProps.custId !== this.props.custId)
        {
            custID = this.props.custId;
            this.setState({custID: this.props.custId})		
            this.setState({
                outgoing: {...this.state.outgoing, CustomerID: custID}
            });
            /*getBeneAccountByCustomerID(custID, sellCcy).then(
                (json) => {
                    this.setState({beneAccounts: json.bankAccounts});
                    for(var i = 0; i < json.bankAccounts.length; i++){
                        json.bankAccounts[i].name = json.bankAccounts[i].Name;
                        if(json.bankAccounts[i].BankAccountID > 0){
                            json.bankAccounts[i].value = json.bankAccounts[i].BankAccountID;
                        } else{                        
                            json.bankAccounts[i].value = json.bankAccounts[i].BeneficiaryID;
                        }
                    }
                    this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: json.bankAccounts}});
                }
            )*/             
            this.loadInstrument(custID);  
            getBeneficiaryForDisbursementsScreen(custID, sellCcy).then(
                (json) => {
                    var helper = [];
                    let beneficiaries = json.beneficiaries;
                    if(beneficiaries !== undefined){
                        var tbaRecord = {value:'-2|0', name:'TBA', BeneficiaryID:-2, BeneficiaryBankAccountID:0};
                        helper.push(tbaRecord);
    
                        //var customerRecord = {value:'-1|0', name:this.props.objCustomer['Customer Name'], BeneficiaryID:-1, BeneficiaryBankAccountID:0}
                        //helper.push(customerRecord);
    
                        for(var i=0; i<beneficiaries.length; i++){
                            beneficiaries[i].value = beneficiaries[i].Value;
                            beneficiaries[i].name = beneficiaries[i].Description;
                            helper.push(beneficiaries[i]);
                        }
                    }

                    this.setState({beneficiaryAccountList: helper});
                }
            )
        }
        if(prevProps.id !== this.props.id)
        {
            var outgoingID = this.props.id.id;
            if(outgoingID != null && outgoingID > 0){
                getOutgoingInformationByID(outgoingID).then(
                    (value) => {
                        this.setState({outgoing: value},
                            () => {
                                let instrumentObject = this.state.dropdowns["Outgoing Instruments"].filter(x => x.name === this.state.outgoing.PaymentInstrument)[0];
                                this.loadLookUpTableByFilter(instrumentObject);
                        });
                        if(value.Status === 'Completed' || value.Status === 'Void'){
                            this.setState({disableControls: true});
                        } else {
                            this.setState({disableControls: false});                            
                        }
                        if(value.Status === 'Void'){
                            this.setState({disableStatus: true});
                        } else {
                            this.setState({disableStatus: false});
                        }
                        for(var i = 0; i < this.state.beneAccounts.length; i++){
                            if(this.state.beneAccounts[i].BankAccountID > 0){
                                this.setState({beneAccountID: this.state.beneAccounts[i].BankAccountID, beneAccountName: this.state.beneAccounts[i].Name, beneID: this.state.beneAccounts[i].BeneficiaryID});
                            } else{                        
                                this.setState({beneAccountID: this.state.beneAccounts[i].BeneficiaryID, beneAccountName: this.state.beneAccounts[i].Name});
                            }
                        }
                    }
                );
                this.setState({disable: false});
            }
            else{
                this.setState({outgoing: {...this.state.outgoing, UpdateToken: '637269570688200000'}})       
            } 
            getLookUpTable(this.state.lookUpTables.toString()).then(
                (json) => {
                    for (var i = 0; i < this.state.lookUpTables.length; i++) {
                        let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                        if (index > -1){
                            var table = json.lookUpTables[index].lookUpTableDetails;
                            for (var j = 0; j < table.length; j++) {
                                table[j].value = table[j].LookUpTableDetail;
                                table[j].name = table[j].LookUpTableDetail;
                            }
                            this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                        }
                    }
            }
            );             
        }
    }

    componentDidMount() {
        var outgoingID = this.props.id;
        var custID = this.props.custId;
        var sellCcy = this.props.sellCcy;
        var dealId = this.props.dealId;
            
        this.setState({sellCcy: sellCcy, custID: custID, dealId: dealId})
        
        if(outgoingID != null && outgoingID > 0){
            getOutgoingInformationByID(outgoingID).then(
                (value) => {
                    this.setState({outgoing: value});
                }
            );
        }
        else{
            this.setState({outgoing: {...this.state.outgoing, UpdateToken: '637269570688200000'}})            
        }
        if(sellCcy != null && sellCcy !== ''){         
            this.setState({outgoing: {...this.state.outgoing, CurrencyID: sellCcy}});       
            getNostroBankAccountsForFunds(sellCcy).then(
                (json) => {
                    for (var i = 0; i < json.nostroBankAccounts.length; i++){
                        json.nostroBankAccounts[i].name = json.nostroBankAccounts[i].BankName;
                        json.nostroBankAccounts[i].value = json.nostroBankAccounts[i].BankAccountID;
                    }
                    this.setState({dropdowns: {...this.state.dropdowns, nostroBankAccounts: json.nostroBankAccounts}});
                }
            )
            if(custID != null && custID > 0){              
                this.setState({outgoing: {...this.state.outgoing, CustomerID: custID}})   
                this.loadInstrument(custID);
                getBeneAccountByCustomerID(custID, sellCcy).then(
                    (json) => {
                        this.setState({beneAccounts: json.bankAccounts});
                        for(var i = 0; i < json.bankAccounts.length; i++){
                            json.bankAccounts[i].name = json.bankAccounts[i].Name;
                            if(json.bankAccounts[i].BankAccountID > 0){
                                json.bankAccounts[i].value = json.bankAccounts[i].BankAccountID;
                            } else{                        
                                json.bankAccounts[i].value = json.bankAccounts[i].BeneficiaryID;
                            }
                        }
                        this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: json.bankAccounts}});
                    }
                )
            }
        }
        if(this.props.dealId != null && this.props.dealId > 0)
        {
            this.setState({dealId: this.props.dealId});
            getDealHeaderInfo(this.props.dealId).then(
                (json) => {
                    for(var i= 0; i < json.dealHeaderInfo.length; i++){
                        this.setState({dealValueDate: json.dealHeaderInfo[i].ValueDate})
                        this.setState({outgoing: {...this.state.outgoing, WireItemFeeCurrencyID: json.dealHeaderInfo[i].BCur, CurrencyID: sellCcy, DealHeaderID: this.props.dealId, CustomerID: custID}})
                    }
                }
            );
        }
        
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                    }
                }
        }
        );
    }
    
    //Get customer instruments
    loadInstrument(customerId){    
        getInstrumentsByCustomer(customerId).then(
            (json) => {
                let jsonInstruments = json.instruments;
                if(jsonInstruments !== undefined){
                    var incomingHelper = [];
                    var outgoingHelper = [];
                    for(var i=0; i<jsonInstruments.length; i++){
                        var instrumentElement = {value:jsonInstruments[i].Instrument,
                            name:jsonInstruments[i].Instrument};
                        if(jsonInstruments[i].InstrumentType === 'Incoming'){
                            incomingHelper.push(instrumentElement);
                        }
                        else{
                            instrumentElement.deliveryMethods = jsonInstruments[i].DeliveryMethods;
                            outgoingHelper.push(instrumentElement);
                        }
                    }
                    this.setState({incomingInstrumentList: incomingHelper,
                        outgoingInstrumentList: outgoingHelper,
                    });   
                    //Load delivery methods 
                    let instruments = outgoingHelper;
                    var deliveryMethodList = {};
                    for(var k=0; k<instruments.length; k++){
                        var list = [];
                        if(instruments[k].deliveryMethods !== ''){
                            var methodsList = instruments[k].deliveryMethods.split('§');
                            for(var j=0; j<methodsList.length; j++){
                                var item = {value:methodsList[j], name:methodsList[j]};
                                list.push(item);
                            }
                        }
                        deliveryMethodList[instruments[k].value] = list;
                    }
                    this.setState({deliveryMethodList: deliveryMethodList});
                }
            }
        );
    }
    
    loadLookUpTableByFilter(value) {
        if (typeof value != "undefined") {
            getLookUpTableByFilter("Settlement Delivery Method", value.Field4).then((response) => {
                if (typeof response !== "undefined") {
                    let deliverMethodsResponse = response.lookUpTables[0].lookUpTableDetails;
                    deliverMethodsResponse.forEach(element => {
                        element.value = element.LookUpTableDetail;
                        element.name = element.LookUpTableDetail;
                    });
                    this.setState({
                        listDeliveryMethods: deliverMethodsResponse
                    });
                } else {
                    this.setState({listDeliveryMethods: []});
                }
            });
        }
    }
    
    validateRequiredFields(){
        var valid = true;
        var beneficiaryaccount = true, instrumenttodeliver = true, deliverymethod = true, valuedate = true, paymentamount = true, status= true, nostroaccount = true;
        //if(this.state.beneAccountID === "select" || this.state.beneAccountID === 0){
        if(this.state.outgoing.BeneficiaryID === "select" || this.state.outgoing.BeneficiaryID === undefined){
            valid = false;
            beneficiaryaccount = false;
        }
        //if(this.state.outgoing.PaymentInstrument === "select" || this.state.dropdowns["Outgoing Instruments"].find(e => e.value === this.state.outgoing.PaymentInstrument) === undefined){
        if(this.state.outgoing.PaymentInstrument === "select" || this.state.outgoing.PaymentInstrument === undefined){
            valid = false;
            instrumenttodeliver = false;
        }
        //if(this.state.outgoing.DeliveryMethod === "select" || this.state.dropdowns["Payment Delivery Method"].find(e => e.value === this.state.outgoing.DeliveryMethod) === undefined){
        if(this.state.outgoing.DeliveryMethod === "select" || this.state.outgoing.DeliveryMethod === undefined){
            valid = false;
            deliverymethod = false;
        }
        if (this.state.outgoing.ValueDate === '') {
            valid = false;
            valuedate = false;
        }
        if (this.state.outgoing.PaymentAmount === "select" || this.state.outgoing.PaymentAmount === '' || this.state.outgoing.PaymentAmount === undefined || this.state.outgoing.PaymentAmount === 0) {
            valid = false;
            paymentamount = false;
        } 
        if(this.state.outgoing.Status === "select" || this.state.dropdowns["Outgoing Fund Status"].find(e => e.value === this.state.outgoing.Status) === undefined){
            valid = false;
            status = false;
        }
        if(this.state.outgoing.NostroAccountID === "select" || this.state.outgoing.NostroAccountID === 0){
            valid = false;
            nostroaccount = false;
        }
        this.setState({
            validations: {
                ...this.state.validations,
                beneficiaryaccount: beneficiaryaccount,
                instrumenttodeliver: instrumenttodeliver,
                deliverymethod: deliverymethod,
                valuedate: valuedate,
                paymentamount: paymentamount,
                status: status,
                nostroaccount: nostroaccount
            }
        })
        return valid;
    }

    onFinishEntityPropertiesSaveProcess(){
        this.setState({entityPropertiesSaveProcess: false});
    }

    handleUpdateOutgoingFundClick = async(event)=>{
        event.preventDefault();            
        if(this.state.beneID > 0)
        {
            this.setState({outgoing: {...this.state.outgoing, BeneficiaryID: this.state.beneID}})
        }
        if(this.state.outgoing.Status === 'Rejected'){
            /*await updateRejectedFeeAmount(this.state.outgoing).then(
                (json) => {
                    console.log(json);
                }
            )*/
            var feeAmount = await updateRejectedFeeAmount(this.state.outgoing);
        }

        if (this.validateRequiredFields()) {
            if(this.state.outgoing.Status === 'Void'){
                if(this.state.outgoing.UpdateToken === undefined)
                {
                    this.setState({outgoing: {...this.state.outgoing, UpdateToken: '637269570688200000'}});
                }
                var json = await updateOutgoingFund(this.state.outgoing);
                this.setState({
                    outgoing: {
                        ...this.state.outgoing, UpdateToken: json.updateToken, OutgoingFundsID: json.outgoingID
                    },
                    showAlert: true, alertTitle: 'OutgoingFund successfully saved.', entityPropertiesSaveProcess: true
                });
                this.props.updateFunction();
                /*updateOutgoingFund(this.state.outgoing).then(
                    (json) => {
                        console.log(json);
                        this.setState({
                            outgoing: {
                                ...this.state.outgoing, UpdateToken: json.updateToken
                            },
                            showAlert: true, alertTitle: 'OutgoingFund successfully saved.', entityPropertiesSaveProcess: true
                        });
                        this.props.updateFunction();
                    }
                )*/
            }
            
            var getamountInBaseCcy = await getAmountInBaseCurrency(this.state.dealId, this.state.outgoing.PaymentAmount);
            this.setState({amountInBaseCurrency: getamountInBaseCcy.response})
            /*getAmountInBaseCurrency(this.state.dealId, this.state.outgoing.PaymentAmount).then(
                (value) => {
                    this.setState({amountInBaseCurrency: value.response})
                }
            );*/

            var getBalanceDue = await getBalanceDueByDealID(this.state.dealId);
            this.setState({balanceDue: getBalanceDue.response});
            /*getBalanceDueByDealID(this.state.dealId).then(
                (value) => {
                    this.setState({balanceDue: value.response});
                }
            );*/
            
            this.setState({test: this.state.beneAccounts.length});
            for (var i = 0; i < this.state.beneAccounts.length; i++){
                if(this.state.beneAccounts[i].Name === this.state.beneAccountName){
                    this.setState({receiveLimit: this.state.beneAccounts[i].ReceiveLimit,
                                    accountCcyID: this.state.beneAccounts[i].AccountCurrencyID,
                                    beneName: this.state.beneAccounts[i].Name});
                }
                
            }
            var hasEntered = false;
            if(this.state.outgoing.Status !== 'Void'){
                if(this.state.outgoing.PaymentInstrument === 'Electronic Funds Transfer' || this.state.outgoing.PaymentInstrument === 'EFT-Direct Credit'){
                    if(this.state.receiveLimit != null && this.state.amountInBaseCurrency > this.state.receiveLimit){
                        hasEntered = true;
                        this.setState({showExceedsLimitAlert: true});
                    }
                } else {
                    if(this.state.receiveLimit != null && this.state.amountInBaseCurrency > this.state.receiveLimit){
                        hasEntered = true;
                        this.setState({showExceedsLimitAlert: true});
                    }
                }

                if(!hasEntered){
                    if(this.state.outgoing.Status === 'Completed' && this.state.balanceDue > 0){
                        hasEntered = true;
                        this.setState({showSendingFundsAlert: true});
                    }
                }
                
                if(!hasEntered){
                    if(this.state.dealValueDate > this.state.outgoing.ValueDate){
                        this.setState({showReleasePaymentAlert: true});
                        hasEntered = true;
                    }
                }
            }

            //if(!hasEntered && this.state.exec){
            if(!hasEntered){
                if(this.state.outgoing.OutgoingFundsID === 0)
                {
                    getDealHeaderInfo(this.state.dealId).then(
                        (json) => {
                            for(var i= 0; i < json.dealHeaderInfo.length; i++){
                                this.setState({
                                    outgoing: {...this.state.outgoing, CurrencyID: json.dealHeaderInfo[i].SCur, BeneficiaryType: 0, VersionNo: 0, UpdateToken: '637269570688200000'}
                                });
                            }
                        }
                    );
                }
                this.saveOutgoing();
            }
        }
    }

    saveOutgoing(){        
        /*if(this.state.beneID > 0)
        {
            this.setState({outgoing: {...this.state.outgoing, BeneficiaryID: this.state.beneID}})
        }*/
        if(this.state.outgoing.PaymentInstrument === 'Electronic Funds Transfer' || this.state.outgoing.PaymentInstrument === 'EFT-Direct Credit'){
            if(this.state.outgoing.CurrencyID !== this.state.accountCcyID && this.state.beneName !== 'TBA'){
                var tempTitle = 'You have chosen to wire ' + this.state.outgoing.CurrencyID + " to a " + this.state.accountCcyID + " beneficiary account. Are you sure this is correct?"
                this.setState({chosenWireTitle: tempTitle, showChosenWireAlert: true});
            } else {
                this.updateOutgoingIntern();                     
            }
        } else {
            this.updateOutgoingIntern();
        }
    }

    updateOutgoingIntern(){
        updateOutgoingFund(this.state.outgoing).then(
            (json) => {
                console.log(json);
                if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                    //this.setState({showAlert: true, alertMessage: 'An error occurred while trying to save the bank. Please try again.', alertTitle: 'Error'});
                    this.setState({showAlert: true, alertMessage: json.Message, alertTitle: 'Error'}); 
                    this.setState({showExceedsLimitAlert: false, showSendingFundsAlert: false, showReleasePaymentAlert: false, showChosenWireAlert: false});
                } else {
                    this.setState({
                        outgoing: {
                            ...this.state.outgoing, UpdateToken: json.updateToken, OutgoingFundsID: json.outgoingID
                        },
                        showAlert: true, alertTitle: 'OutgoingFund successfully saved.', entityPropertiesSaveProcess: true
                    });
                    if(this.state.outgoing.Status !== 'Completed' || this.state.outgoing.Status === 'Void'){  
                        this.setState({disableControls: true});
                    } else{
                        this.setState({disableControls: false});                        
                    }
                    if (this.state.outgoing.Status === 'Void'){
                        this.setState({disableStatus: true});
                    } else {
                        this.setState({disableStatus: false});
                    }
                    this.props.updateFunction();
                    this.setState({showExceedsLimitAlert: false, showSendingFundsAlert: false, showReleasePaymentAlert: false, showChosenWireAlert: false});
                }
            }
        )        
    }
    
    handleClearClick(){
        this.setState({
            outgoing: {...this.state.outgoing, 
                BeneficiaryBankAccountID: 0, 
                BeneficiaryID: '', 
                BeneficiaryName: '',
                NostroAccountID: 0,
                NostroAccountName: '',
                PaymentInstrument: '',
                PaymentAmount: 0,
                OutgoingFundsID: 0,
                DeliveryRefNo: '',
                PaymentReference: '',
                Status: 'Pending',
                VersionNo: '',
                BeneficiaryType: '',
                Comments: '',
                ValueDate: '',
                DeliveryMethod: '',
                WireItemFee: 0,
                WireItemFeeCurrencyID: '',
                DetailsOfPayment: '',
                ContractBalance: '',
                CreatedBy: '',
                UpdateToken: ''
            }, 
            beneAccountID: 0, 
            beneAccountName: '',
            beneID: 0,
            disable: true,
            disableControls: false,
            disableStatus: false,
            listDeliveryMethods: [],
            receiveLimit: 0
        });
    }

    handleReturnToOutgoingFund() {
        this.setState({ viewHistory: false});
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true});
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert(){
        this.setState({showAlert: false});
    }
    
    handleGetDeliveryMethod (value) {
        if(value !== null && value !== undefined && value !== 'select'){
            this.setState({
                outgoing: {...this.state.outgoing, DeliveryMethod: value.value},
                validations: {...this.state.validations, deliverymethod: true}
            });
        }
    }
    /*handleGetDeliveryMethod = (obj) => {
        if(obj.value !== undefined){
            var value = obj.value;
            if(value === 'select'){
                value = '';
            }
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp.DeliveryMethod = value;
            this.setState({outgoingFundList: outgoingTemp});
        }
    }*/
    
    handleGetStatus (value) {
        if(value !== null && value !== undefined && value !== 'select'){
            this.setState({
                outgoing: {...this.state.outgoing, Status: value.value},
                validations: {...this.state.validations, status: true}
            });
            if(value.value !== 'Completed' || value.value !== 'Void'){            
                this.setState({disableControls: false, disableStatus: false});
            }
        }
    }
    
    /*handleGetInstrumentToDeliver (value) {
        if(value !== null && value !== undefined && value !== 'select'){
            this.setState({
                outgoing: {...this.state.outgoing, PaymentInstrument: value.value},
                validations: {...this.state.validations, instrumenttodeliver: true}
            });
            this.loadLookUpTableByFilter(value);
            this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: []}, beneAccountID: 0, beneAccountName: '', beneID: 0});
            //if(!this.state.isFirstLoad){
                if(value.value === 'Electronic Funds Transfer' || value.value === 'EFT-Direct Credit'){
                    getBeneAccountByCustomerID(this.state.custID, this.state.sellCcy).then(
                        (json) => {
                            this.setState({beneAccounts: json.bankAccounts});
                            for(var i = 0; i < json.bankAccounts.length; i++){
                                json.bankAccounts[i].name = json.bankAccounts[i].Name;
                                json.bankAccounts[i].value = json.bankAccounts[i].BankAccountID;
                            }
                            this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: json.bankAccounts}});
                        }
                    );
                }
                else{
                    getBeneAccountByCustomerID(this.state.custID, '-').then(
                        (json) => {
                            this.setState({beneAccounts: json.bankAccounts});
                            for(var i = 0; i < json.bankAccounts.length; i++){
                                json.bankAccounts[i].name = json.bankAccounts[i].Name;
                                json.bankAccounts[i].value = json.bankAccounts[i].BeneficiaryID
                            }
                            this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: json.bankAccounts}});
                        }
                    );
                }
            //}
            //else{
            //    this.setState({isFirstLoad: false});
            //}
        }
    }*/
    handleGetInstrumentToDeliver = (obj) => {
        if(obj.value !== undefined){
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp.PaymentInstrument = obj.value;
            outgoingTemp.DeliveryMethod = '';
            //this.calculateWireItemFee(outgoingTemp, index, obj.value, outgoingTemp[index].PaymentAmount);
            this.setState({outgoing: outgoingTemp});
        }
    }

    handleGetNostroAccount (value) {
        if(value !== null && value !== undefined && value !== 'select' && value!== 0){
            this.setState({
                outgoing: {...this.state.outgoing, NostroAccountID: value.value},
                validations: {...this.state.validations, nostroaccount: true}
            });
        }
    }

    /*handleGetBeneficiaryBankAccount (value) {
        var deliveryMethod = ''
        if(value !== null && value !== undefined && value !== 'select' && value!== 0){
            if(this.state.outgoing.PaymentInstrument === 'Electronic Funds Transfer' || this.state.outgoing.PaymentInstrument === 'EFT-Direct Credit')
            {
                for(var i = 0; i < this.state.beneAccounts.length; i++){
                    var beneBankID = this.state.beneAccounts[i].BankAccountID;
                    var bankAcctName = this.state.beneAccounts[i].Name;
                    var benefID = this.state.beneAccounts[i].BeneficiaryID;
                    deliveryMethod = this.state.beneAccounts[i].DefaultDeliveryMethod;
                    if(beneBankID === Number(value.value)){
                        this.setState({beneAccountName: bankAcctName, beneID: benefID});
                        if(deliveryMethod !== null && deliveryMethod !== ''){
                            this.setState({deliveryMethod: deliveryMethod});
                        }
                    }
                }
                this.setState({
                    beneAccountID: value.value,
                    outgoing: {...this.state.outgoing, BeneficiaryBankAccountID: value.value, BeneficiaryID: ''},
                    validations: {...this.state.validations, beneficiaryaccount: true}
                });
            }
            else
            {      
                for(var i2 = 0; i2 < this.state.beneAccounts.length; i2++){
                    var beneID = this.state.beneAccounts[i2].BeneficiaryID;
                    var beneName = this.state.beneAccounts[i2].Name
                    if(beneID === Number(value.value)){
                        this.setState({beneAccountName: beneName});
                    }
                }
                this.setState({
                    beneAccountID: value.value,
                    outgoing: {...this.state.outgoing, BeneficiaryID: value.value, BeneficiaryBankAccountID: ''},
                    validations: {...this.state.validations, beneficiaryaccount: true}
                });      
            }
        }
        this.setState({
            outgoing: {...this.state.outgoing, DeliveryMethod: deliveryMethod}
        });
    }*/
    handleGetBeneficiaryBankAccount = (obj) => {
        if(obj.value !== undefined){
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            let entityID = obj.value;
            outgoingTemp.EntityID = entityID;
            let ids = entityID.split('|');

            outgoingTemp.BeneficiaryBankAccountID = ids[1];
            outgoingTemp.BeneficiaryID = ids[0];
            outgoingTemp.CountryName = obj.CountryName;
            outgoingTemp.Name = obj.BeneficiaryName;

            if(obj.PreferredMethodOfPayment !== '' &&
                obj.PreferredMethodOfPayment !== undefined){
                outgoingTemp.PaymentInstrument = obj.PreferredMethodOfPayment;
            }
            else{
                outgoingTemp.PaymentInstrument = '';
            }

            if(obj.PreferredDeliveryMethod !== '' &&
                obj.PreferredDeliveryMethod !== undefined){
                outgoingTemp.DeliveryMethod = obj.PreferredDeliveryMethod;
            }
            else{
                outgoingTemp.DeliveryMethod = '';
            }
            outgoingTemp.BeneficiaryType = 0;
            switch(Number(outgoingTemp.BeneficiaryID)){
                case -1:
                    outgoingTemp.BeneficiaryType = 1;
                    break;
                case -2:
                    outgoingTemp.BeneficiaryType = 2;
                    break;
                default:
                    break;
            }

            if(entityID === 'select'){
                outgoingTemp.BeneficiaryID = -1;
                outgoingTemp.BeneficiaryBankAccountID = 0;
            }
            
            this.setState({outgoing: outgoingTemp});
        }
    }

    handleUpdatePaymentAmount (event){
        this.setState({
            outgoing: {...this.state.outgoing, PaymentAmount: event.target.value},
            validations: {...this.state.validations, paymentamount: true}
        });
    }

    handleUpdateDeliveryRefNo (event){
        this.setState({outgoing: {...this.state.outgoing, DeliveryRefNo: event.target.value}});
    }

    handleUpdateDetailsOfPayment (event){
        this.setState({outgoing: {...this.state.outgoing, DetailsOfPayment: event.target.value}});
    }

    handleUpdatePaymentReference (event){
        this.setState({outgoing: {...this.state.outgoing, PaymentReference: event.target.value}});
    }

    handleUpdateWireItemFee (event){
        this.setState({outgoing: {...this.state.outgoing, WireItemFee: event.target.value}});
    }

    handleUpdateComments (event){
        this.setState({outgoing: {...this.state.outgoing, Comments: event.target.value}});
    }

    handleUpdateValueDate(date){
        if(date!==undefined){
            this.setState({outgoing:{...this.state.outgoing, ValueDate: formatDate(date)}});
        }else{
            this.setState({outgoing:{...this.state.outgoing, ValueDate: ''}});
        }
    }

    render(){
        //for required inputs
        var classNameInputName = regularInputClass;
        
        let list = this.state.outgoing;
        //Get delivery methods
        var deliveryMethods = [];
        if(list.PaymentInstrument !== undefined &&
            list.PaymentInstrument !== '' &&
            list.PaymentInstrument !== 'select'){
            deliveryMethods = this.state.deliveryMethodList[list.PaymentInstrument];
        }

        if (this.state.viewHistory) {
            return (<AuditOutgoingFundScreen backLink={this.handleReturnToOutgoingFund.bind(this)} outgoingFundID={this.state.outgoing.OutgoingFundsID} />);
        }

        return(
            <div>
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Beneficiary</label>
                            {/*<div className="uk-margin" style={style.inputStyle}>*/}
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} id="form-beneficiary" className={this.formatValidSelect(this.state.validations.beneficiaryaccount)} getValue={this.handleGetBeneficiaryBankAccount} objValue={{value:this.state.outgoing.BeneficiaryID + '|' + this.state.outgoing.BeneficiaryBankAccountID}} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.beneficiaryAccountList} />
                            </div>
                        </div>
                        <div className="uk-width-1-2" style={{visibility:"hidden"}}>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" />
                            </div>
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Payment Amount (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                {/*<input className="uk-input" type="number" placeholder="0.00" value={this.state.outgoing.PaymentAmount} onChange={this.handleUpdatePaymentAmount.bind(this)}/>*/}
                                <NumberInput disabled={true} className={this.formatValidInput(this.state.validations.paymentamount)} id="form-outgoing-funds-payment-amount" placeholder="0,00" type="Currency" value={this.state.outgoing.PaymentAmount} onChange={this.handleUpdatePaymentAmount.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" htmlFor="form-deal-valueDateFrom" style={style.boldLabel}>Value Date(*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker disabled={true} className={classNameInputName} value={this.state.outgoing.ValueDate} onDayChange={this.handleUpdateValueDate.bind(this)}/>
                            </div>
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Instrument to deliver(*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                {/*<DynamicSelect className={this.formatValidSelect(this.state.validations.instrumenttodeliver)} disabled={this.state.disableControls} objValue={{value: this.state.outgoing.PaymentInstrument}} getValue={this.handleGetInstrumentToDeliver.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Outgoing Instruments"]} id="form-payment-instrument" />*/}
                                <DynamicSelect disabled={true} id="form-payment-instrument" className={this.formatValidSelect(this.state.validations.instrumenttodeliver)} getValue={this.handleGetInstrumentToDeliver} objValue={{value: this.state.outgoing.PaymentInstrument}} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.outgoingInstrumentList} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Delivery Method(*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                {/*<DynamicSelect className={this.formatValidSelect(this.state.validations.deliverymethod)} disabled={this.state.disableControls} objValue={{value: this.state.outgoing.DeliveryMethod}} getValue={this.handleGetDeliveryMethod.bind(this)}
                                data={this.state.listDeliveryMethods} id="form-delivery-method" />*/}
                                <DynamicSelect disabled={true} id="form-delivery-method" className={this.formatValidSelect(this.state.validations.deliverymethod)} getValue={this.handleGetDeliveryMethod} value={this.state.outgoing.DeliveryMethod} objValue={{value: this.state.outgoing.DeliveryMethod}} 
                                listHasPlaceholder={false} placeholder="Select a Method..." data={deliveryMethods} />
                            </div>
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Nostro Bank Account(*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.nostroaccount)} objValue={{value: this.state.outgoing.NostroAccountID}} getValue={this.handleGetNostroAccount.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.nostroBankAccounts} id="form-nostro-account" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Delivery reference Number</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" disabled={true} type="text" value={this.state.outgoing.DeliveryRefNo} onChange={this.handleUpdateDeliveryRefNo.bind(this)} />
                            </div>
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Details of Payment</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <textarea className="uk-textarea" disabled={true}
                                    value={this.state.outgoing.DetailsOfPayment} onChange={this.handleUpdateDetailsOfPayment.bind(this)}></textarea>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Payment Reference</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <textarea className="uk-textarea" disabled={true}
                                    value={this.state.outgoing.PaymentReference} onChange={this.handleUpdatePaymentReference.bind(this)}></textarea>
                            </div>
                        </div>  

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Comments</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <textarea className="uk-textarea" disabled={true}
                                    value={this.state.outgoing.Comments} onChange={this.handleUpdateComments.bind(this)}></textarea>
                            </div>
                        </div>
                        <div className="uk-width-1-2" style={{visibility:"hidden"}}>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" />
                            </div>
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Status(*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.status)} disabled={true}
                                    objValue={{value: this.state.outgoing.Status}} getValue={this.handleGetStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Outgoing Fund Status"]} id="form-outgoing-fund-status" />
                            </div>
                        </div>           
                        <div className="uk-width-1-4">
                            <label className="uk-form-label">Wire Fee (Buy side)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                {/*<input className="uk-input" type="number" placeholder="0.00" value={this.state.outgoing.WireItemFee} onChange={this.handleUpdateWireItemFee.bind(this)}/>*/}
                                <NumberInput className="uk-input" id="form-outgoing-funds-wire-fee" disabled={true} placeholder="0,00" type="Rate" scale={2} value={this.state.outgoing.WireItemFee} onChange={this.handleUpdateWireItemFee.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" style={{visibility:"hidden"}}>.</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <label>{this.state.outgoing.WireItemFeeCurrencyID}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="uk-button uk-button-green" disabled={true} type="button" onClick={this.handleUpdateOutgoingFundClick.bind(this)} hidden>Save</button>
                        <button className="uk-button uk-button-green" disabled={true} type="button" onClick={this.handleClearClick.bind(this)} hidden>Clear Fields</button>
                        <button className="uk-button uk-button-green uk-margin-right" disabled={true} type="button" onClick={this.handleViewHistoryClick.bind(this)}> View History</button>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <hr class=".uk-divider-icon" style={{height: 0.5, backgroundColor: '#E5DBD8'}}></hr>
                    </div>
                    <h5 className="uk-heading-divider properties-tasks-category-title-new1" 
                        uk-toggle="target : #propertiesSection;"
                        style={{...getStyle().titleStyle, cursor: 'pointer'}}>Additional Properties
                        <img 
                            id="propertiesSection"
                            src={PlusIcon}
                            //className="fa fa-fw fa-plus" 
                            style={{position: 'absolute',
                            paddingTop: '16px',
                            right: '30px', color: 'black'}}
                        />
                        <img 
                            id="propertiesSection" 
                            src={MinusIcon}
                            //className="fa fa-fw fa-minus" 
                            style={{position: 'absolute',
                            paddingTop: '16px',
                            right: '30px', color: 'black'}} 
                            hidden
                        />
                    </h5>
                    <div id="propertiesSection" hidden>
                        <div className="uk-width-1-1 uk-margin">
                            <EntityProperties id="outgoing-form" entityType={OutgoingFundType} entityID={this.state.outgoing.OutgoingFundsID} fullScreen={true} runSaveProcess={this.state.entityPropertiesSaveProcess} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} />
                        </div>
                    </div>                    
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox id="exceeds-limit" open={this.state.showExceedsLimitAlert} onClose={this.closeExceedsLimitAlert} title="The amount you have entered exceeds the limit defined for this beneficiary. Are you sure the amount is correct?" message="" type="Yes/No" yesClick={this.yesExceedsLimitAlert} noClick={this.closeExceedsLimitAlert}/>
                {/*<AlertBox open={this.state.showExceedsLimitAlert} onClose={this.closeExceedsLimitAlert.bind(this)} title="Error" message="The amount you have entered exceeds the limit defined for this beneficiary." type="Ok" okClick={this.closeExceedsLimitAlert.bind(this)}/>*/}
                <AlertBox id="sending-funds" open={this.state.showSendingFundsAlert} onClose={this.closeSendingFundsAlert} title="You are sending out funds before all incoming funds have been received. Are you sure?" message="" type="Yes/No" yesClick={this.yesSendingFundsAlert} noClick={this.closeSendingFundsAlert}/>
                <AlertBox id="release-payment" open={this.state.showReleasePaymentAlert} onClose={this.closeReleasePaymentAlert} title="The are releasing a payment before the deal's value date. Are you sure?" message="" type="Yes/No" yesClick={this.yesReleasePaymentAlert} noClick={this.closeReleasePaymentAlert}/>
                <AlertBox id="chosen-wire" open={this.state.showChosenWireAlert} onClose={this.closeChosenWireAlert} title={this.state.chosenWireTitle} message="" type="Yes/No" yesClick={this.yesChosenWireAlert} noClick={this.closeChosenWireAlert}/>
            </div>
        );
    }
}