import React from 'react';
import EntityTasks from '../../../shared/EntityTasks';
import {CustomerType} from '../../../../constants/EntityTypeNames';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';

export default class TasksListScreen extends React.Component {
    state = {
        customerID: 0,
        entityTasksSaveProcess: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false,
    }

    onFinishEntityTasksSaveProcess(){
        this.setState({entityTasksSaveProcess: false, loading:false, showAlert: true, alertTitle: 'Customer Tasks saved.'});
    }

    handleButtonSaveClick(){
        this.setState({entityTasksSaveProcess: true, loading: true});
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    render() {
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <EntityTasks fullScreen={true} entityType={CustomerType} entityID={this.props.customerID} runSaveProcess={this.state.entityTasksSaveProcess} finishSaveProcess={this.onFinishEntityTasksSaveProcess.bind(this)} />
                    </div>
                    <div>
                        <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>                        
                    </div>
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}