import React from 'react';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import { getBankAccountsByPerson, deleteBankAccount } from '../../../../networking/NetworkingBankAccounts';
import BankAccountScreen from '../directDebitAccounts/BankAccountScreen';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
var style = getStyle();

export default class BankAccountsScreen extends React.Component {
    state = {
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        bankAccounts: [],
        selectedBankAccount: null,
        showBankAccount: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteBankAccount: {},
        pages: 1,
        totalPages: 0
    }

    componentDidMount() {
        this.updatePage();
    }

    handleBackLink = () =>
        this.setState({
            showBankAccount: false
        });

    handleAddClick = () =>
        this.setState({
            selectedBankAccount: null,
            showBankAccount: true
        });

    handleDeleteClick = (obj) =>
        this.setState({
            showDeleteAlert: true,
            readyToDeleteBankAccount: obj});

    handleRowClick = (row) =>
        this.setState({
            selectedBankAccount: {
                BankAccountID: row["Account ID"],
                Currency: row.Currency,
                AccountNumber: row["Account Number"]
            },
            showBankAccount: true
        });

    closeAlert = () =>
        this.setState({
            showAlert: false,
            alertMessage: '',
            alertTitle: ''
        });

    closeDeleteAlert = () =>
        this.setState({
            showDeleteAlert: false,
            readyToDeleteBankAccount: {}
        });

    openDeleteAlert = () =>
        this.setState({
            showDeleteAlert: true
        });

    okSuccessAlert = () =>
        this.setState({
            showSuccessDeleteAlert: false
        });

    yesDeleteAlert = async () => {
        const json = await deleteBankAccount(this.state.readyToDeleteBankAccount["Account ID"]);
        if (json !== undefined) {
            if (json.status !== 200) {
                this.setState({
                    showDeleteAlert: false,
                    showSuccessDeleteAlert: true
                });
                this.updatePage();
            } else {
                this.setState({
                    showDeleteAlert: false,
                    showAlert: true,
                    alertMessage: "An error occurred while trying to delete the bank account. Please try again."
                });
            }
        }
    }

    changePage = (value) => {
        this.setState({
            pages: value,
            newSearch: false
        });
    }

    updatePage = async () => {
        const json = await getBankAccountsByPerson(this.props.data);
        if (json !== undefined && json.bankAccounts !== undefined) {
            const bankAccounts = json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null);
            const totalCount = bankAccounts.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({
                bankAccounts,
                totalPages,
                newSearch: false
            });
        } else {
            this.setState({
                bankAccounts: []
            });
        }
    }

    getBankAccounts = async () => {
        const json = await getBankAccountsByPerson(this.props.data);
        if (json !== undefined && json.bankAccounts !== undefined) {
            return json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null);
        } else {
            return [];
        }
    }

    render() {
        if (this.state.showBankAccount) {
            return (
                <BankAccountScreen updatePage={this.updatePage} backLink={this.handleBackLink} data={this.state.selectedBankAccount} personID={this.props.data} />
            );
        }

        return (
            <div>
                <h3 className="uk-heading-divider"style={style.titleStyle}>
                    {this.props.title !== undefined ? this.props.title : "Bank accounts"} &nbsp;
                    <button disabled={true} className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>
                </h3>
                <NewDynamicTable 
                    data={this.state.bankAccounts}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true} 
                    filterFunction={this.getBankAccounts}/>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this bank account?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Bank account successfully removed." message="" type="Ok" okClick={this.okSuccessAlert}/>
            </div>
        );
    }
}