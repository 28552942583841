import React from 'react';
import Paginator from '../../../shared/Paginator';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import ContactMaintenanceScreen from '../contacts/ContactMaintenanceScreen';
import { getContactsByCustomer, getContactByID } from '../../../../networking/NetworkingContacts';

import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

import { getStyle } from '../../../../styles/styles'
var style = getStyle();

export default class ContactsScreen extends React.Component {

    state = {
        contacts: [],
        newContact: false,
        updateContact: false,
        contactUpdateToken: '',
        selectedContact: {},
        currentPage: 1,
        contactID: 0,
        totalPages: 0,
        newSearch: false,
    }

    handleRowClick(obj) {
        console.log(obj);
        getContactByID(obj["Contact ID"]).then(
            (value) => {
                console.log(value)
                this.setState({ updateContact: true, contactID: obj["Contact ID"], contactUpdateToken: value.UpdateToken, selectedContact: value }, () => {

                })
            }
        );
    }

    handleAddContactClick() {
        var newContact =
        {
            "Contact ID": 0,
            "Contact Type": 'Individual',
            "Contact Status": '',
            "Salutation": '',
            "First Name": '',
            "Name": '',
            "Last Name": '',
            "Mobile Number": '',
            "Phone Number": '',
            "Phone Extension": '',
            "Position": '',
            "Trade Limit": 0.0,
            "Trade Limit Warning or Enforce": '',
            "Daily Trade Limit": 0.0,
            "Daily Trade Limit Warning or Enforce": '',
            "Date of Birth": '',
            "Preferred Name": '',
            "Language": '',
            "Employer Name": '',
            "Main Contact": false,
            "Politically Exposed Person": false,
            "PEP Position": '',
            "Institution Type": '',
            "Appointment Date": '',
            "Authorized Signing Officer": false,
            "ByPass Validation": false,
            "Expiry ByPass Date": '',
            "UpdateToken": '0',

            "Address Type": '',
            "Address Value": '',
            "Address Status": '',
            "Address2": '',
            "City": '',
            "Zip Code": '',
            "State ID": 0,
            "Country ID": '',
            "Other State": '',
            "Default Address": false,
            "Since When": '',
            "District": '',
            "POBox": ''
        };
        this.setState({ newContact: true, updateContact: true, selectedContact: newContact });
    }

    getCreateContactDone() {
        this.setState({ newContact: false });
    }

    handleBackLink() {
        this.setState({ updateContact: false });
    }

    clearData() {
        this.setState({
            contacts: [],
        });
    }

    componentDidMount() {
        getContactsByCustomer(this.props.data, 1, 100).then(
            (value) => {
                var helper = [];
                if (value.contacts !== undefined) {
                    console.log(value);
                    this.setState({ contacts: value.contacts });
                } else {
                    this.setState({ contacts: helper });
                }
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                console.log(pages);
                this.setState({ totalPages: pages, newSearch: true });
            }
        );
    }

    changePage(value) {
        this.setState({ currentPage: value })
    }

    updatePage() {
        getContactsByCustomer(this.props.data, this.state.currentPage, 100).then(
            (json) => {
                this.setState({ contacts: json.contacts });
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({ totalPages: pages, newSearch: false });
            }
        );
    }
    getAllContacts = async () => {
        var json = await getContactsByCustomer(this.props.data, 0, 0);
        var helper = json.contacts;
        return helper;
    }
    render() {
        if (this.state.updateContact) {
            return <ContactMaintenanceScreen newContact={this.state.newContact} backFromCreateContact={this.getCreateContactDone.bind(this)}
                updateFunctionOrigin={this.updatePage.bind(this)} backLink={this.handleBackLink.bind(this)} contactID={this.state.contactID}
                customerID={this.props.data} selectedContact={this.state.selectedContact} contactUpdateToken={this.state.contactUpdateToken} />
        }

        return (
            <div>
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Contacts</h3>
                    <NewDynamicTable key="contact-screen"
                        data={this.state.contacts}
                        enableClick={true}
                        clickFunction={this.handleRowClick.bind(this)}
                        newSearch={this.state.newSearch}
                        useDeleteButton={false} 
                        enableSortByHeader={true}
                        usePaginator={true}
                        changePage={this.changePage.bind(this)}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        useFilter={true}
                        filterFunction={this.getAllContacts} />
                </div>
                <div>
                    <button disabled={true} className="uk-button uk-button-green uk-margin-right" onClick={this.handleAddContactClick.bind(this)} type="button">Add Contact</button>
                </div>
            </div>
        );
    }
}