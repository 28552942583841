import React from 'react';
import CustomerScreen from '../pre_trade/customers/updateCustomer/CustomerScreen';
import DealInformationScreen from '../deal/DealInformationScreen';
import PaymentScreen from '../deal/PaymentScreen';
import {ActionName} from '../../constants/ActionNames';
import DepositInfoScreen from '../back_office/deposits/DepositInfoScreen';
import DepositsScreen from '../back_office/deposits/DepositsScreen';
import IncomingPaymentForm from '../deal/IncomingPaymentForm'
import CountryAdditionalSettingsScreen from '../maintenance/country/CountryAdditionalSettingsScreen';

export function getScreen(action,id,handleBack){
    switch(action){
        case ActionName.EditCustomerInfo : return(<CustomerScreen backLink={handleBack} data={id} />);
        case ActionName.ShowCustomerInfo : return(<CustomerScreen backLink={handleBack} data={id} />);
        case ActionName.ShowDealInfo : return(<DealInformationScreen backLink={handleBack} data={id} />);
        case ActionName.ShowPaymentInfo : return(<IncomingPaymentForm backLink={handleBack} data={id} paymentOpenFromBoard={true}/>);
        case ActionName.ShowCustomerPaymentScreen : return(<PaymentScreen backLink={handleBack} data={0} />);
        case ActionName.ShowDepositInfo : return(<DepositInfoScreen backLink={handleBack} data={id} />);
        case ActionName.AddDeposit : return(<DepositsScreen backLink={handleBack} data={0} />);
        case ActionName.ShowCountryAdditionalSettings: return(<CountryAdditionalSettingsScreen backLink={handleBack} data={id}/>)
        default: return (<div></div>);
    }

}