import React from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import { getPricingCategory, addForward } from '../../../networking/NetworkingPricing';
import { getCurrencyList, searchCurrencies } from '../../../networking/NetworkingCurrencies';
import {notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass, getStyle} from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import { formatValidSelect, formatValidInput } from '../../../helpers/FormatHelper';
import { validate } from '../../../helpers/ValidateHelper';
import NumberInput from '../../shared/NumberInput';
const style = getStyle();

export default class ForwardPricingDetailsForm extends React.Component {
    
    state = {
        notFirstLoad: false,
        categoryDetailID: 0,
        category:'',
        homeCurrency:'',
        homeCurrencyName: '',
        markupFactor: '',
        foreignCurrency: '',
        foreignCurrencyName: '',
        amountFrom: '',
        amountTo:'',
        percent: 'PERCENT',
        points: 'POINTS',
        period:'',
        categoryList:[],
        currencyList:[],
        foreignCurrencyList: [],
        updateToken: 0,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        percentOrPoint: 'none',
        errors : [],
        validations: {
            category: true,
            homeCurrency: true,
            foreignCurrency: true,
            amountFrom: true,
            amountTo: true,
            markupFactor: true
        }
    }

    componentDidMount(){
        if (this.props.categoryID !== undefined && this.props.categoryID !== '') {
            this.setState({ category: this.props.categoryID });
            this.props.getForwardList(this.props.categoryID)
        }
        //Dropdown Category
        getPricingCategory().then(
            (value) => {
                var jsonCategory = this.transformCategoryList(value.pricingCategories);
                var helper = [];
                for(var i=0;i<jsonCategory.length;i++){
                    jsonCategory[i].value = jsonCategory[i]['Category ID'];
                    jsonCategory[i].name = jsonCategory[i]['Category Name'];
                    helper.push(jsonCategory[i]);
                }
                console.log(helper);
                //pricingCategories
                this.setState({categoryList: helper});
            }
        );
    }

    //getPricingForwardByID
    componentDidUpdate(prevProps){
        if(this.props.selectedForward !== {} && prevProps.selectedForward !== this.props.selectedForward){
            var forwardDetail = this.props.selectedForward;
            var categoryDetailID = 0;
            
            if(forwardDetail.categoryID !== null){
                categoryDetailID = forwardDetail['PricingCategoryDetailID'];
            }
            if(forwardDetail['Is Percent or Point?']==="PERCENT" || forwardDetail['Is Percent or Point?']==="POINTS"){
                this.setState({percentOrPoint: forwardDetail['Is Percent or Point?']});
            }else if(forwardDetail['Is Percent or Point?']===""){
                this.setState({percentOrPoint: 'none'});
            }
            if(forwardDetail['Home Currency'] !== null)
            {
                this.searchHomeCurrency(forwardDetail['Home Currency']);
            }
            if(forwardDetail['Foreign Currency'] !== null)
            {
                this.searchForeignCurrency(forwardDetail['Foreign Currency']);
            }

            console.log(forwardDetail);
            this.setState({
                categoryDetailID : categoryDetailID,
                homeCurrency:forwardDetail['Home Currency'],
                markupFactor: forwardDetail['Markup Factor'],
                foreignCurrency: forwardDetail['Foreign Currency'],
                amountFrom: forwardDetail['Amount From'],
                amountTo: forwardDetail['Amount To'],
                period: forwardDetail['Period'],
                updateToken: forwardDetail['UpdateToken'],
                percentOrPoint: forwardDetail['Is Percent or Point?']
            });
        };
    }
    
    transformCategoryList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Category ID': list[i]['Category ID'],
            'Category Name': list[i]['Category Name']});
        }
        return helper;
    }

    searchHomeCurrency(query){ 
        searchCurrencies(query, '').then(
            (json) => {
                var helper = [];
                if(query !== 'active'){
                    if(json!==undefined){
                        for(var i=0; i<json.length; i++){
                            helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                        }
                        if(json.length>1){
                            this.setState({currencyList: helper});
                        }else{
                            this.setState({currencyList: helper, homeCurrencyName: helper[0].name});
                        }
                    }else{
                        this.setState({currencyList: helper});
                    }                    
            }}
        );
    }

    searchForeignCurrency(query){ 
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    if(json!==undefined){
                        for(var i=0; i<json.length; i++){
                            helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                        }
                        if(json.length>1){
                            this.setState({foreignCurrencyList: helper});
                        }else{
                            this.setState({foreignCurrencyList: helper, foreignCurrencyName: helper[0].name});
                        }
                    }else{
                        this.setState({foreignCurrencyList: helper});
                    }                    
            }}
        );
    }

    getHomeCurrencySelect(value){
        this.setState({
            validations: {...this.state.validations, homeCurrency: true},
            homeCurrency:value.value
        });
    }
    getForeignCurrencySelect(value){
        this.setState({
            validations: {...this.state.validations, foreignCurrency: true},
            foreignCurrency:value.value
        });
    }
    getCategorySelect(value){
        console.log(value.value);
        if(value.value == undefined||value.value == ''){}
        else{
            this.setState({
                validations: {...this.state.validations, category: true},
                category: value.value
            }, ()=> this.props.getForwardList(value.value));
        }
    }

    handleClearClick(event){
        event.preventDefault();
        this.setState({
            category: "select",
            homeCurrency:'',
            markupFactor: '',
            foreignCurrency: '',
            amountFrom: '',
            amountTo:'',
            percentOrPoint:'' ,
            period: '',
            updateToken:0,
            categoryID:0,
            validations: {
                category: true,
                homeCurrency: true,
                foreignCurrency: true,
                amountFrom: true,
                amountTo: true,
                markupFactor: true
            }
        }, ()=> this.props.updateForwardList(this.state.category));
    }
    
    handleSaveClick(event){
        event.preventDefault();
        let UserID = sessionStorage.getItem('UserID');
        if(this.state.updateToken==0){
            var objectFilter = {
                Period: this.state.period,
                PricingCategoryDetailID: 0,
                CategoryID:this.state.category,
                HomeCurrencyCode:this.state.homeCurrency,
                MarkupFactor: this.state.markupFactor,
                ForeignCurrencyCode: this.state.foreignCurrency,
                TransAmountFrom: this.state.amountFrom,
                TransAmountTo:this.state.amountTo,
                PercentOrPoints:this.state.percentOrPoint,
                userID: UserID   
            }
        }else{
            var catList = this.props.selectedForward;
            var objectFilter = {
                Period: this.state.period,
                PricingCategoryDetailID: catList['PricingCategoryDetailID'],
                CategoryID: this.state.category,
                HomeCurrencyCode:this.state.homeCurrency,
                MarkupFactor: this.state.markupFactor,
                ForeignCurrencyCode: this.state.foreignCurrency,
                TransAmountFrom: this.state.amountFrom,
                TransAmountTo:this.state.amountTo,
                PercentOrPoints:this.state.percentOrPoint,
                userID: UserID,
                updateToken: catList['UpdateToken']  
            }
        }    
        if(this.validateRequiredInputs()){       
            console.log(objectFilter);
            addForward(objectFilter).then(
                (json)=>{
                    this.setState({showAlert: true, alertTitle: 'Pricing Forward Successfully saved.'});
                    this.props.updateForwardList(objectFilter.CategoryID);}
            );
        }
    }
    closeAlert(){
        this.setState({showAlert: false, alertTitle: ''});
    }
    closeAlert2(){
        this.setState({showAlertError: false, alertTitle: ''});
    }
    
    handleDeleteClick(event){
        event.preventDefault();
        let UserID = sessionStorage.getItem('UserID');
        var objectFilter = {
            CategoryID:this.state.category,
            userID: UserID   
        }
        console.log(objectFilter);
    }

    handleUpdateMarkupFactor(event){
        this.setState({
            validations: {...this.state.validations, markupFactor: true},
            markupFactor: event.target.value
        });
    }
    handleUpdatePeriod(event){
        this.setState({period: event.target.value});
    }
    handleUpdateAmountFrom(event){
        this.setState({
            validations: {...this.state.validations, amountFrom: true},
            amountFrom: event.target.value
        });
    }
    handleUpdateAmountTo(event){
        this.setState({
            validations: {...this.state.validations, amountTo: true},
            amountTo: event.target.value
        });
    }
    handleUpdatePercentOrPoints(event){
        const question = event.target.id;
        console.log(question);
        var result = event.target.value;
        console.log(result);
        if(result==""){
            if(question=='radioPer'){
                result = "PERCENT";
                this.setState({percent: result, percentOrPoint: result});
            }else if(question=='radioPoi'){
                result = 'POINTS';
                this.setState({points: result, percentOrPoint: result});
            }
        }else{
            this.setState({percentOrPoint: result});  
        }  
    }
    //Inputs Validations
    validateRequiredInputs(){
        const fields = [
            {displayName: 'Category', validateName: 'category', value:this.state.category, type: 'string'},
            {displayName:'Markup Factor', validateName: 'markupFactor', value:this.state.markupFactor, type: 'number'},
            {displayName:'Amount From', validateName: 'amountFrom', value: this.state.amountFrom, type: 'number'},
            {displayName:'Amount To', validateName: 'amountTo', value: this.state.amountTo, type: 'number'},
            {displayName:'Home Currency', validateName: 'homeCurrency', value:this.state.homeCurrency, type: 'string'},
            {displayName:'Foreign Currency', validateName: 'foreignCurrency', value:this.state.foreignCurrency, type: 'string'}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }
    render() {
        return (
            <div>
                <form className="uk-form-stacked uk-grid uk-margin">
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-category" style={style.boldLabel}>Category (*)</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className={formatValidSelect(this.state.validations.category)} id="form-forward-category" getValue={this.getCategorySelect.bind(this)} placeholder="Select your item" data={this.state.categoryList} objValue={{value: this.state.category, name: this.state.category}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2"><br></br></div>
                    
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-hcurrency" style={style.boldLabel}>Home Currency (*)</label>
                        <div className="uk-form-controls">
                            {/*<DynamicSelect className={classNameInputHCurrency} id="form-forward-hcurrency" getValue={this.getHomeCurrencySelect.bind(this)} placeholder="Select your item" data={this.state.currencyList} objValue={{value: this.state.homeCurrency, name: this.state.homeCurrency}}/>*/}
                            <DynamicSearchSelect className={formatValidSelect(this.state.validations.homeCurrency)} id="form-forward-hcurrency" objValue={{value: this.state.homeCurrency,name: this.state.homeCurrencyName}} placeholder="Select a Currency..." searchFunction={this.searchHomeCurrency.bind(this)} data={this.state.currencyList} getValue={this.getHomeCurrencySelect.bind(this)}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-mfactor" style={style.boldLabel}>Markup Factor (*)</label>
                        <div className="uk-form-controls">
                            <NumberInput className={formatValidInput(this.state.validations.markupFactor)} id="mfactor" type={"Currency"} scale={6} placeholder="0.000000" value={this.state.markupFactor} onChange={this.handleUpdateMarkupFactor.bind(this)}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-fcurrency" style={style.boldLabel}>Foreign Currency (*)</label>
                        <div className="uk-form-controls">
                            {/*<DynamicSelect className={classNameInputFCurrency} id="form-forward-fcurrency" getValue={this.getForeignCurrencySelect.bind(this)} placeholder="Select your item" data={this.state.currencyList} objValue={{value: this.state.foreignCurrency, name: this.state.foreignCurrency}}/>*/}
                            <DynamicSearchSelect className={formatValidSelect(this.state.validations.foreignCurrency)} id="form-forward-fcurrency" objValue={{value: this.state.foreignCurrency,name: this.state.foreignCurrencyName}} placeholder="Select a Currency..." searchFunction={this.searchForeignCurrency.bind(this)} data={this.state.foreignCurrencyList} getValue={this.getForeignCurrencySelect.bind(this)}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-afrom" style={style.boldLabel}>Period (*)</label>
                        <div className="uk-form-controls">
                            <NumberInput className="uk-input" id="period" type={"Integer"} placeholder="0" value={this.state.period} onChange={this.handleUpdatePeriod.bind(this)}/>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-afrom" style={style.boldLabel}>Amount From (*)</label>
                        <div className="uk-form-controls">
                            <NumberInput className={formatValidInput(this.state.validations.amountFrom)} id="afrom" type={"Currency"} scale={2} placeholder="0.00" value={this.state.amountFrom} onChange={this.handleUpdateAmountFrom.bind(this)}/>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-fcurrency">Percent or Points</label>
                        <div className="uk-form-controls">
                            <label><input className="uk-radio" type="radio" id="radioPer" name="radio1"  value={this.state.percent} checked={this.state.percentOrPoint === this.state.percent} onChange={this.handleUpdatePercentOrPoints.bind(this)}/> Percent</label><br></br>
                            <label><input className="uk-radio" type="radio" id="radioPoi" name="radio1"  value={this.state.points} checked={this.state.percentOrPoint === this.state.points} onChange={this.handleUpdatePercentOrPoints.bind(this)}/> Points</label>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <label className="uk-form-label" htmlFor="form-forward-afrom" style={style.boldLabel}>Amount To (*)</label>
                        <div className="uk-form-controls">
                            <NumberInput className={formatValidInput(this.state.validations.amountTo)} id="afrom" type={"Currency"} scale={2} placeholder="0.00" value={this.state.amountTo} onChange={this.handleUpdateAmountTo.bind(this)}/>
                        </div>
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)}>Clear</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)}>Save</button>
                    <hr/>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)}/>
            </div>
        );
    }
}