import React, { useState, useEffect } from 'react';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getStyle, regularSelectClass, notFilledSelectClass } from '../../../../styles/styles';
import useActiveTraders from './hooks/useActiveTraders';
import usePrevious from '../../../maintenance/afterHours/hooks/usePrevious';
import useLookUpTables from './hooks/useLookUpTables';
import { useCallback } from 'react';
import { validate } from '../../../../helpers/ValidateHelper';

const style = getStyle();

const TradingInformationForm = (props) => {
    const [tradingInformation, setTradingInformation] = useState({trader: '', tradeAverageSize: '', confirmationType: '', monthlyAverageVolume: '', confirmationLimit: ''});
    const [validations, setValidations] = useState({trader: true});
    const lookUpTables = useLookUpTables(["Confirmation Type"]);
    const traders = useActiveTraders();
    const previousValidationValue = usePrevious(props.validations);

    useEffect(() => {
        props.isValid(validateTrader(tradingInformation.trader));
    }, []);

    const validateRequiredFields = useCallback(() => {
        const fields = [
            {displayName: 'Trader', validateName: 'trader', value: tradingInformation.trader, type: 'string'}
        ];

        const [, _validations] = validate(fields);
        setValidations(_validations);
        
    }, [tradingInformation.trader]);

    useEffect(() => {
        if (previousValidationValue !== props.validations && !props.validations) {
            validateRequiredFields()
        }
    }, [previousValidationValue, props.validations, validateRequiredFields]);

    const validateTrader = (trader) => {
        const fields = [
            {displayName: 'Trader', validateName: 'trader', value: trader, type: 'string'}
        ];

        const [valid, , errors] = validate(fields);
        
        return {valid, errors}

    }

    const formatValidSelect = (valid) => {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    const handleGetValueTrader = (value) => {
        if (value !== undefined && value !== "select" && value !== '') {
            value.value = value.value === 'select' ? '' : value.value;
            const newTradingInfo = {...tradingInformation, trader: value.value}; 
            setTradingInformation(newTradingInfo);
            setValidations({...validations, trader: true});
            props.getTradingInfo(newTradingInfo);
            props.isValid(validateTrader(value.value));
        }
    }

    const handleGetValueConfirmationType = (value) => {
        if (value !== undefined && value !== "select" && value !== '') {
            value.value = value.value === 'select' ? '' : value.value;
            const newTradingInfo = {...tradingInformation, confirmationType: value.value};
            setTradingInformation(newTradingInfo);
            props.getTradingInfo(newTradingInfo);
        }
    }

    const handleUpdateTradeAverageSize = (event) => {
        const newTradingInfo = {...tradingInformation, tradeAverageSize: event.target.value};
        setTradingInformation(newTradingInfo);
        props.getTradingInfo(newTradingInfo);
    }

    const handleUpdateMonthlyAverageVolume = (event) => {
        const newTradingInfo = {...tradingInformation, monthlyAverageVolume: event.target.value};
        setTradingInformation(newTradingInfo);
        props.getTradingInfo(newTradingInfo);
    }

    const handleUpdateConfirmationLimit = (event) => {
        const newTradingInfo = {...tradingInformation, confirmationLimit: event.target.value};
        setTradingInformation(newTradingInfo);
        props.getTradingInfo(newTradingInfo);
    }

    return(
        <div>
            <div className="">
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Trader (*)</label>
                            <p style={{marginBottom: '0px', marginTop: '2px'}}>{sessionStorage.getItem('TraderName')}</p>
                        </div>
                        <div className="uk-width-1-2" style={{visibility: "hidden"}}>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="text" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Trade Average Size</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="number" placeholder="0.00" value={tradingInformation.tradeAverageSize} onChange={handleUpdateTradeAverageSize} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Confirmation Type</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect className="uk-select" data={lookUpTables.ConfirmationType} objValue={{value: tradingInformation.confirmationType}} getValue={handleGetValueConfirmationType} listHasPlaceholder={true} id="form-confirmation-type" />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Monthly Average Volume</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="number" placeholder="0.00" value={tradingInformation.monthlyAverageVolume} onChange={handleUpdateMonthlyAverageVolume} />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" >Confirmation Limit</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" type="number" placeholder="0.00" value={tradingInformation.confirmationLimit} onChange={handleUpdateConfirmationLimit} />
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TradingInformationForm;
