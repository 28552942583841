import React from 'react';

import OutgoingFundsForm from '../../components/outgoingFunds/OutgoingFundsForm'

import NewDynamicTable from '../shared/NewDynamicTable';
import Paginator from '../shared/Paginator';
import LoadingBox from '../shared/LoadingBox';

import {formatToCurrency} from '../../helpers/FormatHelper';
import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';
import { getOutgoings } from '../../networking/NetworkingFunds';
import { getDealHeaderInfo } from '../../networking/NetworkingDeals';
import { getStyle } from '../../styles/styles';
var style = getStyle();

export default class OutgoingFundsScreen extends React.Component {
    state = {
        outgoings: [],
        originalOutgoings: [],
        outgoingFundsID: 0,
        dealHeaderID:0,
        customerID: 0,
        sellCcy: '',
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        selectedOutgoing: {},
        loading: false,
    }
    
    handleBackLink() {
        this.props.backLink();
    }

    componentDidMount(){
        var outgoingID = 0;
        var dealID = 0;
        if(this.props.id != null){
            outgoingID = this.props.id;
        }
        if(this.props.dealID != null){
            dealID = this.props.dealID;
            getDealHeaderInfo(dealID).then(
                (value) => {
                    if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                        var dealHeaderInfo = value.dealHeaderInfo[0]
                        this.setState({customerID: dealHeaderInfo["Customer ID"], sellCcy: dealHeaderInfo.SCur});
                    }
                }
            );
        }
        this.setState({outgoingFundsID: outgoingID, dealHeaderID: dealID, loading: true});
        getOutgoings(dealID,1,getDefaultPagingNumberOfItems()).then(
            (value) => {
                console.table(value.outgoings);
                var outgoings = value.outgoings;
                this.setState({outgoings: outgoings, originalOutgoings: value.outgoings});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:true, loading:false});
            }
        );
    }
    
    componentDidUpdate(prevProps){             
        if(prevProps.id !== this.props.id){
            var outgoingID = this.props.id;
            this.setState({outgoingFundsID: outgoingID});            
        }  
        if(prevProps.dealID !== this.props.dealID){   
            if(this.props.dealID != null){
                var dealID = this.props.dealID;
                getDealHeaderInfo(dealID).then(
                    (value) => {
                        if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                            var dealHeaderInfo = value.dealHeaderInfo[0]
                            this.setState({customerID: dealHeaderInfo["Customer ID"], sellCcy: dealHeaderInfo.SCur});
                        }
                    }
                );
            }
            this.setState({outgoingFundsID: outgoingID, dealHeaderID: dealID, loading: true});
            getOutgoings(dealID,1,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    console.table(value.outgoings);
                    var outgoings = value.outgoings;
                    this.setState({outgoings: outgoings, originalOutgoings: value.outgoings});
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:true, loading:false});
                }
            );
        }
    }

    changePage(value){
        this.setState({currentPage: value});
        getOutgoings(this.state.dealHeaderID, value, getDefaultPagingNumberOfItems()).then(
            (json) => {
                var outgoings = json.outgoings;
                this.setState({outgoings: outgoings, originalOutgoings: json.outgoings});
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:false});
            }
        );
    }
    
    handleRowClick(obj){
        console.group("Outgoing handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({outgoingFundsID: {id:obj['OutgoingFundsID']}});
    }
    
    updatePage(){
        getOutgoings(this.state.dealHeaderID, this.state.currentPage, getDefaultPagingNumberOfItems()).then(
            (json) => {
                var outgoings = json.outgoings;
                this.setState({outgoings: outgoings, originalOutgoings: json.outgoings});
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:false});
            }
        );
    }
    
    render(){
        var msg = '';
        if(this.state.outgoingFundsID.id > 0){
            msg = 'Create / Update Outgoing Fund -  ID - ' + this.state.outgoingFundsID.id;
        } else {
            msg = 'Create / Update Outgoing Fund';
        }
        return(
            <div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>
                    {msg}                    
                </h3>*/}
                <OutgoingFundsForm updateFunction={this.updatePage.bind(this)} id={this.state.outgoingFundsID} custId={this.state.customerID} dealId={this.state.dealHeaderID} sellCcy={this.state.sellCcy}/>
                <NewDynamicTable formats={[{header: 'Amount', formatFunction: formatToCurrency}]} hiddenHeaders={['OutgoingFundsID', 'DealHeaderID']} data={this.state.outgoings} enableClick={true} clickFunction={this.handleRowClick.bind(this)} newSearch={this.state.newSearch} />
                <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>
                <LoadingBox loading={this.state.loading}/>
            </div>
        );
    }
}