import React from 'react';

import NewDynamicTable from '../shared/NewDynamicTable'

import {getStyle} from '../../styles/styles'
var style = getStyle();

export default class DealEmailLogForm extends React.Component {

    state = {
        customerName: "",
        sentBy: "",
        sentDate: "",
        emailType: "",
        mainReceipt: "",
        ccRecipient: "",
        bccRecipient: "",
        listEmailLogs: [{
            "Sent On": "2020/05/08",
            "Sent By": "Admin",
            Type: "Confirmation"
        }]
    }

    handleBackLink() {
        this.setState({}, () => this.props.backLink());
    }

    render() { 
        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                    </button> 
                    Deal Email Log
                </h3>
                <div className="uk-grid uk-margin">
                    
                    {/*Line1*/}
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Customer Name</label>
                        <input className="uk-input" type="text" value={this.state.customerName} readOnly/>
                    </div>
                    <div className="uk-width-1-3">
                        <label className="uk-form-label">Email type</label>
                        <input className="uk-input" type="text" value={this.state.emailType} readOnly/>
                    </div>
                    <div className="uk-width-1-3"> </div>
                    
                    {/*Line2*/}
                    <div className="uk-width-1-3">                        
                        <label className="uk-form-label">Sent By</label>
                        <input className="uk-input" type="text" value={this.state.sentBy} readOnly/>
                    </div>
                    <div className="uk-width-2-3"> </div>

                    {/*Line3*/}
                    <div className="uk-width-1-3">                        
                        <label className="uk-form-label">Sent Date</label>
                        <input className="uk-input" type="text" value={this.state.sentDate} readOnly/>
                    </div>
                    <div className="uk-width-2-3"> </div>

                    {/*Line4*/}
                    <div className="uk-width-1-3">       
                        <NewDynamicTable data={[{"Main Recipient":""}]}/>                 
                        </div>

                    <div className="uk-width-1-3">
                        <NewDynamicTable data={[{"C.C. Recipient":""}]}/>
                    </div>
                    <div className="uk-width-1-3">
                        <NewDynamicTable data={[{"B.C.C Recipient":""}]}/>
                    </div>

                    {/*Line5*/}
                    <div className="uk-width-1-1">
                        <h4>Email Logs</h4>
                        <NewDynamicTable data={this.state.listEmailLogs}/>
                    </div>

                </div>
            </div>
        );        
    }
}