import React from 'react';
import CustomerOverview from '../shared/CustomerOverview';
import WizardSearchCustomerForm from '../trade/wizard/WizardSearchCustomerForm';

export default class CustomerOverviewScreen extends React.Component {
    state = {
        customerID: 0,
        activeTab: 1
    };

    onCustomerSelected = (objCustomer) => {
        this.setState({
            customerID: objCustomer['Customer ID'],
            activeTab: 2
        });
    };

    onBackClick = () => {
        this.setState({ customerID: 0, activeTab: 1 });
    };

    handleActiveTab = (tab) => {
        return this.state.activeTab === tab ? 'uk-active' : '';
    }

    render() {
        return (
            <div>
                <h3>Customer Overview</h3>
                <ul className="uk-tab">
                    <li className={this.handleActiveTab(1)}><a onClick={() => this.onBackClick()}>Search Customer</a></li>
                    <li className={this.handleActiveTab(2)}><a>Overview</a></li>
                </ul>
                {this.state.activeTab === 1 && <WizardSearchCustomerForm onCustomerSelected={this.onCustomerSelected} />}
                {this.state.activeTab === 2 && <CustomerOverview customerID={this.state.customerID} onBackClick={this.onBackClick} />}
            </div>
        );
    }
}