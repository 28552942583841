import React from 'react';
import { getStyle, regularSelectClass, notFilledSelectClass, notFilledInputClass, regularInputClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getListOfBanks } from '../../../../networking/NetworkingBanks';
import { getBranchesByBankID } from '../../../../networking/NetworkingBankBranches';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();

export default class GenericBankAccountForm extends React.Component {

    state = {
        bankAccount: {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: 0,
            BankName: '',
            BranchID: '',
            BranchName: '',
            Swift: '',
            SwiftID: '',
            TransitNo: '',
            UpdateToken: ''
        },
        dropdowns: {
            banks: [],
            banksSwift: [],
            branches: []
        },
        validations: {
            bank: true
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined && this.props.data !== null) {
            var propsData = this.props.data;
            this.setState({bankAccount: {...this.state.bankAccount, ...propsData}});
            this.getBranches(propsData.BankID);
        }

        getListOfBanks(false).then( // SWIFT
            (json) => {
                for (var i = 0; i< json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                this.setState({dropdowns: {...this.state.dropdowns, banksSwift: json.banks}});
            }
        )

        getListOfBanks(true).then( // BANKS
            (json) => {
                for (var i = 0; i< json.banks.length; i++) {
                    json.banks[i].value = json.banks[i].BankID;
                    json.banks[i].name = json.banks[i].Name;
                }
                this.setState({dropdowns: {...this.state.dropdowns, banks: json.banks}});
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validated !== this.props.validated && !this.props.validated ) {
            this.validateRequiredFields();
        }
    }

    getBranches(bankID) {
        getBranchesByBankID(bankID, 1, 50).then(
            (json) => {
                if (json.branches !== undefined) {
                    for (var i = 0; i< json.branches.length; i++) {
                        json.branches[i].value = json.branches[i]["Branch ID"];
                        json.branches[i].name = json.branches[i].Branch;
                    }
                    this.setState({dropdowns: {...this.state.dropdowns, branches: json.branches}});
                }
            }
        )
    }
    validateRequiredFields() {
        var bankID = true;
        if (this.state.bankAccount.BankID === "select" || this.state.bankAccount.BankID === '' || this.state.bankAccount.BankID === 0 || this.state.bankAccount.BankID === undefined) {
            bankID = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID
            }
        })
    }


    validateRequiredInputs() {
        const fields = [
            {displayName: 'Bank', validateName: 'bank', value: this.state.bankAccount.BankID, type: 'string'}
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };

    }

    validateBank() {
        const fields = [
            {displayName: 'Bank', validateName: 'bank', value: this.state.bankAccount.BankID, type: 'string'}
        ];

        const [valid, , errors] = validate(fields);

        return { valid, errors };
    }

    validateFieldsEmpty() {
        if (this.state.bankAccount.AccountNo === "" && this.state.bankAccount.TransitNo === "" && this.state.bankAccount.ABANo === "" && (this.state.bankAccount.BranchID === "select" || this.state.bankAccount.BranchID === "")) {
            return true;
        }
        return false;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                this.setState({
                    bankAccount: {...this.state.bankAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name},
                    validations: { ...this.state.validations, bank: true}
                },
                    () => {
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                );
            } else {
                this.setState({
                    bankAccount: {...this.state.bankAccount, Swift: value.name, BankID: value.BankID},
                    validations: { ...this.state.validations, bank: true}
                },
                    () => {
                        this.props.getBankAccount(this.state.bankAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                bankAccount: {...this.state.bankAccount, Swift: "", BankID: "", BankName: ""},
                validations: { ...this.state.validations, bank: true}
            },
                () => {
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            this.setState({
                bankAccount: {...this.state.bankAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "": value.SWIFT},
                dropdowns: {...this.state.dropdowns, branches: []},
                validations: { ...this.state.validations, bank: true}
            }, () => {
                if (this.validateBank()) {
                    this.props.getBankAccount(this.state.bankAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: []});
                }
                if (value.value !== '' && value.value !== "select") {
                    this.getBranches(this.state.bankAccount.BankID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                bankAccount: {...this.state.bankAccount, BankID: "", BankName: "", Swift: ""},
                validations: { ...this.state.validations, bank: true}
            },
                () => {
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined) {
            this.setState({
                bankAccount: {...this.state.bankAccount, BranchID: value.value}
            },
                () => {
                    if (this.validateFieldsEmpty()) {
                        this.props.isValid({ valid: true, errors: []});
                    }
                    else {
                        this.props.isValid(this.validateBank());
                    }
                    this.props.getBankAccount(this.state.bankAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            bankAccount: {...this.state.bankAccount, AccountNo: event.target.value}
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: []});
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            bankAccount: {...this.state.bankAccount, Swift: event.target.value}
        },
            () => {
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            bankAccount: {...this.state.bankAccount, ABANo: event.target.value}
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: []});
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            bankAccount: {...this.state.bankAccount, TransitNo: event.target.value}
        },
            () => {
                if (this.validateFieldsEmpty()) {
                    this.props.isValid({ valid: true, errors: []});
                }
                else {
                    this.props.isValid(this.validateBank());
                }
                this.props.getBankAccount(this.state.bankAccount);
            }
        );
    }

    render() {
        return(
            <div>
                <div className="">
                    <form className="uk-form-horizontal uk-margin-large">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">SWIFT</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.bankAccount.BankID}} getValue={this.handleGetValueBankSwift} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banksSwift} id="form-banks-swift" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label" style={style.boldLabel}> Bank (*)</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className={this.formatValidSelect(this.state.validations.bank)} objValue={{value: this.state.bankAccount.BankID}} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks" />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Bank Branch</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <DynamicSelect disabled={true} className="uk-select" objValue={{value: this.state.bankAccount.BranchID}} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                                </div>
                            </div>
                            <div className="uk-width-1-2" style={{visibility: "hidden"}}>
                                <label className="uk-form-label"></label>
                                <div className="uk-margin" style={style.inputStyle}>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Account Number / IBAN</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.bankAccount.AccountNo} onChange={this.handleUpdateAccountNumber} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Routing Code / Transit No</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.bankAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">SWIFT / BIC</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.bankAccount.Swift} onChange={this.handleUpdateSwift} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Institution/ABA No.</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.bankAccount.ABANo} onChange={this.handleUpdateABANo} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}