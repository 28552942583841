import React from 'react';

import { getLookUpTable } from '../../../../networking/Networking';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import DatePicker from '../../../shared/DatePicker';
import NumberInput from '../../../shared/NumberInput';
import { formatDate, formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import { getRiskScoreRanges, getRiskScoreDetails, getCustomerRiskScore } from '../../../../networking/NetworkingCustomers';
import { notFilledInputClass, regularInputClass, notFilledSelectClass, regularSelectClass } from '../../../../styles/styles';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';
import { validate } from '../../../../helpers/ValidateHelper';
var style = getStyle();


export default class ContactInformationForm extends React.Component {

    state = {
        contact: {
            contactID: 0,
            contactType: 'Individual',
            status: '',
            salutation: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNumber: '',
            extension: '',
            cellNumber: '',
            position1: '',
            tradeLimit: 0.0,
            tLWE: '',
            dailyTradeLimit: 0.0,
            dTLWE: '',
            dateOfBirth: '',
            preferredName: '',
            language: '',
            employerName: '',
            isMainContact: false,
            PEP: false,
            pepPosition: '',
            institutionType: '',
            appointmentDate: null,
            isAuthorized: false,
            bypassValidation: false,
            expiryDate: null,
            riskScore: 0,
            updateToken: 0,
            errors: []
        },
        disableInputs: false,
        referredBySelect: [],
        "Contact Type": [],
        "Contact Status ": [],
        "Salutation": [],
        "Languages": [],
        lookUpTables: [
            "Contact Type",
            "Contact Status ",
            "Salutation",
            "Languages"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        },
        showRiskCalculation: false,
        riskScoreDetails: [],
        notFirstLoad: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        validations: {
            firstName: true,
            lastName: true,
            status: true,
            language: true,
        }
    }

    handleClearClick() {
        this.setState({
            contact: {
                contactID: '',
                contactType: '',
                status: '',
                salutation: '',
                firstName: '',
                middleName: '',
                lastName: '',
                phoneNumber: '',
                extension: '',
                cellNumber: '',
                position1: '',
                tradeLimit: '',
                tLWE: '',
                dailyTradeLimit: '',
                dTLWE: '',
                dateOfBirth: '',
                preferredName: '',
                language: '',
                employerName: '',
                isMainContact: false,
                PEP: false,
                pepPosition: '',
                institutionType: '',
                appointmentDate: '',
                isAuthorized: false,
                bypassValidation: false,
                expiryDate: '',
                riskScore: 0,
                updateToken: 0,
            }
        });
    }

    componentDidMount() {
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        console.group(this.state.lookUpTables[i]);
                        console.table(table);
                        console.groupEnd();
                        this.setState({ [this.state.lookUpTables[i]]: table });
                    }
                }
            })
        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreDetails(this.props.customerID).then(
                    (details) => {
                        if (details !== undefined) {
                            getCustomerRiskScore(this.props.customerID).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        riskScore.Color = riskScore.Color.substring(0, 1) + riskScore.Color.substring(3);
                                        this.setState({
                                            riskScore: riskScore,
                                            riskScoreDetails: details.riskScoreDetails
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
        //this.props.isValid(this.validateRequiredInputs());
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedContact !== null && prevProps.selectedContact === null) {
            var contact = this.props.selectedContact;
            this.setState({
                contact: {
                    contactID: contact["Contact ID"],
                    contactType: contact["Contact Type"],
                    status: contact["Contact Status"],
                    salutation: contact["Salutation"],
                    firstName: contact["First Name"],
                    middleName: contact["Name"],
                    lastName: contact["Last Name"],
                    cellNumber: contact["Mobile Number"],
                    phoneNumber: contact["Phone Number"],
                    extension: contact["Phone Extension"],
                    position1: contact["Position"],
                    tradeLimit: contact["Trade Limit"],
                    tLWE: contact["Trade Limit Warning or Enforce"],
                    dailyTradeLimit: contact["Daily Trade Limit"],
                    dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                    dateOfBirth: contact["Date of Birth"],
                    preferredName: contact["Preferred Name"],
                    language: contact["Language"],
                    employerName: contact["Employer Name"],
                    isMainContact: contact["Main Contact"],
                    PEP: contact["Politically Exposed Person"],
                    pepPosition: contact["PEP Position"],
                    institutionType: contact["Institution Type"],
                    appointmentDate: contact["Appointment Date"],
                    isAuthorized: contact["Authorized Signing Officer"],
                    bypassValidation: contact["ByPass Validation"],
                    expiryDate: contact["Expiry ByPass Date"],
                    updateToken: contact["UpdateToken"]
                },
                validations: {
                    firstName: true,
                    lastName: true,
                    status: true,
                    language: true,
                }
            }, () => {
                this.props.isValid(this.validateRequiredInputs());
            })
        }else if(this.props.selectedContact !== null && prevProps.selectedContact !== null && this.props.selectedContact.UpdateToken !== prevProps.selectedContact.UpdateToken){
            var contact = this.props.selectedContact;
            this.setState({
                contact: {
                    contactID: contact["Contact ID"],
                    contactType: contact["Contact Type"],
                    status: contact["Contact Status"],
                    salutation: contact["Salutation"],
                    firstName: contact["First Name"],
                    middleName: contact["Name"],
                    lastName: contact["Last Name"],
                    cellNumber: contact["Mobile Number"],
                    phoneNumber: contact["Phone Number"],
                    extension: contact["Phone Extension"],
                    position1: contact["Position"],
                    tradeLimit: contact["Trade Limit"],
                    tLWE: contact["Trade Limit Warning or Enforce"],
                    dailyTradeLimit: contact["Daily Trade Limit"],
                    dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                    dateOfBirth: contact["Date of Birth"],
                    preferredName: contact["Preferred Name"],
                    language: contact["Language"],
                    employerName: contact["Employer Name"],
                    isMainContact: contact["Main Contact"],
                    PEP: contact["Politically Exposed Person"],
                    pepPosition: contact["PEP Position"],
                    institutionType: contact["Institution Type"],
                    appointmentDate: contact["Appointment Date"],
                    isAuthorized: contact["Authorized Signing Officer"],
                    bypassValidation: contact["ByPass Validation"],
                    expiryDate: contact["Expiry ByPass Date"],
                    updateToken: contact["UpdateToken"]
                },
                validations: {
                    firstName: true,
                    lastName: true,
                    status: true,
                    language: true,
                }
            }, () => {
                this.props.isValid(this.validateRequiredInputs());
            })
        }
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        /*console.log(this.props.saveButton);
        if(this.props.saveButton !== {} && prevProps.saveButton !== this.props.saveButton){
            console.log(this.props.saveButton);
            if(this.props.saveButton){
                this.handleSaveClick();
            }
        }*/
    }

    /*handleSaveClick(){
        var date = new Date();
        let userID = sessionStorage.getItem('UserID');
        if(this.validateRequiredInputs()){
            this.props.isValid();
            var model = {
                ContactID: this.state.contact.contactID,
                CustomerID: this.props.customerID,
                MainContact: this.state.contact.isMainContact,
                Position: this.state.contact.position1,
                TradeLimit: this.state.contact.tradeLimit,
                DailyTradeLimit: this.state.contact.dailyTradeLimit,
                //PersonID: this.props.selectedContact.PersonID,
                BirthdayDate: this.state.contact.dateOfBirth,
                PoliticallyExposedPerson: this.state.contact.PEP,
                AuthorizedSigningOfficer: this.state.contact.isAuthorized,
                PreferredName: this.state.contact.preferredName,
                CreatedBy: userID,
                CreatedOn: date.toISOString(),
                ContactType: this.state.contact.contactType,
                OnlineLanguage: this.state.contact.language,
                PEPPosition: this.state.contact.pepPosition,
                EmployerName: this.state.contact.employerName,
                AppointmentDate: this.state.contact.appointmentDate,
                InstitutionType: this.state.contact.institutionType,
                Status: this.state.contact.status,
                ExpiryByPassDate: this.state.contact.expiryDate,
                ByPassValidation: this.state.contact.bypassValidation,
                TradeLimitWarningOrEnforce: this.state.contact.tLWE,
                DailyTradeLimitWarningOrEnforce: this.state.contact.dTLWE,
                UpdateToken: this.state.contact.updateToken,
                Name: this.state.contact.middleName,
                FirstName: this.state.contact.firstName,
                LastName: this.state.contact.lastName,
                Salutation: this.state.contact.salutation
            }
            this.props.getContactInfo(model);
            this.props.returnButtonState();
        }
    }*/

    toggleShowRiskCalculation() {
        this.setState({ showRiskCalculation: !this.state.showRiskCalculation });
    }

    handleOnCheckMainContact(event) {
        /*let mainContact = event.target.checked;
        let selectedBeneficiaryID = this.state.selectedBeneficiaryID;
        if(!mainContact){
            selectedBeneficiaryID = 0;            
        }*/
        this.setState({
            contact: { ...this.state.contact, isMainContact: event.target.checked }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleOnCheckAuthorized(event) {
        this.setState({
            contact: { ...this.state.contact, isAuthorized: event.target.checked }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleOnCheckBypassValidation(event) {
        this.setState({
            contact: { ...this.state.contact, bypassValidation: event.target.checked }
        }, () => this.props.getContactInfo(this.state.contact))
    }

    handleRadioPEPChange(event) {
        console.group('HandleRadioPEPChange');
        console.log(event.target.value);
        console.groupEnd();
        this.setState({
            contact: { ...this.state.contact, PEP: event.target.value }
        }, () => this.props.getContactInfo(this.state.contact));
    }

    getContactTypeSelect(value) {
        this.setState({ contact: { ...this.state.contact, contactType: value.LookUpTableDetail } },
            () => this.props.getContactInfo(this.state.contact));
    }

    getStatusSelect(value) {
        if (value.value !== undefined) {
            this.setState({
                contact: { ...this.state.contact, status: value.LookUpTableDetail },
                validations: { ...this.state.validations, status: true }
            },
                () => {
                    if (this.validateRequiredInputs()) {
                        this.props.getContactInfo(this.state.contact);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid(this.validateRequiredInputs());
                    }
                });
        } else {
            this.setState({ contact: { ...this.state.contact, status: '' } },
                () => this.props.getContactInfo(this.state.contact));
        }
    }

    getSalutationSelect(value) {
        this.setState({ contact: { ...this.state.contact, salutation: value.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    getLanguageSelect(value) {
        if (value.value !== undefined) {
            this.setState({
                contact: { ...this.state.contact, language: value.LookUpTableDetail },
                validations: { ...this.state.validations, language: true }
            }, () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
        } else {
            this.setState({ contact: { ...this.state.contact, language: '' }, },
                () => this.props.getContactInfo(this.state.contact));
        }
    }

    handleUpdateFirstName(value) {
        this.setState({
            contact: { ...this.state.contact, firstName: value.target.value },
            validations: { ...this.state.validations, firstName: true }
        },
            () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
    }

    handleUpdateMiddleName(value) {
        this.setState({ contact: { ...this.state.contact, middleName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateLastName(value) {
        this.setState({
            contact: { ...this.state.contact, lastName: value.target.value },
            validations: { ...this.state.validations, lastName: true }
        },
            () => {
                if (this.validateRequiredInputs()) {
                    this.props.getContactInfo(this.state.contact);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid(this.validateRequiredInputs());
                }
            });
    }

    handleUpdatePhoneNumber(value) {
        this.setState({ contact: { ...this.state.contact, phoneNumber: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateExtension(value) {
        this.setState({ contact: { ...this.state.contact, extension: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateCellNumber(value) {
        this.setState({ contact: { ...this.state.contact, cellNumber: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePosition1(value) {
        this.setState({ contact: { ...this.state.contact, position1: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateTradeLimit(value) {
        this.setState({ contact: { ...this.state.contact, tradeLimit: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateTLWE(value) {
        this.setState({ contact: { ...this.state.contact, tLWE: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateDailyTradeLimit(value) {
        this.setState({ contact: { ...this.state.contact, dailyTradeLimit: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateDTLWE(value) {
        this.setState({ contact: { ...this.state.contact, dTLWE: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateDateOfBirth(date) {
        this.setState({ contact: { ...this.state.contact, dateOfBirth: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePreferredName(value) {
        this.setState({ contact: { ...this.state.contact, preferredName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateEmployerName(value) {
        this.setState({ contact: { ...this.state.contact, employerName: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdatePEPPosition(value) {
        this.setState({ contact: { ...this.state.contact, pepPosition: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateInstitutionType(value) {
        this.setState({ contact: { ...this.state.contact, institutionType: value.target.value } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleUpdateAppointmentDate(date) {
        this.setState({ contact: { ...this.state.contact, appointmentDate: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    handleExpiryBypassDate(date) {
        this.setState({ contact: { ...this.state.contact, expiryDate: formatDate(date) } }, () => this.props.getContactInfo(this.state.contact));
    }

    formatValidDatePicker(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }
    validateRequiredFields() {
        const fields = [
            { displayName: 'First Name', validateName: 'firstName', value: this.state.contact.firstName, type: 'string' },
            { displayName: 'Last Name', validateName: 'lastName', value: this.state.contact.lastName, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.contact.status, type: 'string' },
            { displayName: 'Language', validateName: 'language', value: this.state.contact.language, type: 'string' }
        ]
        const [valid, validations] = validate(fields);

        this.setState({ validations: validations });
        return valid;
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'First Name', validateName: 'firstName', value: this.state.contact.firstName, type: 'string' },
            { displayName: 'Last Name', validateName: 'lastName', value: this.state.contact.lastName, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.contact.status, type: 'string' },
            { displayName: 'Language', validateName: 'language', value: this.state.contact.language, type: 'string' }
        ]

        const [valid, validations, errors] = validate(fields);

        return { valid, errors };
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    render() {
        var section = (
            <div className="uk-grid uk-margin">
                <div className="uk-width-1 uk-form-stacked">
                    <label className="uk-form-label" >Position</label>
                    <div className="uk-margin" style={style.inputStyle}>
                        <input className="uk-input" disabled={true} id="pepPosition" type="text" placeholder="" value={this.state.contact.pepPosition} onChange={this.handleUpdatePEPPosition.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1 uk-form-stacked">
                    <label className="uk-form-label" >Institution Type</label>
                    <div className="uk-margin" style={style.inputStyle}>
                        <input className="uk-input" disabled={true} id="institutionType" type="text" placeholder="" value={this.state.contact.institutionType} onChange={this.handleUpdateInstitutionType.bind(this)} />
                    </div>
                </div>
                <div className="uk-width-1 uk-form-stacked">
                    <label className="uk-form-label" >Appointment Date</label>
                    <div className="uk-margin" style={style.inputStyle}>
                        <input className="uk-input" disabled={true} id="institutionType" type="text" placeholder="" value={this.state.contact.appointmentDate} onDayChange={this.handleUpdateAppointmentDate.bind(this)} />
                    </div>
                </div>
            </div>
        );

        if (this.state.contact.isAuthorized === true) {
            section = (
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1 uk-form-stacked">
                        <label className="uk-form-label" >Position</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <input disabled={true} className="uk-input" id="pepPosition" type="text" placeholder="" value={this.state.contact.pepPosition} onChange={this.handleUpdatePEPPosition.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1 uk-form-stacked">
                        <label className="uk-form-label" >Institution Type</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <input disabled={true} className="uk-input" id="institutionType" type="text" placeholder="" value={this.state.contact.institutionType} onChange={this.handleUpdateInstitutionType.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1 uk-form-stacked">
                        <label className="uk-form-label" >Appointment Date</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DatePicker disabled={true} className="uk-input" value={this.state.contact.appointmentDate} onDayChange={this.handleUpdateAppointmentDate.bind(this)} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <form className="uk-form-horizontal uk-margin-large">
                    <div className="uk-grid uk-margin">
                        {/*<div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Contact Type *</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect id="contactType-list" className={classNameInputType} getValue={this.getContactTypeSelect.bind(this)} listHasPlaceholder={true} data={this.state["Contact Type"]} objValue={{value: this.state.contact.contactType, LookUpTableDetail: this.state.contact.contactType}}/>
                            </div>
                        </div>*/}
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Salutation</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} id="salutation-list" className="uk-select" getValue={this.getSalutationSelect.bind(this)} listHasPlaceholder={true} data={this.state["Salutation"]} objValue={{ value: this.state.contact.salutation, LookUpTableDetail: this.state.contact.salutation }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} id="status-list" className={formatValidSelect(this.state.validations.status)} getValue={this.getStatusSelect.bind(this)} listHasPlaceholder={true} data={this.state["Contact Status "]} objValue={{ value: this.state.contact.status, LookUpTableDetail: this.state.contact.status }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>First Name (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className={formatValidInput(this.state.validations.firstName)} id="firstName" type="text" placeholder="" value={this.state.contact.firstName} onChange={this.handleUpdateFirstName.bind(this)} />
                            </div>
                        </div>
                        {/*<div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Name</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input className="uk-input" disabled={true} id="middleName" type="text" placeholder="" value={this.state.contact.middleName} onChange={this.handleUpdateMiddleName.bind(this)}/>                               
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>*/}
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Last Name (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className={formatValidInput(this.state.validations.lastName)} id="lastName" type="text" placeholder="" value={this.state.contact.lastName} onChange={this.handleUpdateLastName.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Phone Number</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="phoneNumber" type="text" placeholder="" value={this.state.contact.phoneNumber} onChange={this.handleUpdatePhoneNumber.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Extension</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="extension" type="text" placeholder="" value={this.state.contact.extension} onChange={this.handleUpdateExtension.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Cell Number</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="cellNumber" type="text" placeholder="" value={this.state.contact.cellNumber} onChange={this.handleUpdateCellNumber.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Position</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="position1" type="text" placeholder="" value={this.state.contact.position1} onChange={this.handleUpdatePosition1.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" >Trade Limit</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput disabled={true} className="uk-input" placeholder="0" id="tradeLimit" type="Currency" value={this.state.contact.tradeLimit} onChange={this.handleUpdateTradeLimit.bind(this)} />
                                {/*<input className="uk-input" id="tradeLimit" type="text" placeholder="" value={this.state.contact.tradeLimit} onChange={this.handleUpdateTradeLimit.bind(this)}/>*/}
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" > </label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <select disabled={true} className="uk-select" value={this.state.contact.tLWE} onChange={this.handleUpdateTLWE.bind(this)}>
                                    <option value="">Select an item...</option>
                                    <option value="Warning">Warning</option>
                                    <option value="Enforce">Enforce</option>
                                </select>
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" >Daily Trade Limit</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput disabled={true} className="uk-input" placeholder="0" id="dailyTradeLimit" type="Currency" value={this.state.contact.dailyTradeLimit} onChange={this.handleUpdateDailyTradeLimit.bind(this)} />
                                {/*<input className="uk-input" id="dailyTradeLimit" type="text" placeholder="" value={this.state.contact.dailyTradeLimit} onChange={this.handleUpdateDailyTradeLimit.bind(this)}/>*/}
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" > </label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <select disabled={true} className="uk-select" value={this.state.contact.dTLWE} onChange={this.handleUpdateDTLWE.bind(this)}>
                                    <option value="" >Select an item...</option>
                                    <option value="Warning">Warning</option>
                                    <option value="Enforce">Enforce</option>
                                </select>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Date of Birth</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker disabled={true} className="uk-input" value={this.state.contact.dateOfBirth} onDayChange={this.handleUpdateDateOfBirth.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Preferred Name</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="preferredName" type="text" placeholder="" value={this.state.contact.preferredName} onChange={this.handleUpdatePreferredName.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Language (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect disabled={true} id="language-list" className={formatValidSelect(this.state.validations.language)} getValue={this.getLanguageSelect.bind(this)} listHasPlaceholder={true} data={this.state["Languages"]} objValue={{ value: this.state.contact.language, LookUpTableDetail: this.state.contact.language }} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Employer Name</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <input disabled={true} className="uk-input" id="employerName" type="text" placeholder="" value={this.state.contact.employerName} onChange={this.handleUpdateEmployerName.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                            {/*<div className="uk-form-controls uk-margin" style={style.inputStyle}></div>*/}

                            <label disabled={true} className="uk-form-label" ><input disabled={true} className="uk-checkbox" type="checkbox" checked={this.state.contact.isMainContact} value={this.state.contact.isMainContact} onChange={this.handleOnCheckMainContact.bind(this)} /> Main Contact</label>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                            <label className="uk-form-label">Politically Exposed Person</label>
                            <label disabled={true} className="uk-margin-right"><input disabled={true} value={false} className="uk-radio" type="radio" name="radioPEP" onChange={this.handleRadioPEPChange.bind(this)} checked={this.state.contact.PEP === false} /> No  </label>
                            <label disabled={true} className=""><input disabled={true} value={true} className="uk-radio" type="radio" name="radioPEP" onChange={this.handleRadioPEPChange.bind(this)} checked={this.state.contact.PEP === true} /> Yes</label>
                        </div>
                        {/*<div className="uk-width-1"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>*/}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                            {/*<div className="uk-form-controls uk-margin" style={style.inputStyle}>                                
                                
                            </div>*/}
                            <input disabled={true} className="uk-checkbox" type="checkbox" checked={this.state.contact.isAuthorized} value={this.state.contact.isAuthorized} onChange={this.handleOnCheckAuthorized.bind(this)} />
                            <label disabled={true} className="uk-form-label" style={{ float: "none" }}> Authorized Signing Officer Contact</label>
                        </div>
                        {section}
                        <div className="uk-width-1-2 uk-form-stacked uk-margin">
                            {/*<div className="uk-margin" style={style.inputStyle}>                                
                                
                            </div>*/}
                            <label disabled={true} className="uk-form-label" ><input disabled={true} className="uk-checkbox" type="checkbox" checked={this.state.contact.bypassValidation} value={this.state.contact.bypassValidation} onChange={this.handleOnCheckBypassValidation.bind(this)} /> Bypass Validation</label>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >Expiry Bypass Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker disabled={true} className="uk-input" value={this.state.contact.expiryDate} onDayChange={this.handleExpiryBypassDate.bind(this)} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Risk Score (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <label disabled={true} onClick={this.toggleShowRiskCalculation.bind(this)} className="" style={{ color: this.state.riskScore.Color }}>{this.state.riskScore.Description}</label>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-width-1">
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails} open={this.state.showRiskCalculation} close={this.toggleShowRiskCalculation.bind(this)} />
            </div>
        );
    }

}