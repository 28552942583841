import React from 'react';
import { searchUserReferredByPerson, getCustomerByID, updateCustomer, getRiskScoreRanges, getRiskScoreDetails, getScreenInformation, searchCustomerByIdNameMin, getCustomerRiskScore } from '../../../../networking/NetworkingCustomers';
import { getLookUpTable } from '../../../../networking/Networking';
import DynamicSelect from '../../../shared/DynamicSelect';
import {getStyle, regularInputClass, regularSelectClass, notFilledSelectClass, notFilledInputClass} from '../../../../styles/styles';
import DatePicker from '../../../shared/DatePicker';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import AlertBox from '../../../shared/AlertBox';
import { formatDate, formatValidDatePicker, formatValidSelect, formatValidInput } from '../../../../helpers/FormatHelper';
import NumberInput from '../../../shared/NumberInput';
import AuditCustomerScreen from '../auditEntity/AuditCustomerScreen';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';
import { validate } from '../../../../helpers/ValidateHelper';
import { getCustomerContactsMin } from '../../../../networking/NetworkingContacts';
var style = getStyle();

export default class CustomerInformationScreen extends React.Component {
    state = {
        customer: {
            AffiliateCommission: '',
            AffiliateReferredBy: '',
            AffiliateReferredByName: '',
            BranchDescription: '',
            BypassValidation: '',
            CommissionBase: '',
            CommissionCurrency: '',
            CommissionCurrencyDescription: '',
            CommissionPercentage: '',
            CustomerCode: '',
            CustomerGroupID: '',
            CustomerID: '',
            CustomerType: '',
            DefaultBranchDescription: '',
            DefaultBranchID: '',
            DefaultPricingDescription: '',
            DefaultPricingID: '',
            EmailTemplate: '',
            ExpiryByPassDate: '',
            FeeDescription: '',
            FeeID: '',
            FirstName: '',
            IndustrySector: '',
            LastName: '',
            Name: '',
            OfficeFax: '',
            OnlineFeeCategoryDescription: '',
            OnlineFeeCategoryID: '',
            ParentCustomerID: '',
            ParentCustomerName: '',
            PersonID: '',
            Phone: '',
            PhoneExt: '',
            PreferredLanguage: '',
            PreferredName: '',
            ReferredBy: '',
            ReferredByDescription: '',
            Region: '',
            ReportTemplate: '',
            Salutation: '',
            ServiceLevel: '',
            ShortName: '',
            SpreadPoints: '',
            Status: '',
            TraderID: '',
            UpdatedBy: '',
            UpdateToken: ''
        },
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        viewHistory: false,
        showRiskCalculation: false,
        riskScoreDetails: [],
        referredBySelect: [],
        branches: [],
        feeStructures: [],
        feeCategories: [],
        ratePricingCategories: [],
        contacts: [],
        listParents: [],
        listAffiliates: [],
        currencies: [],
        "BankInterfaces": [],
        "Commission Base": [],
        "Confirmation Type": [],
        "Customer Groups": [],
        "Customer Regions": [],
        "Agent Customer Status": [],
        "Customer Type": [],
        "Deal Status": [],
        "Emails Templates": [],
        "Fee Types": [],
        "Incoming Instruments": [],
        "Industry Sectors": [],
        "Languages": [],
        "Reports Templates": [],
        "Salutation": [],
        "Service Level": [],
        "Spread Points": [],
        lookUpTables: [
            "BankInterfaces",
            "Commission Base",
            "Confirmation Type",
            "Customer Groups",
            "Customer Regions",
            "Agent Customer Status",
            "Customer Type",
            "Deal Status",
            "Emails Templates",
            "Fee Types",
            "Incoming Instruments",
            "Industry Sectors",
            "Languages",
            "Reports Templates",
            "Salutation",
            "Service Level",
            "Spread Points"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        },
        validations: {
            customerType: true,
            status: true,
            companyName: true,
            firstName: true,
            lastName: true,
            preferredLanguage: true,
            expiryBypassDate: true,
            commissionCurrency: true,
            reportTemplate: true,
            emailTemplate: true
        },
        hideGeneralInformation: false,
        hideContactInformation: true,
        hidePricingReferrals: true,
        hideOtherInformation: true
    }

    componentDidMount() {
        var propsCustomer = this.props.data;
        this.setState({customer: {...this.state.customer, ...propsCustomer}});
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        this.setState({[this.state.lookUpTables[i]]: table});
                    }
                }
        });
        searchUserReferredByPerson("_").then(
            (json) => {
                var select = json.users;
                for (var i = 0; i < select.length; i++) {
                    select[i].value = select[i].PersonID;
                    select[i].name = select[i].Name;
                }
                this.setState({referredBySelect: select});
            }
        );
        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreDetails(this.props.data.CustomerID).then(
                    (details) => {
                        if (details !== undefined) {
                            getCustomerRiskScore(this.props.data.CustomerID).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        riskScore.Color = riskScore.Color.substring(0, 1) + riskScore.Color.substring(3);
                                        this.setState({
                                            riskScore: riskScore,
                                            riskScoreDetails: details.riskScoreDetails
                                        });
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
        getScreenInformation(sessionStorage.getItem('AgentCustomerID')).then(
            (json) => {
                let format = (list, property1, property2) => {
                    var helper = [];
                    list.forEach(row => helper.push({value: row[property1], name: row[property2]}));
                    return helper;
                }
                this.setState({
                    branches: format(json.branches, "BranchID", "Description"),
                    feeStructures: format(json.feeStructures, "FeeStructureID", "Description"),
                    feeCategories: format(json.feeCategories, "FeeCategoryID", "CategoryName"),
                    ratePricingCategories: format(json.ratePricingCategories, "CategoryID", "CategoryName")
                });
            }
        );
        getCustomerContactsMin(sessionStorage.getItem('AgentCustomerID')).then(
            (json) => {
                if (json !== undefined && json.contacts.length > 0) {
                    const contacts = json.contacts.map(c => ({...c, name: c.Name, value: c.ContactID}))
                    this.setState({ contacts });
                }
            }
        );
    }

    searchCurrency = (query) => {
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    if( json !== undefined) {
                        for(var i = 0; i < json.length; i++) {
                            helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                        }
                        if (json.length > 1) {
                            this.setState({currencies: helper});
                        } else {
                            this.setState({currencies: helper, customer: {...this.state.customer, CommissionCurrencyDescription: helper[0].name}});
                        }
                    } else {
                        this.setState({currencies: helper});
                    }
                }
            }
        );
    }

    searchParent = (query) => {
        console.group("searchParent");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for(var i=0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({listParents: response.customers});
                }
            }
        )
    }

    searchAffiliate = (query) => {
        console.group("searchAffiliate");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for(var i=0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({listAffiliates: response.customers});
                }
            }
        )
    }

    validateRequiredFields = () => {
        let fields = [
            {displayName: 'Customer Type', validateName: 'customerType', value: this.state.customer.CustomerType, type: 'string'},
            {displayName: 'Status', validateName: 'status', value: this.state.customer.Status , type: 'string'},
            {displayName: 'Preferred Language', validateName: 'preferredLanguage', value: this.state.customer.PreferredLanguage, type: 'string'},
            {displayName: 'Expiry Bypass Date', validateName: 'expiryBypassDate', value: this.state.customer.ExpiryByPassDate, type: 'string'},
            {displayName: 'Confirmation Template', validateName: 'reportTemplate', value: this.state.customer.ReportTemplate, type: 'string'},
            {displayName: 'Email Template', validateName: 'emailTemplate', value: this.state.customer.EmailTemplate, type: 'string'},
            {displayName: 'Commission Currency', validateName: 'commissionCurrency', value: this.state.customer.CommissionCurrency, type: 'string'},
        ]

        if (this.state.customer.CustomerType.includes("Individual")) {
            fields.push({displayName: 'First Name', validateName: 'firstName', value: this.state.customer.FirstName, type: 'string'});
            fields.push({displayName: 'Last Name', validateName: 'lastName', value: this.state.customer.LastName, type: 'string'});
        } else {
            fields.push({displayName: 'Company Name', validateName: 'companyName', value: this.state.customer.Name, type: 'string'})
        }

        const [valid, validations, errors] = validate(fields);

        this.setState({validations: {...this.state.validations, ...validations}});

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    handleUpdateCustomerClick = (event) => {
        event.preventDefault();
        if (this.validateRequiredFields()) {
            let model = {
                CustomerID: this.state.customer.CustomerID,
                Name: this.state.customer.Name,
                ShortName: this.state.customer.ShortName,
                Status: this.state.customer.Status,
                Phone: this.state.customer.Phone === null ? '' : this.state.customer.Phone,
                CustomerCode: this.state.customer.CustomerCode,
                CustomerType: this.state.customer.CustomerType,
                Salutation: this.state.customer.Salutation,
                PreferredName: this.state.customer.PreferredName === null ? '' : this.state.customer.PreferredName,
                DefaultPricingID: this.state.customer.DefaultPricingID,
                SpreadPoints: this.state.customer.SpreadPoints,
                DefaultBranchID: this.state.customer.DefaultBranchID,
                Region: this.state.customer.Region,
                CustomerGroupID: this.state.customer.CustomerGroupID,
                ParentCustomerId: this.state.customer.ParentCustomerID,
                IndustrySector: this.state.customer.IndustrySector,
                CommissionBase: this.state.customer.CommissionBase,
                PersonID: this.state.customer.PersonID,
                ReferredBy: this.state.customer.ReferredBy,
                AffiliateReferredBy: sessionStorage.getItem('AgentCustomerID'),
                CommissionPercentage: this.state.customer.CommissionPercentage,
                BypassValidation: this.state.customer.BypassValidation,
                ExpiryByPassDate: this.state.customer.ExpiryByPassDate,
                PreferredLanguage: this.state.customer.PreferredLanguage,
                OnlineFeeCategoryID: this.state.customer.OnlineFeeCategoryID,
                FeeID: this.state.customer.FeeID,
                CommissionCurrency: this.state.customer.CommissionCurrency,
                EmailTemplate: this.state.customer.EmailTemplate,
                ReportTemplate: this.state.customer.ReportTemplate,
                ServiceLevel: this.state.customer.ServiceLevel,
                FirstName: this.state.customer.FirstName,
                LastName: this.state.customer.LastName,
                OfficeFax: this.state.customer.OfficeFax === null ? '' : this.state.customer.OfficeFax,
                PhoneExt: this.state.customer.PhoneExt === null ? '' : this.state.customer.PhoneExt,
                UpdatedBy: sessionStorage.getItem('UserID'),
                UpdateToken: this.state.customer.UpdateToken
            }
            console.group("UpdateRequest");
            console.log(model);
            console.groupEnd();
            updateCustomer(model).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        console.log(json.Message);
                        this.setState({
                            showAlert: true, alertMessage: "An error occurred while trying to save the customer. Please try again.", alertTitle: "Error"
                        });
                    }
                    else {
                        this.setState({
                            customer: {
                                ...this.state.customer, UpdateToken: json.updateToken
                            },
                            showAlert: true, alertTitle: 'Customer successfully saved.'
                        });
                    }
                }
            )
        }
    }

    formatVisibilityIndividual = (customerType) => {
        return customerType.includes("Individual") ? "visible" : "hidden";
    }

    formatVisibilityCorporate = (customerType) => {
        return !customerType.includes("Individual") ? "visible" : "hidden";
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    handleReturnToCustomerInfo = () => {
        this.setState({ viewHistory: false});
    }

    handleViewHistoryClick = () => {
        this.setState({ viewHistory: true});
    }

    handleHideGeneralInformationClick = () => {
        this.setState({
            hideGeneralInformation: !this.state.hideGeneralInformation
        })
    }

    handleHideContactInformationClick = () => {
        this.setState({
            hideContactInformation: !this.state.hideContactInformation
        })
    }

    handleHidePricingReferralsClick = () => {
        this.setState({
            hidePricingReferrals: !this.state.hidePricingReferrals
        })
    }

    handleHideOtherInformationClick = () => {
        this.setState({
            hideOtherInformation: !this.state.hideOtherInformation
        })
    }

    toggleShowRiskCalculation = () => {
        this.setState({showRiskCalculation: !this.state.showRiskCalculation});
    }
    
    handleGetValueCustomerStatus = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, Status: value.value},
                validations: {...this.state.validations, status: true}
            });
        }
    }

    handleGetValueSpreadPoints = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, SpreadPoints: value.value}});
        }
    }

    handleGetValueServiceLevel = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, ServiceLevel: value.value}});
        }
    }

    handleGetValueEmailTemplate = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, EmailTemplate: value.value},
                validations: {...this.state.validations, emailTemplate: true}
            });
        }
    }

    handleGetValueCustomerType = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, CustomerType: value.value},
                validations: {...this.state.validations, customerType: true}
            });
        }
    }
    handleGetValueSalutation = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, Salutation: value.value}
            });
        }
    }

    handleGetValueRegion = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, Region: value.value}});
        }
    }

    handleGetValueGroups = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, CustomerGroupID: value.value}});
        }
    }

    handleGetValueIndustrySector = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, IndustrySector: value.value}});
        }
    }

    handleGetValueLanguages = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, PreferredLanguage: value.value},
                validations: {...this.state.validations, preferredLanguage: true}
            });
        }
    }

    handleGetValueReportTemplate = (value) => {
        if (value.value !== undefined){
            this.setState({
                customer: {...this.state.customer, ReportTemplate: value.value},
                validations: {...this.state.validations, reportTemplate: true}
            });
        }
    }

    handleGetValueCommissionBase = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, CommissionBase: value.value}});
        }
    }

    handleGetValueReferredBy = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, ReferredBy: value.value}});
        }
    }

    handleGetValueFeeCategories = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, OnlineFeeCategoryID: value.value}});
        }
    }

    handleGetValueFeeID = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, FeeID: value.value}});
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, DefaultBranchID: value.value}});
        }
    }

    handleGetValuePricing = (value) => {
        if (value.value !== undefined) {
            this.setState({customer: {...this.state.customer, DefaultPricingID: value.value}});
        }
    }
    
    handleGetValueParent = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            this.setState({customer: {...this.state.customer, ParentCustomerID: value.value, ParentCustomerName: value.name}});
        }
    }

    handleGetValueAffiliateReferredBy = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            this.setState({customer: {...this.state.customer, AffiliateReferredBy: value.value, AffiliateReferredByName: value.name}});
        }
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setState({
                customer: {...this.state.customer, CommissionCurrency: value.value},
                validations: {...this.state.validations, commissionCurrency: true}
            });
        }
    }

    handleUpdateName = (event) => {
        this.setState({
            customer: {...this.state.customer, Name: event.target.value},
            validations: {...this.state.validations, companyName: true}
        });
    }

    handleUpdateFirstName = (event) => {
        this.setState({
            customer: {...this.state.customer, FirstName: event.target.value},
            validations: {...this.state.validations, firstName: true}
        });
    }

    handleUpdateLastName = (event) => {
        this.setState({
            customer: {...this.state.customer, LastName: event.target.value},
            validations: {...this.state.validations, lastName: true}
        });
    }

    handleUpdateShortName = (event) => {
        this.setState({customer: {...this.state.customer, ShortName: event.target.value}});
    }

    handleUpdatePhone = (event) => {
        this.setState({customer: {...this.state.customer, Phone: event.target.value}});
    }

    handleUpdateParentCustomerName = (event) => {
        this.setState({customer: {...this.state.customer, ParentCustomerName: event.target.value}});
    }

    handleUpdatePreferredName = (event) => {
        this.setState({customer: {...this.state.customer, PreferredName: event.target.value}});
    }

    handleUpdatePhoneExt = (event) => {
        this.setState({customer: {...this.state.customer, PhoneExt: event.target.value}});
    }

    handleUpdateOfficeFax = (event) => {
        this.setState({customer: {...this.state.customer, OfficeFax: event.target.value}});
    }

    handleUpdateExpiryByPassDate = (date) => {
        this.setState({
            customer: {...this.state.customer, ExpiryByPassDate: formatDate(date)},
            validations: {...this.state.validations, expiryBypassDate: true}
        });
    }

    handleUpdateByPassValidation = (event) => {
        this.setState({customer: {...this.state.customer, BypassValidation: event.target.checked}})
    }

    handleUpdateAffiliateCommission = (event) => {
        this.setState({customer: {...this.state.customer, CommissionPercentage: event.target.value}});
    }

    handleSectionIcon = (hidden) => {
        return hidden ?  "fa-sort-desc" : "fa-sort-asc";
    }

    handleSectionIconStyle = (hidden) => {
        return hidden ? {verticalAlign: 'middle', position: "absolute"} : {paddingTop: "10px", verticalAlign: 'middle', position: "absolute"};
    }

    render() {
        if (this.state.viewHistory) {
            return (<AuditCustomerScreen backLink={this.handleReturnToCustomerInfo} customerID={this.props.id} />);
        }

        return (
            <div>
                <div className="">
                    <ul id="accordion-compliance" uk-accordion="multiple: true">
                        {/*General Information*/}
                        <li className="uk-open">
                            <button className="uk-accordion-title">General Information</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}} >
                                <div id="general-information">
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel} >Type (*)</label>
                                            <div className="" style={style.inputStyle} >
                                                <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.customerType)} objValue={{value: this.state.customer.CustomerType}} getValue={this.handleGetValueCustomerType} listHasPlaceholder={true} data={this.state["Customer Type"]} id="form-customer-type" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                {
                                                    this.state.customer.Status === 'Pending' ?
                                                    <p style={{marginBottom: '0px', marginTop: '2px'}}>{this.state.customer.Status}</p>
                                                    :
                                                    <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.status)} objValue={{value: this.state.customer.Status}} getValue={this.handleGetValueCustomerStatus} listHasPlaceholder={true} data={this.state["Agent Customer Status"]} id="form-customer-status" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Parent</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSearchSelect disabled={true} id="parent-list" placeholder="Parent" searchFunction={this.searchParent} getValue={this.handleGetValueParent} data={this.state.listParents} objValue={{value: this.state.customer.ParentCustomerID, name: this.state.customer.ParentCustomerName}}/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                            <label className="uk-form-label" >Salutation</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.Salutation === null ? []: this.state.customer.Salutation}} getValue={this.handleGetValueSalutation} listHasPlaceholder={true} data={this.state["Salutation"]} id="form-salutation" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                            <label className="uk-form-label" style={style.boldLabel}>First Name (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className={formatValidInput(this.state.validations.firstName)} type="text" placeholder="" value={this.state.customer.FirstName} onChange={this.handleUpdateFirstName} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2" style={{visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType)}}>
                                            <label className="uk-form-label" style={style.boldLabel}>Last Name (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className={formatValidInput(this.state.validations.lastName)} type="text" placeholder="" value={this.state.customer.LastName} onChange={this.handleUpdateLastName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2" style={{visibility: this.formatVisibilityCorporate(this.state.customer.CustomerType)}}>
                                            <label className="uk-form-label" style={style.boldLabel}>Company Name (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className={formatValidInput(this.state.validations.companyName)}  type="text" placeholder="" value={this.state.customer.Name} onChange={this.handleUpdateName} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Preferred Name</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.PreferredName} onChange={this.handleUpdatePreferredName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Short Name</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.ShortName} onChange={this.handleUpdateShortName} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Branch</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.DefaultBranchID}} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.branches} id="form-branch" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Region</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.Region}} getValue={this.handleGetValueRegion} listHasPlaceholder={true} data={this.state["Customer Regions"]} id="form-regions" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Group</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.CustomerGroupID}} getValue={this.handleGetValueGroups} listHasPlaceholder={true} data={this.state["Customer Groups"]} id="form-groups" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        {/*Contact Information*/}
                        <li>
                            <button className="uk-accordion-title">Contact Information</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="contact-information">
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-3">
                                            <label className="uk-form-label">Phone Number</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.Phone} onChange={this.handleUpdatePhone} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3">
                                            <label className="uk-form-label">Extension</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.PhoneExt} onChange={this.handleUpdatePhoneExt} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3">
                                            <label className="uk-form-label">Office Fax</label>
                                            <div className="" style={style.inputStyle}>
                                                <input disabled={true} className="uk-input" type="text" placeholder="" value={this.state.customer.OfficeFax} onChange={this.handleUpdateOfficeFax} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        {/*Pricing and Referrals*/}
                        <li>
                            <button className="uk-accordion-title">Pricing and Referrals</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="pricing-referrals">
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Spread / Points</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.SpreadPoints}} getValue={this.handleGetValueSpreadPoints} listHasPlaceholder={true} data={this.state["Spread Points"]} id="form-spread-points" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Service Level</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.ServiceLevel}} getValue={this.handleGetValueServiceLevel} listHasPlaceholder={true} data={this.state["Service Level"]} id="form-service-level" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Pricing</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.DefaultPricingID}} getValue={this.handleGetValuePricing} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.ratePricingCategories} id="form-pricing" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Online Fee Category</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.OnlineFeeCategoryID}} getValue={this.handleGetValueFeeCategories} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.feeCategories} id="form-fee-categories" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Fee Id</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.FeeID}} getValue={this.handleGetValueFeeID} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.feeStructures} id="form-fee-id" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Commission Base</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.CommissionBase}} getValue={this.handleGetValueCommissionBase} listHasPlaceholder={true} data={this.state["Commission Base"]} id="form-commission-base" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Referred By</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.ReferredBy}} getValue={this.handleGetValueReferredBy} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.contacts} id="form-referred-by" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Referred by Affiliate</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSearchSelect disabled={true} id="referred-by-affiliate" placeholder="Referred By Affiliate" searchFunction={this.searchAffiliate} getValue={this.handleGetValueAffiliateReferredBy} data={this.state.listAffiliates} objValue={{value: this.state.customer.AffiliateReferredBy, name: this.state.customer.AffiliateReferredByName}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Affilliate Commission</label>
                                            <div className="" style={style.inputStyle}>
                                                <NumberInput disabled={true} value={this.state.customer.CommissionPercentage} onChange={this.handleUpdateAffiliateCommission} className="uk-input" type="Rate" scale={2} placeholder="" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Commissions Currency (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                {/*<DynamicSelect className={formatValidSelect(this.state.validations.commissionCurrency)} objValue={{value: this.state.customer.CommissionCurrency}} getValue={this.handleGetValueCurrency} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.currencies} id="form-commission-currencies" />*/}
                                                <DynamicSearchSelect disabled={true} className={formatValidSelect(this.state.validations.commissionCurrency)} id="form-commission-currencies" objValue={{value: this.state.customer.CommissionCurrency,name: this.state.customer.CommissionCurrencyDescription}} placeholder="Select a Currency..." searchFunction={this.searchCurrency} data={this.state.currencies} getValue={this.handleGetValueCurrency}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        {/*Other Information*/}
                        <li>
                            <button className="uk-accordion-title">Other Information</button>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="other-information">
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Preferred Language (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.preferredLanguage)} objValue={{value: this.state.customer.PreferredLanguage}} getValue={this.handleGetValueLanguages} listHasPlaceholder={true} data={this.state["Languages"]} id="form-languages" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Industry Sector</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} objValue={{value: this.state.customer.IndustrySector}} getValue={this.handleGetValueIndustrySector} listHasPlaceholder={true} data={this.state["Industry Sectors"]} id="form-industry-sectors" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Bypass Validation (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <div className="uk-grid">
                                                    <div className="uk-width-1-2">
                                                        <label><input className="uk-checkbox" disabled={true} value={this.state.customer.BypassValidation} checked={this.state.customer.BypassValidation} onChange={this.handleUpdateByPassValidation} type="checkbox" /> Expiry bypass date</label>
                                                    </div>
                                                    <div className="uk-width-1-2">
                                                        <DatePicker disabled={true} className={formatValidDatePicker(this.state.validations.expiryBypassDate)} value={this.state.customer.ExpiryByPassDate} onDayChange={this.handleUpdateExpiryByPassDate}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Risk Score (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <label disabled={true} onClick={this.toggleShowRiskCalculation} className="" style={{color: this.state.riskScore.Color}}>{this.state.riskScore.Description}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-grid" style={{marginTop: "0px"}}>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Confirmation Template (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.reportTemplate)} objValue={{value: this.state.customer.ReportTemplate}} getValue={this.handleGetValueReportTemplate} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state["Reports Templates"]} id="form-reports-templates" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" style={style.boldLabel}>Email template (*)</label>
                                            <div className="" style={style.inputStyle}>
                                                <DynamicSelect disabled={true} className={formatValidSelect(this.state.validations.emailTemplate)} objValue={{value: this.state.customer.EmailTemplate}} getValue={this.handleGetValueEmailTemplate} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state["Emails Templates"]} id="form-emails-templates" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div>
                        <button className="uk-button uk-button-green uk-margin-right" disabled={true} onClick={this.handleUpdateCustomerClick} >Save</button>
                        <button className="uk-button uk-button-green uk-margin-right" disabled={true} onClick={this.handleViewHistoryClick}> View History</button>
                    </div>
                    <AlertBox 
                        open={this.state.showAlert}
                        onClose={this.closeAlert}
                        title={this.state.alertTitle}
                        messageBoldList={this.state.errors}
                        message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                    <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails} open={this.state.showRiskCalculation} close={this.toggleShowRiskCalculation} />
                </div>
            </div>
        );
    }
}